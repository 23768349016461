class RenderContext {
  constructor(view, index, row, visible) {
    this.view = view;
    this.index = index;
    this.row = row;
    this.visible = visible;
  }
  /**
   * Calculates the pixels from the row to the left edge of the residue `index`.
   */
  toViewportOffset(index) {
    return this.view.toPixels(index - this.row.start) - this.offset;
  }
  get offset() {
    return this.view.offset.x;
  }
  /**
   * @deprecated Use ChannelView; .residueWidth, .toPixels() or .toResidues().
   */
  get residueWidth() {
    return this.view.residueWidth;
  }
  get width() {
    return this.view.toPixels(this.row.length);
  }
}
export { RenderContext as default };