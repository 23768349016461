import DataCache from "../../../includes/PluginBaseClasses/DataCache.js";
import RowGroup from "./RowGroup.js";
import Annotation from "./Annotation.js";
class AnnotationsCache extends DataCache {
  // Maps annotation ids to annotations.
  data = /* @__PURE__ */new Map();
  // Caches merged annotation rows for quick access in render frames.
  cachedRows = null;
  add(annotation) {
    if (!this.data.has(annotation.id)) {
      this.data.set(annotation.id, annotation);
      this.globals.countNewAnnotation(annotation);
      this.clearCacheAndMaintainFocus();
      this.sv.view.dirty = "annotation created";
      return true;
    }
    return false;
  }
  delete(annotation) {
    if (this.data.has(annotation.id)) {
      const success = this.data.delete(annotation.id);
      if (success) {
        this.globals.countRemovedAnnotation(annotation);
        this.clearCacheAndMaintainFocus();
        this.sv.view.dirty = "annotation deleted";
        return true;
      }
    }
    return false;
  }
  get(id) {
    return this.data.get(id);
  }
  /**
   * Replace all existing annotations with the supplied ones.
   *
   * @param annotations new annotations to populate the cache
   */
  replaceAll(annotations) {
    for (const annotation of this.data.values()) {
      this.globals.countRemovedAnnotation(annotation);
    }
    this.data = /* @__PURE__ */new Map();
    this.processAndStoreNewAnnotations(annotations);
    this.clearCacheAndMaintainFocus();
  }
  set(_, data) {
    const hasNewAnnotations = data.length > 0;
    if (hasNewAnnotations) {
      this.processAndStoreNewAnnotationData(data);
      this.clearCacheAndMaintainFocus();
    }
    return hasNewAnnotations;
  }
  isValid(annotation) {
    return !this.data.has(annotation.id) && annotation.intervals.length !== 0;
  }
  getAll() {
    return Array.from(this.data.values());
  }
  getInRange(range) {
    return this.getAll().filter(annotation => annotation.overlaps(range));
  }
  getAllIntervals() {
    return this.getAll().map(annotation => annotation.intervals).reduce((result, current) => result.concat(current), []);
  }
  getIntervalsByIndex(index) {
    return this.getAllIntervals().filter(interval => interval.range.includes(index));
  }
  getByStartAndRow(residueIndex, rowIndex) {
    const row = this.rows[rowIndex];
    return row?.annotationStartingAt(residueIndex);
  }
  clearCacheAndMaintainFocus() {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.clearCache();
    });
  }
  clearCache() {
    this.cachedRows = null;
    this.sv.emit("data row count changed");
  }
  get rows() {
    return this.sv.view.getCacheableValue(this, "cachedRows", () => {
      const group = this.mergeTypeGroups();
      return Object.freeze(group.rows);
    });
  }
  // Public only for unit testing
  mergeTypeGroups() {
    const groups = this.sortedTypeGroups;
    return this.mergeRelatedRowGroups(groups).sort(this.compareRowGroups).reduce((previous, current) => previous.merge(current), new RowGroup());
  }
  compareRowGroups(a, b) {
    if (a.type === void 0) {
      return 1;
    } else if (b.type === void 0) {
      return -1;
    }
    return b.totalIntervalLength - a.totalIntervalLength || b.meanLength - a.meanLength || a.type.localeCompare(b.type);
  }
  mergeRelatedRowGroups(groups) {
    const result = [];
    const omitList = /* @__PURE__ */new Set();
    for (const current of Object.values(groups)) {
      if (omitList.has(current.type)) {} else {
        const {
          mergedGroup,
          mergedTypes
        } = this.mergeInRelatedAnnotationTypes(groups, current);
        mergedTypes.forEach(mergedType => omitList.add(mergedType));
        result.push(mergedGroup);
      }
    }
    return result;
  }
  get sv() {
    return this.channel.sv;
  }
  mergeInRelatedAnnotationTypes(groups, primary) {
    let group = primary;
    const types = [];
    if (group.length === 1) {
      for (const related of this.getRelatedAnnotationTypeGroups(groups, primary)) {
        if (this.canMergeRelated(group, related)) {
          group = group.merge(related);
          types.push(related.type);
        }
      }
    }
    return {
      mergedGroup: group,
      mergedTypes: types
    };
  }
  canMergeRelated(group, related) {
    return related && related.length === 1 && group.first.canMerge(related.first);
  }
  getRelatedAnnotationTypeGroups(groups, primary) {
    if (primary.type === void 0) {
      return [];
    }
    const types = this.relatedTypes[primary.type] ?? [];
    return types.map(type => groups[type]).filter(group => Boolean(group));
  }
  get relatedTypes() {
    return this.globals.relatedTypes;
  }
  get globals() {
    return this.sv.annotations.data;
  }
  // Public only for unit testing.
  processAndStoreNewAnnotationData(data) {
    const annotations = [];
    for (const annotationData of data) {
      if (annotationData.intervals.length === 0) {
        continue;
      }
      annotations.push(new Annotation(this.channel, annotationData));
    }
    this.processAndStoreNewAnnotations(annotations);
  }
  processAndStoreNewAnnotations(annotations) {
    for (const annotation of annotations) {
      if (this.data.has(annotation.id)) {
        this.globals.countRemovedAnnotation(this.data.get(annotation.id));
      }
      this.globals.countNewAnnotation(annotation);
      this.data.set(annotation.id, annotation);
    }
    this.channel.wrapper.recalculateTrims();
  }
  get sortedTypeGroups() {
    const groups = this.typeGroups;
    Object.values(groups).forEach(group => {
      group.sortRows();
    });
    return groups;
  }
  get typeGroups() {
    const result = {};
    this.typeNames.forEach(name => {
      result[name] = new RowGroup(name);
    });
    this.annotations.sort((a, b) => a.start - b.start).forEach(annotation => {
      const name = annotation.normalType;
      if (result[name]) {
        result[name].addAnnotation(annotation);
      }
    });
    return result;
  }
  get typeNames() {
    const result = /* @__PURE__ */new Set();
    this.annotations.forEach(annotation => {
      const name = annotation.normalType;
      if (this.globals.types[name].visible) {
        result.add(name);
      }
    });
    return result;
  }
  get annotations() {
    return [...this.data.values()];
  }
}
export { AnnotationsCache as default };