import { adjustRange } from "../../includes/misc/DPIRatio.js";
import { centerVertically } from "../../includes/misc/Utils.js";
import { CircularGraphicsTransformer } from "../../includes/RenderEngine/Graphics/CircularGraphicsTransformer.js";
const {
  floor,
  ceil
} = Math;
class ResidueStamp {
  constructor(cache, soft, residue) {
    this.cache = cache;
    const ratio = window.devicePixelRatio;
    this.dimensions = {
      width: ceil(soft.width * ratio),
      height: floor(soft.height * ratio)
    };
    this.canvas.width = this.dimensions.width;
    this.canvas.height = this.dimensions.height;
    if (residue.marginColor) {
      this.cutRectangle(residue.marginColor, this.dimensions);
    }
    this.setBackground(residue.background);
    if (cache.painter.printLetter) {
      this.cutLetter(residue);
      if (residue.isTrimmed) {
        this.cutStrikeThrough(residue.foreground, 1);
      }
    } else if (residue.letter === "-" && !residue.isTrimmed) {
      this.cutStrikeThrough(residue.foreground, 0);
    }
  }
  dimensions;
  canvas = document.createElement("canvas");
  brush = this.canvas.getContext("2d");
  get graphics() {
    return this.cache.painter.graphics;
  }
  pressOnto(destination, offset) {
    const {
      width,
      height
    } = this.dimensions;
    const {
      start,
      length
    } = adjustRange(offset, width);
    const ratio = window.devicePixelRatio;
    if (this.graphics instanceof CircularGraphicsTransformer) {
      const absolutePoint = this.graphics.mapPointsToAbsolute(destination, [{
        x: start,
        y: 0
      }])[0];
      const circularCoordinate = this.graphics.transformPoint(absolutePoint);
      const angle = this.graphics.getAngleFraction(absolutePoint.x) * Math.PI * 2;
      this.graphics.withInitialTransform(destination, () => {
        destination.translate(circularCoordinate.x, circularCoordinate.y);
        destination.rotate(angle);
        destination.drawImage(this.canvas, 0, 0, length, height, 0, 0, length / ratio, height / ratio);
      });
    } else {
      destination.drawImage(this.canvas, 0, 0, length, height, start, 0, length / ratio, height / ratio);
    }
  }
  cutRectangle(color, {
    x = 0,
    y = 0,
    width,
    height
  }) {
    this.brush.fillStyle = color;
    this.brush.fillRect(x, y, width, height);
  }
  setBackground(colors) {
    const stampHeight = this.cache.painter.height * window.devicePixelRatio;
    const center = centerVertically(stampHeight, this.dimensions);
    const height = center.height / colors.length;
    colors.forEach((color, index) => {
      const {
        start,
        length
      } = adjustRange(center.y + index * height, height);
      this.cutRectangle(color, {
        x: 0,
        y: start,
        width: this.dimensions.width,
        height: length
      });
    });
  }
  cutLetter({
    letter,
    foreground
  }) {
    const {
      width,
      height
    } = this.dimensions;
    const pen = this.cache.pen;
    pen.stretchFor(letter);
    this.brush.fillStyle = foreground;
    pen.writeWithoutTransform(this.brush, letter, width / 2, height / 2);
  }
  cutStrikeThrough(color, offCenter) {
    const line = centerVertically(1, this.dimensions);
    line.y += offCenter;
    this.cutRectangle(color, line);
  }
}
export { ResidueStamp as default };