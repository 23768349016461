import Scrollbar from "./Scrollbar.js";
class VerticalScrollbar extends Scrollbar {
  get configuration() {
    return {
      direction: "vertical",
      length: "height",
      offset: "top",
      axis: "y"
    };
  }
}
export { VerticalScrollbar as default };