import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import WuKabatCache from "./WuKabatCache.js";
import GraphChannel from "../../includes/PluginBaseClasses/GraphChannel.js";
class WuKabatChannel extends GraphChannel {
  constructor(wrapper, sequenceType, sequences) {
    super(wrapper);
    this.sequenceType = sequenceType;
    this.cache = new WuKabatCache(this, sequences);
  }
  label = {
    text: "Wu-Kabat variability"
  };
  margin = 4;
  cache;
  calculateLayout(context, bounds) {
    const node = new RenderNode("wuKabat", this, bounds);
    node.setRenderCallback(brush => this.render(brush, context));
    return node;
  }
  render(brush, context) {
    const max = this.cache.maxPossibleScore;
    this.wrapper.view.graphPainter.render(this, context, max);
  }
  get plugin() {
    return this.view.sv.wuKabat;
  }
  get visible() {
    return this.plugin.enabled;
  }
  get height() {
    return this.plugin.height;
  }
  histogramColor() {
    return "#efdcf5";
  }
}
export { WuKabatChannel as default };