const {
  floor,
  imul
} = Math;
class Matrix {
  constructor(dimensions) {
    this.dimensions = dimensions;
    const names = /* @__PURE__ */new Set();
    for (const {
      name,
      size
    } of dimensions) {
      if (!Number.isInteger(size) || size <= 0) {
        throw new Error("Size must be an integer greater than 0.");
      }
      if (names.has(name)) {
        throw new Error("Dimensions must have unique names");
      }
      names.add(name);
    }
  }
  pointer = 0;
  get size() {
    return this.multiply(this.sizes);
  }
  get current() {
    const result = {};
    let pointer = this.pointer;
    for (const {
      name,
      size
    } of this.dimensions) {
      result[name] = pointer % size;
      pointer = floor(pointer / size);
    }
    return result;
  }
  set(indexes) {
    this.pointer = this.calculatePointer(indexes);
  }
  reset() {
    this.pointer = 0;
  }
  increment() {
    this.pointer++;
    if (this.pointer >= this.size) {
      this.pointer = 0;
      return false;
    } else {
      return true;
    }
  }
  decrement() {
    this.pointer--;
    if (this.pointer < 0) {
      this.pointer = this.size - 1;
      return false;
    } else {
      return true;
    }
  }
  comparePosition(other) {
    return this.pointer - this.calculatePointer(other);
  }
  calculatePointer(indexes) {
    this.validatePosition(indexes);
    let pointer = 0;
    let factor = 1;
    for (const {
      name,
      size
    } of this.dimensions) {
      pointer += imul(factor, indexes[name]);
      factor = imul(factor, size);
    }
    return pointer;
  }
  validatePosition(indexes) {
    for (const {
      name,
      size
    } of this.dimensions) {
      if (!(name in indexes)) {
        throw new Error("All dimensions must be defined.");
      } else if (indexes[name] >= size) {
        throw new Error("New position indexes must not exceed dimension sizes.");
      }
    }
  }
  get sizes() {
    return this.dimensions.map(({
      size
    }) => size);
  }
  multiply(factors) {
    return factors.reduce((total, current) => imul(total, current), 1);
  }
}
export { Matrix as default };