import ambiguityMappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import { RNAtoDNA } from "../../includes/misc/Utils.js";
import RRange from "../../includes/Range/RRange.js";
class SequenceSearch {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }
  matchSequence(query, index) {
    if (this.wrapper.sequenceCache) {
      const endIndex = this.matchResidue(RNAtoDNA(query.lower), 0, index);
      if (endIndex !== null) {
        this.wrapper.sv.view.focused = null;
        return new RRange(index, endIndex);
      }
    }
  }
  matchResidue(query, queryIndex, index) {
    if (!this.cache?.isSet(index)) {
      return null;
    }
    const residue = this.getResidue(index);
    const queryChar = query.charAt(queryIndex);
    if (residue === "-") {
      if (query[queryIndex] === "-") {
        if (queryIndex === query.length - 1) {
          return index + 1;
        } else {
          return this.matchResidue(query, queryIndex + 1, index + 1);
        }
      } else if (queryIndex > 0) {
        const match = this.matchResidue(query, queryIndex, index + 1);
        if (match !== null || queryChar !== this.mapper?.wildcard.toLowerCase()) {
          return match;
        } else {
          return this.matchResidue(query, queryIndex + 1, index + 1);
        }
      } else {
        return null;
      }
    } else if (query.length === 1) {
      if (residue === queryChar) {
        return index + 1;
      } else {
        return null;
      }
    } else if (this.mapper?.residuesMatch(residue, queryChar)) {
      if (queryIndex === query.length - 1) {
        return index + 1;
      } else {
        return this.matchResidue(query, queryIndex + 1, index + 1);
      }
    } else {
      return null;
    }
  }
  getResidue(index) {
    return this.cache.getAsDNA(index).toLowerCase();
  }
  get cache() {
    return this.wrapper.sequenceCache;
  }
  get mapper() {
    const sequenceType = this.wrapper.sequenceChannel?.sequenceType;
    if (sequenceType) {
      return ambiguityMappers[sequenceType];
    }
  }
}
export { SequenceSearch as default };