import { AnnotationTypes } from "@geneious/shared-constants";
import Color from "tinycolor2";
import { darkenInstance, pseudoRandom, whitenInstance } from "../../includes/misc/Utils.js";
import AnnotationType from "./AnnotationType.js";
import Annotation from "./Cache/Annotation.js";
class GlobalData {
  constructor(sv) {
    this.sv = sv;
    Object.entries(AnnotationTypes.Known).forEach(([name, type]) => {
      const id = Annotation.normalizeType(name);
      this.typeNameMap[id] = name;
      this.colors.named[id] = this.constructColorScheme(type["color"]);
    });
    this.colors.unnamed = AnnotationTypes.UnknownColors.map(color => this.constructColorScheme(color));
    this._relatedTypes.map(group => group.map(Annotation.normalizeType)).forEach(group => group.forEach(type => {
      this.relatedTypes[type] = group.filter(item => item != type);
    }));
  }
  types = {};
  typeNameMap = {};
  // TODO make into 2 variables, knownTypes and unknownColors.
  colors = {
    named: {},
    unnamed: []
  };
  // TODO Move this to shared-constants.
  // @see https://github.com/Geneious/shared-constants/tree/1b182fa385342d41c4f648c3ebc7016f1a25f5cf/constants/AnnotationTypes
  invisibleTypes = ["extracted region", "translated region", "origin", "trimmed"];
  // A convenient lookup table of related annotation types, keyed by type.
  relatedTypes = {};
  // Groups of related annotation types.
  _relatedTypes = AnnotationTypes.Related;
  countNewAnnotation(annotation) {
    const name = annotation.normalType;
    if (!this.types[name]) {
      const type = new AnnotationType(this, annotation.rawType);
      this.types[name] = type;
      this.sv.emit("new annotation type", type);
    }
    this.types[name].increment();
  }
  countRemovedAnnotation(annotation) {
    this.types[annotation.normalType].decrement();
  }
  constructColorScheme(data) {
    const color = Color(data);
    const dark = color.isDark();
    const base = dark ? darkenInstance(color, 10) : color;
    const arrow = dark ? whitenInstance(base, 45) : darkenInstance(base, 40);
    return {
      arrow: arrow.toRgbString(),
      background: base.toRgbString(),
      text: dark ? "white" : "black",
      // Stroke is darker than than background.
      stroke: darkenInstance(base, 25).toRgbString()
    };
  }
  colorFromType(type) {
    type = type.toLowerCase();
    if (type in this.colors.named) {
      return this.colors.named[type];
    } else {
      return pseudoRandom(type, this.colors.unnamed);
    }
  }
}
export { GlobalData as default };