import ResizableSidebarColumn from "../../includes/PluginBaseClasses/ResizableSidebarColumn.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
class MetadataColumnGroup extends ResizableSidebarColumn {
  constructor(plugin) {
    super(plugin.sv.view);
    this.plugin = plugin;
  }
  get nodeType() {
    return "metadata column group";
  }
  get columnWidth() {
    return this.plugin.width;
  }
  get weight() {
    return 10;
  }
  get enabled() {
    return this.plugin.enabled && this.plugin.enabledColumns.length > 0;
  }
  calculateLayout(bounds, channels) {
    const node = new RenderNode(this.nodeType, this, bounds, this.calculateChildren(bounds, channels));
    return this.setColumnRender(bounds, node);
  }
  calculateChildren(bounds, channels) {
    const children = [];
    if (!this.view.sv.isExportMode) {
      children.push(this.renderResizeHandle(bounds.height));
    }
    let offset = 0 - this.plugin.offset;
    this.plugin.enabledColumns.every(col => {
      if (offset < this.columnWidth) {
        const columnBounds = {
          x: offset,
          y: 0,
          width: Math.min(Math.floor(this.columnWidth - offset), col.widthWithMargin),
          height: bounds.height
        };
        offset += col.widthWithMargin;
        if (offset > 0 && columnBounds.width > 0) {
          children.unshift(col.calculateLayout(columnBounds, channels));
        }
        return true;
      }
    });
    return children;
  }
  alterWrapperNode(node) {
    return node;
  }
  mouseMoveOnDocument(event) {
    if (this.adjustingColumnWidth) {
      this.plugin.width = event.pageX - this.view.element.getBoundingClientRect().left - this.offset;
      document.body.style.cursor = "col-resize";
    }
  }
  mouseUpOnDocument() {
    if (this.adjustingColumnWidth) {
      this.adjustingColumnWidth = false;
      document.body.style.cursor = "auto";
    }
  }
}
export { MetadataColumnGroup as default };