import ConsensusChannel from "./ConsensusChannel.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
const CONSENSUS_DISABLED_MESSAGE = "Consensus is disabled because the number of sequences exceeded the maximum number of supported sequences";
class ConsensusPlugin extends AbstractPlugin {
  dataCallback;
  _enabled;
  _threshold;
  _useQualityScores;
  MAX_NUMBER_OF_SEQUENCES_SUPPORTED_FOR_CONSENSUS = 1e3;
  constructor(sv) {
    super(sv);
    sv.consensus = this;
    sv.registerChannelClass("consensus", ConsensusChannel);
    const options = this.getInitialOptions("consensus", {
      enabled: {
        default: sv.options?.alignment.enabled,
        validator: raw => typeof raw === "boolean"
      },
      threshold: {
        default: 0,
        validator: raw => typeof raw === "number"
      },
      useQualityScores: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      dataCallback: {
        default: void 0,
        validator: raw => typeof raw === "function"
      }
    });
    this._enabled = options.enabled;
    this._threshold = options.threshold;
    this._useQualityScores = options.useQualityScores;
    this.dataCallback = options.dataCallback;
    this.sv.bindOnce("idle", () => {
      if (this.enabled) {
        if (!this.ensureAllSequencesAvailable()) {
          console.warn(CONSENSUS_DISABLED_MESSAGE);
          this.enabled = false;
        }
      }
    });
  }
  /**
   * Send data requests for all sequences to ensure all cache is populated. Return false if there are more than 1000 sequences.
   * @private
   */
  ensureAllSequencesAvailable() {
    if (this.sv.channelView.sequences.length > this.MAX_NUMBER_OF_SEQUENCES_SUPPORTED_FOR_CONSENSUS) {
      return false;
    }
    this.sv.channelView.sequences.forEach(sequence => sequence.requestAllData());
    return true;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(value) {
    let enabled = Boolean(value);
    if (enabled && !this.ensureAllSequencesAvailable()) {
      console.warn(CONSENSUS_DISABLED_MESSAGE);
      enabled = false;
    }
    this._enabled = enabled;
    this.markDirty();
    this.sv.emit("channel visibility changed");
  }
  get threshold() {
    return this._threshold;
  }
  set threshold(value) {
    value = Number(value);
    if (0 <= value && value <= 1) {
      this._threshold = value;
      this.markDirty();
    } else {
      throw new Error('"threshold" must be a number between 0 and 1.');
    }
  }
  get useQualityScores() {
    return this._useQualityScores;
  }
  set useQualityScores(value) {
    this._useQualityScores = Boolean(value);
    this.markDirty();
  }
  serialize() {
    return {
      enabled: this.enabled,
      threshold: this.threshold,
      useQualityScores: this.useQualityScores
    };
  }
}
export { ConsensusPlugin as default };