import { GraphicsTransformer } from "./GraphicsTransformer.js";
class LinearGraphicsTransformer extends GraphicsTransformer {
  constructor(sv) {
    super(sv);
  }
  transformPoint(point) {
    return point;
  }
  reverseTransformPoint(point) {
    return point;
  }
  fillRect(brush, x, y, width, height, color) {
    brush.fillStyle = color;
    brush.fillRect(x, y, width, height);
  }
  fillPolygon(brush, points, color) {
    brush.fillStyle = color;
    brush.fill(this.generatePolygonPath(points));
  }
  strokePolygon(brush, points, color, strokeSize) {
    brush.strokeStyle = color;
    brush.lineWidth = strokeSize;
    brush.stroke(this.generatePolygonPath(points));
  }
  strokeRect(brush, x, y, width, height, color, strokeSize) {
    brush.strokeStyle = color;
    brush.lineWidth = strokeSize;
    brush.strokeRect(x, y, width, height);
  }
  fillText(brush, content, x, y) {
    brush.fillText(content, x, y);
  }
  drawLine(brush, pointA, pointB, color, lineWidth) {
    const path = new Path2D();
    path.moveTo(pointA.x, pointA.y);
    path.lineTo(pointB.x, pointB.y);
    brush.lineWidth = lineWidth;
    brush.strokeStyle = color;
    brush.stroke(path);
  }
  getRect(_, x, y, width, height) {
    const path = new Path2D();
    path.rect(x, y, width, height);
    return path;
  }
  generatePolygonPath(points) {
    const path = new Path2D();
    path.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      path.lineTo(points[i].x, points[i].y);
    }
    path.closePath();
    return path;
  }
}
export { LinearGraphicsTransformer };