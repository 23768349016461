import { Injectable } from '@angular/core';
import {
  FullSequenceViewer,
  RRange,
  SequenceData,
  SequenceWrapper,
  FullEvents,
} from '@geneious/sequence-viewer';
import {
  AnnotationsPlugin,
  ColorByAnnotationPlugin,
  ConsensusPlugin,
  GraphsPlugin,
  HighlightingPlugin,
  IdentityPlugin,
  SortingPlugin,
  TranslationsPlugin,
  WuKabatPlugin,
} from '@geneious/sequence-viewer/plugins';
import { EditDialogEvent, MetadataColumnOrGroup, SequenceEdit } from './sequence-viewer.interfaces';
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter } from 'rxjs';
import SSelection from '@geneious/sequence-viewer/plugins/Selection/SSelection';
import Range from '@geneious/sequence-viewer/includes/Range/Range';

export type SequenceTopology = 'linear' | 'circular';

@Injectable({
  providedIn: 'root',
})
export class SequenceViewerService<T extends SequenceData = SequenceData> {
  showDNAControls: boolean;
  numSequences: number;
  circularModeEnabled: boolean;
  sequenceTopology: SequenceTopology;
  sequences: T[];
  showAlignmentControls: boolean;
  sortingEnabled: boolean;
  // The enabled state of the Highlighting plugin before Color by Annotation was enabled.
  highlightingWasEnabled: boolean;
  highlightingControlsDisabled = false;
  metadataColumns: MetadataColumnOrGroup[];
  liabilityScoreLow = -2000;
  liabilityScoreHigh = -1000;
  liabilityColors = {
    red: '#E60000',
    yellow: '#FFFF00',
    green: '#008000',
  };
  graphsCheckboxesInitialState: {
    all: boolean;
    chromatogram: boolean;
    qualities: boolean;
    identity: boolean;
    wuKabat: boolean;
  };
  sequenceViewerState: {
    reference: number;
    documentsType: string;
  };
  readonly canUndo$ = new BehaviorSubject(false);
  readonly canRedo$ = new BehaviorSubject(false);
  /** Emits true when the SequenceViewer API property has been assigned. */
  private readonly _apiReady$ = new BehaviorSubject(false);
  /** Emits when a dialog is requested. Consumed by SequenceViewerComponent. */
  private readonly _editDialogEvents$ = new Subject<EditDialogEvent>();
  /** Emits when the selection changes. */
  private readonly _selection$ = new ReplaySubject<SSelection[]>(1);
  /** Emits when the sequences selection changes. This is whole sequence selection & not regular sequence selection */
  private readonly _sequencesSelection$ = new ReplaySubject<SequenceWrapper[]>(1);

  private api: FullSequenceViewer;

  constructor() {
    this._apiReady$.pipe(filter((ready) => ready)).subscribe(() => {
      this.circular =
        this.circularModeEnabled &&
        this.sequences.length === 1 &&
        this.sequences[0].sequence.circular;
      this.api.bindOnce('ready', () => this.emitCurrentSelection());
      this.api.bindOnce('ready', () => this.emitCurrentSequencesSelection());
      this.api.bind('selection created', () => this.emitCurrentSelection());
      this.api.bind('selection changed', () => this.emitCurrentSelection());
      this.api.bind('sequences selection changed', () => this.emitCurrentSequencesSelection());
      this.api.bind('view mode changed', () => {
        if (!this.api.view.circular && this.selection.range.wrapAround) {
          this.selection.clear();
        }
      });
    });
  }

  static isDNASequence(sequences: SequenceData[]): boolean {
    return sequences.length && sequences[0].sequence.sequenceType === 'Nucleotide';
  }

  static enableAlignmentFeatures(sequences: SequenceData[]) {
    return sequences.length <= 1000;
  }

  static isAlignmentDocument(documentsType: string): boolean {
    return documentsType === 'Alignment' || documentsType === 'Contig' || documentsType === 'Tree';
  }

  private static setDNAControls(sequences: SequenceData[]): boolean {
    return SequenceViewerService.isDNASequence(sequences);
  }

  dirty() {
    this.api.view.dirty = 'external';
  }

  cleanup() {
    this.api.emit('cleanup');
    this._selection$.complete();
    this._apiReady$.complete();
    this.canUndo$.complete();
    this.canRedo$.complete();
    this._editDialogEvents$.complete();
  }

  get serializedPreferences() {
    return this.api.serialize();
  }

  bind<K extends string & keyof FullEvents>(names: K | K[], callback: FullEvents[K]) {
    this.api.bind(names, callback);
  }

  clearEditHistory() {
    this.api.editing.clearHistory();
    this.updateSubjects();
  }

  getSequenceWrapperAtIndex(index: number): SequenceWrapper | undefined {
    return this.api.channelView.sequences[index];
  }

  getSortedSequences() {
    return this.api.channelView.sequences;
  }
  getConsensusSequence() {
    return this.api.channelView.globals.consensusChannel.entireConsensusSequence;
  }

  getEditedSequenceData() {
    return this.api.editing.getEditedSequenceData();
  }

  newApi(sv: any) {
    this.api = sv;
    this._apiReady$.next(true);
  }

  initialize(
    sequences: T[],
    documentsType: string,
    trees: any,
    sortingEnabled: boolean,
    circularModeEnabled: boolean,
    sequenceTopology: SequenceTopology,
  ) {
    this.showDNAControls = SequenceViewerService.setDNAControls(sequences);
    this.showAlignmentControls = this.setAlignmentControls(sequences, documentsType);
    this.numSequences = sequences.length;
    this.sortingEnabled = sortingEnabled;
    this.sequences = sequences;
    this.circularModeEnabled = circularModeEnabled;
    this.sequenceTopology = sequenceTopology;
  }

  zoomIn() {
    this.api.zoomIn();
  }

  zoomOut() {
    this.api.zoomOut();
  }

  zoomToShowResidues() {
    this.api.zoomToShowResidues();
  }

  zoomToShowAll() {
    this.api.zoomToShowAll();
  }

  undo() {
    if (this.api.editing.canUndo()) {
      this.api.editing.undoEdit();
    }
    this.updateSubjects();
  }

  redo() {
    if (this.api.editing.canRedo()) {
      this.api.editing.redoEdit();
    }
    this.updateSubjects();
  }

  applyEdit(edit: SequenceEdit) {
    const range = new RRange(edit.min, edit.max);

    this.api.editing.editSequence(edit.sequenceIndex, range, edit.newResidues);
    this.updateSubjects();

    this.dirty();
  }

  resetEdits() {
    this.api.editing.resetEdits();
    this.updateSubjects();
  }

  unsetReferenceSequence() {
    this.api.referenceSequenceIndex = null;
  }

  setReferenceSequence(index: number) {
    this.api.referenceSequenceIndex = index;
  }

  get referenceSequenceIndex() {
    return this.api.referenceSequenceIndex;
  }

  get residueWidth() {
    return this.api.residueWidth;
  }

  get currentPositionIndex() {
    return this.api.indexOfCurrentPosition;
  }

  get labelColumnWidth() {
    return this.api.labelColumnWidth;
  }

  get tree() {
    return this.api?.tree;
  }

  private setAlignmentControls(sequences: SequenceData[], documentsType: string): boolean {
    return (
      SequenceViewerService.enableAlignmentFeatures(sequences) && this.isAlignment(documentsType)
    );
  }

  private isAlignment(documentsType: string): boolean {
    return documentsType
      ? SequenceViewerService.isAlignmentDocument(documentsType)
      : this.alignment && this.alignment.enabled === true;
  }

  get circular(): boolean {
    return this.api?.view.circular;
  }

  set circular(value: boolean) {
    this.api.view.circular = value;
  }

  get colorScheme() {
    return this.showDNAControls ? this.DNAColorScheme : this.proteinColorScheme;
  }

  get alignment(): { enabled: boolean } {
    return this.api?.alignment;
  }

  get isDNA(): boolean {
    return this.api?.isDNA;
  }

  get canUndo(): boolean {
    return this.api.editing.canUndo();
  }

  get canRedo(): boolean {
    return this.api.editing.canRedo();
  }

  get translations(): TranslationsPlugin {
    return this.api?.translations;
  }

  get annotations(): AnnotationsPlugin {
    return this.api?.annotations;
  }

  get graphs(): GraphsPlugin {
    return this.api?.graphs;
  }

  get identity(): IdentityPlugin {
    return this.api?.identity;
  }

  get wuKabat(): WuKabatPlugin {
    return this.api?.wuKabat;
  }

  get consensus(): ConsensusPlugin {
    return this.api?.consensus;
  }

  get highlighting(): HighlightingPlugin {
    return this.api?.highlighting;
  }

  get search() {
    return this.api?.search;
  }

  get selection() {
    return this.api?.selection;
  }

  get metadata() {
    return this.api?.metadata;
  }

  get colorByAnnotation(): ColorByAnnotationPlugin {
    return this.api?.colorByAnnotation;
  }

  get sorting(): SortingPlugin {
    return this.api?.sorting;
  }

  get DNAColorScheme(): string {
    return this.api?.DNAColorScheme;
  }

  set DNAColorScheme(raw) {
    this.api.DNAColorScheme = raw;
  }

  get proteinColorScheme(): string {
    return this.api?.proteinColorScheme;
  }

  set proteinColorScheme(raw) {
    this.api.proteinColorScheme = raw;
  }

  get sequenceSelection() {
    return this.api?.sequenceSelection;
  }

  set colorScheme(raw) {
    if (this.showDNAControls) {
      this.DNAColorScheme = raw;
    } else {
      this.proteinColorScheme = raw;
    }
  }

  get sortedColumns() {
    const columns = [...this.metadata.columns];
    // Numeric collation: '1' < '2' < '10'.
    // Sensitivity for case insensitive base letter matching: a ≠ b, a = á, a = A.
    const options = { numeric: true, sensitivity: 'base' as const };

    return columns.sort((a, b) => a.name.localeCompare(b.name, undefined, options));
  }

  /**
   * Sends a request to show an edit dialog. The request may be ignored if
   * another dialog is already open.
   *
   * @param kind the type of dialog to show
   * @param data the data to send the dialog
   */
  requestEditDialog(kind: EditDialogEvent['kind'], data?: EditDialogEvent['data']): void {
    this._editDialogEvents$.next({ kind, data });
  }

  /**
   * The stream of requested edit dialogs.
   */
  get editDialogEvents$(): Observable<EditDialogEvent> {
    return this._editDialogEvents$.asObservable();
  }

  /**
   * The stream of selection changes.
   */
  get selection$(): Observable<SSelection[]> {
    return this._selection$.asObservable();
  }

  /**
   * The stream of sequences selection changes.
   */
  get sequencesSelection$(): Observable<SequenceWrapper[]> {
    return this._sequencesSelection$.asObservable();
  }

  /**
   * Emits false, then true when the SequenceViewer API property has been
   * assigned.
   */
  get apiReady$(): Observable<boolean> {
    return this._apiReady$.asObservable();
  }

  /**
   * Updates the selection and triggers an emission from sequenceSelection$.
   */
  setSelection(range: Range, wrapper: SequenceWrapper) {
    this.selection.setSelection(range, wrapper);
    this.emitCurrentSelection();
  }

  private updateSubjects() {
    this.canUndo$.next(this.api.editing.canUndo());
    this.canRedo$.next(this.api.editing.canRedo());
    this.emitCurrentSelection();
  }

  private emitCurrentSelection() {
    this._selection$.next(this.api.selection.current);
  }

  private emitCurrentSequencesSelection() {
    this._sequencesSelection$.next(this.api.sequenceSelection.selectedSequences);
  }
}
