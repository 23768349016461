import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import RRange from "../../includes/Range/RRange.js";
import Pen from "../../includes/RenderEngine/Pen.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import { adjustRange } from "../../includes/misc/DPIRatio.js";
import { centerVertically, darken, textColorForBackground } from "../../includes/misc/Utils.js";
import SSelection from "../Selection/SSelection.js";
import AnnotationTranslationsCache from "./AnnotationTranslationsCache.js";
import FrameTranslationsCache from "./FrameTranslationsCache.js";
import TranslationsSearch from "./TranslationsSearch.js";
import Range from "../../includes/Range/Range.js";
const {
  floor,
  ceil
} = Math;
class TranslationsChannel extends DataChannel {
  constructor(wrapper) {
    super(wrapper);
    this.wrapper = wrapper;
    this.frameTranslationsCache = new FrameTranslationsCache(this);
    this.annotationTranslationsCache = new AnnotationTranslationsCache(this);
    this._search = new TranslationsSearch(this);
    this.sv.bindToNode("mousedown", "wrapper channel wrapper", () => this.mouseDownOnWrapperChannel());
    this.sv.bindToNode("mousemove", "translation row", (node, event, offset) => this.mouseEventOnTranslation(node, offset));
    this.sv.bindToNode("click", "translation row", (node, event, offset) => this.mouseEventOnTranslation(node, offset, true));
    this.sv.bindToNode("mouseleave", "translation row", () => this.mouseLeavingTranslations());
    this.sv.bind(["all data requests resolved", "sequence edited", "translation table changed"], () => {
      this.frameTranslationsCache.clear();
      this.annotationTranslationsCache.clear();
    });
  }
  margin = 2;
  frameTranslationsCache;
  annotationTranslationsCache;
  rowMargin = 2;
  _search;
  // Font size is set in renderThirds().
  pen = new Pen(0, "center", "middle");
  get sv() {
    return this.view.sv;
  }
  search(query, index) {
    return this._search.matchTranslation(query, index);
  }
  getThird(index, frame) {
    if (frame) {
      return this.frameTranslationsCache.get(frame, index);
    } else {
      const annotation = this.sv.annotations?.getLastAnnotationAtIndex(this.wrapper, index);
      if (annotation) {
        return this.annotationTranslationsCache.get(annotation, index);
      }
    }
  }
  calculateLayout(context, bounds) {
    return new RenderNode("translation channel", this, bounds, this.frames?.map((frame, index) => {
      return this.calculateRowLayout(context, index, frame);
    }));
  }
  calculateRowLayout(context, index, frame) {
    const width = this.view.wrapped ? this.view.toPixels(context.visible.length) : this.view.viewport.width;
    const bounds = {
      x: 0,
      y: index * this.rowHeightWithMargin,
      width,
      height: this.rowHeight
    };
    const node = new RenderNode("translation row", frame, bounds);
    node.setRenderCallback(() => this.renderRow(context, frame));
    return node;
  }
  renderRow(context, frame) {
    if (frame) {
      this.renderFromFrame(context, frame);
    } else {
      this.renderFromAnnotationsOrSelection(context);
    }
  }
  renderFromFrame(context, frame) {
    this.renderThirds(context.visible, context, frame, this.frameTranslationsCache);
  }
  /**
   * Render translations for all visible CDS and ORF annotations.
   *
   * Annotation are translated and rendered in _reverse_ order (of the annotation's start position), so that
   * annotations which start earlier overwrite overlapping annotations that start later in the sequence view.
   */
  renderFromAnnotationsOrSelection(context) {
    const annotations = this.annotationTranslationsCache.translatableAnnotations(context.visible);
    const selected = this.selectedAnnotation;
    if (selected) {
      this.brush.globalAlpha = 0.3;
    }
    annotations.forEach(annotation => {
      return this.translateAndRenderAnnotation(annotation, context);
    });
    this.brush.globalAlpha = 1;
    if (selected && context.visible.overlaps(selected.range)) {
      this.translateAndRenderAnnotation(selected, context);
    }
  }
  translateAndRenderAnnotation(annotation, context) {
    const translation = this.annotationTranslationsCache.trimmedUngappedRanges(annotation).filter(range => context.visible.overlaps(range.range));
    this.renderThinLinesBetweenRanges(context, translation);
    if (!this.view.residuesAreVisible) {
      translation.forEach(({
        range
      }) => this.renderThickLine(context, range));
    } else {
      translation.forEach(({
        range
      }) => {
        this.renderThirds(range, context, annotation, this.annotationTranslationsCache);
      });
    }
  }
  renderThirds({
    start,
    end
  }, context, translationContext, cache) {
    const frame = typeof translationContext === "number" ? translationContext : void 0;
    const thirds = [];
    for (let index = start; index < end; index++) {
      const codonThird = cache.get(translationContext, index);
      if (codonThird) {
        const {
          third,
          bounds
        } = this.processThirds(codonThird, index, context, frame);
        thirds.push({
          third,
          bounds,
          index
        });
      }
    }
    thirds.forEach(({
      third,
      bounds,
      index
    }) => {
      this.renderBackground(third, index, bounds, frame);
    });
    thirds.forEach(({
      third,
      bounds
    }) => {
      this.renderLetter(third, bounds);
    });
  }
  processThirds({
    letter,
    position,
    colorCode
  }, index, context, frame) {
    const bounds = {
      x: context.toViewportOffset(index),
      y: 0,
      width: this.view.residueWidth,
      height: this.residueHeight
    };
    const third = {
      letter,
      position,
      color: this.color(colorCode),
      colorCode
    };
    this.sv.emit("alter translation data", third, index, this.wrapper, frame);
    this.sv.emit("alter translation colors", third);
    return {
      third,
      bounds
    };
  }
  renderBackground(third, index, bounds, frame) {
    const background = third.color.background;
    if (background === null) {
      return;
    } else if (this.isSelected(index, frame)) {
      this.paintBox(bounds, darken(background, 7).toRgbString());
      this.renderSelection(bounds);
    } else if (this.isHovered(index, frame)) {
      this.paintBox(bounds, darken(background, 10).toRgbString());
    } else {
      this.paintBox(bounds, background);
    }
  }
  renderLetter(third, bounds) {
    if (this.sequencePainter.printLetter) {
      this.pen.fontSize = this.sequencePainter.fontSize;
      if (third.position === 1) {
        this.renderLabel(bounds, third.letter, third.color.text);
      }
    }
  }
  paintBox({
    x,
    y,
    width,
    height
  }, color) {
    if (this.outsideViewport(x, width)) {
      return;
    }
    const {
      start,
      length
    } = adjustRange(x, width);
    this.graphics.fillRect(this.brush, start, y, length, height, color || this.wrapper.background);
  }
  renderSelection({
    x,
    y,
    width,
    height
  }) {
    if (this.outsideViewport(x, width)) {
      return;
    }
    const {
      start,
      length
    } = adjustRange(x, width);
    if (this.sv.selection) {
      this.graphics.strokePolygon(this.brush, [{
        x: start,
        y
      }, {
        x: start + length,
        y
      }, {
        x: start,
        y: y + height
      }, {
        x: start + height,
        y: y + height
      }], this.sv.selection.colors.outline, 3);
    }
  }
  renderLabel({
    x,
    y,
    width,
    height
  }, label, color) {
    if (this.outsideViewport(x, width)) {
      return;
    }
    this.brush.fillStyle = color;
    this.pen.write(this.graphics, this.brush, label, x + width / 2, y + height / 2);
  }
  renderThinLinesBetweenRanges(context, ranges) {
    let previous;
    ranges.forEach(({
      range
    }) => {
      if (previous && !previous.touches(range)) {
        const isAfter = range.isAfter(previous);
        const start = isAfter ? previous.end : range.end;
        const end = isAfter ? range.start : previous.start;
        this.renderThinLine(context, new RRange(start, end));
      }
      previous = range;
    });
  }
  renderThinLine(context, range) {
    this.renderLine(1, context, range, "black");
  }
  renderThickLine(context, range) {
    this.renderLine(this.sequencePainter.height, context, range, "black");
  }
  renderLine(size, context, range, color) {
    const x = context.toViewportOffset(range.start);
    const width = this.view.toPixels(range.length);
    const vertical = centerVertically(size, {
      width,
      height: this.sequencePainter.height
    });
    const horizontal = adjustRange(x, width);
    if (horizontal.length > 0) {
      this.sequencePainter.renderBox(color, {
        x: horizontal.start,
        y: vertical.y,
        width: horizontal.length,
        height: vertical.height
      });
    }
  }
  color(residue) {
    const scheme = this.sv.proteinColorSchemes[this.plugin.colorScheme];
    const color = this.sequencePainter.colorForResidue(scheme, residue);
    return {
      text: textColorForBackground(color),
      background: color
    };
  }
  isSelected(index, frame) {
    return this.emphasizeCodon(this.plugin.selectedTranslation, index, frame);
  }
  isHovered(index, frame) {
    return this.emphasizeCodon(this.plugin.hoveredTranslation, index, frame);
  }
  emphasizeCodon(codon, index, frame) {
    return codon && codon.wrapper === this.wrapper && codon.range.includes(index) && codon.frame === frame;
  }
  outsideViewport(x, width) {
    return x + width < 0 || x + width > this.sv.channelView.width;
  }
  /** Calculations */
  getDNA(range) {
    if (this.wrapper.type === "sequence") {
      this.populateCaches(range);
    }
    if (!this.sequenceCache) {
      return [];
    }
    return this.sequenceCache.getRangeAsDNA(range);
  }
  populateCaches(range) {
    this.rangesCache.request(range);
    this.sequenceCache.request(range);
  }
  translateSequence(sequence, isFirst) {
    const result = [];
    for (let i = 0; i < sequence.length; i += 3) {
      if (isFirst !== void 0) {
        isFirst = isFirst ? null : false;
      } else {
        isFirst = i === 0 ? null : false;
      }
      const translation = this.globals.translateCodon(sequence.slice(i, i + 3), isFirst);
      if (translation) {
        for (let position = 0; position < 3; position++) {
          const index = i + position;
          result[index] = {
            letter: translation.text,
            colorCode: translation.colorCode,
            position
          };
        }
      }
    }
    return result;
  }
  regap({
    start,
    end
  }, sequence) {
    const result = [];
    let index = 0;
    for (let i = start; i < end; i++) {
      if (sequence[index] === void 0) {
        index++;
      } else if (this.sequenceCache.isDefined(i)) {
        result[i] = sequence[index++];
      }
    }
    return result;
  }
  translationRange(entire, requested, frameOffset) {
    entire = this.rangesCache.ungapRange(entire);
    requested = this.rangesCache.ungapRange(requested);
    const result = this.containedResidues(entire, frameOffset).clip(this.touchedResidues(requested, frameOffset));
    return this.rangesCache.gapRangeExcludingEdges(result);
  }
  touchedResidues(range, frame) {
    return new RRange(this.floorToFrame(range.start, frame), this.ceilToFrame(range.end, frame));
  }
  containedResidues(range, frame) {
    return new RRange(this.ceilToFrame(range.start, frame), this.floorToFrame(range.end, frame));
  }
  floorToFrame(n, frame) {
    return this.roundToFrame(n, frame, floor);
  }
  ceilToFrame(n, frame) {
    return this.roundToFrame(n, frame, ceil);
  }
  roundToFrame(n, frame, roundMethod) {
    return roundMethod((n - frame) / 3) * 3 + frame;
  }
  /** Mouse events */
  mouseEventOnTranslation(node, offset, select) {
    if (this !== node.parent?.reference) {
      return;
    }
    const frame = node.reference;
    const view = this.sv.channelView;
    const index = floor(view.toResidues(offset.x + view.offset.x));
    const range = frame ? this.frameTranslationsCache.getCodonRange(frame, index) : this.annotationTranslationsCache.getCodonRange(index);
    if (!range || range.length === 0) {
      return;
    }
    if (select) {
      this.plugin.selectedTranslation = {
        frame,
        range,
        wrapper: this.wrapper
      };
      if (this.sv.selection) {
        this.sv.selection.dragging = false;
        this.sv.selection.current = [new SSelection(Range.fromData(range, this.wrapper.sequence.length), [this.wrapper])];
      }
    } else {
      this.plugin.hoveredTranslation = {
        frame,
        range,
        wrapper: this.wrapper
      };
    }
  }
  mouseLeavingTranslations() {
    this.plugin.hoveredTranslation = null;
  }
  mouseDownOnWrapperChannel() {
    this.plugin.selectedTranslation = null;
  }
  /** Getters */
  get selectedAnnotation() {
    const node = this.focusedRenderNode;
    if (node) {
      const interval = node.reference;
      return interval && interval.annotation;
    }
  }
  get focusedRenderNode() {
    const node = this.view.sv.view.focused;
    if (node?.type === "interval") {
      const interval = node.reference;
      if (interval.annotation.wrapper === this.wrapper) {
        return node;
      }
    }
  }
  get visible() {
    if (this.wrapper.type === "globals wrapper" && !this.sv.consensus?.enabled) {
      return false;
    }
    return this.plugin.enabled;
  }
  // Translations use the sequence data.
  get sequenceCache() {
    return this.wrapper.sequenceCache;
  }
  get rangesCache() {
    return this.wrapper.rangesCache;
  }
  get frames() {
    return this.globals.frames || [null];
  }
  get height() {
    if (this.frames === null) {
      return 0;
    }
    return this.frames.length * this.rowHeightWithMargin - this.rowMargin;
  }
  get rowHeightWithMargin() {
    return this.rowHeight + this.rowMargin;
  }
  get rowHeight() {
    return this.sequencePainter.channelHeightNoMargin;
  }
  get residueHeight() {
    return this.sequencePainter.height;
  }
  get plugin() {
    return this.sv.translations;
  }
  get globals() {
    return this.plugin.data;
  }
}
export { TranslationsChannel as default };