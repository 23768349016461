import { Observable, of } from 'rxjs';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { SequenceDocument, SequenceDataInDocument } from './sequence-selection.service';
import { SequenceData } from '@geneious/sequence-viewer/types';

@Injectable({
  providedIn: 'root',
})
export class SequenceDataService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private errorHandler: ErrorHandler,
  ) {}

  fetchSequences(
    id: string,
    revision?: number,
  ): Observable<SequenceDocument<SequenceDataInDocument>> {
    return this.getSequenceForEntity(id, revision).pipe(
      retry({ count: 1, delay: 250 }),
      map((result) => {
        // Most times this will be an array of sequences, however for tree alignments
        // this is now an object with the array of sequences inside on a property 'sequences'.
        const resultSequences = Array.isArray(result) ? result : result.sequences;
        const sequences = resultSequences.map((sequence, sequenceIndexInDocument) => ({
          ...sequence,
          // The id of the document this sequence resides in.
          documentId: id,
          // The index of this sequence in its document.
          sequenceIndexInDocument,
        }));
        return { ...result, sequences };
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return of({ sequences: [] });
      }),
    );
  }

  getSequenceForEntity(
    entityID: string,
    revision?: number,
  ): Observable<SequenceDocument | SequenceData[]> {
    const queryString = revision ? `?revision=${revision}` : '';
    return this.http.get<SequenceDocument | SequenceData[]>(
      `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/documents/${entityID}/parts/SEQUENCE_VIEW/data${queryString}`,
    );
  }

  static processAnnotations(annotations: any[]) {
    const polymorphisms: any[] = [];
    const numbering: any[] = [];
    const others: any[] = [];
    const numberingTypes = ['Numbering summary', 'Kabat numbering', 'IMGT numbering'];

    annotations.forEach((annotation, index) => {
      // SV requires a unique ID per annotation per sequence.
      annotation.id = `Bx-annotation-index-${index}`;

      // Split the annotations by type.
      if (annotation.type === 'Polymorphism') {
        polymorphisms.push(annotation);
      } else if (numberingTypes.includes(annotation.type)) {
        numbering.push(annotation);
      } else {
        others.push(annotation);
      }
    });

    return { polymorphisms, numbering, others };
  }
}
