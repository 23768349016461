import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { pseudoRandom, textColorForBackground } from "../../includes/misc/Utils.js";
import { palettes } from "./ColorByAnnotationPalettes.js";
class ColorByAnnotationPlugin extends AbstractPlugin {
  _annotationType;
  constructor(sv) {
    super(sv);
    sv.colorByAnnotation = this;
    sv.bind("alter residue colors", (residue, channel) => this.alterResidueColors(residue, channel));
    sv.bind("alter sequence lines", (context, channel, collection) => this.alterSequenceLine(context, channel, collection));
    this._annotationType = this.getInitialOptions("colorByAnnotation", {
      annotationType: {
        default: null,
        validator: raw => typeof raw === "string"
      }
    }).annotationType;
  }
  get annotationType() {
    return this._annotationType;
  }
  set annotationType(raw) {
    this.setStringOption("_annotationType", raw);
  }
  serialize() {
    return {
      annotationType: this.annotationType
    };
  }
  // For zoom levels 1 and 2.
  alterResidueColors(residue, wrapper) {
    if (residue.letter === " ") {
      return;
    }
    if (!this.annotationType || wrapper.colorScheme !== "byAnnotation") {
      return;
    }
    const intervals = this.sv.annotations.getIntervalsByIndexAndType(wrapper, residue.index, this._annotationType);
    const annotations = [...new Set(intervals.map(interval => interval.annotation))];
    const {
      printLetter
    } = this.sv.channelView.sequencePainter;
    if (printLetter) {
      if (annotations.length > 4) {
        residue.foreground = "white";
        residue.background = ["black"];
      } else if (annotations.length > 0) {
        const colors = this.getColorsForAnnotations(annotations);
        residue.foreground = textColorForBackground(colors[0]);
        residue.background = colors;
      }
    } else {
      if (residue.letter === "-") {
        if (annotations.length > 1) {
          residue.foreground = "black";
        } else if (annotations.length === 1) {
          residue.foreground = this.getColorsForAnnotations(annotations)[0];
        } else {
          residue.foreground = this.grey;
        }
      } else {
        if (annotations.length > 4) {
          residue.background = ["black"];
        } else if (annotations.length > 0) {
          residue.background = this.getColorsForAnnotations(annotations);
        } else {
          residue.background = [this.grey];
        }
      }
    }
  }
  // Public only for tests.
  getColorsForAnnotations(annotations) {
    return annotations.map(name => this.getAnnotationColor(name));
  }
  getAnnotationColor(annotation) {
    return pseudoRandom(annotation.name.toLowerCase(), palettes[10]);
  }
  // For zoom level 3.
  alterSequenceLine(context, channel, collection) {
    if (!this.annotationType || channel.colorScheme !== "byAnnotation" || channel.sequenceRange === void 0) {
      return;
    }
    const range = context.visible.intersection(channel.sequenceRange);
    collection.insertAndDivide(range, null, overlap => ({
      colors: [this.grey],
      size: overlap?.size ?? null,
      priority: overlap?.priority ?? 0,
      background: overlap?.background
    }));
    this.colorLinesByAnnotations(context, channel, collection);
    collection.values.forEach(segment => {
      if (!segment) {
        return;
      }
      const threshold = segment.size === "thin" ? 1 : 4;
      if (segment && segment.colors.length > threshold) {
        segment.colors = ["black"];
      }
    });
  }
  colorLinesByAnnotations(context, channel, collection) {
    const sequenceRange = channel.sequenceRange;
    if (sequenceRange === void 0) {
      return;
    }
    this.sv.annotations.getByRangeFromWrapper(channel, context.visible).filter(annotation => annotation.normalType === this.annotationType).forEach(annotation => {
      const color = this.getAnnotationColor(annotation);
      annotation.intervals.filter(interval => interval.range.length > 0).map(interval => interval.range.intersection(sequenceRange)).forEach(range => collection.insertAndDivide(range, null, overlap => {
        const colors = [];
        if (overlap && (overlap.colors.length > 1 || overlap.colors[0] !== this.grey)) {
          colors.push(...overlap.colors);
        }
        colors.push(color);
        return {
          colors,
          size: overlap?.size ?? null,
          priority: (overlap?.priority ?? 0) + 100,
          background: overlap?.background
        };
      }));
    });
  }
  get grey() {
    return this.sv.channelView.sequencePainter.grey;
  }
}
export { ColorByAnnotationPlugin as default };