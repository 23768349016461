import { clearCanvas } from "../misc/Utils.js";
class Layer {
  constructor(view, parentLayer) {
    this.view = view;
    this.parentLayer = parentLayer;
    this.brush = this.canvas.getContext("2d");
  }
  brush;
  canvas = document.createElement("canvas");
  _debug = false;
  get graphics() {
    return this.view.graphics;
  }
  debug() {
    this._debug = true;
    this.debugContainer.appendChild(this.canvas);
  }
  reset(dimensions) {
    clearCanvas(this.canvas, this.brush, dimensions);
    if (this._debug) {
      this.canvas.style.height = this.canvas.height / window.devicePixelRatio / 4 + "px";
      this.canvas.style.display = "block";
      this.canvas.style.margin = "2px";
      this.canvas.style.border = "1px solid black";
      this.canvas.style.backgroundColor = "rgba(255, 0, 0, 0.3)";
    }
  }
  applyToBaseLayer(y = 0) {
    const {
      width,
      height
    } = this.canvas;
    const scale = window.devicePixelRatio;
    if (this.parentLayer) {
      this.parentLayer.brush.drawImage(this.canvas, 0, y, width / scale, height / scale);
    } else {
      this.view.brush.drawImage(this.canvas, 0, y, width / scale, height / scale);
    }
  }
  // TODO Optimize: Check `triggers` to determine if render is really necessary.
  render(node) {
    const visibleRange = this.view.clipToViewport(node);
    if (visibleRange.isEmpty) {
      throw new Error("Layer RenderNode must be in viewport");
    }
    const {
      start,
      length
    } = visibleRange;
    if (this.view.isCircular) {
      this.reset({
        width: this.view.width,
        height: this.view.height
      });
    } else {
      this.reset({
        width: node.bounds.width,
        height: length
      });
    }
    this.graphics.withInitialTransform(this.brush, () => {
      this.brush.translate(0, 0 - start);
      this.paint(node);
    });
    this.applyToBaseLayer(start);
  }
  paint(_node) {}
  get debugContainer() {
    const match = this.view.element.querySelector("div.layer-debugger");
    if (match) {
      return match;
    } else {
      const element = document.createElement("div");
      element.classList.add("layer-debugger");
      element.style.width = "auto";
      element.style.position = "absolute";
      element.style.right = "0";
      this.view.element.appendChild(element);
      return element;
    }
  }
}
export { Layer as default };