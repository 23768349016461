import { reduce2Dto1D } from "../../includes/misc/Math.js";
import HorizontalScrollbar from "./Scrollbar/HorizontalScrollbar.js";
import VerticalScrollbar from "./Scrollbar/VerticalScrollbar.js";
const {
  round
} = Math;
class ScrollPlugin {
  constructor(sv) {
    this.sv = sv;
    sv.scroll = this;
    this.corner = document.createElement("div");
    this.corner.className = "sv-scrollbar-corner";
    this.horizontalBar = new HorizontalScrollbar(sv, this.corner);
    this.verticalBar = new VerticalScrollbar(sv, this.corner);
    sv.bind("ready", () => {
      this.removeOldElements();
      sv.view.element.querySelector(".sv-bottom")?.appendChild(this.horizontalBar.container);
      sv.view.element.querySelector(".sv-right")?.appendChild(this.verticalBar.container);
      this.horizontalBar.container.appendChild(this.corner);
      sv.bind("wheel", e => this.onScroll(e));
      sv.bind(["offset changed", "zoom", "resize", "wrapped changed", "column visibility changed"], () => this.updateBars());
      this.updateBars();
    });
    sv.bind("view mode changed", () => {
      this.horizontalBar.hide();
      this.verticalBar.hide();
    });
  }
  horizontalBar;
  verticalBar;
  corner;
  updateBars() {
    this.sv.bindOnce("postrender", () => {
      if (this.sv.view.circular) {
        return;
      }
      this.horizontalBar.container.style.width = `${this.sv.channelView.viewport.width + this.corner.offsetWidth}px`;
      this.horizontalBar.update();
      this.verticalBar.update();
    });
  }
  onScroll(event) {
    if (this.sv.view.circular) {
      return;
    }
    if (event.altKey) {
      return;
    }
    if (this.sv.view.hovered?.type === "metadata" && this.sv.metadata?.scrollable() && event.shiftKey) {
      return;
    }
    let delta;
    if (this.isHorizontalScroll(event)) {
      delta = {
        x: reduce2Dto1D(event.deltaX, event.deltaY),
        y: 0
      };
    } else {
      delta = {
        x: event.deltaX,
        y: event.deltaY
      };
    }
    delta = {
      x: round(delta.x),
      y: round(delta.y)
    };
    if (this.sv.changeOffsetBy(delta)) {
      event.preventDefault();
    }
    this.updateBarOffset();
  }
  isHorizontalScroll(event) {
    return this.hasHorizontalScrollbar && (!this.hasVerticalScrollbar || this.hasVerticalScrollbar && event.shiftKey);
  }
  get hasHorizontalScrollbar() {
    return !this.horizontalBar.isFullyVisible;
  }
  get hasVerticalScrollbar() {
    return !this.verticalBar.isFullyVisible;
  }
  updateBarOffset() {
    this.horizontalBar.updateOffset();
    this.verticalBar.updateOffset();
  }
  /**
   * Removes any leftover scrollbars from previous instances.
   */
  removeOldElements() {
    const classNames = [this.corner.className, this.horizontalBar.className, this.verticalBar.className];
    classNames.map(name => {
      const element = this.sv.view.element.querySelector(`.${name}`);
      if (element) {
        element.remove();
      }
    });
  }
}
export { ScrollPlugin as default };