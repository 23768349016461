import Layer from "../../includes/PluginBaseClasses/Layer.js";
import SidebarColumn from "../../includes/PluginBaseClasses/SidebarColumn.js";
import RRange from "../../includes/Range/RRange.js";
import Pen from "../../includes/RenderEngine/Pen.js";
import { exponentExceeds, formatToSF, getExponent, isNumeric, roundToSF, toExponentialNotation } from "../../includes/misc/Math.js";
import { textColorForBackground } from "../../includes/misc/Utils.js";
class MetadataColumn extends SidebarColumn {
  constructor(plugin, options) {
    super(plugin.sv.view);
    this.plugin = plugin;
    this.margin = this.plugin.COLUMN_MARGIN;
    this.layer = new Layer(this.view, this.plugin.columnGroup?.layer);
    this.sv.bindToNode("mouseleave", "metadata column", () => this.mouseLeave());
    this.sv.bindToNode("mousemove", "metadata column", (node, event, offset) => this.mouseMove(node, offset));
    this.name = options.name;
    this.label = options.label || options.name;
    this.groupName = options.groupName;
    this.type = options.type;
    this.enabled = options.enabled ?? false;
    this.width = options.width ?? 0;
    this.cellRenderer = options.cellRenderer;
    this.tooltipRenderer = options.tooltipRenderer;
    this.pen.fontSize = 10;
    this.pen.style = "bold";
    const groupName = this.pen.measureWidth(this.groupName ?? "");
    this.pen.style = "";
    const name = this.pen.measureWidth(this.label);
    this.labelWidth = Math.max(name, groupName) + this.labelPadding;
  }
  name;
  label;
  groupName;
  type;
  pen = new Pen(0, "center", "middle");
  labelIsHovered = false;
  labelWidth;
  range;
  _enabled = false;
  _width = 0;
  cellRenderer;
  tooltipRenderer;
  // Set textCenter to 9 so that the text is centered on the labels which are 18px tall.
  textCenter = 9;
  labelPadding = 3;
  get enabled() {
    return this.plugin.enabled && this._enabled;
  }
  set enabled(raw) {
    const value = Boolean(raw);
    if (value !== this._enabled) {
      this._enabled = value;
      this.view.dirty = "column visibility changed";
      this.plugin.sv.emit(["column visibility changed", "height changed"]);
    }
  }
  get weight() {
    return 10;
  }
  get width() {
    const minWidth = this.plugin.showValue || this.groupName ? 40 : 20;
    return Math.max(minWidth, this._width);
  }
  set width(value) {
    this._width = value;
  }
  get offset() {
    let offset = this.plugin.columnGroup?.offset ?? 0;
    this.plugin.columns.some(col => {
      if (col.enabled) {
        if (col === this) {
          return true;
        }
        offset += col.widthWithMargin;
      }
    });
    return offset;
  }
  get nodeType() {
    return "metadata";
  }
  get hasData() {
    if (this.type === "continuous") {
      this.plugin.sv.channelView.channels.forEach(channel => {
        const value = this.getValue(channel);
        if (value != null) {
          this.stretch(value);
        }
      });
      return this.range != null;
    } else {
      return this.plugin.sv.channelView.channels.some(channel => {
        return this.getValue(channel) !== void 0;
      });
    }
  }
  serialize() {
    return {
      name: this.name,
      label: this.label,
      groupName: this.groupName,
      type: this.type,
      enabled: this.enabled,
      width: this.width,
      cellRenderer: this.cellRenderer
    };
  }
  getValue(channel) {
    const raw = channel.metadata[this.name];
    if (raw !== void 0) {
      if (this.type === "continuous") {
        return isNumeric(raw) ? Number(raw) : void 0;
      } else if (this.type === "boolean" || this.type === "text") {
        return raw;
      }
    }
  }
  getTooltip(channel) {
    if (this.tooltipRenderer) {
      return this.tooltipRenderer(channel.metadata[this.name], channel.metadata, this.name);
    } else {
      const value = this.getValue(channel);
      const formatted = this.type === "continuous" ? this.formatValue(value, false) : value;
      return `${this.label}: ${formatted}`;
    }
  }
  formatValue(value, isCell) {
    if (value == null || Number.isNaN(value) || !Number.isFinite(value)) {
      return isCell ? "" : "(no data)";
    }
    if (isCell) {
      const rounded = roundToSF(value, 2).value;
      if (exponentExceeds(rounded, -100, 100)) {
        return `e${getExponent(rounded)}`;
      }
    }
    if (exponentExceeds(value, -2, 5)) {
      return toExponentialNotation(value, isCell ? 2 : 4);
    }
    return formatToSF(value, isCell ? 5 : 7);
  }
  alterWrapperNode(node) {
    const channel = node.reference;
    const cell = this.getCell(channel);
    if (channel.type === "globals wrapper") {
      node.setRenderCallback(() => this.renderLabel(channel));
      node.type = `${node.type} label`;
    } else {
      node.setRenderCallback(() => this.renderCell(cell, channel));
    }
    return node;
  }
  /**
   * Returns the current state of this class as a MetadataColumnOptions object
   *
   * @returns the options object
   */
  toOptions() {
    return {
      name: this.name,
      enabled: this.enabled,
      groupName: this.groupName,
      label: this.label,
      type: this.type,
      width: this.width,
      cellRenderer: this.cellRenderer,
      tooltipRenderer: this.tooltipRenderer
    };
  }
  columnRender(bounds) {
    if (bounds.width === this.widthWithMargin) {
      this.layer.brush.strokeStyle = "#CCCCCC";
      this.layer.brush.beginPath();
      this.layer.brush.moveTo(bounds.width - this.margin / 2, 0);
      this.layer.brush.lineTo(bounds.width - this.margin / 2, bounds.height);
      this.layer.brush.stroke();
    }
  }
  getCell(channel) {
    if (this.cellRenderer) {
      return this.cellRenderer(channel.metadata[this.name]);
    } else if (this.type === "continuous") {
      return {
        background: this.getColor(channel)
      };
    } else {
      return {};
    }
  }
  renderCell(cell, channel) {
    if (cell) {
      const {
        brush,
        graphics
      } = this.layer;
      const {
        background,
        foreground,
        icon
      } = cell;
      this.pen.align = "center";
      if (background) {
        brush.fillStyle = background;
        brush.fillRect(0, 0, this.width, channel.height);
      }
      if (icon) {
        this.pen.fontSize = 18;
        this.pen.color = foreground || "black";
        this.pen.write(graphics, brush, icon, this.width / 2, this.textCenter);
      } else if (this.plugin.showValue && this.type === "continuous" || this.type === "text") {
        this.pen.fontSize = 10;
        this.pen.color = foreground || background && textColorForBackground(background) || "black";
        const value = this.getValue(channel);
        const text = this.type === "continuous" ? this.formatValue(value, true) : value;
        if (text) {
          this.pen.write(graphics, brush, this.displayLabel(this.width - this.labelPadding * 2, text), this.width / 2, this.textCenter);
        }
      }
    }
  }
  renderLabel(channel) {
    const {
      brush,
      graphics
    } = this.layer;
    this.pen.fontSize = 10;
    this.pen.color = "black";
    this.pen.align = "left";
    brush.fillStyle = "white";
    const labelSortingMargin = this.sv.sorting && this.sv.sorting.currentSort.length > 0 ? 20 : 0;
    const labelChannelWidth = channel.height - labelSortingMargin;
    const availableWidth = this.labelIsHovered && this.labelWidth > labelChannelWidth ? this.labelWidth : labelChannelWidth;
    brush.save();
    brush.rotate(Math.PI / 180 * 270);
    brush.fillRect(0 - labelSortingMargin, 0, 0 - (availableWidth + channel.margin), this.width);
    if (this.groupName) {
      this.pen.style = "bold";
      this.pen.write(graphics, brush, this.displayLabel(availableWidth, this.groupName), 0 - (availableWidth + labelSortingMargin), this.width / 2 - 7);
      this.pen.style = "";
      this.pen.write(graphics, brush, this.displayLabel(availableWidth, this.label), 0 - (availableWidth + labelSortingMargin), this.width / 2 + 7);
    } else {
      this.pen.write(graphics, brush, this.displayLabel(availableWidth, this.label), 0 - (availableWidth + labelSortingMargin), this.width / 2);
    }
    brush.restore();
  }
  mouseMove(node, offset) {
    const halfLabelResizerSize = this.sv.metadata.labelResizerSize / 2;
    const halfColumnResizerSize = this.sv.metadata.columnResizerSize / 2;
    this.labelIsHovered = node && offset.y <= this.sv.channelView.globals.height + this.sv.channelView.offset.y - halfLabelResizerSize && offset.x < this.width - halfColumnResizerSize && offset.x > halfColumnResizerSize && node.reference.name == this.name;
  }
  mouseLeave() {
    this.labelIsHovered = false;
  }
  displayLabel(availableWidth, text) {
    if (this.pen.measureWidth(text) > availableWidth) {
      availableWidth -= this.pen.measureWidth("...");
      return this.charactersForWidth(text, availableWidth) + "...";
    } else {
      return text;
    }
  }
  charactersForWidth(text, goalWidth) {
    let i = 1;
    while (i < text.length && this.pen.measureWidth(text.substr(0, i)) <= goalWidth) {
      i++;
    }
    return text.substr(0, i - 1);
  }
  getColor(channel) {
    const value = this.getValue(channel);
    if (value !== void 0) {
      const score = this.getFraction(value);
      return this.channelView.graphPainter.heatmapColor(score);
    }
  }
  getFraction(value) {
    if (!this.range) {
      return 1;
    }
    const {
      start,
      length
    } = this.range;
    return length ? (value - start) / length : 1;
  }
  stretch(value) {
    if (!this.range) {
      this.range = new RRange(value, value);
    } else {
      const {
        start,
        end
      } = this.range;
      if (value < start) {
        this.range = new RRange(value, end);
      } else if (value > end) {
        this.range = new RRange(start, value);
      }
    }
  }
  get sv() {
    return this.plugin.sv;
  }
}
export { MetadataColumn as default };