import RRange from "../../../includes/Range/RRange.js";
import { fixOrder } from "../../../includes/misc/Utils.js";
class Interval {
  constructor(annotation, range, truncatedLeft, truncatedRight, direction) {
    this.annotation = annotation;
    this.range = range;
    this.truncatedLeft = truncatedLeft;
    this.truncatedRight = truncatedRight;
    this.direction = direction;
  }
  /**
   * Creates a new Interval from the raw IntervalData.
   */
  static fromData(data, annotation) {
    return new Interval(annotation, new RRange(Number(data.min) - 1, Number(data.max)), Boolean(data.truncatedMin), Boolean(data.truncatedMax), Interval.normalizeDirection(data.direction));
  }
  /**
   * Returns an IntervalData object that represents the Interval's state.
   *
   * @returns a copy of the raw IntervalData
   */
  getData() {
    return {
      ...this.range.to1BasedInclusiveInterval(),
      direction: this.direction,
      truncatedMin: this.truncatedLeft,
      truncatedMax: this.truncatedRight
    };
  }
  /**
   * Returns a copy of this Interval object with the range set to the specified value.
   *
   * @param newRange range for the new Interval
   * @returns the new Interval
   */
  withRange(newRange) {
    return new Interval(this.annotation, newRange, this.truncatedLeft, this.truncatedRight, this.direction);
  }
  /**
   * Returns a new Interval object with a trimmed range. Trims the end by
   * `offset` if `shrinkEnd` is true. Otherwise, trims the start.
   *
   * @param offset how much to shrink the range
   * @param shrinkEnd determines whether to shrink the end or the start
   * @returns the new Interval
   */
  shrink(offset, shrinkEnd) {
    return this.withRange(this.range.shrinkAt(offset, shrinkEnd));
  }
  /**
   * Returns a new Interval object with a larger range. Grows the end by
   * `offset` if `growEnd` is true. Otherwise, grows the start.
   *
   * @param offset how much to grow the range
   * @param shrinkEnd determines whether to grow the end or the start
   * @returns the new Interval
   */
  grow(offset, growEnd) {
    return this.withRange(this.range.growAt(offset, growEnd));
  }
  /**
   * Returns a new Interval object with the {@link right} side truncated to the
   * specified value.
   *
   * @param newRight the new value for Interval.right (the range end)
   * @returns the new Interval
   */
  truncateRight(newRight) {
    return new Interval(this.annotation, new RRange(this.left, newRight), this.truncatedLeft, true, this.direction);
  }
  /**
   * Returns a new Interval object with the {@link left} side truncated to the
   * specified value.
   *
   * @param newLeft the new value for Interval.left (the range start)
   * @returns the new Interval
   */
  truncateLeft(newLeft) {
    return new Interval(this.annotation, new RRange(newLeft, this.right), true, this.truncatedRight, this.direction);
  }
  /**
   * Returns a new Interval object that is translated by the given amount.
   *
   * @param amount the amount to shift the range
   * @returns the new Interval
   */
  shift(amount) {
    return this.withRange(this.range.shift(amount));
  }
  get propertiesAsHTML() {
    const items = this.displayQualifiers.map(({
      name,
      value
    }) => `<li><strong>${name}:</strong> ${value}</li>`).join("");
    return `<ul>${items}</ul>`;
  }
  get displayQualifiers() {
    return [{
      name: "Name",
      value: this.annotation.name
    }, {
      name: "Type",
      value: this.annotation.rawType
    }, {
      name: "Interval",
      value: this.coordinateLabel
    }].concat(this.annotation.nonSystemProperties).filter(qualifier => qualifier.value?.length);
  }
  get coordinateLabel() {
    const labels = this.annotation.intervals.map(other => {
      return other === this ? `<strong>${other._coordinateLabel}</strong>` : other._coordinateLabel;
    });
    return labels.join(", ");
  }
  get _coordinateLabel() {
    const {
      first,
      last
    } = this.biologicalCoordinates;
    const direction = this.direction;
    if (this.between) {
      return `between ${last} and ${first}`;
    } else if (direction > 0) {
      return `${first} \u2192 ${last}`;
    } else if (direction < 0) {
      return `${last} \u2192 ${first}`;
    } else if (first === last) {
      return `${first}`;
    } else {
      return `${first} - ${last}`;
    }
  }
  get left() {
    return this.range.start;
  }
  get right() {
    return this.range.end;
  }
  get length() {
    return this.range.length;
  }
  get biologicalCoordinates() {
    return {
      first: this.left + 1,
      last: this.right
    };
  }
  get between() {
    return this.range.length < 1;
  }
  get zeroLength() {
    return this.between;
  }
  /**
   * Reverses the order of the items, but only if this is a reversed interval.
   */
  fixOrder(items) {
    return fixOrder(items, this.isReversed);
  }
  get isReversed() {
    return this.direction < 0;
  }
  get isForwards() {
    return this.direction > 0;
  }
  get hasDirection() {
    return !this.undirected;
  }
  get undirected() {
    return this.direction == 0;
  }
  get ungappedRanges() {
    const rangesCache = this.annotation.rangesCache;
    if (rangesCache === void 0) {
      return [];
    }
    const ranges = rangesCache.getInRangeAsMultiRange(this.range).intersection(this.range).ranges;
    return this.fixOrder(ranges);
  }
  static normalizeDirection(raw) {
    switch (typeof raw) {
      case "number":
        return raw;
      case "string":
        {
          const lower = raw.toLowerCase();
          const map = {
            reverse: -1,
            left: -1,
            undirected: 0
          };
          return lower in map ? map[lower] : 1;
        }
      default:
        return 1;
    }
  }
}
export { Interval as default };