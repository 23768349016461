import StampCache from "../../plugins/Sequences/StampCache.js";
import RRange from "../Range/RRange.js";
import { adjustRange } from "../misc/DPIRatio.js";
import { clamp } from "../misc/Math.js";
import { centerVertically, isCanonicalNucleotide, lighten, notEmpty, textColorForBackground } from "../misc/Utils.js";
import SequenceLines from "./SequenceLines.js";
const {
  min,
  floor,
  round
} = Math;
class SequencePainter {
  constructor(view) {
    this.view = view;
    this.colorSchemes = {
      Nucleotide: view.sv.DNAColorSchemes,
      AminoAcid: view.sv.proteinColorSchemes
    };
    if (this.view.sv.selection) {
      this.settings.selectionMargins = 6;
    }
    this.stampCache = new StampCache(this);
    this.view.sv.bind("wrapped changed", () => this.view.sv.emit("height changed"));
  }
  settings = {
    selectionMargins: 0,
    zoomedInHeight: 7,
    zoomedOutHeight: 7,
    maxTextHeight: 16,
    textThreshold: 5,
    charAspectRatio: 0.6,
    fontSizeFraction: 0.85
  };
  grey = "#9da0a1";
  qualityColorList = [
  // From lowest to highest quality.
  "#0064FF", "#1E82FF", "#3CA0FF", "#5ABEFF", "#78DCFF", "#96FAFF"];
  aaTable = {
    A: "Ala",
    R: "Arg",
    N: "Asn",
    D: "Asp",
    C: "Cys",
    E: "Glu",
    Q: "Gln",
    G: "Gly",
    H: "His",
    I: "Ile",
    L: "Leu",
    K: "Lys",
    M: "Met",
    F: "Phe",
    P: "Pro",
    S: "Ser",
    T: "Thr",
    W: "Trp",
    Y: "Tyr",
    V: "Val"
  };
  colorSchemes;
  stampCache;
  get printLetter() {
    return this.residueWidth >= this.settings.textThreshold;
  }
  get brush() {
    return this.view.brush;
  }
  render(context, channel) {
    const visible = context.visible;
    if (channel.rangesCache) {
      channel.rangesCache.request(visible);
    }
    if (!this.view.residuesAreVisible) {
      this.renderLines(context, channel);
    } else {
      channel.sequenceCache?.request(visible);
      if (channel.qualitiesCache && channel.colorScheme === "quality") {
        channel.qualitiesCache.request(visible);
      }
      this.renderResidues(context, channel);
    }
  }
  renderBox(color, {
    x,
    y,
    width,
    height
  }) {
    this.graphics.fillRect(this.brush, x, y, width, height, color);
  }
  /**
   * Renders the sequence as lines when zoomed too far out to render individual residues.
   */
  renderLines(context, channel) {
    const lines = this.newSequenceLines(context, channel);
    if (lines) {
      this.view.sv.emit("alter sequence lines", context, channel, lines);
      this.renderByPixels(context, lines);
    }
  }
  newSequenceLines({
    visible
  }, channel) {
    if (channel.sequenceRange === void 0 || channel.rangesCache === void 0) {
      return;
    }
    const result = new SequenceLines(channel.sequenceRange, {
      colors: ["black"],
      size: "thin",
      priority: 99
    });
    const ungappedRanges = channel.rangesCache.getInRange(visible).map(({
      range
    }) => range);
    result.insertMultipleAndReplace(ungappedRanges, {
      colors: ["black"],
      size: "thick",
      priority: 9
    });
    result.insertMultipleAndReplace(channel.trims.ranges, {
      colors: [this.grey],
      size: "thick",
      priority: 10
    });
    return result;
  }
  renderByPixels(context, lines) {
    const visible = context.visible;
    const start = this.toPixels(visible.start);
    const end = this.toPixels(visible.end);
    const offset = this.toPixels(context.row.start) + context.offset;
    for (let i = start; i < end; i++) {
      const residues = new RRange(floor(this.view.toResidues(i)), floor(this.view.toResidues(i + 1)));
      const values = lines.items.filter(item => item.range.overlaps(residues)).map(item => item.value).filter(notEmpty);
      const initial = {
        priority: 0,
        size: null,
        colors: []
      };
      const pixel = values.reduce((prev, curr) => prev.priority > curr.priority ? prev : curr, initial);
      this.renderPixel(pixel, i - offset);
    }
  }
  toPixels(residue) {
    return floor(this.view.toPixels(residue));
  }
  renderPixel(pixel, x) {
    const size = pixel.size === "thick" ? this.height : 1;
    const {
      y,
      height: totalHeight
    } = centerVertically(size, {
      width: 1,
      height: this.heightWithMargin
    });
    const horizontal = adjustRange(x, 1);
    if (pixel.background) {
      this.renderBox(pixel.background, {
        x: horizontal.start,
        y: 0,
        width: horizontal.length,
        height: this.heightWithMargin
      });
    }
    const height = totalHeight / pixel.colors.length;
    pixel.colors.forEach((color, index) => {
      const vertical = adjustRange(y + index * height, height);
      this.renderBox(color, {
        x: horizontal.start,
        y: vertical.start,
        width: horizontal.length,
        height: vertical.length
      });
    });
  }
  /**
   * Mid and close zoom levels.
   */
  renderResidues(context, channel) {
    const {
      start,
      end
    } = context.visible;
    for (let index = start; index < end; index++) {
      const residue = this.getResidue(index, channel);
      if (residue) {
        const dimensions = {
          width: this.residueWidth,
          height: this.heightWithMargin
        };
        this.stampCache.get(residue, dimensions).pressOnto(this.brush, context.toViewportOffset(index));
      }
    }
  }
  getResidue(index, channel) {
    const residue = channel.getResidue(index);
    if (residue) {
      this.view.sv.emit("alter residue data", residue, channel);
      residue.foreground = this.textColor(residue, channel);
      residue.background = [this.backgroundColor(residue, channel)];
      this.view.sv.emit("alter residue colors", residue, channel);
      if (this.view.sv.showThreeLetterAminoAcids) {
        this.threeLetterAminoAcids(residue, channel);
      }
      return Object.freeze(residue);
    }
  }
  threeLetterAminoAcids(residue, {
    sequenceType
  }) {
    if (sequenceType === "AminoAcid" && this.residueWidth > 50 && this.aaTable[residue.letter]) {
      residue.letter = this.aaTable[residue.letter];
    }
  }
  textColor({
    index,
    letter,
    isTrimmed
  }, channel) {
    if (isTrimmed) {
      return this.grey;
    } else if (letter === "-") {
      return "black";
    } else {
      const color = this.mapResidueColor(channel, letter);
      if (channel.useQuality(index) || this.isBackgroundColorScheme(channel)) {
        return textColorForBackground(color);
      } else {
        return color;
      }
    }
  }
  backgroundColor({
    index,
    letter,
    isTrimmed,
    isDeemphasized
  }, channel) {
    const printLetter = this.printLetter;
    const scheme = channel.colorScheme;
    if (isTrimmed || isDeemphasized && letter !== "-") {
      return printLetter ? channel.background : this.grey;
    } else if (letter === "-") {
      return scheme === "quality" && !isDeemphasized ? this.mapResidueColor(channel, "-", "quality") : channel.background;
    } else if (channel.useQuality(index)) {
      return isCanonicalNucleotide(letter) ? this.calculateQualityColor(index, channel) : this.mapResidueColor(channel, letter, "quality");
    } else if (this.isBackgroundColorScheme(channel)) {
      return this.mapResidueColor(channel, letter);
    } else if (!printLetter) {
      return lighten(this.mapResidueColor(channel, letter));
    } else {
      return channel.background;
    }
  }
  calculateQualityColor(i, channel) {
    const score = channel.qualitiesCache.get(i) ?? 0;
    const maxQuality = channel.qualitiesCache.maxQuality;
    const count = this.qualityColorList.length;
    const bracket = floor(score * count / maxQuality);
    const index = clamp(bracket, count - 1);
    return this.qualityColorList[index];
  }
  isBackgroundColorScheme(channel) {
    const scheme = this.colorSchemes[channel.sequenceType][channel.colorScheme];
    return !scheme.isForeground;
  }
  mapResidueColor(channel, residue, name = channel.colorScheme) {
    const scheme = this.colorSchemes[channel.sequenceType][name];
    return this.colorForResidue(scheme, residue);
  }
  colorForResidue(scheme, residue) {
    if (residue == null || residue.length !== 1) {
      throw new Error(`Residues must be one character: ${residue}`);
    }
    residue = residue.toLowerCase();
    if (residue === "u") {
      residue = "t";
    }
    return scheme.colors[residue] || scheme.defaultColor;
  }
  get residueWidth() {
    return this.view.residueWidth;
  }
  get fontSize() {
    const {
      charAspectRatio,
      fontSizeFraction
    } = this.settings;
    return fontSizeFraction * min(this.height, round(this.residueWidth / charAspectRatio));
  }
  get channelHeight() {
    return this.channelHeightNoMargin + this.settings.selectionMargins;
  }
  get channelHeightNoMargin() {
    return this.view.wrapped ? this.settings.maxTextHeight : this.height;
  }
  get heightWithMargin() {
    return this.height + this.settings.selectionMargins;
  }
  get height() {
    const {
      residuesAreVisible,
      residueWidth
    } = this.view;
    const {
      zoomedInHeight,
      zoomedOutHeight,
      maxTextHeight,
      charAspectRatio,
      textThreshold
    } = this.settings;
    if (!residuesAreVisible) {
      return zoomedOutHeight;
    } else if (residueWidth >= textThreshold) {
      return min(maxTextHeight, round(residueWidth / charAspectRatio));
    } else {
      return zoomedInHeight;
    }
  }
  get graphics() {
    return this.view.graphics;
  }
}
export { SequencePainter as default };