async function exportInSlices(sv, dimensions, debugEnabled) {
  const WIDTH = dimensions.width;
  const HEIGHT = dimensions.height;
  const hasTrees = !!sv.tree?.enabled;
  const hasLabels = !!sv.labels?.enabled;
  const hasMetadata = !!sv.metadata?.enabled;
  const sidebarWidth = sv.channelView.viewport.sidebarWidth;
  const referenceSequenceHeight = sv.channelView.reference ? sv.channelView.reference.heightWithMargin : 0;
  const metadataLabelHeight = sv.metadata.labelHeight;
  const metadataVisible = sv.metadata.metadataVisible;
  function getLabelHeight() {
    return Math.max(metadataLabelHeight, 60);
  }
  if (metadataVisible) {
    sv.bind("alter globals wrapper height", getLabelHeight);
  }
  const maxYTiles = calculateMaxYTiles(sv, HEIGHT, referenceSequenceHeight);
  const maxXTiles = calculateMaxXTiles(sv, WIDTH, sidebarWidth);
  for (let y = 0; y < maxYTiles; y++) {
    if (y > 0 && sv.sorting) {
      sv.sorting.pinReference = false;
    }
    const lastYTile = y === maxYTiles - 1;
    if (lastYTile) {
      sv.export.height = heightRemaining(sv, y, referenceSequenceHeight, HEIGHT);
      sv.offset = {
        x: 0,
        y: Number.MAX_SAFE_INTEGER
      };
    } else if (y === 1) {
      const yOffset = referenceSequenceHeight ? sv.offset.y + HEIGHT - referenceSequenceHeight : sv.offset.y + HEIGHT;
      sv.offset = {
        x: 0,
        y: yOffset
      };
    } else if (y > 1) {
      sv.offset = {
        x: 0,
        y: sv.offset.y + HEIGHT
      };
    }
    resetCanvasWidth(sv, WIDTH);
    for (let x = 0; x < maxXTiles; x++) {
      const lastXTile = x === maxXTiles - 1;
      if (x === 0) {
        setPluginEnabled(sv, "tree", hasTrees);
        setPluginEnabled(sv, "labels", hasLabels);
        setPluginEnabled(sv, "metadata", hasMetadata);
      } else {
        setPluginEnabled(sv, "tree", false);
        setPluginEnabled(sv, "labels", false);
        setPluginEnabled(sv, "metadata", false);
      }
      if (lastXTile) {
        const currentOffset = sv.offset;
        sv.export.width = widthRemaining(sv, x, sidebarWidth, WIDTH);
        sv.offset = {
          x: Number.MAX_SAFE_INTEGER,
          y: currentOffset.y
        };
      } else if (x === 1) {
        sv.changeOffsetBy({
          x: WIDTH - sidebarWidth,
          y: 0
        });
      } else if (x > 1) {
        sv.changeOffsetBy({
          x: WIDTH,
          y: 0
        });
      }
      await sv.bindOncePromise("idle");
      await createImage(sv, {
        x,
        y
      }, debugEnabled);
    }
  }
  if (metadataVisible) {
    sv.unbind("alter globals wrapper height", getLabelHeight);
  }
  sv.emit("export complete");
  return;
}
function setPluginEnabled(sv, name, enabled) {
  const plugin = sv[name];
  if (plugin) {
    plugin.enabled = enabled;
  }
}
function resetCanvasWidth(sv, width) {
  const currentOffset = sv.offset;
  sv.export.width = width;
  sv.offset = {
    x: 0,
    y: currentOffset.y
  };
}
function createImage(sv, coordinates, debugEnabled) {
  if (debugEnabled) {
    console.log(coordinates, sv.view.brush.canvas.toDataURL());
  }
  return new Promise((resolve, reject) => {
    sv.view.brush.canvas.toBlob(blob => {
      if (!blob) {
        reject("canvas.toBlob returned null");
        return;
      }
      sv.emit("export newImage", new File([blob], `Sequence Viewer Image ${coordinates.x}_${coordinates.y}.png`));
      resolve(blob);
    });
  });
}
function widthRemaining(sv, x, sidebarWidth, WIDTH) {
  if (x === 0) {
    return sv.channelView.width + sidebarWidth;
  } else if (x === 1) {
    if (sv.channelView.width < WIDTH) {
      return sv.channelView.width;
    }
    return sv.channelView.width + sidebarWidth - WIDTH;
  } else {
    return sv.channelView.width - sv.channelView.offset.x - WIDTH;
  }
}
function heightRemaining(sv, y, referenceSequenceHeight, HEIGHT) {
  if (y === 0) {
    return sv.channelView.height;
  } else if (y === 1) {
    return sv.channelView.height + referenceSequenceHeight - HEIGHT;
  } else {
    return sv.channelView.height - sv.channelView.offset.y - HEIGHT;
  }
}
function calculateMaxXTiles(sv, dimension, sidebarWidth) {
  return Math.ceil((sv.channelView.width + sidebarWidth) / dimension);
}
function calculateMaxYTiles(sv, dimension, referenceSequenceHeight) {
  return Math.ceil((sv.channelView.height + referenceSequenceHeight) / dimension);
}
export { exportInSlices };