import ambiguityMappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import AbstractCalculator from "../../includes/PluginBaseClasses/AbstractCalculator.js";
const {
  max,
  ceil
} = Math;
class WuKabatCalculator extends AbstractCalculator {
  getCandidateSequences(index) {
    return this.sequences.filter(sequence => !sequence.wrapper.isTrimmed(index));
  }
  getAggregate(residues) {
    const counts = /* @__PURE__ */new Map();
    for (let residue of residues) {
      if (residue === " ") {
        residue = "-";
      }
      const count = counts.get(residue);
      if (count) {
        counts.set(residue, count + 1);
      } else {
        counts.set(residue, 1);
      }
    }
    const bestScore = max(...counts.values());
    const uniqueResidues = counts.size;
    return this.sequences.length * uniqueResidues / bestScore;
  }
  get maxPossibleScore() {
    const unique = this.totalUniqueCharacters;
    const sequences = this.sequences.length;
    if (sequences <= unique) {
      return sequences * sequences;
    } else {
      return sequences * unique / ceil(sequences / unique);
    }
  }
  get totalUniqueCharacters() {
    return ambiguityMappers[this.type].totalUniqueCharacters;
  }
}
export { WuKabatCalculator as default };