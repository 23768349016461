import AbstractChannel from "../Channels/AbstractChannel.js";
import RenderNode from "../RenderEngine/RenderNode.js";
import { centerVertically } from "../misc/Utils.js";
class DataChannel extends AbstractChannel {
  constructor(wrapper) {
    super(wrapper.view);
    this.wrapper = wrapper;
  }
  search(_query, _residue, _dataRow) {
    return;
  }
  sequenceNode(type, context, bounds, channel) {
    const inner = centerVertically(this.sequencePainter.heightWithMargin, bounds);
    const child = new RenderNode(type, channel, inner);
    child.setRenderCallback(() => this.sequencePainter.render(context, channel.wrapper));
    return new RenderNode(type + "wrapper", this, bounds, [child]);
  }
  get sequencePainter() {
    return this.wrapper.view.sequencePainter;
  }
  // A channel can have its own length, or get its length from the parent or view.
  get length() {
    return this.sv.channelView.length;
  }
  get rowCount() {
    return 1;
  }
  get isSticky() {
    return false;
  }
}
export { DataChannel as default };