import ambiguityMappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import { complement, fixOrder, indexRange, RNAtoDNA } from "../../includes/misc/Utils.js";
import RRange from "../../includes/Range/RRange.js";
class TranslationsSearch {
  constructor(channel) {
    this.channel = channel;
  }
  matchTranslation(query, index) {
    if (this.cache) {
      const endIndex = this.matchCodon(RNAtoDNA(query.lower), 0, index);
      if (endIndex != null) {
        this.sv.view.focused = null;
        return new RRange(index, endIndex);
      }
    }
  }
  matchCodon(query, queryIndex, index) {
    const residues = query.split("");
    const frames = this.channel.globals.frames;
    if (this.sv.translations?.frames !== "" && frames) {
      for (const frame of frames) {
        if (this.codonStartsAtIndex(index, frame)) {
          const reversed = frame < 0;
          const normalized = fixOrder(residues, reversed).join("");
          const endIndex = this.matchByFrame(normalized, queryIndex, index, reversed);
          if (endIndex != null) {
            return endIndex;
          }
        }
      }
    } else {
      if (!this.sv.annotations) {
        return void 0;
      }
      const annotations = this.sv.annotations.getByRangeFromWrapper(this.channel.wrapper, indexRange(index)).filter(annotation => annotation.isTranslatable);
      for (const annotation of annotations) {
        const reversed = annotation.intervals[0].isReversed;
        const normalized = fixOrder(residues, reversed).join("");
        const endIndex = this.matchByAnnotation(normalized, queryIndex, index, annotation);
        if (endIndex != null) {
          return endIndex;
        }
      }
    }
  }
  codonStartsAtIndex(index, frame) {
    const frameOffset = this.channel.frameTranslationsCache.frameOffset(frame) ?? 0;
    return (this.channel.rangesCache.toUngapped(index) - frameOffset) % 3 === 0;
  }
  matchByFrame(query, queryIndex, index, reverse = false) {
    const codon = [];
    while (codon.length < 3) {
      if (!this.cache.isSet(index)) {
        return null;
      }
      const residue = this.cache.getAsDNA(index);
      if (residue !== "-") {
        codon.push(reverse ? complement[residue] : residue);
      }
      index++;
    }
    if (reverse) {
      codon.reverse();
    }
    const queryChar = query.charAt(queryIndex);
    const translatedCodon = this.sv.translations.data.translateCodon(codon, null)?.text.trim();
    if (query.length === 1) {
      if (translatedCodon === queryChar) {
        return index;
      } else {
        return null;
      }
    } else if (this.codonMatchesCharacter(translatedCodon, queryChar)) {
      if (queryIndex === query.length - 1) {
        return index;
      } else {
        return this.matchByFrame(query, queryIndex + 1, index, reverse);
      }
    } else {
      return null;
    }
  }
  matchByAnnotation(query, queryIndex, index, annotation) {
    const queryChar = query.charAt(queryIndex);
    const gapped = this.channel.annotationTranslationsCache.translationAnnotation(annotation);
    const codonWithEnd = this.getCodonAtIndex(gapped, index);
    if (!codonWithEnd) {
      return null;
    }
    const {
      codon,
      endIndex
    } = codonWithEnd;
    if (this.codonMatchesCharacter(codon, queryChar)) {
      if (queryIndex === query.length - 1) {
        return endIndex;
      } else {
        return this.matchByAnnotation(query, queryIndex + 1, endIndex, annotation);
      }
    } else {
      return null;
    }
  }
  getCodonAtIndex(gapped, index) {
    const codon = gapped[index];
    if (!codon || codon.position !== 0) {
      return null;
    }
    return {
      codon: codon.letter.trim().toLowerCase(),
      endIndex: index + 3
      // End index is exclusive
    };
  }
  codonMatchesCharacter(codon, queryChar) {
    if (!codon) {
      return false;
    }
    const parts = codon.split("/");
    if (parts.length === 1) {
      return this.codonsMatch(queryChar, codon);
    } else if (parts.length === 2) {
      return this.anyCodonMatches(queryChar, parts);
    } else {
      throw new Error("Translation search does not support more than 2 ambiguous translations.");
    }
  }
  anyCodonMatches(reference, candidates) {
    return candidates.some(aa => this.codonsMatch(aa, reference));
  }
  codonsMatch(a, b) {
    return ambiguityMappers.AminoAcid.residuesMatch(a, b);
  }
  get cache() {
    return this.channel.wrapper.sequenceCache;
  }
  get sv() {
    return this.channel.sv;
  }
}
export { TranslationsSearch as default };