const EDITED_NUCLEOTIDE_QUALITY = 63;
const GRAPH_GAP_VALUE = -2147483648;
const AnnotationType = {
  CDS: "CDS",
  Crispr: "CRISPR",
  DNAProbeBind: "DNA probe bind",
  /**
   * Records an edit where residues were removed.
   * @see {@link QualifierName.OriginalBases}
   */
  EditingHistoryDeletion: "Editing History Deletion",
  /**
   * Records an edit where residues were inserted.
   * @see {@link QualifierName.OriginalBases}
   */
  EditingHistoryInsertion: "Editing History Insertion",
  /**
   * Records an edit where a range of residues were replaced with new ones. Note
   * that the length of the new residues can be different to the original range.
   * @see {@link QualifierName.OriginalBases}
   */
  EditingHistoryReplacement: "Editing History Replacement",
  ExtractedRegion: "extracted region",
  MiscFeature: "misc_feature",
  Motif: "motif",
  OptimizedCodon: "Optimized Codon",
  ORF: "ORF",
  PotentialRestrictionSite: "potential restriction site",
  PrimerBind: "primer_bind",
  PrimerBindReverse: "primer_bind_reverse",
  RestrictionSite: "restriction site",
  TranslatedRegion: "translated region"
};
const QualifierName = {
  /**
   * The qualifier name that NCBI uses to provide the translation of a CDS
   * annotation, taking into account qualifiers that Geneious may not, e.g.
   * `transl_except`.
   */
  Translation: "translation",
  /**
   * When editing an annotation containing a `translation` property, Geneious
   * renames this property to `Original Translation`
   */
  OriginalTranslation: "Original Translation",
  /**
   * Qualifier name used by NCBI for the frame of a CDS (may be 1, 2 or 3).
   */
  CodonStart: "codon_start",
  /**
   * Records the bases that were originally in the location covered by one of
   * the following annotations:
   * - {@link AnnotationType.EditingHistoryDeletion}
   * - {@link AnnotationType.EditingHistoryInsertion}
   * - {@link AnnotationType.EditingHistoryReplacement}
   */
  OriginalBases: "Original Bases",
  /**
   *  Stores a user-friendly description of an edit.
   */
  EditingHistory: "Editing History"
};
class EditingAnnotationTypes {
  /** Types of annotations that have codon_start qualifiers */
  checkCodonStartTypes = this.lowercaseSet(AnnotationType.CDS, AnnotationType.ORF);
  /** Types of annotations that should always be removed when edited */
  removeWhenEditedTypes;
  /** Editing history annotations */
  editingHistoryTypes = this.lowercaseSet(AnnotationType.EditingHistoryDeletion, AnnotationType.EditingHistoryInsertion, AnnotationType.EditingHistoryReplacement);
  constructor() {
    const alwaysRemoveTypes = this.lowercaseSet(AnnotationType.DNAProbeBind, AnnotationType.Motif, AnnotationType.PrimerBind, AnnotationType.PrimerBindReverse);
    const readonlyTypes = this.lowercaseSet(AnnotationType.Crispr, AnnotationType.EditingHistoryDeletion, AnnotationType.EditingHistoryInsertion, AnnotationType.EditingHistoryReplacement, AnnotationType.ExtractedRegion, AnnotationType.OptimizedCodon, AnnotationType.PotentialRestrictionSite, AnnotationType.RestrictionSite, AnnotationType.TranslatedRegion);
    const readonlyExceptions = this.lowercaseSet(AnnotationType.OptimizedCodon, ...this.editingHistoryTypes);
    const removeWhenEditedTypes = /* @__PURE__ */new Set();
    readonlyTypes.forEach(t => removeWhenEditedTypes.add(t));
    readonlyExceptions.forEach(t => removeWhenEditedTypes.delete(t));
    alwaysRemoveTypes.forEach(t => removeWhenEditedTypes.add(t));
    this.removeWhenEditedTypes = removeWhenEditedTypes;
  }
  lowercaseSet(...items) {
    return new Set(items.map(item => item.toLowerCase()));
  }
  /**
   * Returns true if an annotation should be removed due to an edit (based on
   * its type). This method assumes that the annotations has an interval that
   * overlaps with the edit range - otherwise, it should not be removed.
   *
   * @param annotation the annotation
   * @returns true if the annotation should be removed
   */
  removeWhenEdited(annotation) {
    return this.removeWhenEditedTypes.has(annotation.normalType);
  }
  /**
   * Returns true if an annotation may need its {@link QualifierName.CodonStart}
   * qualifier adjusting, based on its type.
   *
   * @param annotation the annotation
   * @returns true if the annotation's `codon_start` qualifier should be checked
   */
  checkCodonStart(annotation) {
    return this.checkCodonStartTypes.has(annotation.normalType);
  }
  /**
   * Returns true if the annotation is an editing history annotation.
   *
   * @param annotation the annotation
   * @returns true if the annotation type is an editing history type
   */
  isEditingHistoryAnnotation(annotation) {
    return this.editingHistoryTypes.has(annotation.normalType);
  }
}
export { AnnotationType, EDITED_NUCLEOTIDE_QUALITY, EditingAnnotationTypes, GRAPH_GAP_VALUE, QualifierName };