import Color from "tinycolor2";
import ambiguityMappers from "../AmbiguityMappers/AmbiguityMappers.js";
import RRange from "../Range/RRange.js";
import { bottomAlignOffset, centerOffset } from "./Math.js";
function centerVertically(height, dimensions) {
  return {
    x: 0,
    y: centerOffset(dimensions.height, height),
    width: dimensions.width,
    height
  };
}
function bottomAlignVertically(height, dimensions) {
  return {
    x: 0,
    y: bottomAlignOffset(dimensions.height, height),
    width: dimensions.width,
    height
  };
}
function boundsOverlap(a, b) {
  if (!a || !b) {
    return false;
  } else {
    return a.x < b.x + b.width && b.x < a.x + a.width && a.y < b.y + b.height && b.y < a.y + a.height;
  }
}
const complement = {
  A: "T",
  T: "A",
  U: "A",
  C: "G",
  G: "C",
  M: "K",
  K: "M",
  R: "Y",
  Y: "R",
  W: "W",
  S: "S",
  B: "V",
  V: "B",
  D: "H",
  H: "D",
  N: "N"
};
function RNAtoDNA(RNA) {
  return RNA.replace(/U/g, "T").replace(/u/g, "t");
}
function fixReverseComplement(DNA, reversed = true) {
  const sequence = fixOrder(DNA, reversed);
  if (!reversed) {
    return sequence;
  } else {
    return sequence.map(nucleotide => complement[nucleotide] || nucleotide);
  }
}
function fixOrder(sequence, reversed = true) {
  const result = [...sequence];
  if (reversed) {
    result.reverse();
  }
  return result;
}
function getUngappedRanges(sequence) {
  const ranges = [];
  let startIndex = 0;
  const nextMatch = matchGenerator(/[^- ]+/g, sequence);
  for (let match = nextMatch(); match; match = nextMatch()) {
    ranges.push({
      start: startIndex,
      range: {
        start: match.index,
        length: match[0].length
      }
    });
    startIndex += match[0].length;
  }
  return ranges;
}
function matchGenerator(expression, haystack) {
  return () => expression.exec(haystack);
}
function hashCode(value) {
  let hash = 0;
  let index = 0;
  if (value.length > 0) {
    while (index < value.length) {
      hash = (hash << 5) - hash + value.charCodeAt(index++) | 0;
    }
  }
  return hash;
}
function pseudoRandom(key, items) {
  const hash = hashCode(key);
  const index = Math.abs(hash % items.length);
  return items[index];
}
function indexRange(index) {
  return new RRange(index, index + 1);
}
function lowestString(candidates) {
  return candidates.reduce((a, b) => a < b ? a : b);
}
function darken(color, step) {
  return darkenInstance(Color(color), step);
}
function darkenInstance(color, step) {
  return Color.mix(color, Color("black"), step);
}
function whitenInstance(color, step) {
  return Color.mix(color, Color("white"), step);
}
const lightenCache = {};
function lighten(color) {
  if (!(color in lightenCache)) {
    lightenCache[color] = Color(color).lighten(15).toString();
  }
  return lightenCache[color];
}
function textColorForBackground(background) {
  return Color(background).isDark() ? "white" : "black";
}
function isCanonicalNucleotide(residue) {
  return ambiguityMappers.Nucleotide.isCanonical(residue);
}
function clipBrush(graphics, brush, {
  width,
  height
}) {
  brush.beginPath();
  const rect = graphics.getRect(brush, 0, 0, width, height);
  brush.clip(rect);
}
function clearCanvas(canvas, brush, {
  width,
  height
}) {
  brush.clearRect(0, 0, canvas.width, canvas.height);
  const scale = window.devicePixelRatio;
  canvas.width = width * scale;
  canvas.height = height * scale;
  brush.scale(scale, scale);
}
function isPrimaryButton(e) {
  return e.button === 0;
}
function isSequenceWrapper(wrapper) {
  return wrapper.type === "sequence";
}
function notEmpty(value) {
  return value !== null && value !== void 0;
}
function hasValidMateIndex(pairInformation) {
  return (pairInformation?.mateIndex ?? -1) >= 0;
}
function partitionArray(array, predicate) {
  const partitioned = [[], []];
  for (const elem of array) {
    predicate(elem) ? partitioned[0].push(elem) : partitioned[1].push(elem);
  }
  return partitioned;
}
function roundRect(ctx, x, y, width, height, radius = 5) {
  if (ctx.roundRect) {
    ctx.roundRect(x, y, width, height, radius);
    return;
  }
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}
function boundIndex(value, sequenceLength) {
  if (value <= sequenceLength) {
    return value;
  }
  return value - sequenceLength;
}
function distanceBetweenIndexes(indexA, indexB, sequenceLength) {
  if (indexB >= indexA) {
    return indexB - indexA;
  }
  return sequenceLength - indexA + indexB;
}
export { RNAtoDNA, bottomAlignVertically, boundIndex, boundsOverlap, centerVertically, clearCanvas, clipBrush, complement, darken, darkenInstance, distanceBetweenIndexes, fixOrder, fixReverseComplement, getUngappedRanges, hasValidMateIndex, hashCode, indexRange, isCanonicalNucleotide, isPrimaryButton, isSequenceWrapper, lighten, lowestString, matchGenerator, notEmpty, partitionArray, pseudoRandom, roundRect, textColorForBackground, whitenInstance };