import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FolderService } from '../folders/folder.service';
import { defaultIfEmpty, first, map, switchMap, take } from 'rxjs/operators';
import { DatabaseRootFolder } from '../folders/models/folder.model';
import { IChildrenOptions } from '../../../nucleus/v2/folder-http.v2.service';
import { MasterDatabaseFolderAndFiles } from './index';
import { DocumentService } from '../../../nucleus/services/documentService/document-service.v1';
import { AnnotatedPluginDocument } from '../geneious';
import { AntibodyType } from './antibody-type/antibody-type';
import { DocumentServiceTableInfo } from '../../../nucleus/services/documentService/types';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectReferenceDatabaseID } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class MasterDatabaseService {
  static readonly TABLE_NAME = 'MASTER_DATABASE';

  constructor(
    private store: Store<AppState>,
    private folderService: FolderService,
    private documentService: DocumentService,
    private documentHttpService: DocumentHttpV2Service,
  ) {}

  getTable(masterDatabaseID: string): Observable<DocumentServiceTableInfo> {
    return this.documentService.getTable(masterDatabaseID, MasterDatabaseService.TABLE_NAME);
  }

  getDatabases(): Observable<MasterDatabaseFolderAndFiles[]> {
    return this.store.pipe(
      select(selectReferenceDatabaseID),
      take(1),
      switchMap((referenceDatabaseID) =>
        this.folderService.getChildren(referenceDatabaseID).pipe(first()),
      ),
      switchMap((folders) => {
        const masterDatabaseRootFolder = folders
          .filter((folder): folder is DatabaseRootFolder => folder instanceof DatabaseRootFolder)
          .find((folder) => folder.isMasterDatabaseRoot);
        return this.folderService.getChildren(masterDatabaseRootFolder.id).pipe(first());
      }),
      switchMap((folders) => {
        const folderChildren$: Observable<MasterDatabaseFolderAndFiles>[] = folders.map(
          (folder) => {
            const params: IChildrenOptions = {
              folderID: folder.id,
              pageLimit: 1000,
              pageOffset: 0,
              maxDepth: 1,
            };
            return this.folderService
              .getFilteredChildrenEntities(params)
              .pipe(map((response) => ({ folder: folder, databases: response.data })));
          },
        );

        return forkJoin(folderChildren$);
      }),
      defaultIfEmpty([]),
    );
  }

  validateSequenceTypeFromAnnotatedSequences(
    masterDatabaseID: string,
    importSequencesResultDocument: AnnotatedPluginDocument,
  ): Observable<{ matchesAlphabet: boolean; matchesAntibodyType: boolean }> {
    return this.documentHttpService.get(masterDatabaseID).pipe(
      map((item) => AnnotatedPluginDocument.fromNucleusItemV2(item)),
      map((masterDatabase) => {
        const alphabet: 'NUCLEOTIDE' | 'PROTEIN' = masterDatabase.getAllFields().alphabet;
        const antibodyType: AntibodyType = masterDatabase.getAllFields().antibodyType;
        const importedSequencesAlphabet =
          importSequencesResultDocument.getAllFields().molType === 'AA' ||
          importSequencesResultDocument.getAllFields().proteinSequenceCount > 0
            ? 'PROTEIN'
            : 'NUCLEOTIDE';
        // TODO Improve the determination for the import sequences antibody type as currently checking the description for 'Scaffold Annotator V2'
        //      is probably not full proof.
        const importedSequencesAntibodyType = importSequencesResultDocument
          .getAllFields()
          .description.includes('Scaffold Annotator v2')
          ? AntibodyType.NonStandard
          : AntibodyType.Standard;
        return {
          matchesAlphabet: alphabet === importedSequencesAlphabet,
          matchesAntibodyType: antibodyType === importedSequencesAntibodyType,
        };
      }),
    );
  }

  static isBestMatch(
    match: { collection_id: string; sequence_match_id: number },
    query: { best_match_urn: string },
  ): boolean {
    return MasterDatabaseService.getMatchUrn(match) === query.best_match_urn;
  }

  static getMatchUrn(match: { collection_id: string; sequence_match_id: number }): string {
    return `${match.collection_id}:${match['sequence_match_id']}`;
  }
}
