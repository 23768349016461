class Properties {
  constructor(merge) {
    this.merge = merge;
  }
}
function Defaults(defaults) {
  return new Properties((suppliedOptions, sv, path) => {
    suppliedOptions = suppliedOptions || {};
    const result = {};
    Object.entries(defaults).forEach(([key, item]) => {
      const raw = suppliedOptions[key];
      if (item instanceof Properties) {
        result[key] = item.merge(raw, sv, path.concat(key));
      } else {
        const option = isMigrated(item) ? item : {
          default: item,
          validator: () => true
        };
        if (raw === void 0) {
          result[key] = option.default;
        } else if (option.validator(raw)) {
          result[key] = raw;
        } else {
          result[key] = option.default;
          setTimeout(() => sv.emit("invalid initialization option", path, key, raw));
        }
      }
    });
    return result;
  });
}
function isMigrated(raw) {
  const item = raw;
  return Boolean(item && item.validator);
}
function Items(template) {
  return new Properties((props, sv, path = []) => {
    props = props || [];
    return props.map((prop, index) => Defaults(template).merge(prop, sv, path.concat(index)));
  });
}
export { Defaults, Items, Properties };