import ConsensusCalculator from "./ConsensusCalculator.js";
import StructureMap from "../../includes/misc/StructureMap.js";
import DerivedCache from "../../includes/PluginBaseClasses/DerivedCache.js";
import CharCache from "../../includes/PluginBaseClasses/CharCache.js";
class ConsensusCache extends DerivedCache {
  calculator;
  caches = new StructureMap(["useQualityScores", "threshold"]);
  constructor(channel, sequences) {
    super(channel, sequences);
    this.calculator = new ConsensusCalculator(this);
  }
  // @see SSelection
  // noinspection JSUnusedGlobalSymbols
  rangeIsSet = CharCache.prototype.rangeIsSet;
  // noinspection JSUnusedGlobalSymbols
  getAsDNA = CharCache.prototype.getAsDNA;
  // noinspection JSUnusedGlobalSymbols
  isDefined = CharCache.prototype.isDefined;
  // noinspection JSUnusedGlobalSymbols
  getRange = CharCache.prototype.getRange;
  // noinspection JSUnusedGlobalSymbols
  getRangeAsDNA = CharCache.prototype.getRangeAsDNA;
  // noinspection JSUnusedGlobalSymbols
  requestIndex = CharCache.prototype.requestIndex;
  get(index) {
    const cache = this.getCache();
    if (cache === void 0) {
      return;
    }
    if (cache[index] < 1) {
      const residue = this.calculator.get(index);
      if (residue) {
        cache[index] = residue.charCodeAt(0);
        return residue;
      }
    } else {
      return String.fromCharCode(cache[index]);
    }
  }
  clear() {
    this.caches.clear();
  }
  hasData() {
    return true;
  }
  maxIndex() {
    return this.channel.length;
  }
  getCache() {
    const {
      useQualityScores,
      threshold
    } = this.channel.plugin;
    const key = {
      useQualityScores,
      threshold
    };
    if (!this.caches.has(key)) {
      this.caches.set(key, new Uint8ClampedArray(this.channel.length));
    }
    return this.caches.get(key);
  }
}
export { ConsensusCache as default };