import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import SequenceChannel from "../Sequences/SequenceChannel.js";
import PairedReadsLayer from "./PairedReadsLayer.js";
import SequenceWrapper from "../../includes/Channels/SequenceWrapper.js";
import { hasValidMateIndex } from "../../includes/misc/Utils.js";
class PairedReadsPlugin extends AbstractPlugin {
  sequence = null;
  layer;
  constructor(sv) {
    super(sv);
    sv.pairedReads = this;
    sv.bindToNode("mouseenter", "label", node => this.showPairBoxes(node));
    sv.bindToNode("mouseenter", "sequence channel", node => this.showPairBoxes(node));
    sv.bindToNode("mouseleave", "label", () => this.hidePairBoxes());
    sv.bindToNode("mouseleave", "sequence channel", () => this.hidePairBoxes());
    sv.bind("alter label", (label, channel) => this.addIcons(label, channel));
    sv.bind("ready", () => this.layer = new PairedReadsLayer(this));
  }
  findPair(node) {
    if (!node) {
      return void 0;
    }
    const channel = node.reference;
    if (!channel.pairInformation) {
      return void 0;
    }
    return this.sv.channelView.sequences.find(sequence => channel.pairInformation.mateIndex === sequence.originalIndex);
  }
  serialize() {
    return void 0;
  }
  addIcons(label, channel) {
    const paired = this.sv.channelView.sequences.some(sequence => hasValidMateIndex(sequence.pairInformation));
    if (paired) {
      this.addIcon(label, channel);
    }
  }
  hidePairBoxes() {
    this.sequence = null;
  }
  showPairBoxes(node) {
    const wrapper = this.getSequenceWrapper(node);
    if (!wrapper) {
      return;
    }
    if (hasValidMateIndex(wrapper.pairInformation)) {
      this.sequence = wrapper.sequenceChannel ?? null;
    }
  }
  getSequenceWrapper(node) {
    if (node.reference instanceof SequenceWrapper) {
      return node.reference;
    } else if (node.reference instanceof SequenceChannel) {
      return node.reference["wrapper"];
    }
  }
  addIcon(label, channel) {
    label.icon = hasValidMateIndex(channel.pairInformation) ? "paired" : "unpaired";
  }
}
export { PairedReadsPlugin as default };