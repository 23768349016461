import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import TreeColumn from "./TreeColumn.js";
import { parse_newick } from "@geneious/biojs-io-newick";
class TreePlugin extends AbstractPlugin {
  levels;
  tree;
  _enabled;
  _width;
  newickData;
  constructor(sv) {
    super(sv);
    sv.tree = this;
    const options = this.getInitialOptions("tree", {
      enabled: {
        default: sv.options?.alignment.enabled,
        validator: raw => typeof raw === "boolean"
      },
      width: {
        default: void 0,
        validator: raw => typeof raw === "number"
      },
      newickData: {
        default: void 0,
        validator: raw => typeof raw === "string"
      }
    });
    this._enabled = options.enabled;
    this.newickData = options.newickData;
    if (options.newickData) {
      this.parse();
      this._width = options.width || this.levels * 6;
      this.sv.bind("register sidebar columns", () => new TreeColumn(this));
    }
  }
  parse() {
    const rawTree = parse_newick(this.newickData);
    this.tree = this.growTree(rawTree);
    this.levels = 1 + this.tree.level;
  }
  growTree(rawTree) {
    if (rawTree.children) {
      const children = rawTree.children.map(child => this.growTree(child));
      const level = 1 + Math.max(...children.map(child => child.level));
      return {
        children,
        level
      };
    } else {
      const index = Number(rawTree.name);
      if (String(index) !== rawTree.name) {
        console.error(rawTree);
        throw new Error("Name of newick rawTree nodes must be an integer.");
      } else {
        return {
          index,
          level: 0
        };
      }
    }
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    const changed = this.setBooleanOption("_enabled", raw);
    if (changed) {
      this.sv.emit("column visibility changed");
      if (this._enabled && this.sv.sorting) {
        this.sv.sorting.sortBy([]);
      }
    }
  }
  get width() {
    return this._width ?? 0;
  }
  set width(raw) {
    const changed = this.setNumberOption("_width", raw);
    if (changed) {
      this.sv.emit("column visibility changed");
    }
  }
  serialize() {
    return {
      enabled: this.enabled,
      width: this.width,
      newickData: this.newickData
    };
  }
}
export { TreePlugin as default };