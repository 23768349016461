import RenderEngine from "../RenderEngine/RenderEngine.js";
import RenderNode from "../RenderEngine/RenderNode.js";
import { centerVertically } from "../misc/Utils.js";
import ChannelViewport from "./ChannelViewport.js";
import RRange from "../Range/RRange.js";
import Pen from "../RenderEngine/Pen";
class View {
  constructor(sv, elementOrSelector) {
    this.sv = sv;
    this._verticallyCenter = this.sv.options?.centered ?? false;
    this.element = this._initElement(elementOrSelector);
    this.isCircular = this.sv.options?.circular ?? false;
    this.renderEngine = new RenderEngine(this);
    try {
      this.channelViewport = new ChannelViewport(sv);
    } catch (error) {
      this.displayError(error);
    }
    this.sv.bind("ready", () => this.render(0));
  }
  element;
  layout = null;
  isCircular;
  idle = null;
  _hovered = null;
  _focused = null;
  // Cache the actual dimensions so that the viewer can be hidden & redisplayed.
  // Initialize with actual dimensions but small, so the viewer can be initialised hidden.
  // TODO Write tests for these scenarios.
  _width = 50;
  _height = 50;
  // UI and Data events can mark the view as dirty by adding a dirty flag.
  dirtyTriggers = /* @__PURE__ */new Set();
  renderEngine;
  channelViewport;
  // Enables optimization by caching expensive calculated values across render frames.
  // Disable to confirm if bugs are caused by caching (more precisely; missing cache invalidation).
  cachingEnabled = true;
  _verticallyCenter;
  SEQUENCE_INFO_FONT_SIZE = 14;
  pen = new Pen(this.SEQUENCE_INFO_FONT_SIZE, "center", "alphabetic", "#666");
  isCanvasElementInsideDocument = false;
  get width() {
    this._width = this.renderEngine.wrapper.offsetWidth || this._width;
    return this.sv.export && this.sv.export.enabled ? this.sv.export.width : this._width;
  }
  get height() {
    this._height = this.renderEngine.wrapper.offsetHeight || this._height;
    return this.sv.export && this.sv.export.enabled ? this.sv.export.height : this._height;
  }
  get hovered() {
    return this._hovered;
  }
  set hovered(value) {
    if (this._hovered !== value) {
      this._hovered = value;
      this.dirty = "hovered node changed";
    }
  }
  get focused() {
    return this._focused;
  }
  set focused(value) {
    if (this._focused !== value) {
      this._focused = value;
      this.dirty = "focused node changed";
    }
  }
  get circular() {
    return this.isCircular;
  }
  set circular(value) {
    if (this.isCircular === value) {
      return;
    }
    this.isCircular = value;
    this.sv.emit("view mode changed");
    this.dirty = "view mode changed";
  }
  get brush() {
    return this.renderEngine.brush;
  }
  get graphics() {
    return this.renderEngine.graphics;
  }
  /**
   * Marks the view as dirty, causing it to render on the next animation frame.
   *
   * @param reason What sort of event is making the view dirty?  E.g. data, zoom, offset, selection, hover.
   */
  set dirty(reason) {
    this.dirtyTriggers.add(reason);
  }
  get canvasElement() {
    return this.renderEngine.element;
  }
  get channelView() {
    return this.channelViewport.view;
  }
  get isActive() {
    return this.element.contains(document.activeElement);
  }
  updateSize() {
    this.channelViewport.resize();
  }
  clipToViewport(node) {
    const range = new RRange(0, node.bounds.height);
    const yOffset = 0 - node.absoluteY;
    const viewport = new RRange(yOffset, yOffset + this.height);
    return range.clip(viewport);
  }
  /**
   * Helper to get, calculate and/or set values that are cached across render frames.
   *
   * The caller is exclusively responsible for invalidating the cache.
   *
   * Caching can be disabled with: `sv.view.cachingEnabled = false`
   *
   * This is useful to determine if caching is the cause of bugs.
   *
   * @param object              The object hosting the cached value. Usually `this` in the caller.
   * @param key                 The name of the property in `object` where the cached value should be
   *                                          stored and accessed.
   * @param calculateCallback   Calculates and returns the current value. This is only called if the
   *                                          value is not already cached. The returned value is immediately cached.
   * @returns the value
   */
  getCacheableValue(object, key, calculateCallback) {
    const value = object[key] || calculateCallback();
    if (this.cachingEnabled && object[key] == null) {
      object[key] = value;
    }
    return value;
  }
  calculateLayout() {
    const bounds = this.bounds;
    const children = this.channelView.calculateLayout(bounds);
    if (this.isCircular) {
      children.push(this.circularSequenceInfo());
    }
    return new RenderNode("view", this, bounds, children);
  }
  circularSequenceInfo() {
    const lineSpacing = 10;
    const infoHeight = this.SEQUENCE_INFO_FONT_SIZE * 2;
    const graphics = this.graphics;
    const centerY = graphics.centerY;
    const y = Math.ceil(Math.min(centerY - infoHeight / 2, this.height - infoHeight - 10));
    const x = Math.ceil(this.width / 2);
    const infoBounds = {
      x,
      y,
      width: Math.max(graphics.radius - this.channelView.height, 0),
      height: infoHeight
    };
    const sequenceInfo = new RenderNode("sequence info", this, infoBounds);
    sequenceInfo.setRenderCallback(brush => {
      this.graphics.withInitialTransform(brush, () => {
        const sequence = this.channelView.sequences[0];
        const availableWidth = Math.max(graphics.radius - this.channelView.height, 0);
        if (availableWidth === 0) {
          return;
        }
        let sequenceName = sequence.name;
        let sequenceLength = `${sequence.sequence.length.toLocaleString()} bp`;
        const maxChars = Math.floor(availableWidth / brush.measureText("T").width);
        if (maxChars <= 3) {
          sequenceName = "...";
          sequenceLength = "...";
        } else {
          if (brush.measureText(sequenceName).width > availableWidth) {
            sequenceName = sequenceName.slice(0, maxChars - 3) + "...";
          }
          if (brush.measureText(sequenceLength).width > availableWidth) {
            sequenceLength = sequenceLength.slice(0, maxChars - 3) + "...";
          }
        }
        this.pen.writeWithoutTransform(brush, sequenceName, sequenceInfo.bounds.x, sequenceInfo.bounds.y);
        this.pen.writeWithoutTransform(brush, sequenceLength, sequenceInfo.bounds.x, sequenceInfo.bounds.y + this.SEQUENCE_INFO_FONT_SIZE + lineSpacing);
      });
    });
    return sequenceInfo;
  }
  get bounds() {
    const bounds = {
      x: 0,
      y: 0,
      width: this.width,
      height: this.height
    };
    if (this.circular) {
      bounds.width = this.channelView.width;
    }
    if (!this.circular) {
      if (this.sv.loaded && !this.channelView.wrapped && this._verticallyCenter) {
        const rowHeight = this.channelView.rowHeight;
        if (rowHeight < this.height) {
          return centerVertically(rowHeight, bounds);
        }
      }
    }
    return bounds;
  }
  resumeRenderingWhenCanvasIsInDocument() {
    const DOMObserver = new MutationObserver(() => {
      this.isCanvasElementInsideDocument = document.contains(this.element);
      if (this.isCanvasElementInsideDocument) {
        DOMObserver.disconnect();
        this.render(0);
      }
    });
    DOMObserver.observe(document, {
      childList: true,
      subtree: true
    });
  }
  render(time) {
    this.isCanvasElementInsideDocument = document.contains(this.element);
    if (!this.isCanvasElementInsideDocument) {
      this.resumeRenderingWhenCanvasIsInDocument();
      return;
    }
    if (this.sv._error != null) {
      this.displayError(this.sv._error);
      return;
    }
    try {
      this.emitIdleEventOrRender(time);
      window.requestAnimationFrame(nextTime => this.render(nextTime));
    } catch (e) {
      this.displayError(e);
    }
  }
  emitIdleEventOrRender(time) {
    if (this.isDirty) {
      this._render(time);
      if (this.idle === true) {
        this.idle = false;
      }
    } else if (this.idle === null && this.sv.loaded) {
      this.sv.emit("first idle");
      this.sv.emit("idle");
      this.idle = true;
    } else if (this.idle === false) {
      this.sv.emit("idle");
      this.idle = true;
    } else {}
  }
  _render(time) {
    const parameters = [time, this.dirtyTriggers];
    this.dirtyTriggers = /* @__PURE__ */new Set();
    this.sv.emit("prerender", ...parameters);
    this.layout = this.calculateLayout();
    this.renderEngine.paint(this.layout);
    this.sv.emit("postrender", ...parameters);
  }
  get isDirty() {
    return this.dirtyTriggers.size > 0;
  }
  displayError(error) {
    let message;
    let stack;
    if (error instanceof Error) {
      message = error.message;
      stack = error.stack;
    } else if (typeof error === "string") {
      message = error;
    } else {
      message = JSON.stringify(error);
    }
    this.element.innerHTML = `<strong>Can't load sequence view.</strong> An unexpected error occurred: <code>${message}</code>`;
    const niceError = new Error(`Geneious Sequence Viewer: ${message}`, {
      cause: error
    });
    if (stack) {
      niceError.stack += "\nCause:\n" + stack;
    }
    throw niceError;
  }
  _initElement(element) {
    let message = "The provided HTML element is not valid";
    if (typeof element !== "object") {
      message = `No element matches selector '${element}'`;
      element = document.querySelector(element);
    }
    if (!element) {
      throw new Error(message);
    }
    element.innerHTML = `
            <div class="sv-container">
                <div class="sv-main">
                    <div class="sv-viewport-wrapper">
                        <canvas class="sv-viewport" tabindex="-1"></canvas>
                    </div>

                    <div class="sv-right"></div>
                </div>

                <div class="sv-bottom"></div>
            </div>
        `;
    return element;
  }
}
export { View as default };