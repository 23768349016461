import ReferenceWrapper from "../../includes/Channels/ReferenceWrapper.js";
import SequenceWrapper from "../../includes/Channels/SequenceWrapper.js";
import ResizableSidebarColumn from "../../includes/PluginBaseClasses/ResizableSidebarColumn.js";
import Pen from "../../includes/RenderEngine/Pen.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import { darken, hasValidMateIndex } from "../../includes/misc/Utils.js";
import AnnotationsTrack from "../Annotations/AnnotationsTrack.js";
import ConsensusChannel from "../Consensus/ConsensusChannel.js";
import IdentityChannel from "../Identity/IdentityChannel.js";
import WuKabatChannel from "../WuKabat/WuKabatChannel.js";
const {
  max,
  round
} = Math;
class LabelsColumn extends ResizableSidebarColumn {
  constructor(plugin) {
    super(plugin.sv.view);
    this.plugin = plugin;
    this.sv.bindToNode("mousemove", "label", (node, event, offset) => this.iconTooltip(node, event, offset));
    this.sv.addEventListenerToDocument("mouseup", () => this.mouseUpOnDocument());
    this.sv.addEventListenerToDocument("mousemove", e => this.mouseMoveOnDocument(e));
  }
  margin = 2;
  minWidth = 20;
  fontSize = 12;
  pen = new Pen(this.fontSize, "left", "middle", "black");
  labelPadding = 6;
  iconPadding = 5;
  iconWidth = 20;
  backgroundColor = "#f6f6f6";
  borderColor = darken(this.backgroundColor, 25).toRgbString();
  adjustingLabelsColumnWidth = false;
  calculateLayout(bounds, channels) {
    return super.calculateLayout(bounds, channels, this.view.width);
  }
  get columnWidth() {
    const maxWidth = round((this.sv.view.width - this.offset) * 0.8);
    if (this.plugin.width < this.minWidth) {
      return this.minWidth;
    } else if (this.plugin.width > maxWidth) {
      return maxWidth;
    } else {
      return this.plugin.width;
    }
  }
  get weight() {
    return 0;
  }
  get nodeType() {
    return "label wrapper";
  }
  get enabled() {
    return this.plugin.enabled;
  }
  alterWrapperNode(node, channel) {
    node.children = this.labelNodes(channel);
    node.setBackgroundCallback(() => this.renderBackground({
      width: this.columnWidth,
      height: node.bounds.height + node.reference.margin
    }));
    return node;
  }
  labelNodes(wrapperChannel) {
    const result = [];
    const processChannel = (node, yOffset) => {
      const channel = node.reference;
      if (this.channelWithLabel(channel)) {
        result.push(this.labelNode(channel, yOffset));
      }
    };
    processChannel(wrapperChannel, 0);
    wrapperChannel.children[0].children.forEach(child => {
      processChannel(child, child.bounds.y + wrapperChannel.children[0].bounds.y);
    });
    return result;
  }
  channelWithLabel(channel) {
    return channel instanceof SequenceWrapper || channel instanceof AnnotationsTrack || channel instanceof ConsensusChannel || channel instanceof IdentityChannel || channel instanceof WuKabatChannel || channel instanceof ReferenceWrapper;
  }
  labelNode(channel, yOffset) {
    const node = new RenderNode("label", channel, {
      x: 0,
      y: yOffset,
      width: this.columnWidth,
      height: this.labelHeight
    });
    node.setRenderCallback(() => this.renderLabel(node));
    return node;
  }
  renderBackground({
    width,
    height
  }) {
    const brush = this.layer.brush;
    brush.fillStyle = this.backgroundColor;
    brush.fillRect(0, 0, width, height);
    brush.fillStyle = this.borderColor;
    brush.fillRect(width, 0, 1, height);
  }
  renderLabel(node) {
    const channel = node.reference;
    const height = this.labelHeight;
    this.pen.style = channel === channel.sv.channelView.reference || channel === channel.view.referenceWrapper ? "bold" : "";
    const width = this.labelIsHovered(node) && !this.adjustingLabelsColumnWidth ? max(this.labelWidth(channel.label), this.columnWidth) : this.columnWidth;
    this._renderLabel(channel, node, {
      width,
      height
    });
  }
  _renderLabel(channel, node, {
    width,
    height
  }) {
    const {
      brush,
      graphics
    } = this.layer;
    brush.fillStyle = this.backgroundColor;
    brush.fillRect(0, 0, width, height);
    brush.fillStyle = this.borderColor;
    const extension = width - this.columnWidth;
    if (this.isSubordinate(channel)) {
      brush.fillRect(this.columnWidth, 0, extension, 1);
    } else {
      brush.fillRect(0, 0, width, 1);
    }
    brush.fillRect(width, 0, 1, height);
    brush.fillRect(this.columnWidth, height, extension, 1);
    brush.beginPath();
    brush.rect(0, 1, width - 1, height - 2);
    brush.clip();
    if (channel.label.icon) {
      this.drawPairIcon(height, channel.label.icon);
    }
    this.pen.write(graphics, brush, this.truncateDisplayLabel(node, channel.label), this.textLabelPadding(channel.label), height / 2);
  }
  truncateDisplayLabel(node, label) {
    const availableLabelWidth = this.columnWidth - 1 - this.textLabelPadding(label);
    if (!this.adjustingLabelsColumnWidth && this.labelIsHovered(node) || availableLabelWidth > this.pen.measureWidth(label.text)) {
      return label.text;
    } else {
      const labelPaddingRight = availableLabelWidth * 0.01;
      const availableLabelWidthHalf = 0.5 * (availableLabelWidth - labelPaddingRight - this.pen.measureWidth("..."));
      return this.charactersForWidth(label.text, availableLabelWidthHalf, false) + "..." + this.charactersForWidth(label.text, availableLabelWidthHalf, true);
    }
  }
  charactersForWidth(text, goalWidth, reverse) {
    if (reverse) {
      text = Array.from(text).reverse().join("");
    }
    let i = 1;
    while (i < text.length && this.pen.measureWidth(text.substr(0, i)) <= goalWidth) {
      i++;
    }
    const output = text.substr(0, i - 1);
    return reverse ? Array.from(output).reverse().join("") : output;
  }
  isSubordinate(channel) {
    if (channel["wrapper"]) {
      const wrapper = channel["wrapper"];
      return wrapper.type === "sequence";
    }
    return false;
  }
  labelIsHovered({
    reference,
    renderContext
  }) {
    const node = this.view.hovered;
    return node !== null && node.type == "label" && node.reference === reference && node.renderContext?.index === renderContext?.index;
  }
  get labelHeight() {
    return round(this.fontSize + this.labelPadding * 2);
  }
  labelWidth(label) {
    return round(this._labelWidth(label) + this.labelPadding * 2);
  }
  _labelWidth(label) {
    const textWidth = round(this.pen.measureWidth(label.text));
    return label.icon ? textWidth + this.iconWidth + this.iconPadding : textWidth;
  }
  textLabelPadding(label) {
    return label.icon ? this.labelPadding + this.iconWidth + this.iconPadding : this.labelPadding;
  }
  drawPairIcon(height, type) {
    const hOffset = this.labelPadding;
    const vOffset = height / 3;
    const boxWidth = this.iconWidth * (2 / 5);
    const boxHeight = height / 3;
    const lineWidth = this.iconWidth * (1 / 5);
    const lineHeight = 1;
    this.drawPairBox({
      x: hOffset,
      y: vOffset,
      width: boxWidth,
      height: boxHeight
    });
    this.drawPairLine({
      x: hOffset,
      y: vOffset,
      width: lineWidth,
      height: lineHeight
    }, boxWidth, boxHeight);
    const xRightBox = hOffset + boxWidth + lineWidth;
    this.drawPairBox({
      x: xRightBox,
      y: vOffset,
      width: boxWidth,
      height: boxHeight
    });
    this.drawPairCross(height, hOffset, vOffset, boxWidth, lineWidth, type);
  }
  drawPairBox({
    x,
    y,
    width,
    height
  }) {
    const brush = this.layer.brush;
    brush.fillStyle = "rgb(26, 21, 119)";
    brush.fillRect(x, y, width, height);
    brush.fillStyle = "rgb(95, 95, 214)";
    brush.fillRect(x + 1, y + 1, width - 2, height - 2);
    brush.fillStyle = "rgb(45, 38, 198)";
    brush.fillRect(x + 1, y + 3, width - 2, height - 4);
  }
  drawPairLine({
    x,
    y,
    width,
    height
  }, boxWidth, boxHeight) {
    const brush = this.layer.brush;
    brush.fillStyle = "rgb(26, 21, 119)";
    brush.fillRect(x + boxWidth, y + boxHeight / 2, width, height);
  }
  drawPairCross(height, hOffset, vOffset, boxWidth, lineWidth, type) {
    const brush = this.layer.brush;
    if (type === "unpaired") {
      brush.beginPath();
      brush.strokeStyle = "rgb(256, 50, 50)";
      brush.moveTo(hOffset + boxWidth + lineWidth + 7, vOffset * (2 / 3));
      brush.lineTo(hOffset + boxWidth - 7, height - vOffset * (2 / 3));
      brush.stroke();
    }
  }
  iconTooltip(node, event, offset) {
    const pairInformation = node.reference["pairInformation"];
    if (hasValidMateIndex(pairInformation) && offset.x <= this.labelPadding + this.iconWidth) {
      this.sv.tooltip?.watch(node, event, () => {
        const pair = this.sv.pairedReads?.findPair(node);
        const pairName = pair ? pair.label.text : "an unavailable sequence";
        return node.reference["label"].text + " is paired with " + pairName;
      });
    }
  }
  mouseMoveOnDocument(event) {
    if (this.adjustingColumnWidth) {
      this.plugin.width = event.pageX - this.view.element.getBoundingClientRect().left - this.offset;
      document.body.style.cursor = "col-resize";
    }
  }
  mouseUpOnDocument() {
    if (this.adjustingColumnWidth) {
      this.plugin.width = this.columnWidth;
      this.adjustingColumnWidth = false;
      document.body.style.cursor = "auto";
    }
  }
  get sv() {
    return this.plugin.sv;
  }
}
export { LabelsColumn as default };