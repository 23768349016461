import { FormatterService } from '../../shared/formatter.service';
import { DocumentTableService } from '../document-table-service/document-table.service';
import {
  MetadataColumnAbomination,
  MetadataColumnGroup,
  MetadataColumnOrGroup,
} from '../../features/sequence-viewer-angular/sequence-viewer.interfaces';
import { MetadataColumnOptions, PrimitiveMap, SequenceData } from '@geneious/sequence-viewer/types';
import { Injectable } from '@angular/core';
import { NgsSequenceViewerService } from '../ngs/ngs-sequence-viewer.service';
import {
  ViewerMasterDatabaseSearchSelection,
  ViewerMultipleTableDocumentSelection,
  isViewerMasterDatabaseSearchSelection,
} from '../viewer-components/viewer-document-data';
import { MasterDatabaseService } from '../master-database/master-database.service';
import { APP_NAME } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class SequenceViewerMetadataService {
  /** Columns used by the score metadata column column/tooltip renderers. */
  readonly SCORE_COLUMNS = [
    'Score',
    'Error',
    'Asset',
    'Liability (High)',
    'Liability (Medium)',
    'Liability (Low)',
  ];

  /**
   * Metadata fields applied to an Alignment sequence if it was produced with
   * the 'combineDuplicateSequences' option.
   */
  readonly ALIGNMENT_COMBINED_SEQUENCES_METADATA = [
    'BX_Representative sequence',
    'BX_All sequence names',
    'BX_Total combined sequences',
  ];
  readonly ALIGNMENT_REPRESENTATIVE_SEQUENCES_METADATA = [
    'BX_REPRESENTATIVE_SEQUENCE_Name',
    'BX_REPRESENTATIVE_SEQUENCE_Score',
    'BX_REPRESENTATIVE_SEQUENCE_ID',
  ];

  /** Metadata keys that indicate a bad sequence. */
  private readonly BAD_SEQUENCE_METADATA = [
    'Error',
    'Frame Shift',
    'Stop Codon',
    'Likely Sequencing Error (VDJ-REGION)',
    'Likely Sequencing Error (VJ-REGION)',
    'Possible Sequencing Error (VDJ-REGION)',
    'Possible Sequencing Error (VDJ-REGION)',
  ];

  /** Special key for.... TODO idk */
  readonly CUSTOM_COLUMN_KEY = 'customBooleanColumn';

  /** Values used for rendering liability scores */
  private readonly LIABILITY_SCORE = {
    low: -2000,
    high: -1000,
    colors: {
      red: '#E60000',
      yellow: '#FFFF00',
      green: '#008000',
    },
  };

  readonly HIDDEN_METADATA_FIELD_PREFIX = 'ReadsNamingScheme';

  /** Fields that should not be presented to the user in the 'Metadata' sidebar of Sequence Viewer */
  readonly HIDDEN_METADATA_FIELDS: { [type: string]: boolean } = {
    row_number: true,
    BX_row_number: true,
    geneious_row_index: true,
    BX_geneious_row_index: true,
    'Associated Sequences': true,
    'BX_Associated Sequences': true,
    modified_date: true,
    document_size: true,
    sequence_length: true,
    gcPercent: true,
    sequence_residues: true,
    topology: true,
    molType: true,
    cache_created: true,
    cache_name: true,
    documentClass: true,
    unread: true,
    'importedFrom.filename': true,
    'importedFrom.path': true,
    'BX_All sequence IDs': true,
  };

  private readonly PREFIX_PATTERN =
    /^(BX_)?(AGGREGATE_)?(REPRESENTATIVE_SEQUENCE_)?(CHERRY_PICKING_)?(ASSAY_DATA_)?/;

  /**
   * Get the color for the given liability score
   * @param value the liability score
   */
  private liabilityScoreColor(value: number): string {
    const colors = this.LIABILITY_SCORE.colors;
    if (value < this.LIABILITY_SCORE.low) {
      return colors.red;
    } else if (value < this.LIABILITY_SCORE.high) {
      return colors.yellow;
    } else {
      return colors.green;
    }
  }

  /**
   * Updates the thresholds for rendering liability scores
   *
   * @param thresholds the new upper and lower limits
   */
  liabilityScoreThresholdChanged({
    liabilityScoreLow,
    liabilityScoreHigh,
  }: {
    liabilityScoreLow: number;
    liabilityScoreHigh: number;
  }) {
    this.LIABILITY_SCORE.low = liabilityScoreLow;
    this.LIABILITY_SCORE.high = liabilityScoreHigh;
  }

  /**
   * Builds metadata columns from metadata property of SequenceData.
   * Handles both Continuous and Boolean types, and the Labels column.
   * If the columns contain any invalid values, then the entire column will not be included.
   *
   * @param sequences
   * @param hasLiabilityScore
   * @param colIds
   */
  buildMetadataColumnsFromSequences(
    sequences: SequenceData[],
    hasLiabilityScore: boolean = false,
    colIds: string[] = [],
  ): MetadataColumnOrGroup[] {
    const validColIds = colIds.filter((colId) => !this.isHiddenMetadataField(colId));
    const uniqueColumns = this.getUniqueColumns(sequences, validColIds, hasLiabilityScore);
    return this.groupMetadataColumns(uniqueColumns, hasLiabilityScore);
  }

  private isHiddenMetadataField(label: string) {
    return (
      this.HIDDEN_METADATA_FIELDS[label] || label?.startsWith(this.HIDDEN_METADATA_FIELD_PREFIX)
    );
  }

  private getUniqueColumns(
    sequences: SequenceData[],
    colIds: string[],
    hasLiabilityScore: boolean,
  ): { uniqueMetadataColumns: UniqueColumns; uniqueLabelMetadataColumns: UniqueColumns } {
    // Map to store processed metadata columns to avoid duplicates.
    const uniqueMetadataColumns: UniqueColumns = {};
    const uniqueLabelMetadataColumns: UniqueColumns = {};

    colIds.forEach((key) => {
      uniqueMetadataColumns[key] = undefined;
    });

    if (
      sequences.length > 0 &&
      sequences[0].metadata != null &&
      sequences[0].metadata[this.CUSTOM_COLUMN_KEY] != null
    ) {
      // Add any extra metadata properties that are not present on the table columns.
      const customColumn = sequences[0].metadata[this.CUSTOM_COLUMN_KEY] as string;
      uniqueMetadataColumns[customColumn] = undefined;
    }

    sequences
      .filter((sequence) => sequence.metadata)
      .forEach((sequence) => {
        const metadataKeys = Object.keys(sequence.metadata).filter(
          (key) => !this.isHiddenMetadataField(key),
        );

        metadataKeys.forEach((key) => {
          const value = sequence.metadata[key];
          if (key.toLowerCase() === 'labels' || key.toLowerCase() === 'bx_labels') {
            // Labels are stored as comma separated strings on alignments and are stored as an array of strings on result documents.
            const labels: string[] =
              typeof value === 'string' ? value.split(',') : (value as unknown as string[]);
            labels.forEach((label) => {
              this.setColumn(
                uniqueLabelMetadataColumns,
                uniqueMetadataColumns,
                `${label.trim()} (label)`,
                true,
              );
            });
          } else {
            this.setColumn(
              uniqueMetadataColumns,
              uniqueLabelMetadataColumns,
              key,
              value,
              hasLiabilityScore,
            );
          }
        });
      });
    return { uniqueMetadataColumns, uniqueLabelMetadataColumns };
  }

  /**
   * Sorts array of columns in place, according to the order specified by sortedColNames.
   *
   * @param columns The columns to sort
   * @param sortedColNames Specifies the order of columns
   * @returns Original columns, now sorted
   */
  sortMetadataColumns(
    columns: MetadataColumnAbomination[],
    sortedColNames: string[],
  ): MetadataColumnAbomination[] {
    const getColRank = (col: MetadataColumnAbomination) => {
      const index = sortedColNames.indexOf(col.name);
      return index === -1 ? sortedColNames.length : index;
    };
    columns.sort((a, b) => getColRank(a) - getColRank(b));
    return columns;
  }

  /**
   * Find unique ID column name based on metadata columns and sequence name pattern
   * @param metadata metadata map
   * @param sequenceName sequence name
   */
  findUniqueMetadataColumnName(metadata: PrimitiveMap, sequenceName: string): string {
    let idColumnName = 'BX_ID';
    let compositeIDColumnName = 'BX_Composite Cluster ID';
    //Alignments created from cluster tables do not have BX_ID metadata column instead it has 'BX_ClusterName ID' for standard clusters or 'BX_Composite Cluster ID' for similarity clusters
    if (metadata.BX_ID == null) {
      const idColumns = Object.keys(metadata).filter(
        (x) => x.startsWith('BX') && x.endsWith(' ID'),
      );
      if (idColumns.length == 1) {
        idColumnName = idColumns[0];
      } else if (idColumns.length > 1) {
        idColumnName = idColumns.includes(compositeIDColumnName)
          ? compositeIDColumnName
          : idColumns.find((col) => sequenceName.startsWith(col.slice(3, col.lastIndexOf(' ID'))));
      }
    }
    return idColumnName;
  }

  /**
   * Creates and sets a renderer for the metadata key/value pair in
   * the map of column renderers.
   *
   * NOTE: this method
   *
   * @param primaryColumns the main group of columns to add the renderer to
   * @param otherColumns another group of columns that may already contain a
   *    renderer
   * @param key the metadata key
   * @param value an example metadata value
   * @param hasLiabilityScore whether the metadata value is for a liability score
   */
  private setColumn(
    primaryColumns: UniqueColumns,
    otherColumns: UniqueColumns,
    key: string,
    value: string | number | boolean,
    hasLiabilityScore: boolean = false,
  ) {
    const metadataColumn = this.buildSvRenderer(key, value, hasLiabilityScore);
    const previousMetadataColumn = primaryColumns[key] || otherColumns[key];

    // If new column, remember it.
    if (metadataColumn && previousMetadataColumn === undefined) {
      primaryColumns[key] = metadataColumn;
      // If not a new column and the types don't match from the previous value of the column then it's an invalid column.
      // If the value is blank, then we can just ignore it.
      // Metadata Columns can have blank values.
    } else if (
      (previousMetadataColumn &&
        metadataColumn &&
        previousMetadataColumn.type !== metadataColumn.type) ||
      (!metadataColumn && value !== '')
    ) {
      // Mixed type columns should fall back to the text renderer.
      // Can occur when a string is "13E4" as it will be interpreted as a number when it is meant to be a string.
      primaryColumns[key] = this.resetColumnRenderer(metadataColumn ?? { name: key });
      if (otherColumns[key]) {
        otherColumns[key] = this.resetColumnRenderer(otherColumns[key]);
      }
    }
  }

  /**
   * Returns a copy of the renderer options with the type set to 'text' and the
   * renderers removed.
   *
   * @param column the column options
   * @returns the column options reset to a text renderer
   */
  private resetColumnRenderer(column: MetadataColumnOptions): MetadataColumnOptions {
    const { cellRenderer, tooltipRenderer, ...rest } = column;
    return {
      ...rest,
      type: SvSidebarRendererType.TEXT,
    };
  }

  private groupMetadataColumns(
    {
      uniqueMetadataColumns,
      uniqueLabelMetadataColumns,
    }: { uniqueMetadataColumns: UniqueColumns; uniqueLabelMetadataColumns: UniqueColumns },
    hasLiabilityScore: boolean,
  ): MetadataColumnOrGroup[] {
    const uniqueMetadataColumnGroups = new Map();
    const liabilityScore: MetadataColumnOptions[] = [];

    Object.keys(uniqueMetadataColumns).forEach((key) => {
      if (key.toLowerCase() === 'labels' || key.toLowerCase() === 'bx_labels') {
        Object.keys(uniqueLabelMetadataColumns).forEach((label) => {
          const column = uniqueLabelMetadataColumns[label];
          // Ignore null metadata columns.
          if (column) {
            this.groupColumns(uniqueMetadataColumnGroups, column, label);
          }
        });
      } else {
        const column = uniqueMetadataColumns[key];
        // Ignore null metadata columns.
        if (column) {
          if (
            hasLiabilityScore &&
            (key.toLowerCase() === 'score' || key.toLowerCase() === 'bx_score')
          ) {
            // The liability score column should be first and not part of a group.
            liabilityScore.push(column);
          } else {
            this.groupColumns(uniqueMetadataColumnGroups, column, key);
          }
        }
      }
    });

    return liabilityScore.concat(Array.from(uniqueMetadataColumnGroups.values()));
  }

  private groupColumns(
    uniqueMetadataColumnGroups: Map<string, MetadataColumnGroup>,
    column: MetadataColumnOptions,
    key: string,
  ) {
    const isAggregate =
      this.ALIGNMENT_COMBINED_SEQUENCES_METADATA.includes(key) || key.includes('AGGREGATE_');
    const isRepresentativeSequenceMetadata =
      this.ALIGNMENT_REPRESENTATIVE_SEQUENCES_METADATA.includes(key);

    let groupName = isAggregate
      ? 'Alignment Metadata'
      : isRepresentativeSequenceMetadata
        ? 'Representative Sequence'
        : APP_NAME;
    const strippedKey = key.replace(this.PREFIX_PATTERN, '');

    const split = DocumentTableService.splitAssayColumnKey(strippedKey);
    if (split) {
      if (isAggregate) {
        column.label = `${split.tableName}: ${split.columnName}`;
      } else {
        column.label = split.columnName;
        groupName = split.tableName;
      }
    } else {
      column.label = strippedKey;
    }

    const group = uniqueMetadataColumnGroups.get(groupName);
    if (group) {
      group.columns.push(column);
    } else {
      uniqueMetadataColumnGroups.set(groupName, {
        name: groupName,
        columns: [column],
      });
    }
  }

  /**
   * There can be multiple sequences per APD, therefore this duplicates the apd's metadata to their associated sequences.
   */
  addAssayDataToSelectedSequences(
    sequences: SequenceData[],
    state: ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection,
  ): SequenceData[] {
    if (isViewerMasterDatabaseSearchSelection(state)) {
      if (state.subTableRows.length === 1) {
        const subTableRow = state.subTableRows[0];
        return this.addAssayDataToAlignment(sequences, subTableRow.parentData, subTableRow);
      } else if (state.rows.length === 1 && state.rows[0].best_match_urn != null) {
        const subTableRow = state.rows[0].matches.find((match: any) =>
          MasterDatabaseService.isBestMatch(match, state.rows[0]),
        );
        return this.addAssayDataToAlignment(sequences, state.rows[0], subTableRow);
      } else {
        return this.addAssayDataToSequences(sequences, state.rows);
      }
    } else {
      return this.addAssayDataToSequences(sequences, state.selectedRows);
    }
  }

  private addAssayDataToSequences(sequences: SequenceData[], rows: any[]): SequenceData[] {
    const newSequences: SequenceData[] = [];
    let lastIndex = 0;
    rows
      .filter((row) => NgsSequenceViewerService.getAssociatedSequencesProperty(row) != null)
      .forEach((row) => {
        const sequenceIds = this.getAssociatedSequencesIDs(row);

        // The number of sequences this should loop through
        const upTo = lastIndex + sequenceIds.length;

        for (lastIndex; lastIndex < upTo; lastIndex++) {
          if (sequences[lastIndex]) {
            newSequences.push(this.addAssayDataToSequence(sequences[lastIndex], row));
          }
        }
      });

    return newSequences;
  }

  private addAssayDataToAlignment(
    sequences: SequenceData[],
    firstRow: { [key: string]: any },
    otherRow: { [key: string]: any },
  ): SequenceData[] {
    return [this.addAssayDataToSequence(sequences[0], firstRow)].concat(
      sequences.slice(1).map((sequence) => this.addAssayDataToSequence(sequence, otherRow)),
    );
  }

  private addAssayDataToSequence(sequence: SequenceData, row: any) {
    // Important to check the row actually is there and not undefined (e.g. loading or similar).
    // SV crashes if we pass it falsy value.
    if (row.Labels) {
      row.Labels.forEach((label: string) => (row[`${label} (label)`] = true));
    }
    const existingMetadata = (sequence as any).metadata;
    const newMetadata = Object.assign({}, row);
    if (existingMetadata != null && existingMetadata.bxSampleName != null) {
      // This metadata exists on comparison documents already and is useful for the user.
      const sampleName = existingMetadata.bxSampleName;
      newMetadata[sampleName] = true;
      // Make sure this new property gets identified and added to the list of columns.
      newMetadata[this.CUSTOM_COLUMN_KEY] = sampleName;
    }
    sequence.metadata = newMetadata;
    return sequence;
  }

  /**
   * If the apd has more than 1 associated sequence then the value of the property will be a string of sequence ids delimited by '#'.
   * e.g. 1#2#4
   *
   * If there is only 1 associated sequence then it'll be a single integer value of number type.
   *
   * @param row
   */
  private getAssociatedSequencesIDs(row: any): number[] {
    const associatedSequences = NgsSequenceViewerService.getAssociatedSequencesProperty(row);

    if (typeof associatedSequences === 'number') {
      return [associatedSequences];
    } else if (typeof associatedSequences === 'string') {
      return associatedSequences.split('#').map((id) => parseInt(id, 10));
    }

    return [];
  }

  /**
   * Builds and returns an SVSidebarRenderer based on the key and value of the column.
   *
   * @param {string} key - name of column
   * @param {any} value - value of the column cell
   * @param {boolean} hasLiabilityScore - if score should be handled specially
   * @returns SVSidebarRenderer
   */
  buildSvRenderer(
    key: string,
    value: boolean | number | string,
    hasLiabilityScore: boolean,
  ): MetadataColumnOptions | undefined {
    const valueLowerCase = String(value).toLowerCase();
    const valueIsNumber = FormatterService.isNumeric(value);
    const valueIsBoolean =
      typeof value === 'boolean' ||
      valueLowerCase === 'true' ||
      valueLowerCase === 'false' ||
      valueLowerCase === 'no' ||
      valueLowerCase === 'yes';
    const valueIsBlankString = typeof value === 'string' && value.trim() === '';

    const friendlyName = key.replace('BX_', '');
    const name = this.SCORE_COLUMNS.includes(friendlyName) ? friendlyName : key;

    if (hasLiabilityScore && name === 'Score') {
      return {
        name: 'Score',
        label: 'Liability score',
        enabled: true,
        tooltipRenderer: this.liabilityScoreTooltipRenderer,
        cellRenderer: this.liabilityScoreCellRenderer,
      };
    } else if (this.BAD_SEQUENCE_METADATA.includes(friendlyName)) {
      // Primarily for the Antibody Annotator.
      return {
        name: name,
        label: friendlyName,
        enabled: true,
        type: SvSidebarRendererType.BOOLEAN,
        cellRenderer: this.badSequenceDataCellRenderer,
      };
    } else if (name === 'status') {
      // For the scaffold and pyro pipelines.
      return {
        name: name,
        label: friendlyName,
        enabled: true,
        type: SvSidebarRendererType.BOOLEAN,
        cellRenderer: (data: boolean | number | string): LiabilityColumn => {
          const goodRenderer = { foreground: 'green', icon: '✓' };
          const badRenderer = { foreground: 'red', icon: '⚠' };
          return this.pickLiabilityRenderer<LiabilityColumn>(data, goodRenderer, badRenderer, {});
        },
        tooltipRenderer: (data: any, metadata: any): string => {
          const errors = metadata.errors ? metadata.errors : '';
          const badRenderer = `${data} ${errors}`.trim();
          return this.pickLiabilityRenderer<string>(data, data, badRenderer, '');
        },
      };
    } else if (valueIsNumber) {
      return {
        name: name,
        label: friendlyName,
        type: SvSidebarRendererType.CONTINUOUS,
      };
    } else if (valueIsBoolean) {
      return {
        name: name,
        label: friendlyName,
        type: SvSidebarRendererType.BOOLEAN,
        cellRenderer: this.booleanCellRenderer,
      };
    } else if (!valueIsBlankString) {
      return {
        name: name,
        label: friendlyName,
        type: SvSidebarRendererType.TEXT,
      };
    }
  }

  private pickLiabilityRenderer<T>(
    statusValue: boolean | number | string,
    goodRenderer: T,
    badRenderer: T,
    defaultRenderer: T,
  ): T {
    const parsed = String(statusValue).toUpperCase();

    if (parsed === 'CORRECT') {
      return goodRenderer;
    } else if (typeof statusValue === 'string') {
      return badRenderer;
    } else {
      return defaultRenderer;
    }
  }

  private readonly booleanCellRenderer = (data: boolean | number | string): LiabilityColumn => {
    const goodRenderer = { foreground: 'green', icon: '✓' };
    const badRenderer = { foreground: 'red', icon: '✗' };

    const lowerCaseString = String(data).toLowerCase();

    const falseBoolean =
      (typeof data === 'boolean' && data === false) ||
      lowerCaseString === 'false' ||
      lowerCaseString === 'no';

    const trueBoolean =
      (typeof data === 'boolean' && data === true) ||
      lowerCaseString === 'true' ||
      lowerCaseString === 'yes';

    if (falseBoolean) {
      return badRenderer;
    } else if (trueBoolean) {
      return goodRenderer;
    }
  };

  private readonly badSequenceDataCellRenderer = (
    data: boolean | number | string,
  ): LiabilityColumn => {
    // TODO Check if the assumptions made are correct else fix this.
    // Unsure if this is correct for all these liabilities.
    // I assume that they are falsy if they didn't occur on the sequence, otherwise they have some value.
    if (data) {
      return { foreground: 'red', icon: '⚠' };
    }
  };

  private readonly liabilityScoreCellRenderer = (
    data: boolean | number | string,
  ): LiabilityColumn => {
    if (FormatterService.isNumeric(data)) {
      return { background: this.liabilityScoreColor(Number(data)) };
    }
  };

  private readonly liabilityScoreTooltipRenderer = (
    value: boolean | number | string,
    metadata: Record<string, boolean | number | string>,
  ) => {
    const pairs = [
      { name: 'Liability score', value: FormatterService.isNumeric(value) ? value : '(no data)' },
    ];

    if (metadata.Error) {
      pairs.push({ name: 'Errors', value: metadata.Error });
    }

    const liabilities = [];
    for (const n of ['High', 'Medium', 'Low']) {
      const liability = metadata[`Liability (${n})`];
      if (liability) {
        liabilities.push(...String(liability).split('; '));
      }
    }

    if (liabilities.length) {
      pairs.push({ name: 'Liabilities', value: liabilities.join(', ') });
    }

    if (metadata.Asset) {
      pairs.push({ name: 'Assets', value: metadata.Asset });
    }

    const items = pairs
      .map((pair) => `<li><strong>${pair.name}:</strong> ${pair.value}</li>`)
      .join('\n');
    return `<ul>${items}</ul>`;
  };
}

export interface UniqueColumns {
  [type: string]: MetadataColumnOptions;
}

export enum SvSidebarRendererType {
  CONTINUOUS = 'continuous',
  BOOLEAN = 'boolean',
  TEXT = 'text',
}

export interface LiabilityColumn {
  /** css color code */
  foreground?: string;
  /** css color code */
  background?: string;
  /** Single characters only or it wont show. */
  icon?: string;
}
