import EventRegistry from "./Registry.js";
class Events {
  nodeRegistry = new EventRegistry();
  onceRegistry = new EventRegistry();
  persistentRegistry = new EventRegistry();
  /**
   * Emits an event - fetches the functions that have been bound to the
   * specified event(s) and calls them with the provided parameters.
   *
   * @param names of events to emit
   * @param parameters arguments to pass to the bound functions
   */
  emit(names, ...parameters) {
    if (typeof names === "string") {
      this.emitSingle(names, ...parameters);
      return;
    }
    for (const name of names) {
      this.emitSingle(name, ...parameters);
    }
  }
  emitSingle(name, ...parameters) {
    this.persistentRegistry.invoke(name, handler => handler(...parameters));
    this.onceRegistry.invoke(name, handler => handler(...parameters));
    this.onceRegistry.clear(name);
  }
  /**
   * Emits the specified event and returns an array of all values returned by
   * the bound functions.
   *
   * @param names of events to emit
   * @param parameters arguments to pass to the bound functions
   */
  invoke(name, ...parameters) {
    const result = [...this.persistentRegistry.invoke(name, fn => fn(...parameters)), ...this.onceRegistry.invoke(name, fn => fn(...parameters))];
    this.onceRegistry.clear(name);
    return result.filter(item => item != null);
  }
  emitAndBubble(name, start, event, position = {
    x: 0,
    y: 0
  }) {
    const pos = {
      ...position
    };
    for (let node = start; node; node = node.parent) {
      pos.x -= node.bounds.x;
      pos.y -= node.bounds.y;
    }
    this._emitAndBubble(name, start, pos, event);
  }
  _emitAndBubble(name, node, position, event) {
    this.matchAndEmit(name, node, event, position);
    if (node.parent) {
      const parent = {
        x: position.x + node.bounds.x,
        y: position.y + node.bounds.y
      };
      this._emitAndBubble(name, node.parent, parent, event);
    }
  }
  matchAndEmit(name, node, event, position) {
    this.nodeRegistry.invoke(name, ({
      type,
      callback
    }) => {
      if (node.type === type) {
        callback(node, event, position);
      }
    });
  }
  /**
   * Registers a callback for an event on one or more types of RenderNode.
   *
   * @param name the event that will trigger the callback
   * @param types a string containing comma-separated RenderNode types to listen
   *    for the event on
   * @param callback the function to call every time the event occurs on a
   *    RenderNode with a matching type
   */
  bindToNode(name, types, callback) {
    types.split(",").forEach(type => this.nodeRegistry.bind(name, {
      type: type.trim(),
      callback
    }));
  }
  /**
   * Registers a callback function to the specified event(s). This function
   * will be called every time the event is emitted/invoked.
   *
   * @param names of events to bind the callback to
   * @param callback the function to call when the event emits
   */
  bind(names, callback) {
    this.persistentRegistry.bind(names, callback);
  }
  /**
   * Registers a callback function to be invoked once for each event name.
   *
   * @param names of events to bind the callback to
   * @param callback the function to call when the event emits
   */
  bindOnce(names, callback) {
    this.onceRegistry.bind(names, callback);
  }
  /**
   * Unbinds a callback from an event.
   *
   * @param name event name
   * @param callback the bound function
   * @returns true if the function was unbound, false if it was not found
   */
  unbind(name, callback) {
    const results = [this.nodeRegistry.unbind(name, callback), this.onceRegistry.unbind(name, callback), this.persistentRegistry.unbind(name, callback)];
    return results.some(result => result);
  }
}
export { Events as default };