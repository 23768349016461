class UngappedRanges {
  constructor(annotation) {
    this.annotation = annotation;
    annotation.intervals.forEach(interval => {
      interval.ungappedRanges.forEach(range => {
        this.ranges.push({
          range,
          interval
        });
        this.length += range.length;
      });
    });
    Object.freeze(this.ranges);
    Object.freeze(this);
  }
  length = 0;
  ranges = [];
  /**
   * Removes or shrinks the first last range/s to remove the specified number of residues.
   */
  shrink(start, end) {
    if (start + end > this.length) {
      throw new Error("Can not shrink the ranges to a length less than zero");
    }
    const result = [...this.ranges];
    result.reverse();
    this.trim(result, start, false);
    result.reverse();
    this.trim(result, end, true);
    return result;
  }
  trim(items, offset, atEnd) {
    while (true) {
      const item = items.pop();
      if (item === void 0) {
        return;
      }
      const {
        range,
        interval
      } = item;
      if (range.length == offset) {
        return;
      } else if (range.length < offset) {
        offset -= range.length;
      } else {
        items.push({
          range: range.shrinkAt(offset, atEnd ? !interval.isReversed : interval.isReversed),
          interval
        });
        return;
      }
    }
  }
}
export { UngappedRanges as default };