import { clamp } from "../../includes/misc/Math.js";
import { isPrimaryButton } from "../../includes/misc/Utils.js";
const {
  round
} = Math;
class MetadataScrollbar {
  sv;
  container;
  // The visible part of the scrollbar.
  track;
  // The scrollbar track, minus the padding. This element makes it easier to calculate offsets.
  innerTrack;
  // The draggable part of the scrollbar.
  // @see https://webkit.org/blog/363/styling-scrollbars/.
  thumb;
  dragging = false;
  mouseOffset = 0;
  minLength = 20;
  constructor(sv) {
    this.sv = sv;
    this.container = document.createElement("div");
    this.container.classList.add("sv-scrollbar", this.className);
    this.container.addEventListener("wheel", e => this.sv.emit("wheel", e));
    this.track = document.createElement("div");
    this.track.classList.add("sv-scrollbar-track");
    this.container.appendChild(this.track);
    this.innerTrack = document.createElement("div");
    this.innerTrack.classList.add("sv-scrollbar-inner-track");
    this.track.appendChild(this.innerTrack);
    this.thumb = document.createElement("div");
    this.thumb.classList.add("sv-scrollbar-thumb");
    this.innerTrack.appendChild(this.thumb);
    this.sv.addEventListenerToDocument("mousemove", e => this.mouseMove(e));
    this.sv.addEventListenerToDocument("mouseup", e => this.mouseUp(e));
    this.thumb.addEventListener("mousedown", e => this.mouseDownOnThumb(e));
    this.hide();
  }
  hide() {
    this.container.classList.add("sv-scrollbar-hidden");
  }
  show() {
    this.container.classList.remove("sv-scrollbar-hidden");
  }
  updateOffset() {
    this.styleLength = this.thumbLength;
    this.styleOffset = this.offset;
  }
  update() {
    if (this.isFullyVisible) {
      this.hide();
    } else {
      this.show();
      this.updateOffset();
    }
  }
  mouseDownOnThumb(e) {
    if (!isPrimaryButton(e)) {
      return;
    }
    e.preventDefault();
    this.dragging = true;
    this.mouseOffset = this.localPos(e);
    this.thumb.classList.add("sv-scrollbar-pressed");
  }
  mouseUp(e) {
    if (!isPrimaryButton(e)) {
      return;
    }
    this.dragging = false;
    this.thumb.classList.remove("sv-scrollbar-pressed");
  }
  mouseMove(e) {
    if (this.dragging) {
      const globalPos = this.globalPos(e);
      const pos = this.relativeToTrack(globalPos) - this.mouseOffset;
      this.setBoundedOffset(pos);
    }
  }
  // Note similarities to ChannelView.setOffset().
  // @see ChannelView.ts.
  setBoundedOffset(offset) {
    const boundedOffset = clamp(offset, this.maxOffset);
    if (boundedOffset !== this.offset) {
      this.setOffsetFraction(boundedOffset / this.maxOffset);
      this.update();
    }
  }
  setOffsetFraction(fraction) {
    const maxOffset = this.plugin.enabledColumnsWidth - this.plugin.width;
    this.plugin.offset = round(maxOffset * clamp(fraction, 1));
    this.sv.emit("column visibility changed");
  }
  get thumbLength() {
    let thumbFraction = this.plugin.width / this.plugin.enabledColumnsWidth;
    if (!isFinite(thumbFraction)) {
      thumbFraction = 0;
    }
    const length = thumbFraction * this.trackLength;
    return clamp(round(length), this.trackLength, this.minLength);
  }
  get offset() {
    const offset = this.viewOffsetFraction * this.maxOffset;
    return clamp(offset, this.maxOffset, 0);
  }
  get viewOffsetFraction() {
    return this.plugin.offset / this.plugin.maxOffset;
  }
  get maxOffset() {
    return this.trackLength - this.thumbLength;
  }
  get isFullyVisible() {
    return this.plugin.enabledColumnsWidth <= this.plugin.width;
  }
  localPos(e) {
    return e.offsetX;
  }
  globalPos(e) {
    return e.pageX;
  }
  relativeToTrack(pos) {
    return pos - this.track.getBoundingClientRect()["left"];
  }
  get className() {
    return "sv-scrollbar-metadata";
  }
  get cornerClassName() {
    return "sv-scrollbar-corner-metadata-show";
  }
  get thumbElementOffset() {
    return this.thumb["offsetLeft"];
  }
  get thumbElementLength() {
    return this.thumb["offsetWidth"];
  }
  get trackLength() {
    return this.innerTrack["clientWidth"];
  }
  set styleLength(length) {
    this.thumb.style["width"] = length + "px";
  }
  set styleOffset(offset) {
    this.thumb.style["left"] = offset + "px";
  }
  get plugin() {
    return this.sv.metadata;
  }
}
export { MetadataScrollbar as default };