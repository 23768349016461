const {
  round,
  log
} = Math;
class FrameTimer {
  constructor(plugin) {
    this.plugin = plugin;
    if (plugin.measure) {
      plugin.sv.bind("first idle", () => this.prepare());
      plugin.sv.bind("prerender", () => this.increment());
      console.log(`SV: Now measuring ${plugin.measure} performance`);
    }
  }
  counter = 0;
  framesToPrime = 100;
  framesToMeasure = 100;
  totalFrames = this.framesToPrime + this.framesToMeasure;
  endsAtTarget = true;
  event = null;
  dimension = {
    "horizontal-scroll": "x",
    "vertical-scroll": "y"
  };
  increment() {
    this.counter++;
    if (this.counter === this.framesToPrime) {
      this.start();
    } else if (this.counter === this.totalFrames) {
      this.finish();
    }
    if (this.event && this.counter < this.totalFrames) {
      setTimeout(() => this.dispatchEvent());
    }
  }
  prepare() {
    this.plugin.stayAwake = true;
    if (this.plugin.measure === "zoom") {
      const view = this.plugin.sv.channelView;
      const {
        textThreshold
      } = view.sequencePainter.settings;
      view.setResidueWidth(textThreshold / view.maxResidueWidth);
    }
  }
  start() {
    const options = this.eventOptions;
    if (options) {
      this.event = new WheelEvent("wheel", options);
    }
    performance.mark("svStart");
  }
  dispatchEvent() {
    if (this.event && this.validate(false)) {
      this.plugin.sv.view.canvasElement.dispatchEvent(this.event);
    }
  }
  finish() {
    performance.mark("svEnd");
    this.plugin.stayAwake = false;
    this.event = null;
    if (!this.endsAtTarget || this.validate(true)) {
      performance.measure("svTime", "svStart", "svEnd");
      console.log("SV: Performance measuring has stopped");
    }
    this.plugin.measure = "done";
  }
  get measurements() {
    const {
      channelView
    } = this.plugin.sv;
    const measure = this.plugin.measure;
    if (measure === "zoom") {
      return {
        current: channelView.residueWidth,
        target: channelView.maxResidueWidth
      };
    } else if (measure !== void 0) {
      const dimension = this.dimension[measure];
      if (dimension) {
        return {
          current: channelView.offset[dimension],
          target: channelView.maxOffset[dimension]
        };
      }
    }
  }
  get eventOptions() {
    switch (this.plugin.measure) {
      case "vertical-scroll":
        return {
          deltaY: this.getScrollIncrement()
        };
      case "horizontal-scroll":
        return {
          deltaX: this.getScrollIncrement()
        };
      case "zoom":
        return {
          altKey: true,
          deltaX: this.zoomIncrement
        };
    }
  }
  validate(finishedMeasuring) {
    const measurements = this.measurements;
    if (!measurements) {
      return true;
    } else {
      const {
        current,
        target
      } = measurements;
      const atEnd = current === target;
      if (finishedMeasuring === atEnd) {
        return true;
      } else {
        this.plugin.measure = "done";
        throw new Error(`${this.plugin.measure} should equal maximum at end of performance measurement.`);
      }
    }
  }
  getScrollIncrement() {
    const measurements = this.measurements;
    if (measurements === void 0) {
      return;
    }
    const {
      current,
      target
    } = measurements;
    const remainingOffset = target - current;
    const increment = remainingOffset / this.framesToMeasure;
    if (increment > 100) {
      this.endsAtTarget = false;
      return 100;
    } else {
      return round(increment);
    }
  }
  get zoomIncrement() {
    const frames = this.framesToMeasure;
    const {
      zoomFactor,
      zoom
    } = this.plugin.sv;
    const measurements = this.measurements;
    if (measurements === void 0) {
      return;
    }
    const {
      current,
      target
    } = measurements;
    const rate = (target / current) ** (1 / frames) - 1;
    const increment = zoom.zoomFactor * log(1 + rate) / log(zoomFactor);
    return increment;
  }
}
export { FrameTimer as default };