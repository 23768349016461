import RRange from "./RRange.js";
import { indexRange } from "../misc/Utils.js";
class MultiRange {
  ranges = [];
  /**
   * @param ranges
   * @param processed True if all of the ranges provided are already non-intersecting.
   * @returns {MultiRange}
   */
  constructor(ranges = [], processed = false) {
    if (!processed) {
      return this.add(ranges);
    }
    this.ranges = ranges;
    Object.freeze(this.ranges);
    Object.freeze(this);
  }
  get gaps() {
    const result = [];
    for (let i = 1; i < this.ranges.length; i++) {
      const current = this.ranges[i];
      const previous = this.ranges[i - 1];
      result.push(new RRange(previous.end, current.start));
    }
    return new MultiRange(result);
  }
  addIndex(index) {
    return this.add(indexRange(index));
  }
  /**
   * @param ranges A range or array of ranges to add to the MultiRange.
   * @returns {MultiRange} A new MultiRange object containing the new ranges.
   */
  add(ranges) {
    if (Array.isArray(ranges)) {
      return new MultiRange(this._addMulti(this.ranges, ranges), true);
    } else {
      return new MultiRange(this._add(this.ranges, ranges), true);
    }
  }
  /**
   * Returns the [sub]ranges that overlap the query range.
   */
  intersection(query) {
    const result = this.ranges.map(range => range.intersection(query)).filter(range => !range.isEmpty);
    return new MultiRange(result);
  }
  contains(range) {
    const first = this.ranges.find(range2 => range2.end >= range.start);
    return first != null && first.containsRange(range);
  }
  // TODO Binary search could improve this (but not for trims which are only 2 ranges)
  containsIndex(index) {
    for (const range of this.ranges) {
      if (index < range.start) {
        return false;
      } else if (index < range.end) {
        return true;
      }
    }
    return false;
  }
  /**
   * Takes an array of ranges and adds a new range to it, joining the new range with any range it is touching.
   * @param ranges
   * @param range
   * @returns {RRange[]}
   * @private
   */
  _add(ranges, range) {
    if (range.isEmpty) {
      return ranges;
    }
    let newRange = range;
    let first;
    for (first = 0; first < ranges.length; first++) {
      if (ranges[first].end >= range.start) {
        break;
      }
    }
    if (first < ranges.length && range.touches(ranges[first])) {
      newRange = newRange.union(ranges[first]);
    }
    let end;
    for (end = first; end < ranges.length; end++) {
      if (ranges[end].start > range.end) {
        break;
      }
    }
    const last = end - 1;
    if (last >= 0 && range.touches(ranges[last])) {
      newRange = newRange.union(ranges[first]);
    }
    return ranges.slice(0, first).concat(newRange).concat(ranges.slice(end, ranges.length));
  }
  /**
   * Add multiple ranges at the same time.
   * @param oldRanges
   * @param newRanges
   * @returns {RRange[]}
   * @private
   */
  _addMulti(oldRanges, newRanges) {
    return newRanges.reduce((accum, range) => this._add(accum, range), oldRanges);
  }
  static fromArrays(ranges) {
    return new MultiRange(ranges.map(([start, end]) => new RRange(start, end)));
  }
  toString() {
    return "[" + this.ranges.map(range => range.toString()).join(", ") + "]";
  }
}
export { MultiRange as default };