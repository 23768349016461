class Pen {
  static brush = document.createElement("canvas").getContext("2d");
  fontName = "'Open Sans', sans-serif";
  // Metrics determined with the help of http://codepen.io/MisterCurtis/pen/doEzzx.
  // @see https://developer.apple.com/library/content/documentation/TextFonts/Conceptual/CocoaTextArchitecture/Art/glyph_metrics_2x.png
  capHeight = 0.72;
  // TODO Are these still useful?
  // xHeight = 0.54
  // ascent = 0.9
  // descent = 0.21
  fontSize;
  align;
  color;
  style = "";
  // Horizontal stretch only
  stretch = 1;
  // The only text baseline that Firefox and Chrome handle consistently is 'alphabetic'.
  // @see https://github.com/pixijs/pixi.js/issues/1021.
  // @see https://www.w3schools.com/tags/tryit.asp?filename=tryhtml5_canvas_textbaseline in Chrome and Firefox.
  baseline;
  handlers = {
    alphabetic: () => 0,
    middle: () => this.fontSize * (this.capHeight / 2)
  };
  // Some letters are too wide/narrow, so adjust the letters to fit.
  letterWidthAdjustments = {
    W: 0.8,
    M: 0.8,
    D: 0.9,
    N: 0.9,
    Q: 0.9,
    F: 1.1,
    "-": 2
  };
  constructor(fontSize, align, baseline, color) {
    this.fontSize = fontSize;
    this.baseline = baseline;
    this.align = align;
    this.color = color;
  }
  /**
   * Stretches writing to improve spacing of individual lettering in variable-width fonts.
   */
  stretchFor(letter) {
    this.stretch = this.letterWidthAdjustments[letter] || 1;
  }
  write(graphics, brush, text, x, y) {
    this.withBrush(brush, () => {
      const handler = this.handlers[this.baseline];
      graphics.fillText(brush, text, x / this.stretch, handler() + y);
    });
  }
  writeWithoutTransform(brush, text, x, y) {
    this.withBrush(brush, () => {
      const handler = this.handlers[this.baseline];
      brush.fillText(text, x / this.stretch, handler() + y);
    });
  }
  measureWidth(text) {
    return this.withBrush(Pen.brush, brush => brush.measureText(text).width);
  }
  withBrush(brush, callback) {
    brush.save();
    const size = this.fontSize.toPrecision(2);
    brush.font = `${this.style} ${size}px ${this.fontName}`;
    brush.textAlign = this.align;
    brush.textBaseline = "alphabetic";
    brush.fillStyle = this.color;
    brush.scale(this.stretch, 1);
    const value = callback(brush);
    brush.restore();
    return value;
  }
}
export { Pen as default };