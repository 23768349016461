import ReferenceWrapper from "../../includes/Channels/ReferenceWrapper.js";
import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import { clipBrush } from "../../includes/misc/Utils.js";
import AnnotationsCache from "./Cache/AnnotationsCache.js";
import IntervalPainter from "./IntervalPainter.js";
const {
  max,
  round
} = Math;
class AnnotationsChannel extends DataChannel {
  constructor(wrapper, dataCallback) {
    super(wrapper);
    this.dataCallback = dataCallback;
    this.margin = this.wrapper.type === "globals wrapper" ? 7 : 0;
    this._cache = new AnnotationsCache(this);
    this.painter = new IntervalPainter(this.wrapper.view);
    this.isReference = wrapper instanceof ReferenceWrapper;
  }
  // No margin between this channel and the next one, because rowMargin is enough,
  // unless this AnnotationsChannel is the global AnnotationsChannel.
  margin;
  _cache;
  isReference;
  rowHeight = 15;
  rowMargin = 3;
  // If the left and/or right ends of the interval are not in the viewport then the bounds is adjusted so that
  // large number rounding errors are not introduced for long intervals. However, setting the bounds to the start or
  // end of the viewport will cause the ending to be rendered even though they shouldn't.
  // @see https://stackoverflow.com/questions/8784405/large-numbers-in-html-canvas-translate-result-in-strange-behavior
  // Used by intervalRenderNodeWidth and intervalBounds
  // fudgeFactor needs to be larger than the widest ending.
  // TODO Use a big integer library?
  // @see https://www.npmjs.com/package/big-integer
  fudgeFactor = 1e3;
  painter;
  search(query, residue, dataRow) {
    const annotation = this.cache.getByStartAndRow(residue, dataRow);
    if (annotation && annotation.matches(query.keywords)) {
      this.sv.focused = {
        type: "interval",
        reference: annotation.intervals[0]
      };
      return annotation.range;
    }
  }
  calculateLayout(context, bounds) {
    const {
      visible
    } = context;
    this.cache.request(visible);
    const node = new RenderNode("annotations channel", this, bounds, this.cache.rows.map((row, index) => {
      const annotations = row.annotationsIn(visible);
      return new RenderNode("annotation row", row, this.rowBounds(index, bounds.width), this.rowChildren(annotations, context));
    }));
    if (!this.sv.view.circular) {
      node.setBackgroundCallback(() => clipBrush(this.graphics, this.brush, {
        width: context.width,
        height: bounds.height
      }));
    }
    return node;
  }
  rowBounds(index, width) {
    return {
      x: 0,
      y: index * this.rowHeightWithMargin,
      width,
      height: this.rowHeightWithMargin
    };
  }
  rowChildren(annotations, context) {
    return annotations.map(annotation => annotation.intervals).reduce((result, current) => result.concat(current), []).filter(interval => interval.range.overlaps(context.visible)).map(interval => this.intervalNode(interval, context));
  }
  intervalNode(interval, context) {
    let start = interval.left;
    let end = interval.right;
    if (start === end) {
      start -= 0.5;
      end += 0.5;
    }
    const {
      bounds,
      center
    } = this.intervalBounds(start, end, context);
    const node = new RenderNode("interval", interval, bounds);
    node.setRenderCallback(brush => this.painter.render(interval, node.bounds, center, brush, context));
    return node;
  }
  intervalBounds(start, end, context) {
    const fudge = this.fudgeFactor;
    const offset = this.view.offset.x;
    const left = round(this.view.toPixels(start - context.row.start));
    const right = round(this.view.toPixels(end - context.row.start));
    let viewportWidth = this.view.viewport.width;
    if (this.sv.view.circular) {
      viewportWidth = this.view.width;
    }
    const onScreen = {
      left: left >= offset - fudge,
      right: right <= offset + viewportWidth + fudge
    };
    const center = (left + right) / 2 - this.view.toPixels(context.row.start);
    return {
      center: onScreen.left ? center - left : center - offset + fudge,
      bounds: {
        // fudgeFactor is subtracted from the start to prevent the left ending of an interval from rendering when it shouldn't
        x: onScreen.left ? left - offset : 0 - fudge,
        y: 0,
        width: this.intervalRenderNodeWidth(onScreen, max(right - left, 1), right),
        height: this.rowHeight
      }
    };
  }
  intervalRenderNodeWidth({
    left,
    right
  }, total, rightOffset) {
    const fudge = this.fudgeFactor;
    let viewportWidth = this.view.viewport.width;
    if (this.sv.view.circular) {
      viewportWidth = this.view.width;
    }
    if (left && right) {
      return total;
    } else if (!left && !right) {
      return viewportWidth + 2 * fudge;
    } else if (left && !right) {
      return viewportWidth + fudge;
    } else if (!left && right) {
      return rightOffset - this.view.offset.x + fudge;
    } else {
      return 0;
    }
  }
  requestData() {
    this.cache.getPendingAndMarkRequested().ranges.forEach(range => {
      this.wrapper.sv.emit("data request started");
      const request = {
        annotations: range
      };
      this.dataCallback(request, response => {
        if (response.data["annotations"]) {
          const changed = this.wrapper.cacheNewData(request["annotations"], response.data["annotations"], this.cache);
          if (changed) {
            this.sv.view.dirty = "new data";
          }
        }
        if (response.complete) {
          this.sv.emit("data request resolved");
        }
      });
    });
  }
  clearCacheAndMaintainFocus() {
    this.cache.clearCacheAndMaintainFocus();
  }
  clearCache() {
    this.cache.clearCache();
  }
  get index() {
    return this.sv.annotations.getChannels(this.wrapper).indexOf(this);
  }
  get visible() {
    if (!this.sv.annotations.enabled) {
      return false;
    } else if (this.sv.annotations.filter === "all") {
      return true;
    } else if (this.wrapper.type === "globals wrapper" && this.sv.annotations.filter !== "reference") {
      return true;
    } else if (this.wrapper.type === "reference wrapper" && this.sv.annotations.filter === "reference") {
      return true;
    } else {
      return false;
    }
  }
  get cache() {
    if (this.isReference) {
      return this.view.reference.annotationsCache;
    } else {
      return this._cache;
    }
  }
  get rowCount() {
    return this.cache.rows.length;
  }
  get height() {
    return this.rowHeightWithMargin * this.cache.rows.length;
  }
  get rowHeightWithMargin() {
    return this.rowHeight + this.rowMargin;
  }
}
export { AnnotationsChannel as default };