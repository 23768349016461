const {
  round
} = Math;
function adjustRange(start, length) {
  const ratio = window.devicePixelRatio;
  const offset = _adjust(start, ratio);
  const end = _adjust(start + length, ratio);
  return {
    start: offset,
    length: end - offset
  };
}
function adjustOffset(offset) {
  return _adjust(offset, window.devicePixelRatio);
}
function _adjust(x, ratio) {
  return round(x * ratio) / ratio;
}
function isLowDPIScreen() {
  return window.devicePixelRatio === 1;
}
function centerStrokeCoordinateForLowDPIScreen(raw) {
  return isLowDPIScreen() ? round(raw) + 0.5 : raw;
}
export { _adjust, adjustOffset, adjustRange, centerStrokeCoordinateForLowDPIScreen, isLowDPIScreen };