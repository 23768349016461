import WuKabatCalculator from "./WuKabatCalculator.js";
import DerivedCache from "../../includes/PluginBaseClasses/DerivedCache.js";
class WuKabatCache extends DerivedCache {
  calculator;
  constructor(channel, sequences) {
    super(channel, sequences);
    this.calculator = new WuKabatCalculator(this);
  }
  get maxPossibleScore() {
    return this.calculator.maxPossibleScore;
  }
}
export { WuKabatCache as default };