import DataCache from "../../includes/PluginBaseClasses/DataCache.js";
const {
  sqrt,
  min,
  max
} = Math;
class ChromatogramCache extends DataCache {
  nucleotideBases = ["a", "c", "g", "t"];
  baseCallPositions = [];
  traces = {
    a: [],
    c: [],
    g: [],
    t: []
  };
  call(index) {
    return this.baseCallPositions[index];
  }
  /**
   * Returns true if the specified index maps to a valid (non-gap) base call
   * position.
   *
   * @param index the index
   * @returns whether the index is a valid call
   */
  hasValidCall(index) {
    const position = this.call(index);
    return position != null && position >= 0;
  }
  /**
   * Like {@link call}, but calls that are out of bounds are converted to the
   * min or max position for the base.
   *
   * @param index the index
   * @param base the base to target
   * @returns the traces position
   */
  safeCall(index, base) {
    if (index < 0) {
      return 0;
    }
    if (index >= this.baseCallPositions.length) {
      return this.traces[base].length - 1;
    }
    return this.baseCallPositions[index];
  }
  trace(letter, index) {
    return this.traces[letter][index];
  }
  tracesIn(letter, index) {
    return this.tracesInRange(letter, this.baseCallPositions[index], this.baseCallPositions[index + 1]);
  }
  /**
   * Gets a slice of the traces array for the provided base. Note that the
   * provided position parameters should be indices for the traces array (like
   * the values in the positions array).
   *
   * @param letter the base
   * @param startPosition the start index in the traces array
   * @param endPosition the (exclusive) end index in the traces array
   * @returns a slice of the traces array
   */
  tracesInRange(letter, startPosition, endPosition) {
    return this.traces[letter].slice(startPosition, endPosition);
  }
  tracesLength(letter) {
    return this.traces[letter].length;
  }
  /**
   * @returns the length of the positions array
   */
  numPositions() {
    return this.baseCallPositions.length;
  }
  /**
   * Calculate the maximum trace value to render - anything above this value gets cut off.
   * Returns 3 standard deviations above the mean, or the maximum value if it is smaller.
   * @param range
   */
  max(range) {
    const calls = this.baseCallPositions.slice(range.start, range.end);
    const peakValues = this.nucleotideBases.map(base => {
      return calls.map(i => this.traces[base][i]).filter(t => t);
    });
    const all = peakValues.reduce((a, b) => a.concat(b), []);
    const n = all.length;
    const avg = all.reduce((a, b) => a + b, 0) / n;
    const sqrdev = x => (x - avg) * (x - avg);
    const variance = all.reduce((sum, x) => sum + sqrdev(x), 0) / n;
    const std = sqrt(variance);
    return min(avg + std * 3, all.reduce((a, b) => max(a, b), 0));
  }
  set(range, data) {
    if (!data) {
      return false;
    }
    if (range.start !== 0 || range.length !== data.baseCallPositions.length) {
      throw new Error(`ChromatogramCache does not support dynamic data loading - set() was called with range ${range}`);
    }
    if (this.dataChanged(data)) {
      this.replaceAll(data);
      return true;
    }
    return false;
  }
  dataChanged({
    baseCallPositions,
    ...traces
  }) {
    if (baseCallPositions.length !== this.baseCallPositions.length) {
      return true;
    }
    if (baseCallPositions.some((position, i) => position !== this.baseCallPositions[i])) {
      return true;
    }
    for (const [base, traceData] of Object.entries(traces)) {
      const oldTraces = this.traces[base];
      const newTraces = traceData ?? [];
      if (newTraces.length !== oldTraces || newTraces.some((trace, i) => trace !== oldTraces[i])) {
        return true;
      }
    }
    return false;
  }
  get hasData() {
    return Object.values(this.traces).some(trace => trace.length > 0);
  }
  /**
   * Returns a copy of all data in the cache.
   *
   * @returns the chromatogram data
   */
  getAllData() {
    return {
      baseCallPositions: [...this.baseCallPositions],
      a: [...this.traces.a],
      c: [...this.traces.c],
      t: [...this.traces.t],
      g: [...this.traces.g]
    };
  }
  /**
   * Replaces chromatogram data in the cache without checking whether it's changed.
   *
   * @param data the new chromatogram data. It can be partial data if you only
   *    want to replace certain properties.
   */
  replaceAll({
    baseCallPositions,
    ...traces
  }) {
    if (baseCallPositions) {
      this.baseCallPositions = baseCallPositions;
    }
    for (const [base, traceData] of Object.entries(traces)) {
      this.traces[base] = traceData ?? [];
    }
  }
}
export { ChromatogramCache as default };