export enum AntibodyType {
  Standard = 'Standard',
  NonStandard = 'Non-standard',
}

export const ANTIBODY_TYPES: Readonly<{ name: string; value: AntibodyType }>[] = [
  {
    name: 'Standard (Ig-like antibodies)',
    value: AntibodyType.Standard,
  },
  {
    name: 'Non-standard (Scaffold peptides)',
    value: AntibodyType.NonStandard,
  },
];
