import { clipBrush } from "../../includes/misc/Utils.js";
import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import { RRange } from "../../index.js";
const {
  max,
  min,
  round,
  floor,
  ceil
} = Math;
class GraphsChannel extends DataChannel {
  _margin = 2;
  hasData = false;
  constructor(wrapper, enabled) {
    super(wrapper);
    if (enabled) {
      this.sv.bindOnce("data request resolved", () => {
        if (this.plugin.qualities && this.qualitiesCache.hasData || this.plugin.chromatogram && this.chromatogramCache.hasData) {
          this.ensureHeight();
        }
      });
    }
  }
  calculateLayout(context, bounds) {
    const node = new RenderNode("graphs channel", this, bounds);
    node.setRenderCallback(brush => this.render(brush, context, bounds));
    return node;
  }
  render(brush, context, {
    height
  }) {
    const width = floor(this.sv.channelView.toPixels(context.visible.length));
    clipBrush(this.graphics, brush, {
      width,
      height
    });
    this.renderQualityScore(brush, context);
    if (context.residueWidth >= 1) {
      this.renderChromatogram(brush, context);
    }
  }
  renderQualityScore(brush, context) {
    if (!this.sv.graphs?.qualities) {
      return;
    }
    const {
      visible,
      residueWidth
    } = context;
    const cache = this.qualitiesCache;
    cache.request(visible);
    const seqCache = this.sequenceCache;
    seqCache.request(visible);
    const left = context.toViewportOffset(visible.start);
    const height = this.height;
    brush.beginPath();
    let x = left;
    let y;
    brush.moveTo(left, height);
    for (let i = visible.start; i < visible.end; i++) {
      x = left + this.view.toPixels(i - visible.start);
      if (this.isValidQuality(i)) {
        this.ensureHeight();
        y = cache.getScaled(i, height);
      } else {
        y = height;
      }
      brush.lineTo(round(x), y);
      brush.lineTo(round(x + residueWidth), y);
    }
    brush.lineTo(x + residueWidth, height);
    brush.fillStyle = "#edfcff";
    brush.fill();
    brush.beginPath();
    for (let i = visible.start; i < visible.end; i++) {
      x = left + this.view.toPixels(i - visible.start);
      if (this.isValidQuality(i)) {
        y = cache.getScaled(i, height) || height;
        if (this.isValidQuality(i - 1)) {
          brush.lineTo(round(x) + 0.5, y + 0.5);
        } else {
          brush.moveTo(round(x) + 0.5, y + 0.5);
        }
        brush.lineTo(round(x + residueWidth) + 0.5, y + 0.5);
      }
    }
    brush.strokeStyle = "#96c4da";
    brush.stroke();
  }
  renderChromatogram(brush, context) {
    if (!this.sv.graphs?.chromatogram || !this.chromatogramCache.hasData) {
      return;
    }
    const residueWidth = this.view.residueWidth;
    const visible = context.visible;
    const cache = this.chromatogramCache;
    cache.request(visible);
    const left = context.toViewportOffset(visible.start);
    const height = this.height;
    const scale = height / cache.max(visible);
    const colorMap = this.colorMap().colors;
    this.ensureHeight();
    const rangesToRender = this.getChromatogramRenderRanges(visible);
    if (rangesToRender.length === 0) {
      return;
    }
    for (const base of cache.nucleotideBases) {
      brush.strokeStyle = colorMap[base];
      brush.beginPath();
      for (const renderRange of rangesToRender) {
        const wrappingTraces = this.getTracesForWrappingHalfResidues(renderRange, base);
        const validRange = renderRange.validRange;
        const xStart = left + residueWidth * (validRange.start - visible.start);
        brush.moveTo(xStart, height - scale * wrappingTraces.left[0]);
        const halfResidue = residueWidth / 2;
        this.renderTraces(brush, wrappingTraces.left, halfResidue, xStart, height, scale);
        const centerAlignedXStart = xStart + halfResidue;
        for (const residueIndex of validRange) {
          this.renderTraces(brush, cache.tracesIn(base, residueIndex), residueWidth, centerAlignedXStart + residueWidth * (residueIndex - validRange.start), height, scale);
        }
        const rightXStart = centerAlignedXStart + residueWidth * validRange.length;
        const rightXOffset = halfResidue / wrappingTraces.right.length;
        this.renderTraces(brush, wrappingTraces.right, halfResidue, rightXStart + rightXOffset, height, scale);
      }
      brush.stroke();
    }
  }
  /**
   * Renders a section of chromatogram trace data as a line graph.
   *
   * @param brush the canvas rendering context with paint methods
   * @param traces the trace values to graph
   * @param renderWidth the width that the graph should take up
   * @param xStart the x point to start drawing from
   * @param height the height of the graph
   * @param scale the scale to apply to the trace values
   */
  renderTraces(brush, traces, renderWidth, xStart, height, scale) {
    if (traces.length === 0) {
      return;
    }
    const gap = renderWidth / traces.length;
    const valuesPerPixel = max(floor(traces.length / renderWidth), 1);
    for (let j = 0; j < traces.length; j += valuesPerPixel) {
      const valuesInPixel = traces.slice(j, j + valuesPerPixel);
      const y = height - scale * max(...valuesInPixel);
      const x = xStart + gap * j;
      brush.lineTo(x, y);
    }
  }
  /**
   * Splits up the visible range into a list of subranges that have valid calls
   * and are unbroken by gaps. Each subrange also contains additional data
   * needed for rendering - see {@link ChromatogramRenderRange} for more info.
   *
   * @param visible the visible range of indices
   * @returns the ranges to render
   */
  getChromatogramRenderRanges(visible) {
    const cache = this.chromatogramCache;
    const ranges = [];
    let residueIndex = visible.find(i => cache.hasValidCall(i));
    if (residueIndex === void 0) {
      return ranges;
    }
    const numPositions = cache.numPositions();
    const maxResidueIndex = Math.min(numPositions, visible.end);
    while (residueIndex < maxResidueIndex) {
      const validLeft = residueIndex;
      let validRight = validLeft;
      for (let i = validLeft + 1; i <= numPositions; i++) {
        if (!cache.hasValidCall(i) || i === maxResidueIndex) {
          validRight = i - 1;
          break;
        }
      }
      let neighborLeft = validLeft - 1;
      if (ranges.length) {
        neighborLeft = ranges[ranges.length - 1].validRange.end;
      } else {
        while (!cache.hasValidCall(neighborLeft) && neighborLeft > -1) {
          neighborLeft--;
        }
      }
      let neighborRight = validRight + 1;
      while (!cache.hasValidCall(neighborRight) && neighborRight < numPositions) {
        neighborRight++;
      }
      ranges.push({
        validRange: new RRange(validLeft, validRight),
        neighborIndices: {
          left: neighborLeft,
          right: neighborRight
        }
      });
      residueIndex = neighborRight;
    }
    return ranges;
  }
  getTracesForWrappingHalfResidues({
    validRange,
    neighborIndices
  }, base) {
    const cache = this.chromatogramCache;
    const leftPosition = cache.call(validRange.start);
    const tracesToLeftNeighbor = leftPosition - cache.safeCall(neighborIndices.left, base);
    const residuesToLeftNeighbor = validRange.start - neighborIndices.left;
    const tracesPerResidueOnLeft = tracesToLeftNeighbor / residuesToLeftNeighbor;
    const leftPositionMinusHalfResidue = min(floor(leftPosition - tracesPerResidueOnLeft / 2), leftPosition - 1);
    const rightPosition = cache.call(validRange.end);
    const tracesToRightNeighbor = cache.safeCall(neighborIndices.right, base) - rightPosition;
    const residuesToRightNeighbor = neighborIndices.right - validRange.end;
    const tracesPerResidueOnRight = tracesToRightNeighbor / residuesToRightNeighbor;
    const rightPositionPlusHalfResidue = max(ceil(rightPosition + tracesPerResidueOnRight / 2), rightPosition + 1);
    return {
      left: cache.tracesInRange(base, leftPositionMinusHalfResidue, leftPosition),
      right: cache.tracesInRange(base, rightPosition, rightPositionPlusHalfResidue)
    };
  }
  colorMap() {
    const schemes = this.view.sv.DNAColorSchemes;
    const name = this.view.sv.DNAColorScheme;
    const scheme = schemes[name];
    if (!scheme.isDynamic) {
      if (scheme.isForeground) {
        return scheme;
      } else {
        const fore = name.replace("Background", "Foreground");
        if (schemes[fore]) {
          return schemes[fore];
        }
      }
    }
    return schemes.geneiousForeground;
  }
  isValidQuality(index) {
    return this.qualitiesCache.isSet(index) && (this.qualitiesCache.get(index) ?? -1) >= 0 && this.sequenceCache.isDefined(index);
  }
  ensureHeight() {
    if (!this.hasData) {
      this.view.maintainFocusWhileChangingRowHeight(() => this.hasData = true);
      this.view.sv.view.dirty = "quality channel data confirmed";
    }
  }
  get visible() {
    return this.plugin.qualities || this.plugin.chromatogram;
  }
  get plugin() {
    return this.sv.graphs;
  }
  get height() {
    return this.hasData && this.view.sv.graphs ? this.view.sv.graphs.height : 0;
  }
  get margin() {
    return this.hasData ? this._margin : 0;
  }
  get qualitiesCache() {
    return this.wrapper.qualitiesCache;
  }
  get sequenceCache() {
    return this.wrapper.sequenceCache;
  }
  get chromatogramCache() {
    return this.wrapper.chromatogramCache;
  }
}
export { GraphsChannel as default };