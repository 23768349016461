import Scrollbar from "./Scrollbar.js";
class HorizontalScrollbar extends Scrollbar {
  get configuration() {
    return {
      direction: "horizontal",
      length: "width",
      offset: "left",
      axis: "x"
    };
  }
}
export { HorizontalScrollbar as default };