import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isMetadataColumnGroup } from './sequence-viewer-utils';
import { MetadataColumnOrGroup, RemoveSvOption } from './sequence-viewer.interfaces';
import { SequenceViewerService } from './sequence-viewer.service';
import { SequenceViewerMetadataService } from '../../core/sequence-viewer/sequence-viewer-metadata.service';
import {
  FullSequenceViewer,
  InitializeOptions,
  SequenceData,
  SequenceWrapper,
} from '@geneious/sequence-viewer';
import Annotation from '@geneious/sequence-viewer/plugins/Annotations/Cache/Annotation';
import Options from '@geneious/sequence-viewer/includes/misc/Options';
import { MetadataColumnOptions } from '@geneious/sequence-viewer/types';
import { APP_NAME } from 'src/app/app.constants';

/**
 * Wrapper for the external Sequence Viewer library.
 *
 * @see https://github.com/Geneious/sequence-viewer
 *
 * - Knows how to fetch the sequence data from APIs for the SV.
 * - Instantiates, and reinstantiates if the IDs change.
 * - Exposes the SV API on the `sv` property.
 * - Persists options across selection changes and navigation events;
 */
@Directive({
  selector: '[bxSequenceViewer]',
  standalone: true,
})
export class SequenceViewerDirective implements OnChanges {
  @Input() sequences: SequenceData[];
  @Input() trees: string[];
  @Input() consensus?: { annotations: Annotation };
  @Input() reference: number;
  @Input() metadataColumns: MetadataColumnOrGroup[];
  @Input() metadataColumnOrder: string[];
  @Input() documentsType: string | null = null;
  @Input() sequenceEditable = false;
  @Input() savedOptions: Record<string, unknown> = {};
  @Input() exportOptions?: Record<string, unknown>;
  @Input() enableSequenceSelection?: boolean = false;
  @Input() circularModeEnabled?: boolean = false;
  @Output() newViewer: EventEmitter<any> = new EventEmitter();
  @Output() savedOptionsRemove: EventEmitter<RemoveSvOption> = new EventEmitter();
  @Output() optionChangedSV: EventEmitter<Object> = new EventEmitter();

  serverOptions?: Options;

  constructor(
    private container: ElementRef,
    private sequenceViewerService: SequenceViewerService,
    private svMetadataService: SequenceViewerMetadataService,
  ) {}

  /**
   * promisesOfSequences is deprecated and should be replaced with the @Input sequences.
   */
  ngOnChanges({ sequences }: SimpleChanges) {
    if (sequences && sequences.currentValue && sequences.currentValue.length > 0) {
      this.onSequencesChange();
    }
  }

  private onSequencesChange() {
    const options = this.options();

    options.sequences = this.sequences;

    if (this.circularModeEnabled) {
      options.circular = !this.sequenceViewerService.circular;
      options.annotations = {
        types: {
          source: !this.sequenceViewerService.circular,
        },
      };
    }

    if (this.trees && SequenceViewerService.enableAlignmentFeatures(this.sequences)) {
      options.trees = this.trees;
    }

    this.sequenceViewerService.metadataColumns = this.metadataColumns || [];

    // Restore metadata from saved options.
    this.restoreSavedMetadata(
      this.sequenceViewerService.metadataColumns,
      options.metadataColumns as Record<string, boolean>,
      options.metadataColumnWidths as Record<string, number>,
    );

    // Flattens the columns and groups into an array of columns for initialising SV.
    let flatMetadataColumns = this.sequenceViewerService.metadataColumns.flatMap((current) => {
      if (isMetadataColumnGroup(current)) {
        const groupName = current.name === APP_NAME ? null : current.name;
        return current.columns.map((column) => ({ ...column, groupName }));
      }
      return [current];
    });
    if (this.metadataColumnOrder) {
      flatMetadataColumns = this.svMetadataService.sortMetadataColumns(
        flatMetadataColumns,
        this.metadataColumnOrder,
      );
    }

    if (this.consensus) {
      if (options.consensus) {
        // If there is both consensus data on the document
        // and consensus initialization options, merge them.
        this.consensus = Object.assign(this.consensus, options.consensus);
      }
      options.consensus = this.consensus;
    }

    if (this.reference) {
      options.reference = this.reference;
    }

    // The sequence viewer should never be passed some AA and some Nucleotides; only all of one
    // kind or the other. It doesn't make sense to show them together so we don't.
    // Therefore it's ok to check the type of the first sequence only.
    if (!SequenceViewerService.isDNASequence(options.sequences as SequenceData[])) {
      options.sequenceType = 'AA';
      if (options.translations) {
        (options.translations as Record<string, unknown>).enabled = false;
      }
    }

    options.editable = this.sequenceEditable;
    options.export = this.exportOptions;
    options.fetchDataOnce = true;

    this.instantiateViewer({
      ...options,
      metadataColumns: flatMetadataColumns as MetadataColumnOptions[],
      sequences: options.sequences as SequenceData[],
    });
  }

  private options() {
    // Clone the server saved options so they become immutable.
    this.serverOptions = JSON.parse(JSON.stringify(this.savedOptions));

    return this.defaultOptions();
  }

  private defaultOptions(): Record<string, unknown> {
    const fixedOptions = {
      alignment: {
        // Check if the number of sequences is not too large for trees to be displayed.
        enabled: SequenceViewerService.enableAlignmentFeatures(this.sequences)
          ? // Check if documentsType is an alignment.
            // If no documentsType is given then set enabled to undefined and let the automatic alignment detection determine it.
            this.documentsType && SequenceViewerService.isAlignmentDocument(this.documentsType)
          : false,
      },
      centered: false,
      selection: {
        selectWholeSequenceOnLabelClick: !this.enableSequenceSelection,
      },
    };

    // Determine initial state of graphs checkboxesState based on saved options and default values.
    const checkboxes = this.checkboxesState();
    this.sequenceViewerService.graphsCheckboxesInitialState = checkboxes;

    const result: Record<string, unknown> = { ...this.serverOptions, ...fixedOptions };

    result.sequenceSelection = {
      ...(result.sequenceSelection as Record<string, unknown>),
      enabled: this.enableSequenceSelection,
    };

    // Set initial state of graphs plugins based on the all graphs saved option. This allows disabling a plugin saved
    // as enabled because all graphs were saved as disabled.
    // @see OptionsPanelComponent.allGraphs.
    result.graphs = {
      ...(result.graphs as Record<string, unknown>),
      chromatogram: checkboxes.all && checkboxes.chromatogram,
      qualities: checkboxes.all && checkboxes.qualities,
    };
    result.identity = {
      ...(result.identity as Record<string, unknown>),
      enabled: checkboxes.all && checkboxes.identity,
    };
    result.wuKabat = {
      ...(result.wuKabat as Record<string, unknown>),
      enabled: checkboxes.all && checkboxes.wuKabat,
    };

    if (this.sequenceViewerService.showAlignmentControls) {
      if (
        result.highlighting &&
        typeof (result.highlighting as Record<string, unknown>).enabled === 'boolean'
      ) {
        this.sequenceViewerService.highlightingWasEnabled = (
          result.highlighting as { enabled: boolean }
        ).enabled;
      } else {
        this.sequenceViewerService.highlightingWasEnabled = true;
      }
    } else {
      this.sequenceViewerService.highlightingWasEnabled = false;
    }

    // Disable the highlighting plugin if restoring Color By Annotation.
    if (this.isSavedColorSchemeByAnnotation(result)) {
      result.highlighting = {
        ...(result.highlighting as Record<string, unknown>),
        enabled: false,
      };
      this.sequenceViewerService.highlightingControlsDisabled = true;
    }

    // Some saved options need to be explicitly ignored given the data as SV will initialise them otherwise.
    if (!this.sequenceViewerService.showAlignmentControls && this.serverOptions) {
      delete result.identity;
      delete result.wuKabat;
      delete result.consensus;
      // Don't apply saved consensusOnly option if there is no consensus.
      if (result.annotations) {
        delete (result.annotations as Record<string, unknown>).consensusOnly;
      }
      delete result.highlighting;
    }

    if (this.exportOptions?.enabled) {
      delete result.selection;
    }

    return result;
  }

  private checkboxesState() {
    /** Returns rawValue if it is a defined boolean, otherwise returns defaultValue */
    function booleanOrDefault(rawValue: unknown, defaultValue: boolean): boolean {
      return typeof rawValue === 'boolean' ? rawValue : defaultValue;
    }

    const options = this.serverOptions as unknown as Record<string, any>;
    const isAlignment = this.sequenceViewerService.showAlignmentControls;

    return {
      all: booleanOrDefault(options?.allGraphs, true),
      chromatogram: booleanOrDefault(options?.graphs?.chromatogram, true),
      qualities: booleanOrDefault(options?.graphs?.qualities, false),
      identity: !isAlignment ? false : booleanOrDefault(options?.identity?.enabled, true),
      wuKabat: !isAlignment ? false : booleanOrDefault(options?.wuKabat?.enabled, false),
    };
  }

  private restoreSavedMetadata(
    provided: MetadataColumnOrGroup[],
    metadataColumnsState: { [type: string]: boolean },
    metadataColumnWidthsState: { [type: string]: number },
  ) {
    // Metadata is stored on the server as a Map of <column name> to <enabled state>.
    provided.forEach((current) => {
      if (isMetadataColumnGroup(current)) {
        // Recursion!
        this.restoreSavedMetadata(current.columns, metadataColumnsState, metadataColumnWidthsState);
      } else {
        if (metadataColumnsState) {
          const savedOptionsColumnEnabled = metadataColumnsState[current.name];
          current.enabled = savedOptionsColumnEnabled ?? false;
        }
        if (metadataColumnWidthsState) {
          const savedOptionsColumnWidth = metadataColumnWidthsState[current.name];
          if (savedOptionsColumnWidth) {
            current.width = savedOptionsColumnWidth;
          }
        }
      }
    });
  }

  private isSavedColorSchemeByAnnotation(options: any): boolean {
    const plugin = options.sequencesPlugin;
    const DNAData = this.sequenceViewerService.showDNAControls;

    return (
      plugin &&
      ((DNAData && plugin.DNAColorScheme === 'byAnnotation') ||
        (!DNAData && plugin.proteinColorScheme === 'byAnnotation'))
    );
  }

  private instantiateViewer(options: InitializeOptions) {
    const viewer = new FullSequenceViewer(this.container.nativeElement, options);

    this.newViewer.emit(viewer);

    // Bind to initialisation option errors from SV core and emit them to sequence viewer component to pass on to BX.
    viewer.bind('invalid initialization option', (path, key, value) => {
      this.savedOptionsRemove.emit({
        path: path.map((p) => p.toString()),
        key,
        logError: true,
        value,
      });
    });

    // Emit saved view options on idle render frames.
    viewer.bind('idle', () => {
      // Round to nearest residue and add 1 because start position is restored by 1-based position rather than 0-based index.
      const startPosition = Math.round(this.sequenceViewerService.currentPositionIndex) + 1;
      const residueWidth = Math.round(this.sequenceViewerService.residueWidth * 100) / 100;

      this.optionChangedSV.emit({ range: { start: startPosition } });
      this.optionChangedSV.emit({ zoom: { residueWidth: residueWidth } });
      this.optionChangedSV.emit({
        labels: { width: this.sequenceViewerService.labelColumnWidth },
      });
      this.optionChangedSV.emit({ tree: { width: this.sequenceViewerService.tree.width } });
      this.optionChangedSV.emit({
        metadata: { labelHeight: this.sequenceViewerService.metadata.labelHeight },
      });
      this.optionChangedSV.emit({ metadataColumnWidths: this.getEnabledMetadataColumnWidths() });
    });

    viewer.bind('selection changed', () => {
      const selection = this.sequenceViewerService.selection;

      // TODO Also save selections on the consensus.
      const originalIndex = (selection.anchorWrapper as SequenceWrapper).originalIndex;
      if (originalIndex == null) {
        return;
      }

      this.optionChangedSV.emit({
        selection: {
          selections: [
            {
              positionStart: selection.anchor,
              positionEnd: selection.endAnchor,
              sequenceStart: originalIndex,
              sequenceEnd: originalIndex + 1,
            },
          ],
        },
      });
    });

    viewer.bind('sorting changed', () => {
      this.optionChangedSV.emit({
        sorting: { currentSort: this.sequenceViewerService.sorting.currentSort },
      });
    });
  }

  private getEnabledMetadataColumnWidths() {
    return this.sequenceViewerService.metadata.columns.reduce((agg: any, col: any) => {
      if (col.enabled) {
        agg[col.name] = col.width;
      }
      return agg;
    }, {});
  }
}
