import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../features/grid/datasource/grid.resource';
import { FolderService } from '../folders/folder.service';
import { GridSelectionStateService } from '../grid-selection-state/grid-selection-state.service';
import { getRowIdentifier } from '../ngs/getRowIdentifier';
import { FetchService } from '../results/assay-data/fetch.service';
import { ViewerPageURLSelectionState } from './viewer-page.component';

class ViewerPageDocumentsResource implements IGridResource {
  constructor(
    private folderID: string,
    private folderService: FolderService,
  ) {}

  query(
    params: IGetRowsRequestMinimal | any,
    optionalParams?: any,
  ): Observable<IGridResourceResponse<any>> {
    // Support calling this method from both bx-grid and fetchService.
    const pageLimit = params.endRow - params.startRow;
    const pageOffset = params.startRow;

    return this.folderService
      .getFilteredChildrenEntities(
        {
          folderID: this.folderID,
          pageLimit,
          pageOffset,
          maxDepth: 1,
          sortOn: '',
          excludeTypes: ['folder'],
        },
        false,
      )
      .pipe(
        // Map to IGridResourceResponse
        map((response) => ({
          data: response.data,
          metadata: {
            total: response.metadata.page.total,
            limit: response.metadata.page.limit,
            offset: response.metadata.page.offset,
          },
        })),
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ViewerPageService {
  constructor(
    private fetchService: FetchService,
    private folderService: FolderService,
    private gridSelectionStateService: GridSelectionStateService,
  ) {}

  fetchDocuments(state: ViewerPageURLSelectionState): Observable<any[]> {
    return this.fetchService
      .getAll(new ViewerPageDocumentsResource(state.folderID, this.folderService), undefined)
      .pipe(
        // The Main UI application combines metadata onto the main object.
        // TODO This needs to changed app wide. We don't want to do this. Better typing would help.
        map((res) => res.rows.map((row) => ({ ...row, ...row.metadata }))),
        map((rows) => {
          if (state.selectAll) {
            return rows.filter((row) => !state.ids.includes(getRowIdentifier(row)));
          } else {
            return rows.filter((row) => state.ids.includes(getRowIdentifier(row)));
          }
        }),
        tap((rows) =>
          this.gridSelectionStateService.storeSelectionState(state.folderID, {
            totalSelected: rows.length,
            total: rows.length,
            ids: state.ids,
            rows: rows,
          }),
        ),
      );
  }
}
