import GraphChannel from "../../includes/PluginBaseClasses/GraphChannel.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import IdentityCache from "./IdentityCache.js";
class IdentityChannel extends GraphChannel {
  constructor(wrapper, sequenceType, sequences) {
    super(wrapper);
    this.sequenceType = sequenceType;
    this.cache = new IdentityCache(this, sequences);
  }
  label = {
    text: "Identity"
  };
  margin = 4;
  histogramColorList = [
  // 100%. Green
  "#00B200",
  // >= 30%. Yellow
  "#B2B200",
  // < 30%. Red
  "#FF0000",
  // 0%. Black line
  "#000000"];
  cache;
  calculateLayout(context, bounds) {
    const node = new RenderNode("identity", this, bounds);
    node.setRenderCallback(brush => this.render(brush, context));
    return node;
  }
  render(brush, context) {
    this.wrapper.view.graphPainter.render(this, context, 1);
  }
  histogramColor(score) {
    if (score === 1) {
      return this.histogramColorList[0];
    } else if (score >= 0.3) {
      return this.histogramColorList[1];
    } else if (score > 0) {
      return this.histogramColorList[2];
    } else {
      return this.histogramColorList[3];
    }
  }
  get plugin() {
    return this.view.sv.identity;
  }
  get visible() {
    return this.plugin.enabled;
  }
  get height() {
    return this.plugin.height;
  }
}
export { IdentityChannel as default };