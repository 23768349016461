import MultiRange from "../Range/MultiRange.js";
import RRange from "../Range/RRange.js";
import Range from "../Range/Range.js";
class DataCache {
  constructor(channel) {
    this.channel = channel;
    this.sv.bind("prerender", () => this.clearRequest());
  }
  pending = RRange.empty;
  requested = new MultiRange();
  getPendingAndMarkRequested() {
    const pending = this.pending.cut(this.requested.ranges);
    this.markRequested();
    return new MultiRange(pending);
  }
  request(range) {
    if (range instanceof Range) {
      const ranges = range.toRRanges();
      for (const range2 of ranges) {
        this.pending = range2.union(this.pending);
      }
    } else {
      this.pending = range.union(this.pending);
    }
  }
  get sv() {
    return this.channel.sv;
  }
  markRequested() {
    this.requested = this.requested.add(this.pending);
    this.clearRequest();
  }
  clearRequest() {
    this.pending = RRange.empty;
  }
}
export { DataCache as default };