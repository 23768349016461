import UngappedRangesCache from "../Sequences/UngappedRangesCache.js";
import ConsensusCache from "./ConsensusCache.js";
import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import StructureMap from "../../includes/misc/StructureMap.js";
import SequenceSearch from "../Sequences/SequenceSearch.js";
import { getUngappedRanges } from "../../includes/misc/Utils.js";
class ConsensusChannel extends DataChannel {
  constructor(wrapper, sequenceType, sequences) {
    super(wrapper);
    this.sequenceType = sequenceType;
    this.cache = new ConsensusCache(this, sequences);
    this.sequenceSearch = new SequenceSearch(this.wrapper);
  }
  label = {
    text: "Consensus"
  };
  cache;
  ungappedRanges = new StructureMap(["quality", "threshold"]);
  sequenceSearch;
  search(query, index) {
    return this.sequenceSearch.matchSequence(query, index);
  }
  calculateLayout(context, bounds) {
    return this.sequenceNode("consensus channel", context, bounds, this);
  }
  get height() {
    const {
      selectionMargins,
      maxTextHeight
    } = this.wrapper.view.sequencePainter.settings;
    return maxTextHeight + selectionMargins + 2;
  }
  get margin() {
    if (this.wrapper.translationsChannel && this.wrapper.translationsChannel.visible) {
      return 0;
    } else if (this.wrapper.annotationsChannel && this.wrapper.annotationsChannel.visible) {
      return 3;
    } else {
      return 10;
    }
  }
  get visible() {
    return this.sv.consensus.enabled && this.sv.sequencesPlugin.visible;
  }
  get plugin() {
    return this.sv.consensus;
  }
  get rangesCache() {
    const cache = this.ungappedRanges.getOrCreate(this.key, () => new UngappedRangesCache(this.wrapper));
    if (cache) {
      this.finishInitializingUngappedRangesCache(cache);
    }
    return cache;
  }
  get key() {
    const {
      useQualityScores,
      threshold
    } = this.plugin;
    return {
      threshold,
      quality: useQualityScores
    };
  }
  finishInitializingUngappedRangesCache(cache) {
    if (!cache.hasSuppliedData) {
      const sequence = this.entireConsensusSequence;
      if (!sequence.includes("?")) {
        cache.set(null, getUngappedRanges(sequence));
      }
    }
  }
  get entireConsensusSequence() {
    const result = [];
    for (let index = 0; index < this.view.length; index++) {
      result.push(this.cache.get(index));
    }
    return result.join("");
  }
}
export { ConsensusChannel as default };