import ambiguityMappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import AbstractCalculator from "../../includes/PluginBaseClasses/AbstractCalculator.js";
class ConsensusCalculator extends AbstractCalculator {
  noData = " ";
  // "A threshold of 60% seems to do a good job of not calling a base if the confidence value for two base calls are
  // similar."
  // @see svn.biomatters.com/geneious/trunk/common/src/com/biomatters/geneious/common/consensusSequence/ConsensusOptions.java
  thresholdForQualityScores = 0.6;
  getCandidateResidues(index, sequences) {
    const quality = this.plugin.useQualityScores;
    return sequences.map(sequence => ({
      // This works for proteins because there is no amino acid that is represented with 'U'.
      char: sequence.cache.getAsDNA(index),
      weight: quality && sequence.wrapper.qualitiesCache.get(index) || 0
    }));
  }
  /**
   * Calculate the consensus of a column at a particular index.
   */
  getAggregate(candidates) {
    const weights = this.normalizeWeights(candidates);
    if (weights.length === 0) {
      return " ";
    }
    const quality = this.useQuality;
    const support = this.accumulateSupport(weights);
    const threshold = quality ? this.thresholdForQualityScores : this.threshold;
    const bestChars = this.getCharsToPassThreshold(support, threshold, quality);
    const result = this.code.toAmbiguous(bestChars);
    return this.toRNAIfNeeded(result);
  }
  /**
   * Get the weights of all characters in a column.
   */
  normalizeWeights(weights) {
    if (weights.every(({
      weight
    }) => weight === 0)) {
      const weight = 1;
      return weights.map(({
        char
      }) => ({
        char,
        weight
      }));
    } else {
      return weights;
    }
  }
  /**
   * Create a map containing the cumulative support for each canonical character.
   */
  accumulateSupport(weights) {
    const support = /* @__PURE__ */new Map();
    weights.forEach(({
      char,
      weight
    }) => {
      const canonicalChars = this.code.toCanonical(char) ?? /* @__PURE__ */new Set();
      const weightPerChar = weight / canonicalChars.size;
      canonicalChars.forEach(canonicalChar => {
        const value = support.get(canonicalChar);
        if (value === void 0) {
          support.set(canonicalChar, weightPerChar);
        } else {
          support.set(canonicalChar, value + weightPerChar);
        }
      });
    });
    return support;
  }
  /**
   * Accumulates characters with the most weight until the total weight passes the threshold.
   */
  getCharsToPassThreshold(support, threshold, useQualityScores) {
    const orderedChars = Array.from(support).sort(([, a], [, b]) => b - a);
    const total = orderedChars.reduce((sum, [, n]) => sum + n, 0);
    const thresholdFrequency = total * threshold;
    const bestChars = /* @__PURE__ */new Set();
    let totalFrequency = 0;
    let prevFrequency = 0;
    if (useQualityScores && orderedChars.length >= 2 && this.code.isGap(orderedChars[0][0]) && total - orderedChars[1][1] >= thresholdFrequency) {
      return new Set(this.code.gap);
    }
    for (const [char, frequency] of orderedChars) {
      const criteria =
      // Enough nucleotides have been added to pass the threshold.
      totalFrequency >= thresholdFrequency &&
      // There are no more nucleotides with the same frequency as the last one added to create ambiguity.
      frequency !== prevFrequency &&
      // At least one nucleotide has been added to the total.
      totalFrequency > 0;
      if (criteria) {
        break;
      }
      bestChars.add(char);
      totalFrequency += frequency;
      prevFrequency = frequency;
    }
    return bestChars;
  }
  get useQuality() {
    return this.plugin.useQualityScores;
  }
  get threshold() {
    return this.plugin.threshold;
  }
  get plugin() {
    return this.cache.channel.plugin;
  }
  toRNAIfNeeded(char) {
    return this.cache.channel.sv.isRNA && char === "T" ? "U" : char;
  }
  get code() {
    return ambiguityMappers[this.type];
  }
}
export { ConsensusCalculator as default };