import MultiRange from "../Range/MultiRange.js";
import RRange from "../Range/RRange.js";
import RenderContext from "../RenderEngine/RenderContext.js";
import RenderNode from "../RenderEngine/RenderNode.js";
import { centerVertically } from "../misc/Utils.js";
import AbstractChannel from "./AbstractChannel.js";
class WrapperChannel extends AbstractChannel {
  constructor(view, metadata = {}) {
    super(view);
    this.metadata = metadata;
    view.sv.bind("channel visibility changed", () => {
      this.cachedVisibleChildren = null;
    });
  }
  // TODO Allow sequences to be disabled/hidden in a view?
  // Emit 'channel visibility changed' event when changing this value.
  visible = true;
  // Tracks regions covered by annotations of type `trimmed`.
  trims = new MultiRange();
  // Cache visible children to optimize rendering with large numbers of sequences.
  cachedVisibleChildren = null;
  _minHeight = 24;
  cacheNewData(request, data, cache) {
    const start = data.start == null ? request.start : data.start;
    const length = data.length == null ? request.length : data.length;
    const range = new RRange(start, start + length);
    return cache.set(range, data.data);
  }
  calculateLayout(context, bounds) {
    const children = [];
    let offset = 0;
    let height = 0;
    const visible = context.visible.clip(this.range);
    if (!visible.isEmpty) {
      const subContext = new RenderContext(this.view, context.index, context.row, visible);
      this.visibleChildren.forEach(channel => {
        children.push(channel.calculateLayout(subContext, {
          x: 0,
          y: offset,
          width: bounds.width,
          height: channel.height
        }));
        offset += channel.heightWithMargin;
        if (channel.heightWithMargin > 0) {
          height = offset - channel.margin;
        }
      });
      if (this.sv.annotations) {
        this.sv.annotations.getChannels(this).forEach(channel => channel.cache.request(visible));
      }
    }
    return this.renderNode(height, bounds, children);
  }
  renderNode(height, bounds, children) {
    const inner = this.innerVerticalAlignment(height, bounds);
    const child = new RenderNode("wrapper channel", this, inner, children);
    const node = new RenderNode("wrapper channel wrapper", this, bounds, [child]);
    node.setBackgroundCallback(() => {
      this.brush.fillStyle = "white";
      this.brush.fillRect(0, 0, bounds.width, this.heightWithMargin);
    });
    return node;
  }
  innerVerticalAlignment(height, bounds) {
    return centerVertically(height, bounds);
  }
  getResidue(index) {
    const letter = this.sequenceCache?.get(index);
    if (letter && letter !== " ") {
      const isTrimmed = this.isTrimmed(index);
      return {
        index,
        letter,
        isTrimmed,
        isDeemphasized: isTrimmed
      };
    }
  }
  isTrimmed(index) {
    return this.trims.containsIndex(index);
  }
  addToTrims(annotation) {
    if (annotation.normalType === "trimmed") {
      annotation.intervals.forEach(interval => {
        this.trims = this.trims.add(new RRange(interval.left, interval.right));
      });
    }
  }
  /**
   * Re-calculates trims from the annotations in the cache.
   */
  recalculateTrims() {
    const annotations = this.annotationsCache?.getAll();
    if (!annotations) {
      return;
    }
    this.trims = new MultiRange(annotations.filter(annotations2 => annotations2.normalType === "trimmed").flatMap(annotation => annotation.intervals).map(interval => interval.range));
  }
  get sequenceRange() {
    return this.rangesCache?.entireRange;
  }
  get childrenHeight() {
    const children = this.visibleChildren;
    if (children.length < 1) {
      return 0;
    }
    const height = children.reduce((sum, child) => sum + child.heightWithMargin, 0);
    const lastMargin = children[children.length - 1].margin;
    return Math.max(this.minHeight, height - lastMargin);
  }
  get height() {
    return this.childrenHeight;
  }
  get minHeight() {
    return this._minHeight;
  }
  get visibleChildren() {
    return this.view.sv.view.getCacheableValue(this, "cachedVisibleChildren", () => {
      return this.children.filter(child => child.visible);
    });
  }
  get currentIndex() {
    return this.view.channels.indexOf(this);
  }
  get sequenceType() {
    return this.sequenceChannel?.sequenceType ?? "Nucleotide";
  }
  get colorScheme() {
    const sv = this.view.sv;
    return this.sequenceType === "Nucleotide" ? sv.DNAColorScheme : sv.proteinColorScheme;
  }
  get background() {
    return "white";
  }
}
export { WrapperChannel as default };