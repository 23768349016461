class StructureMap {
  constructor(properties) {
    this.properties = properties;
  }
  // Map can lookup entries faster than Object because it doesn't walk the prototype chain.
  map = /* @__PURE__ */new Map();
  has(key) {
    return this.map.has(this.serialize(key));
  }
  get(key) {
    return this.map.get(this.serialize(key));
  }
  set(key, value) {
    return this.map.set(this.serialize(key), value);
  }
  clear() {
    this.map.clear();
  }
  getOrCreate(key, create) {
    const _key = this.serialize(key);
    if (!this.map.has(_key)) {
      const item = create();
      if (item) {
        this.map.set(_key, item);
      }
    }
    return this.map.get(_key);
  }
  serialize(data) {
    const result = this.properties.map(name => data[name]);
    return result.join();
  }
}
export { StructureMap as default };