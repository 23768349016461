import DeemphasizedRanges from "./DeemphasizedRanges.js";
import StructureMap from "../../includes/misc/StructureMap.js";
class HighlightingCache {
  constructor(plugin, wrapper) {
    this.plugin = plugin;
    this.wrapper = wrapper;
    plugin.sv.bind("sequence edited", () => this.data.clear());
  }
  // Cache deemphasized ranges per highlighting settings.
  data = new StructureMap(["highlight", "byConsensus", "quality", "threshold", "reference"]);
  isDeemphasized(residue) {
    return this.cache.isDeemphasized(residue);
  }
  getInRange(query) {
    return this.cache.getInRange(query);
  }
  get cache() {
    return this.data.getOrCreate(this.key, () => new DeemphasizedRanges(this.plugin, this.wrapper));
  }
  get key() {
    const {
      useQualityScores,
      threshold
    } = this.plugin.sv.consensus;
    return {
      highlight: this.plugin.highlight,
      byConsensus: this.plugin.highlightByConsensus,
      quality: useQualityScores,
      threshold,
      reference: this.referenceIndex
    };
  }
  get referenceIndex() {
    const reference = this.plugin.sv.channelView.reference;
    if (!this.plugin.highlightByConsensus) {
      return reference?.originalIndex;
    }
  }
}
export { HighlightingCache as default };