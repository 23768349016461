import Annotation from "../Annotations/Cache/Annotation.js";
class SequencesState {
  constructor(view, annotationsPlugin) {
    this.view = view;
    this.annotationsPlugin = annotationsPlugin;
  }
  /** The original state of each edited sequence, stored as raw data */
  data = {};
  /**
   * Adds the state of the sequence at the specified index to the store if it
   * does not yet contain data for that sequence index.
   *
   * @param sequenceIndex index of the sequence to store the state of
   */
  storeSequenceState(sequenceIndex) {
    if (this.data[sequenceIndex]) {
      return;
    }
    this.data[sequenceIndex] = this.getCurrentRawSequenceData(sequenceIndex);
  }
  /**
   * Returns the current state (_not_ the stored state) of the sequence at the
   * specified index. This data is cloned from the sequence viewer caches, so
   * the class instances are not referenced elsewhere in SV.
   *
   * @param sequenceIndex the index of the sequence to get the state of
   * @returns a copy of the sequence state
   */
  getCurrentSequenceState(sequenceIndex) {
    const sequenceData = this.getCurrentRawSequenceData(sequenceIndex);
    return this.convertRawSequenceDataToInstances(sequenceData);
  }
  /**
   * Returns the current state (_not_ the stored state) of the sequence at the
   * specified index as raw data.
   *
   * @param sequenceIndex the index of the sequence to get the state of
   * @returns a copy of the sequence state as raw data
   */
  getCurrentRawSequenceData(sequenceIndex) {
    const sequenceWrapper = this.getSequenceWrapperByIndex(sequenceIndex);
    const data = {
      sequence: this.getEditableSequenceProperties(sequenceWrapper)
    };
    if (sequenceWrapper.annotationsCache) {
      data.annotations = this.getAnnotationData(sequenceWrapper.annotationsCache);
    }
    if (this.annotationsPlugin) {
      data.annotationTracks = this.annotationsPlugin.getTracks(sequenceWrapper).map(track => ({
        qualifiers: track.qualifiers.map(qualifier => ({
          ...qualifier
        })),
        annotations: this.getAnnotationData(track.cache)
      }));
    }
    return data;
  }
  /**
   * Gets extended data on the current state (_not_ the stored state) of the
   * sequence at the specified index. The returned data includes additional
   * details like sequence name and type, and is designed to provide information
   * that external consumers of the EditingPlugin need to send edited sequences
   * to their backend.
   *
   * @param sequenceIndex the index of the sequence to get the data of
   * @returns extended data on the current state of the specified sequence
   */
  getCurrentSequenceStateExtendedData(sequenceIndex) {
    const sequenceWrapper = this.getSequenceWrapperByIndex(sequenceIndex);
    const {
      index,
      ...coreSequenceData
    } = this.getEditableSequenceProperties(sequenceWrapper);
    const data = {
      sequence: {
        ...coreSequenceData,
        name: sequenceWrapper.name,
        sequenceType: sequenceWrapper.sequenceType,
        circular: false
      },
      metadata: {
        ...sequenceWrapper.metadata
      },
      annotations: sequenceWrapper.annotationsCache ? this.getAnnotationData(sequenceWrapper.annotationsCache) : [],
      annotationTracks: []
    };
    if (sequenceWrapper.pairInformation) {
      data.sequence.pairInformation = sequenceWrapper.pairInformation;
    }
    if (this.annotationsPlugin) {
      data.annotationTracks = this.annotationsPlugin.getTracks(sequenceWrapper).map(track => {
        return {
          name: track.label.text,
          annotations: this.getAnnotationData(track.cache),
          qualifiers: track.qualifiers.map(qualifier => ({
            ...qualifier
          }))
        };
      });
    }
    return data;
  }
  /**
   * Reads editable sequence properties from the sequence wrapper and returns
   * them in an object.
   *
   * @param wrapper the sequence wrapper
   * @returns the object containing editable sequence properties
   */
  getEditableSequenceProperties(wrapper) {
    const editableSequence = {
      index: wrapper.originalIndex,
      sequence: wrapper.sequenceCache.wholeSequence
    };
    if (wrapper.chromatogramCache.hasData) {
      editableSequence.chromatogram = wrapper.chromatogramCache.getAllData();
    }
    if (wrapper.qualitiesCache.hasData) {
      editableSequence.qualities = wrapper.qualitiesCache.getAllData();
    }
    return editableSequence;
  }
  /**
   * Gets the sequence wrapper at the specified index. If no sequence is found,
   * throws an error.
   *
   * @param sequenceIndex the index
   * @returns the sequence wrapper
   */
  getSequenceWrapperByIndex(sequenceIndex) {
    const sequence = this.view.sequences[sequenceIndex];
    if (!sequence) {
      throw new Error("Invalid sequenceIndex: " + sequenceIndex);
    }
    return sequence;
  }
  /**
   * Returns the data in the stored sequence state map. The returned class
   * instances are copies that are not referenced elsewhere in SV.
   *
   * @returns the stored sequence data
   */
  getStoredState() {
    const mappedEntries = Object.entries(this.data).map(([index, sequenceData]) => {
      const mappedState = this.convertRawSequenceDataToInstances(sequenceData);
      return [parseInt(index), mappedState];
    });
    return Object.fromEntries(mappedEntries);
  }
  /**
   * Converts raw sequence data into the class instances used by sequence viewer
   * (e.g. Annotation, Interval).
   *
   * @param sequenceData the raw data
   * @returns the data with class instances where possible
   */
  convertRawSequenceDataToInstances(sequenceData) {
    const data = {
      sequence: sequenceData.sequence
    };
    if (sequenceData.annotations) {
      data.annotations = sequenceData.annotations.map(annotationData => new Annotation(this.view.sequences[data.sequence.index].annotationsChannel, annotationData));
    }
    if (sequenceData.annotationTracks && this.annotationsPlugin) {
      const tracks = this.annotationsPlugin.getTracks(this.view.sequences[data.sequence.index]);
      data.annotationTracks = sequenceData.annotationTracks.map((track, trackIndex) => ({
        ...track,
        annotations: track.annotations.map(annotationData => new Annotation(tracks[trackIndex], annotationData))
      }));
    }
    return data;
  }
  /**
   * Applies the stored sequence state (set by calling {@link storeSequenceState})
   * to the sequence viewer.
   */
  applyStoredState() {
    this.applyState(this.getStoredState());
  }
  /**
   * Applies the specified state to the sequences viewer.
   *
   * @param state the state to apply
   */
  applyState(state) {
    for (const sequenceIndex of Object.keys(state)) {
      const i = parseInt(sequenceIndex);
      this.applySequenceState(i, state[i]);
    }
  }
  /**
   * Applies the state to the sequence at the specified index.
   *
   * @param sequenceIndex
   * @param sequenceState
   */
  applySequenceState(sequenceIndex, sequenceState) {
    const sequence = this.view.sequences[sequenceIndex];
    sequence.sequenceCache.replaceSequence(sequenceState.sequence.sequence);
    if (sequenceState.sequence.qualities) {
      sequence.qualitiesCache.replaceAll(sequenceState.sequence.qualities);
    }
    if (sequenceState.sequence.chromatogram) {
      sequence.chromatogramCache.replaceAll(sequenceState.sequence.chromatogram);
    }
    if (sequenceState.annotations && sequence.annotationsCache) {
      sequence.annotationsCache.replaceAll(sequenceState.annotations);
    }
    if (sequenceState.annotationTracks && this.annotationsPlugin) {
      const tracks = this.annotationsPlugin.getTracks(sequence);
      for (let i = 0; i < sequenceState.annotationTracks.length; i++) {
        const replacementTrackData = sequenceState.annotationTracks[i];
        tracks[i].qualifiers = replacementTrackData.qualifiers;
        tracks[i].cache.replaceAll(replacementTrackData.annotations);
      }
    }
  }
  /**
   * Clears all recorded state.
   */
  clear() {
    this.data = {};
  }
  getAnnotationData(cache) {
    return cache.getAll().map(anno => anno.getData());
  }
}
export { SequencesState as default };