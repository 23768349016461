import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import SSelection from "../Selection/SSelection.js";
import { clamp, isNumeric } from "../../includes/misc/Math.js";
import Matrix from "./Matrix.js";
import Range from "../../includes/Range/Range.js";
const {
  max
} = Math;
class SearchPlugin extends AbstractPlugin {
  // Tracks a set of pointers that address sequential positions in a multidimensional matrix.
  matrix;
  // TODO Store the last matched position instead?
  positionSaved = false;
  constructor(sv) {
    super(sv);
    sv.search = this;
    sv.bind("ready", () => this.initialize());
    sv.bind("selection created", () => this.positionSaved = false);
    sv.bind(["data row count changed", "channel visibility changed"], () => {
      setTimeout(() => this.onDimensionLengthChanged());
    });
  }
  next(query) {
    return this.search(true, query);
  }
  previous(query) {
    return this.search(false, query);
  }
  /**
   * Splits a search phrase (indexed text or search query) into keywords.
   *
   * Keywords are either;
   *   - at least 2 characters long.
   *   - numeric.
   *
   * Keywords may include;
   *   - *latin* characters, a-z.
   *   - Digits, 0-9.
   *   - Underscore, hyphen and colon characters.
   *   - Dots when numeric.
   *
   * Any other characters are discarded.
   *
   * The results are lower-cased, unordered and may include duplicates.
   */
  static toKeywords(phrase) {
    const keywords = [];
    phrase.toLowerCase().split(/[^a-z0-9:_\-.]+/).forEach(word => {
      const splitOnDots = word.split(".");
      if (splitOnDots.length === 2 && isNumeric(word)) {
        keywords.push(word);
      } else {
        keywords.push(...splitOnDots);
      }
    });
    return keywords.filter(word => {
      if (word.length > 1) {
        return true;
      } else if (isNumeric(word)) {
        return word.length > 0;
      }
    });
  }
  /**
   * Determines if a search query matches a search index.
   */
  queryMatchesIndex(query, index) {
    return query.size > 0 ? Array.from(query).every(keyword => index.has(keyword)) : false;
  }
  search(forwards, raw) {
    if (!this.matrix || raw.length < 1) {
      return;
    }
    const increment = () => forwards ? this.matrix.increment() : this.matrix.decrement();
    const done = () => forwards ? this.matrix.comparePosition(start) >= 0 : this.matrix.comparePosition(start) <= 0;
    const start = this.initializePosition(forwards);
    const query = {
      raw,
      lower: raw.toLowerCase(),
      keywords: new Set(SearchPlugin.toKeywords(raw))
    };
    do {
      const match = this.match(query);
      if (match) {
        return match;
      }
    } while (increment());
    console.log("SV Search: Wrapping...");
    for (; !done(); increment()) {
      const match = this.match(query);
      if (match) {
        return match;
      }
    }
    console.log("SV Search: No matches found.");
  }
  match(query) {
    const position = this.position;
    if (!position) {
      return void 0;
    }
    const {
      dataRow,
      dataChannel,
      wrapper,
      residue
    } = position;
    const wrapperChannel = this.channels[wrapper];
    const channel = wrapperChannel.visibleChildren[dataChannel];
    if (channel && dataRow < channel.rowCount) {
      const range = channel.search(query, residue, dataRow);
      if (range) {
        return this.select(wrapperChannel, range);
      }
    }
  }
  select(wrapper, range) {
    const match = new SSelection(Range.fromData(range, wrapper.sequence.length), [wrapper]);
    if (this.selectionPlugin) {
      this.selectionPlugin.current = [match];
    }
    this.sv.channelView.focusOn(match);
    this.positionSaved = true;
    return match;
  }
  initializePosition(forwards) {
    if (!this.sv.selection?.anchorWrapper) {
      this.matrix.reset();
    } else {
      const selection = this.sv.selection.current[0];
      const wrapper = selection.wrappers[0];
      const focused = this.sv.focused;
      const interval = focused && focused.type === "interval" && focused.reference;
      if (!this.positionSaved) {
        const {
          dataRow,
          dataChannel
        } = this.getDataChannel(wrapper, interval);
        this.matrix.set({
          dataRow,
          dataChannel: wrapper.visibleChildren.indexOf(dataChannel),
          wrapper: wrapper.currentIndex,
          residue: selection.range.start
        });
        this.positionSaved = true;
      }
      if (interval || !selection.range.isEmpty) {
        forwards ? this.matrix.increment() : this.matrix.decrement();
      }
    }
    return this.position;
  }
  getDataChannel(wrapper, interval) {
    if (interval) {
      return {
        dataRow: interval.annotation.rowIndex,
        dataChannel: interval.annotation.channel
      };
    } else {
      return {
        dataRow: 0,
        dataChannel: wrapper.sequenceChannel
      };
    }
  }
  initialize() {
    const view = this.sv.channelView;
    const largest = this.largestSizes;
    if (view.length) {
      this.matrix = new Matrix([
      // The order is important.
      {
        name: "dataRow",
        size: largest.dataRow
      }, {
        name: "dataChannel",
        size: largest.dataChannel
      }, {
        name: "wrapper",
        size: view.channels.length
      }, {
        name: "residue",
        size: view.length
      }]);
    }
  }
  get largestSizes() {
    const result = {
      dataRow: 1,
      dataChannel: 1
    };
    function newSize(dimension, size) {
      result[dimension] = max(result[dimension], size);
    }
    this.channels.forEach(channel => {
      newSize("dataChannel", channel.visibleChildren.length);
      channel.visibleChildren.forEach(child => {
        newSize("dataRow", child.rowCount);
      });
    });
    return result;
  }
  onDimensionLengthChanged() {
    if (!this.matrix) {
      return;
    }
    const previous = this.matrix.current;
    this.initialize();
    const position = {};
    this.matrix.dimensions.forEach(({
      name,
      size
    }) => {
      position[name] = clamp(previous[name], size);
    });
    this.matrix.set(position);
  }
  get position() {
    return this.matrix?.current;
  }
  get channels() {
    return this.sv.channelView.channels;
  }
  get selectionPlugin() {
    return this.sv.selection;
  }
  serialize() {
    return void 0;
  }
}
export { SearchPlugin as default };