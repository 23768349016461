class DerivedCache {
  constructor(channel, sequences) {
    this.channel = channel;
    this.sequences = sequences;
    this.channel.view.sv.bind("all data requests resolved", () => this.clear());
    this.channel.view.sv.bind("sequence edited", () => this.clear());
  }
  cache = [];
  clear() {
    this.cache = [];
  }
  isSet(index) {
    return this.calculator.isSet(index);
  }
  get(index) {
    if (!(index in this.cache)) {
      this.cache[index] = this.calculator.get(index);
    }
    return this.cache[index];
  }
  request(visible) {
    this.sequences.forEach(sequence => sequence.cache.request(visible));
  }
}
export { DerivedCache as default };