import { fixOrder, fixReverseComplement, indexRange } from "../../includes/misc/Utils.js";
class FrameTranslationsCache {
  constructor(channel) {
    this.channel = channel;
  }
  caches = /* @__PURE__ */new Map();
  clear() {
    this.caches.clear();
  }
  isSet(frame, index) {
    return index in this.getCache(frame);
  }
  get(frame, index) {
    const wrapper = this.channel.wrapper;
    if (!wrapper.sequenceCache?.isDefined(index)) {
      if (wrapper.type === "sequence") {
        this.channel.populateCaches(indexRange(index));
      }
      return;
    }
    if (!this.isSet(frame, index)) {
      this.getCodon(frame, index).forEach((third, i) => {
        this.getCache(frame)[i] = third;
      });
    }
    return this.getCache(frame)[index];
  }
  getCodonRange(frame, index) {
    const frameOffset = this.frameOffset(frame);
    return this.channel.translationRange(this.channel.range, indexRange(index), frameOffset);
  }
  frameOffset(frame) {
    if (!frame) {
      return null;
    } else if (frame < 0) {
      return (this.channel.rangesCache.ungappedLength + (frame + 1)) % 3;
    } else {
      return frame - 1;
    }
  }
  getCache(frame) {
    if (!this.caches.has(frame)) {
      this.caches.set(frame, []);
    }
    return this.caches.get(frame) ?? [];
  }
  getCodon(frame, index) {
    const rangesCache = this.channel.wrapper.rangesCache;
    if (frame && rangesCache) {
      const frameOffset = this.frameOffset(frame);
      const range = this.channel.translationRange(this.channel.range, indexRange(index), frameOffset);
      const sequence = this.getUngappedSequence(range);
      const ungappedRange = rangesCache.ungapRange(range);
      const isReversed = frame < 0;
      const isFirst = isReversed ? ungappedRange.end === rangesCache.ungappedLength + (frame + 1) : ungappedRange.start === frame - 1;
      const thirds = this.translateSequenceByFrame(sequence, isReversed, isFirst);
      return this.channel.regap(range, fixOrder(thirds, isReversed));
    }
    return [];
  }
  getUngappedSequence(query) {
    const result = [];
    const continuous = this.channel.rangesCache.getInRangeAsMultiRange(query);
    const ranges = continuous.intersection(query).ranges;
    ranges.forEach(range => {
      result.push(...this.channel.getDNA(range));
    });
    return result;
  }
  translateSequenceByFrame(sequence, isReversed, isFirst) {
    const reversed = fixReverseComplement(sequence, isReversed);
    return this.channel.translateSequence(reversed, isFirst);
  }
}
export { FrameTranslationsCache as default };