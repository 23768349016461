// An ideal order of what regions should be in a list for a Biologist.
// Lower case on purpose for search ability
const ANTIBODY_REGIONS_ORDER = {
  'heavy fr1': 1,
  'heavy cdr1': 2,
  'heavy fr2': 3,
  'heavy cdr2': 4,
  'heavy fr3': 5,
  'heavy cdr3': 6,
  'heavy fr4': 7,
  'heavy vdj-region': 8,
  'heavy vdjc-region': 9,
  'light fr1': 10,
  'light cdr1': 11,
  'light fr2': 12,
  'light cdr2': 13,
  'light fr3': 14,
  'light cdr3': 15,
  'light fr4': 16,
  'light vj-region': 17,
  'light vjc-region': 18,
  'scfv region': 19,
  'scfv linker': 20,
  fr1: 1,
  cdr1: 2,
  fr2: 3,
  cdr2: 4,
  fr3: 5,
  cdr3: 6,
  fr4: 7,
};

// Lower case on purpose for search ability
const BICYCLE_REGIONS_ORDER = {
  pama: 1,
  alanine: 2,
  'bicycle extension': 3,
  'n-terminal extension': 4,
  bicycle: 5,
  c1: 6,
  loop1: 7,
  c2: 8,
  loop2: 9,
  c3: 10,
  'c-terminal extension': 11,
  'agaa extension': 12,
};

// All cluster regions that could appear as a column in an Annotated Result.
export const ANNOTATOR_CLUSTER_REGIONS: string[] = [
  'Heavy FR1',
  'Heavy CDR1',
  'Heavy FR2',
  'Heavy CDR2',
  'Heavy FR3',
  'Heavy CDR3',
  'Heavy FR4',
  'Light FR1',
  'Light CDR1',
  'Light FR2',
  'Light CDR2',
  'Light FR3',
  'Light CDR3',
  'Light FR4',
  'Heavy V Gene',
  'Heavy D Gene',
  'Heavy J Gene',
  'Heavy C Gene',
  'Light V Gene',
  'Light J Gene',
  'Light C Gene',
  'VDJ Region',
  'VDJC Region',
  'VJ Region',
  'VJC Region',
];

/**
 * Returns a number representing the order of the region based on the preset region's order defined
 * by a Human or Bicycle Antibody. Best used within the Array's .sort() method.
 */
const REGIONS_ORDER = { ...ANTIBODY_REGIONS_ORDER, ...BICYCLE_REGIONS_ORDER };
export function getOrderOfRegion(regionName: string, chain?: string): number | undefined {
  return REGIONS_ORDER[
    chain
      ? ((chain.toLowerCase() + ' ' + regionName.toLowerCase()) as keyof typeof REGIONS_ORDER)
      : (regionName.toLowerCase() as keyof typeof REGIONS_ORDER)
  ];
}
