import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import SequenceSearch from "./SequenceSearch.js";
class SequenceChannel extends DataChannel {
  // This DataChannel can only be used in SequenceWrapper channels.
  constructor(wrapper, sequenceType) {
    super(wrapper);
    this.sequenceType = sequenceType;
  }
  margin = 2;
  sequenceSearch = new SequenceSearch(this.wrapper);
  search(query, index) {
    return this.sequenceSearch.matchSequence(query, index);
  }
  calculateLayout(context, bounds) {
    return this.sequenceNode("sequence channel", context, bounds, this);
  }
  get cache() {
    return this.wrapper.sequenceCache;
  }
  get height() {
    return this.sequencePainter.channelHeight;
  }
  get visible() {
    return this.sv.sequencesPlugin.visible;
  }
  pendingAt(index) {
    return index < this.cache.maxIndex() && typeof this.cache.get(index) !== "string";
  }
}
export { SequenceChannel as default };