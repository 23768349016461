import RRange from "./RRange.js";
import { boundIndex, distanceBetweenIndexes } from "../misc/Utils.js";
class Range {
  constructor(start, length, sequenceLength) {
    this.start = start;
    this.length = length;
    this.sequenceLength = sequenceLength;
    if (length < 0 || length > sequenceLength) {
      throw new Error("Invalid Range length: " + length);
    }
    if (start < 0 || start > sequenceLength) {
      throw new Error("Invalid Range start: " + start);
    }
    this.wrapAround = start + length > sequenceLength;
    this.end = boundIndex(start + length, sequenceLength);
    Object.freeze(this);
  }
  /** The end index of the range */
  end;
  /** True if the range crosses the origin of the sequence. */
  wrapAround;
  /**
   * Create a Range from a RRange instance.
   * @param range the RRange instance to create the Range from.
   * @param sequenceLength the length of the sequence that contain this range
   */
  static fromData(range, sequenceLength) {
    return new Range(range.start, range.length, sequenceLength);
  }
  /**
   * Convert to RRange. If the range does not span over the origin, only one RRange will be
   * produced. Otherwise, a list of two RRange including a range spanning from range start
   * to sequence end, and a range from sequence start to the range end will be returned.
   *
   * @returns the RRanges covering the same points as this range
   */
  toRRanges() {
    const {
      start,
      end
    } = this;
    if (!this.wrapAround) {
      return [new RRange(start, end)];
    }
    const startToOrigin = new RRange(start, this.sequenceLength);
    const originToEnd = new RRange(0, end);
    return [startToOrigin, originToEnd];
  }
  map(callback) {
    const result = [];
    for (const [value, index] of this.entries()) {
      result.push(callback(value, index));
    }
    return result;
  }
  every(callback) {
    return !this.some((value, index) => !callback(value, index));
  }
  some(callback) {
    for (const [value, index] of this.entries()) {
      if (callback(value, index)) {
        return true;
      }
    }
    return false;
  }
  /**
   * True if this range fully contains the second range, even if it is empty.
   */
  contains(targetRange) {
    if (targetRange.length > this.length) {
      return false;
    }
    const ranges = this.toRRanges();
    if (targetRange instanceof RRange) {
      return ranges.some(range => range.contains(targetRange));
    }
    const targetRanges = targetRange.toRRanges();
    if (!targetRange.wrapAround) {
      return ranges.some(range => range.contains(targetRanges[0]));
    }
    if (!this.wrapAround) {
      return false;
    }
    return ranges[0].contains(targetRanges[0]) && ranges[1].contains(targetRanges[1]) || ranges[0].contains(targetRanges[1]) && ranges[1].contains(targetRanges[0]);
  }
  /**
   * Returns true if the two ranges are overlapping or adjacent (start of one == end of other).
   */
  touches(range) {
    return this.toRRanges().some(r => r.touches(range));
  }
  /**
   * Returns true if the two ranges are overlapping (a zero-length range overlaps any range that it touches).
   */
  overlaps(range) {
    const ranges = this.toRRanges();
    return ranges.some(r => r.overlaps(range)) || this.zeroLengthOverlap(range);
  }
  /**
   * Grow the range by a certain amount at start & certain amount at end.
   *
   * @param startAmount the amount to grow the range by at the start
   * @param endAmount the amount to grow the range by at the end
   * @returns the grown range
   */
  grow(startAmount, endAmount) {
    const start = this.shiftPoint(this.start, -startAmount);
    const end = this.shiftPoint(this.end, endAmount);
    return new Range(start, distanceBetweenIndexes(start, end, this.sequenceLength), this.sequenceLength);
  }
  /**
   * Grow the range by a certain amount at start or end.
   *
   * @param amount the amount to grow the range by
   * @param atEnd whether to grow it at the end or the start
   *
   * @returns the grown range
   */
  growAt(amount, atEnd) {
    const start = !atEnd ? amount : 0;
    const end = atEnd ? amount : 0;
    return this.grow(start, end);
  }
  /**
   * Shrink the range by a certain amount at start & certain amount at end.
   *
   * @param startAmount the amount to shrink the range by at the start
   * @param endAmount the amount to shrink the range by at the end
   * @returns the shrunk range
   */
  shrink(startAmount, endAmount) {
    const start = this.shiftPoint(this.start, startAmount);
    const end = this.shiftPoint(this.end, -endAmount);
    return new Range(start, distanceBetweenIndexes(start, end, this.sequenceLength), this.sequenceLength);
  }
  /**
   * Shrink the range by a certain amount at start or end.
   *
   * @param amount the amount to shrink the range by
   * @param atEnd whether to shrink it at the end or the start
   *
   * @returns the shrunk range
   */
  shrinkAt(amount, atEnd) {
    const start = !atEnd ? amount : 0;
    const end = atEnd ? amount : 0;
    return this.shrink(start, end);
  }
  /**
   * Translates the range forwards (clockwise) by the given amount.
   *
   * @param amount the amount to translate the range by
   * @returns the translated range
   */
  shift(amount) {
    const start = this.shiftPoint(this.start, amount);
    const end = this.shiftPoint(this.end, amount);
    return new Range(start, distanceBetweenIndexes(start, end, this.sequenceLength), this.sequenceLength);
  }
  /**
   * Translates the point forwards (clockwise) by the given amount, ensuring it stays in bounds.
   *
   * @param point the point to shift
   * @param amount the amount to shift by
   * @returns the shifted point
   */
  shiftPoint(point, amount) {
    const shiftedPoint = point + amount % this.sequenceLength;
    if (shiftedPoint >= this.sequenceLength) {
      return shiftedPoint - this.sequenceLength;
    }
    if (shiftedPoint < 0) {
      return this.sequenceLength + shiftedPoint;
    }
    return shiftedPoint;
  }
  zeroLengthOverlap(range) {
    return (this.isEmpty || range.isEmpty) && this.touches(range);
  }
  get isEmpty() {
    return this.length === 0;
  }
  /**
   * The midpoint between the two ends of the range. Rounds down for ranges with lengths that are
   * an even number.
   */
  get center() {
    return this.shiftPoint(this.start, Math.floor(this.length / 2));
  }
  /**
   * Returns the value of the first number in the range where predicate is
   * true, or undefined if no numbers in the range match the predicate.
   *
   * @param predicate the predicate
   * @returns the first matching number or undefined
   */
  find(predicate) {
    for (const [value, index] of this.entries()) {
      if (predicate(value, index)) {
        return value;
      }
    }
    return void 0;
  }
  /**
   * Returns a boolean indicating whether a value is included in the range.
   * @param value the value to find
   */
  includes(value) {
    if (!this.wrapAround) {
      return this.start <= value && value < this.end;
    }
    const sequenceEnding = this.sequenceLength - 1;
    return this.start <= value && value <= sequenceEnding || value >= 0 && value < this.end;
  }
  /**
   * Range uses 0-based indexing with an intervals that is inclusive on the
   * lower bound and exclusive on the upper bound. Interval data for sequence
   * annotations use 1-based indexing with intervals that are inclusive on both
   * bounds.
   *
   * @returns a 1-based inclusive range - the type used for raw interval data
   */
  to1BasedInclusiveInterval() {
    return {
      min: this.start + 1,
      max: this.end
    };
  }
  /**
   * Iterate through each position of the range
   */
  *[Symbol.iterator]() {
    for (const [value] of this.entries()) {
      yield value;
    }
  }
  /**
   * Iterate through each position of the range but including the index of each position
   */
  *entries() {
    for (let i = 0; i < this.length; i++) {
      const value = boundIndex(this.start + i, this.sequenceLength);
      yield [value, i];
    }
  }
  /**
   * Return the interval as a string in interval notation; Displays as [start, end).
   *
   * The brackets represent an interval that is inclusive on the lower bound and exclusive on the upper bound.
   *
   * @see https://en.wikipedia.org/wiki/Interval_(mathematics)#Notations_for_intervals
   */
  toString() {
    return `[${this.start}, ${this.end})`;
  }
}
export { Range as default };