import SequenceWrapper from "../../includes/Channels/SequenceWrapper.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { distanceBetweenIndexes, isPrimaryButton, isSequenceWrapper } from "../../includes/misc/Utils.js";
import ConsensusChannel from "../Consensus/ConsensusChannel.js";
import SequenceChannel from "../Sequences/SequenceChannel.js";
import SSelection from "./SSelection.js";
import SelectionLayer from "./SelectionLayer.js";
import Range from "../../includes/Range/Range.js";
const {
  round,
  min
} = Math;
class SelectionPlugin extends AbstractPlugin {
  anchorWrapper = null;
  cursorWrapper = null;
  dragging = false;
  // This is only used to track the direction of the continuous selection (when dragging with mouse).
  // One-time selection like selection set via the `current` setter are assumed to be forward.
  isForwardSelection = null;
  colors = {
    light: "#ACC2DB",
    dark: "#6B8199",
    blue: "#1962A3",
    outline: "rgba(50, 100, 200, 0.4)"
  };
  /** Warning: undefined until sv emits 'ready' */
  layer;
  maxCopySize = 1e6;
  scrolling = 0;
  constructor(sv) {
    super(sv);
    sv.selection = this;
    const options = this.getInitialOptions("selection", {
      selectWholeSequenceOnLabelClick: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      selections: {
        default: [],
        validator: values => Array.isArray(values) && values.every(raw => this.validateSelection(raw))
      }
    });
    sv.bind("ready", () => {
      const selection = options.selections[0];
      if (selection) {
        const index = sv.channelView.channels.findIndex(current => {
          return isSequenceWrapper(current) && current.originalIndex === selection.sequenceStart;
        });
        if (index !== -1) {
          this.layer = new SelectionLayer(this, selection.positionStart, selection.positionEnd);
          this.anchorWrapper = sv.channelView.channels[index];
        }
      }
      if (!this.layer) {
        this.layer = new SelectionLayer(this);
      }
    });
    sv.bindToNode("click", "interval", node => this.clickOnAnnotation(node));
    sv.bindToNode("click", "label", node => {
      if (options.selectWholeSequenceOnLabelClick) {
        this.selectAllResiduesInChannel(node);
      }
    });
    sv.bindToNode("mousedown", "wrapper channel wrapper", (node, event, offset) => this.mouseDownOnWrapperChannel(node, event, offset));
    sv.bindToNode("mousemove", "wrapper channel wrapper", (node, event, offset) => this.mouseMoveOnWrapperChannel(node, offset));
    sv.bindToNode("dblclick", "wrapper channel wrapper", node => this.selectAllResiduesInChannel(node));
    sv.addEventListenerToDocument("mousemove", e => this.mouseMoveOnDocument(e));
    sv.addEventListenerToDocument("mouseup", e => this.mouseUpOnDocument(e));
    sv.bindToNode("mouseleave", "view row", () => this.mouseLeavingRow());
    sv.addEventListenerToDocument("copy", e => this.copyOnDocument(e));
    sv.bind("alter residue colors", (residue, channel) => this.alterResidue(residue, channel));
    sv.bind("alter sequence lines", (context, channel, lines) => this.alterSequenceLines(channel, lines));
    sv.bind("prerender", () => this.onPrerender());
    sv.bind("context menu", ({
      renderNode
    }) => this.setCursorForContextMenu(renderNode));
  }
  serialize() {
    return {
      selections: this.current.map(selection => selection.serialize()).filter(selection => selection)
    };
  }
  setCursorForContextMenu(renderNode) {
    if (!renderNode) {
      return;
    }
    if (renderNode.reference instanceof SequenceChannel) {
      if (this.cursor) {
        const range = new Range(this.cursor, 1, renderNode.reference.length);
        if (this.range?.contains(range)) {
          return;
        }
        this.setSelection(range, renderNode.reference.wrapper);
      }
    }
  }
  validateSelection(raw) {
    const properties = [raw.positionStart, raw.positionEnd, raw.sequenceStart, raw.sequenceEnd];
    if (!properties.every(value => typeof value === "number" && value >= 0)) {
      return false;
    }
    if (raw.positionStart === raw.positionEnd || raw.sequenceStart === raw.sequenceEnd) {
      return false;
    }
    return true;
  }
  alterResidue(residue, channel) {
    if (this.isSelected(residue.index, channel)) {
      const {
        printLetter
      } = this.sv.channelView.sequencePainter;
      const {
        blue,
        dark,
        light
      } = this.colors;
      const highlight = residue.isDeemphasized ? dark : light;
      if (printLetter || residue.letter === "-" || residue.letter === " ") {
        residue.foreground = highlight;
        residue.background = [blue];
      } else {
        residue.background = [highlight];
      }
      residue.marginColor = blue;
    }
  }
  alterSequenceLines(channel, lines) {
    const painter = this.sv.channelView.sequencePainter;
    const grey = painter.grey;
    const {
      blue,
      dark,
      light
    } = this.colors;
    const ranges = this.current.filter(selection => selection.wrappers.includes(channel)).flatMap(({
      range
    }) => range.toRRanges());
    const selected = {
      colors: [blue],
      size: "thick",
      priority: 100,
      background: blue
    };
    lines.insertMultiple(ranges, selected, overlap => {
      const colors = overlap?.colors[0] === grey ? [dark] : [light];
      return {
        colors,
        size: overlap?.size ?? null,
        priority: selected.priority,
        background: selected.background
      };
    });
  }
  isSelected(index, channel) {
    return this.anchorWrapper === channel && this.current.length > 0 && this.current.some(selection => selection.range.includes(index));
  }
  clickOnAnnotation(node) {
    const {
      range,
      annotation
    } = node.reference;
    this.current = [new SSelection(Range.fromData(range, annotation.wrapper.sequence.length), [annotation.wrapper])];
    this.dragging = false;
    this.sv.view.dirty = "selection created";
    this.sv.emit("selection changed");
  }
  mouseUpOnDocument(e) {
    if (isPrimaryButton(e) && this.dragging) {
      this.dragging = false;
      if (this.cursor !== null && this.anchorWrapper?.range.touchesIndex(this.cursor)) {
        this.layer.endAnchor = this.cursor;
        this.sv.view.dirty = "selection created";
        this.sv.emit("selection changed");
        document.getSelection()?.["empty"]();
      }
    }
    this.scrolling = 0;
  }
  mouseMoveOnDocument(e) {
    if (this.dragging && this.anchor !== null && this.anchor !== void 0 && this.cursor !== null && this.anchorWrapper?.range.touchesIndex(this.cursor)) {
      this.cursorWrapper = this.anchorWrapper;
      this.layer.endAnchor = this.cursor;
      if (this.anchor !== this.cursor) {
        if (this.sv.view.circular && this.isForwardSelection !== null) {
          return;
        }
        if (this.sv.view.circular) {
          const sequenceLength = this.anchorWrapper.sequenceChannel?.length ?? 0;
          const forwardDistance = distanceBetweenIndexes(this.anchor, this.cursor, sequenceLength);
          const backwardDistance = distanceBetweenIndexes(this.cursor, this.anchor, sequenceLength);
          this.isForwardSelection = forwardDistance < backwardDistance;
        } else {
          this.isForwardSelection = this.anchor < this.cursor;
        }
      }
      this.sv.view.dirty = "selecting";
      if (this.sv.wrapped) {} else {
        const offset = this.sv.view.element.getBoundingClientRect().left;
        const speed = this.scrollSpeed(e.pageX - offset);
        if (speed === 0 || !this.atSequenceEnd(speed)) {
          this.scrolling = speed;
        }
      }
    }
  }
  onPrerender() {
    if (this.scrolling && this.anchorWrapper) {
      this.sv.changeOffsetBy({
        x: this.scrolling,
        y: 0
      });
      const atEnd = this.atSequenceEnd(this.scrolling);
      const range = atEnd && this.anchorWrapper.rangesCache ? this.anchorWrapper.rangesCache.entireRange : this.sv.range;
      const anchor = this.scrolling < 0 ? round(range.start) : round(range.end);
      this.layer.cursor = anchor;
      this.layer.endAnchor = anchor;
      if (atEnd) {
        this.scrolling = 0;
      }
      this.markDirty();
    }
  }
  atSequenceEnd(offset) {
    if (this.anchorWrapper === null || this.anchorWrapper.rangesCache === void 0) {
      return false;
    }
    if (offset < 0) {
      return this.sv.range.start <= this.anchorWrapper.rangesCache.entireRange.start;
    } else if (offset > 0) {
      return this.sv.range.end >= this.anchorWrapper.rangesCache.entireRange.end;
    } else {
      throw new Error("Offset must be a non-zero value.");
    }
  }
  scrollSpeed(cursor) {
    const {
      sidebarWidth,
      width
    } = this.sv.channelView.viewport;
    const residueWidth = this.sv.residueWidth;
    const left = sidebarWidth + residueWidth;
    const right = width + sidebarWidth - residueWidth;
    if (cursor < left) {
      return cursor - left;
    } else if (cursor > right) {
      return cursor - right;
    } else {
      return 0;
    }
  }
  mouseMoveOnWrapperChannel(node, offset) {
    if (this.isGlobalsRulerChannel(node, offset)) {
      return;
    }
    this.cursorWrapper = node.reference;
    this.layer.cursor = this._cursorIndex(node, offset);
  }
  mouseLeavingRow() {
    if (!this.dragging) {
      this.layer.cursor = null;
      this.cursorWrapper = null;
    }
  }
  mouseDownOnWrapperChannel(node, event, offset) {
    if (!isPrimaryButton(event)) {
      return;
    }
    if (this.isGlobalsRulerChannel(node, offset)) {
      return;
    }
    const wrapper = node.reference;
    this.dragging = true;
    this.layer.endAnchor = null;
    this.isForwardSelection = null;
    if (event.shiftKey) {
      this.layer.endAnchor = this.layer.cursor;
      this.sv.view.dirty = "selecting";
    } else if (!wrapper.sequenceChannel) {
      this.layer.anchor = null;
      this.anchorWrapper = null;
      this.dragging = false;
    } else {
      const index = this._cursorIndex(node, offset);
      this.layer.anchor = min(index, this.sv.channelView.length);
      this.anchorWrapper = wrapper;
      this.sv.view.dirty = "selecting";
    }
  }
  isGlobalsRulerChannel(node, offset) {
    const wrapper = node.reference;
    if (wrapper.type === "globals wrapper" && wrapper.rulerChannel) {
      return offset.y <= wrapper.rulerChannel.height;
    }
  }
  _cursorIndex(node, offset) {
    const view = this.sv.channelView;
    return round(view.toResidues(offset.x + view.offset.x)) + (node.rowRange?.start ?? 0);
  }
  copyOnDocument(e) {
    if (document.getSelection()?.toString().trim().length) {
      return;
    }
    const selections = this.current;
    if (selections.length === 0) {
      return;
    }
    const size = this.checkSize(selections);
    if (size < 1) {
      return;
    }
    this.requestData(selections);
    if (size > this.maxCopySize) {
      this.copyError(`The selection is ${size} residues long, but the maximum is ${this.maxCopySize}.`);
    } else if (!this.checkCaches(selections)) {
      this.copyError("The selected region is loading. Try copying again in a few seconds.");
    } else {
      document.getSelection()?.["empty"]();
      e.preventDefault();
      const processed = selections.toString().replace(/ /g, "-");
      e.clipboardData?.setData("text/plain", processed);
    }
  }
  checkSize(selections) {
    return selections.reduce((sum, selection) => sum + selection.length, 0);
  }
  checkCaches(selections) {
    return selections.every(selection => selection.checkCaches());
  }
  requestData(selections) {
    selections.forEach(selection => selection.requestData());
  }
  copyError(message) {
    window.alert("Selection has not been copied.\n" + message);
  }
  get current() {
    const result = [];
    const range = this.range;
    if (range && this.anchorWrapper) {
      const channels = [this.anchorWrapper];
      result.push(new SSelection(range, channels));
    }
    return result;
  }
  set current(value) {
    if (value.length === 0) {
      this.clear();
      return;
    }
    const {
      start,
      end
    } = value[0].range;
    this.layer.anchor = start;
    this.layer.endAnchor = end;
    this.isForwardSelection = true;
    this.anchorWrapper = value[0].wrappers[0];
    this.cursorWrapper = this.anchorWrapper;
    this.sv.view.dirty = "selection set";
  }
  get range() {
    if (this.anchor != null && this.endAnchor != null) {
      return this.layer.range;
    }
  }
  // noinspection JSUnusedGlobalSymbols
  get currentSelectionCountIncludingChannels() {
    return this.current.length;
  }
  clear() {
    this.anchorWrapper = null;
    this.layer.anchor = null;
    this.layer.endAnchor = null;
    this.isForwardSelection = null;
    this.markDirty("selection cleared");
  }
  setSelection(range, wrapper) {
    this.current = [new SSelection(range, [wrapper])];
  }
  get cursor() {
    return this.layer?.cursor;
  }
  get anchor() {
    return this.layer?.anchor;
  }
  get endAnchor() {
    return this.layer?.endAnchor;
  }
  selectAllResiduesInChannel(node) {
    if (node.reference instanceof SequenceWrapper) {
      this.current = [new SSelection(Range.fromData(node.reference.range, node.reference.sequence.length), [node.reference])];
    } else if (node.reference instanceof ConsensusChannel) {
      this.current = [new SSelection(Range.fromData(node.reference.range, node.reference.wrapper.sequence.length), [node.reference.wrapper])];
    }
  }
}
export { SelectionPlugin as default };