import AmbiguityMapper from "./AmbiguityMapper.js";
import { Alphabets } from "@geneious/shared-constants";
const mappers = {
  Nucleotide: instantiateMapper(Alphabets.Nucleotide),
  AminoAcid: instantiateMapper(Alphabets.AminoAcid)
};
function instantiateMapper(alphabet) {
  return new AmbiguityMapper(alphabet.canonical, alphabet.wildcard, alphabet.reverseAmbiguities);
}
var AmbiguityMappers_default = mappers;
export { AmbiguityMappers_default as default };