import Path from "./Path.js";
class InwardZigZag extends Path {
  padding = 2;
  constructor() {
    super([
    // 45º angles; 1:1.
    [0, 0], [1, 1], [0, 2], [1, 3], [0, 4]]);
    this.multiply(1 / 4);
  }
}
export { InwardZigZag as default };