import RRange from "../Range/RRange.js";
class AbstractChannel {
  constructor(view) {
    this.view = view;
    view.sv.bind("channel visibility changed", () => this.clearCachedHeightWithMargin());
    view.sv.bind("height changed", () => this.clearCachedHeightWithMargin());
  }
  // Cache height with margin to optimize rendering with large numbers of sequences.
  // This is set via View.getCacheableValue() in a way that bypasses TypeScript's access-checks.
  cachedHeightWithMargin = null;
  get heightWithMargin() {
    return this.view.sv.view.getCacheableValue(this, "cachedHeightWithMargin", () => {
      return this.height + this.margin;
    });
  }
  get range() {
    return new RRange(0, this.view.length);
  }
  get brush() {
    return this.view.brush;
  }
  get sv() {
    return this.view.sv;
  }
  get graphics() {
    return this.view.graphics;
  }
  clearCachedHeightWithMargin() {
    this.cachedHeightWithMargin = null;
  }
}
export { AbstractChannel as default };