const {
  min,
  max
} = Math;
class Path {
  constructor(points) {
    this.points = points;
  }
  // Most ending paths don't need padding, only if they have elements that are likely to touch text labels.
  padding = 0;
  get width() {
    return this.rightMost - this.leftMost;
  }
  get widthIncludingPadding() {
    return this.width + this.padding;
  }
  get leftMost() {
    return min(...this.xCoordinates);
  }
  get rightMost() {
    return max(...this.xCoordinates);
  }
  get xCoordinates() {
    return this.points.map(([x]) => x);
  }
  add(valueX, valueY) {
    return this.map(([x, y]) => [x + valueX, y + valueY]);
  }
  multiply(factor) {
    return this.map(([x, y]) => [x * factor, y * factor]);
  }
  multiplyX(factor) {
    return this.map(([x, y]) => [x * factor, y]);
  }
  mirror() {
    return this.map(([x, y]) => [0 - x, y]);
  }
  reverse() {
    return this.processPoints(points => points.reverse());
  }
  map(callback) {
    return this.processPoints(points => points.map(callback));
  }
  processPoints(callback) {
    this.points = callback(this.points);
    return this;
  }
  extend(path) {
    return new Path(this.points.concat(path.points));
  }
  clone() {
    return new Path(this.points.map(([x, y]) => [x, y]));
  }
}
export { Path as default };