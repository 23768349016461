import { clearCanvas, isPrimaryButton } from "../misc/Utils.js";
import { CircularGraphicsTransformer } from "./Graphics/CircularGraphicsTransformer";
import { LinearGraphicsTransformer } from "./Graphics/LinearGraphicsTransformer";
class RenderEngine {
  constructor(view) {
    this.view = view;
    this.element = view.element.querySelector("canvas.sv-viewport");
    this.wrapper = this.element.parentElement;
    this.element.style["webkitFontSmoothing"] = "antialiased";
    this.brush = this.element.getContext("2d");
    this.graphics = this.view.circular ? new CircularGraphicsTransformer(this.sv) : new LinearGraphicsTransformer(this.sv);
    this.sv.bind("ready", () => this.initialize());
    this.sv.bind("column visibility changed", () => this.updateViewSize());
    this.sv.bind("view mode changed", () => this.initializeGraphics());
    if (document["fonts"]) {
      document["fonts"].load("12px 'Open Sans'").then(() => this.view.dirty = "fonts loaded");
    }
  }
  brush;
  element;
  wrapper;
  graphics;
  mouseDownPosition = null;
  mouseDownNode = null;
  /**
   * Initialize graphics for render transformation
   */
  initializeGraphics() {
    this.graphics = this.view.circular ? new CircularGraphicsTransformer(this.sv) : new LinearGraphicsTransformer(this.sv);
  }
  /**
   * Paints the render tree, starting from the root
   */
  paint(root) {
    const brush = this.brush;
    clearCanvas(this.element, brush, this.view);
    this.element.style.height = this.view.height + "px";
    brush.fillStyle = "white";
    brush.fillRect(0, 0, this.view.width, this.view.height);
    root.translate(brush, () => root.paint(brush));
  }
  initialize() {
    this.updateViewSize();
    if (!this.sv.export || !this.sv.export.enabled) {
      const observer = new ResizeObserver(() => this.updateViewSize());
      observer.observe(this.wrapper);
    }
    this.element.addEventListener("mousedown", e => this.onMouseDown(e));
    this.element.addEventListener("mouseup", e => this.onMouseUp(e));
    this.element.addEventListener("mouseenter", e => this.onHover(e));
    this.element.addEventListener("mousemove", e => this.onHover(e));
    this.element.addEventListener("mouseleave", e => this.onHover(e));
    this.element.addEventListener("contextmenu", e => this.onContextMenu(e));
    this.element.addEventListener("dblclick", e => this.onDoubleClick(e));
    this.element.addEventListener("wheel", e => {
      this.sv.bindOnce("postrender", () => this.onHover(e));
      this.sv.emit("wheel", e);
    });
  }
  updateViewSize() {
    setTimeout(() => {
      this.view.updateSize();
      this.sv.emit("resize");
      this.view.dirty = "resize";
    });
  }
  onDoubleClick(event) {
    event.preventDefault();
    const {
      node,
      position
    } = this.preprocessMouseEvent(event);
    if (node) {
      this.sv.emitAndBubble("dblclick", node, event, position);
    }
  }
  onMouseDown(event) {
    if (!isPrimaryButton(event)) {
      return;
    }
    event.preventDefault();
    this.element.focus();
    const {
      node,
      position
    } = this.preprocessMouseEvent(event);
    if (node) {
      this.sv.emitAndBubble("mousedown", node, event, position);
    }
    this.mouseDownPosition = position;
    this.mouseDownNode = node;
    this.view.focused = null;
  }
  /**
   * Updates view.focused, runs click/blur/focus events, and then marks the view dirty if focused node has changed.
   */
  onMouseUp(event) {
    if (!isPrimaryButton(event)) {
      return;
    }
    event.preventDefault();
    const {
      node,
      position
    } = this.preprocessMouseEvent(event);
    const previous = this.view.focused;
    if (this.isClick(node, position)) {
      this.bubbleEvents(node, previous, this.clickEventNames, event, position);
      this.view.focused = node;
    } else if (this.mouseDownNode && previous) {
      this.sv.emitAndBubble("blur", previous, event, position);
    }
    this.mouseDownPosition = null;
    this.mouseDownNode = null;
  }
  isClick(node, position) {
    const down = this.mouseDownNode;
    if (node && down && down.reference === node.reference) {
      return this.mouseDownPosition?.x === position.x;
    }
    return false;
  }
  get clickEventNames() {
    return {
      main: "click",
      current: "focus",
      stale: "blur"
    };
  }
  get hoverEventNames() {
    return {
      main: "mousemove",
      current: "mouseenter",
      stale: "mouseleave"
    };
  }
  /**
   * Sets view.hovered to null by default, runs mouse enter/move/leave events, and then marks the view dirty if the
   * hovered node has changed.
   */
  onHover(event) {
    const {
      node,
      position
    } = this.preprocessMouseEvent(event);
    this.bubbleEvents(node, this.view.hovered, this.hoverEventNames, event, position);
    this.view.hovered = node;
  }
  /**
   * Prevent native context menu from showing up & emit a context menu event.
   */
  onContextMenu(event) {
    event.preventDefault();
    const {
      node,
      position
    } = this.preprocessMouseEvent(event);
    this.sv.emit("context menu", {
      renderNode: node,
      position
    });
  }
  preprocessMouseEvent(event) {
    const position = this.graphics.reverseTransformPoint({
      x: event.offsetX - this.element.offsetLeft,
      y: event.offsetY - this.element.offsetTop
    });
    const node = this.findNode(this.view.layout, position);
    return {
      node,
      position
    };
  }
  /**
   * Helper for exclusive node states like 'focused' and 'hovered' that cause secondary events to bubble up and down
   * sub-branches of the render tree.
   *
   * @param {RenderNode}  node        The current node that is now in this state.
   * @param {RenderNode}  previous    The node that was previously in this state.
   * @param {StringMap}   names       The names of the events
   *                                    - main    Primary event triggering this mouse event; event.type usually.
   *                                    - current Secondary event for nodes now in this state.
   *                                    - stale   Secondary event for nodes previously in this state.
   * @param {MouseEvent}  event       The original DOM event.
   * @param {Vector}      position    The position of the event, relative to the viewport.
   * @returns {RenderNode}            The node that is now in this state.
   */
  bubbleEvents(node, previous, {
    main,
    current,
    stale
  }, event, position) {
    const {
      stalePath,
      currentPath
    } = this.uniqueAscendants(previous, node);
    this.partialBubbling(stale, stalePath, event, position);
    this.partialBubbling(current, currentPath, event, position);
    if (node) {
      this.sv.emitAndBubble(main, node, event, position);
    }
  }
  partialBubbling(name, nodes, event, position) {
    nodes.forEach(node => {
      this.sv.events.matchAndEmit(name, node, event, position);
    });
    if (nodes.length > 0) {
      this.sv.emit(name, [nodes[0], position, event]);
    }
  }
  uniqueAscendants(stale, current) {
    const stalePath = stale?.path ?? [];
    const currentPath = current?.path ?? [];
    while (this.sameNodes(stalePath[0], currentPath[0])) {
      stalePath.shift();
      currentPath.shift();
    }
    stalePath.reverse();
    currentPath.reverse();
    return {
      stalePath,
      currentPath
    };
  }
  /**
   * Recursively finds the RenderNode that contains `point`.
   *
   * Returns the bottom-most (aka leafiest) node that the point is touching, or null if no children are
   * being touched.
   */
  findNode(node, point) {
    if (node && this.contains(node.bounds, point)) {
      const sortedChildren = node.children.sort((a, b) => b.zIndex - a.zIndex);
      for (const child of sortedChildren) {
        const match = this.findNode(child, {
          x: point.x - node.bounds.x,
          y: point.y - node.bounds.y
        });
        if (match) {
          return match;
        }
      }
      return node;
    }
    return null;
  }
  /**
   * Returns true if the point (in coordinates relative to the parent node) is touching the node.
   */
  contains(node, point) {
    return node.x <= point.x && point.x < node.x + node.width && node.y <= point.y && point.y < node.y + node.height;
  }
  sameNodes(a, b) {
    if (a && b && a.type === b.type) {
      if (a.type === "view row") {
        return a.renderContext?.index === b.renderContext?.index;
      }
      return a.reference === b.reference;
    }
    return false;
  }
  get sv() {
    return this.view.sv;
  }
}
export { RenderEngine as default };