import { forkJoin, from as observableFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { parseSequenceIdString } from './ngs.operators';
import { Ngs2Service, NGSSequence } from './ngs2-service';
import {
  isViewerMasterDatabaseSearchSelection,
  ViewerMasterDatabaseSearchSelection,
  ViewerMultipleTableDocumentSelection,
} from '../viewer-components/viewer-document-data';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { fetchSequences } from './store/ngs.actions';
import { selectNGS } from './store/ngs.selectors';
import { SequenceData } from '@geneious/sequence-viewer/types';

/**
 * Retrieves Sequences from the Nucleus NGS Service for a given document.
 * This service leverages the `ngs` store slice with NGRX. It caches currently selected sequences to avoid refetching already selected
 * sequences if additions to the existing selection is made.
 *
 * Only a maximum of 1000 sequences can be fetched to avoid excess load.
 */
@Injectable({
  providedIn: 'root',
})
export class NgsSequenceViewerService {
  static MAX_NUMBER_OF_SEQUENCES = 1000;

  constructor(
    private ngs2Service: Ngs2Service,
    private store: Store<AppState>,
  ) {}

  getSequences(
    documentID: string,
    selectionState: ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection,
  ): Observable<SequenceData[]> {
    if (isViewerMasterDatabaseSearchSelection(selectionState)) {
      throw new Error('Wrong selection type for this resource.');
    }
    // TODO update parsing of indices when the data format changes to be more intuitive.
    // IDs currently of the format rowID##SeqIndex or rowID##SeqIndex1#SeqIndex2
    // Some ids are combinations and refer to two sequences ie 192#193 . We should show both in the sequence viewer.
    // This case can occur if a row in the sequence table represents two sequences, the heavy and light chain.
    // This occurs when the NGS pipeline is run on unmerged paired reads.
    // However in that case the ID is purely the row, and the sequence IDs that you want are in the associatedSequences column
    return observableFrom(selectionState.selectedRows) // Create sequence of ids from array.
      .pipe(
        map((row) => NgsSequenceViewerService.getAssociatedSequencesProperty(row) as string),
        // Parse & Flatten from an array of ids.
        parseSequenceIdString(),
        // Accumulate sequence back to an array.
        toArray(),
        map((selectedIDs) =>
          selectedIDs.slice(0, NgsSequenceViewerService.MAX_NUMBER_OF_SEQUENCES),
        ),
        tap((selectedIDs) => this.store.dispatch(fetchSequences({ documentID, ids: selectedIDs }))),
        switchMap((selectedIDs) => {
          return this.store.pipe(
            select(selectNGS),
            filter((state) => !state.fetching),
            take(1),
            tap((state) => {
              if (state.error) {
                throw new Error('Failed to fetch sequences');
              }
            }),
            map((ngsState) => selectedIDs.map((sequenceID) => ngsState.entities[sequenceID])),
          );
        }),
      );
  }

  static getAssociatedSequencesProperty(row: {
    associated_sequences?: string | number;
    'Associated Sequences'?: string | number;
    'query:Associated Sequences'?: string | number;
  }): string | number {
    // TODO Remove row.associated_sequences?
    // Also check for 'query:Associated Sequences' as it is what Master Database search results use.
    // NOTE in some old results before some date before 2019, 'Associated Sequences' could be an Integer instead of a string.
    return (
      row.associated_sequences ?? row['Associated Sequences'] ?? row['query:Associated Sequences']
    );
  }

  getS3SequencesView(documentID: string, rowIDs: number[]): Observable<NGSSequence[]> {
    const requests: Observable<NGSSequence[]>[] = [];
    for (let i = 0; i < rowIDs.length; i += this.ngs2Service.MAX_SELECT_QUERY_SIZE) {
      const rowIDsSlice = rowIDs.slice(i, i + this.ngs2Service.MAX_SELECT_QUERY_SIZE);
      requests.push(this.ngs2Service.getSequenceViews(documentID, rowIDsSlice));
    }

    return forkJoin(requests).pipe(map((response) => response.flat()));
  }
}
