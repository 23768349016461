import { SequenceColors } from "@geneious/shared-constants";
import Events from "./includes/Events/Events.js";
import RenderNode from "./includes/RenderEngine/RenderNode.js";
import View from "./includes/Views/View.js";
import GeneiousJSONReader from "./includes/misc/GeneiousJSONReader.js";
import Options from "./includes/misc/Options.js";
class SequenceViewer {
  /**
   * Create an instance of SequenceViewer.
   *
   * Usually you will only use this class because you needed to create a custom instance of SequenceViewer with specific
   * plugins. For example, when you are loading sequence viewer purely for viewing the sequence & won't be needing the
   * export functionality, you can omit the export plugin from the `plugins` list. This help reducing the size of the
   * bundle, however, SequenceViewer is not guaranteed to work correctly with missing plugins.
   *
   * Therefore, it's recommended to use {@link FullSequenceViewer} and {@link MinimalSequenceViewer} when you need full
   * set of plugins or when you just need the bare minimum, respectively.
   *
   * @param elementOrSelector (required) the element or the selector of the element that will contain the sequence viewer.
   * @param options (required) the options to initialise sequence viewer with.
   * @param plugins the list of plugins to initialise sequence viewer with.
   */
  constructor(elementOrSelector, options, plugins = []) {
    this.plugins = plugins;
    this.rawOptions = options;
    this._events = new Events();
    this.options = new Options(GeneiousJSONReader.preprocess(options), this);
    this.showThreeLetterAminoAcids = this.options.showThreeLetterAminoAcids;
    this.sequenceType = this.options.sequenceType;
    this.alignment = this.options.alignment;
    this.plugins?.forEach(plugin => new plugin(this));
    this.view = new View(this, elementOrSelector);
  }
  version = "5.1.2";
  options;
  _events;
  view;
  loaded = false;
  /**
   * Registry of plugin's data channel classes.
   *
   * The classes are not instantiated. Instantiated channels are stored wrapper channels.
   * @see SequenceWrapper.constructor()
   * @see GlobalsWrapper.constructor()
   */
  channelClasses = /* @__PURE__ */new Map();
  /**
   * @deprecated
   * Registry of plugin's data cache classes.
   *
   * The classes are not instantiated. Instantiated channels are stored sequence wrapper channels.
   * @see SequenceWrapper.constructor()
   */
  cacheClasses = /* @__PURE__ */new Map();
  // Global settings for for API functions.
  zoomFactor = 1.3;
  // Residue width to use for "zoom to show residues".
  showResiduesWidth = 13;
  showThreeLetterAminoAcids = false;
  // Tell typescript about properties that optional core plugins use (without importing the plugins).
  // TODO Remove internal APIs like [plugin].data.
  scroll;
  selection;
  search;
  zoom;
  developer;
  alignment;
  tooltip;
  ruler;
  graphs;
  consensus;
  translations;
  highlighting;
  annotations;
  identity;
  wuKabat;
  tree;
  metadata;
  colorByAnnotation;
  sorting;
  pairedReads;
  sequencesPlugin;
  sequenceSelection;
  labels;
  export;
  editing;
  _error = null;
  DNAColorSchemes = SequenceColors.Nucleotide;
  proteinColorSchemes = SequenceColors.AminoAcid;
  sequenceType;
  rawOptions;
  get events() {
    return this._events;
  }
  get focused() {
    return this.view.focused;
  }
  set focused(data) {
    if (!data) {
      this.view.focused = null;
    } else {
      const {
        type,
        reference
      } = data;
      this.view.focused = new RenderNode(type, reference, {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      });
    }
  }
  get DNAColorScheme() {
    return this.sequencesPlugin?.DNAColorScheme;
  }
  set DNAColorScheme(raw) {
    this.sequencesPlugin.DNAColorScheme = raw;
  }
  get proteinColorScheme() {
    return this.sequencesPlugin.proteinColorScheme;
  }
  set proteinColorScheme(raw) {
    this.sequencesPlugin.proteinColorScheme = raw;
  }
  get referenceSequenceIndex() {
    return this.channelView.reference?.originalIndex;
  }
  set referenceSequenceIndex(index) {
    this.channelView.setReference(index);
  }
  addEventListenerToElement(element, type, listener) {
    element.addEventListener(type, listener);
    this._events.bind("cleanup", () => element.removeEventListener(type, listener));
  }
  addEventListenerToDocument(type, listener) {
    this.addEventListenerToElement(document, type, listener);
  }
  /**
   * Registers a callback function to the specified event(s). This function
   * will be called every time the event is emitted/invoked.
   *
   * @param names of events to bind the callback to
   * @param callback the function to call when the event emits
   */
  bind(names, callback) {
    this.events.bind(names, callback);
  }
  /**
   * Registers a callback function to be invoked once for each event name.
   *
   * The name of this method is a bit misleading - if multiple event names are
   * specified, the callback can be called multiple times.
   *
   * @param names of events to bind the callback to
   * @param callback the function to call when the event emits
   */
  bindOnce(names, callback) {
    this.events.bindOnce(names, callback);
  }
  bindOncePromise(name) {
    return new Promise(resolve => {
      this.events.bindOnce(name, resolve);
    });
  }
  /**
   * Registers a callback for an event on one or more types of RenderNode.
   *
   * @param name the event that will trigger the callback
   * @param types a string containing comma-separated RenderNode types to listen
   *    for the event on
   * @param callback the function to call every time the event occurs on a
   *    RenderNode with a matching type
   */
  bindToNode(name, types, callback) {
    this.events.bindToNode(name, types, callback);
  }
  unbind(name, callback) {
    return this.events.unbind(name, callback);
  }
  emit(names, ...parameters) {
    this.events.emit(names, ...parameters);
  }
  emitAndBubble(name, node, event, position) {
    this.events.emitAndBubble(name, node, event, position);
  }
  invoke(name, ...parameters) {
    return this.events.invoke(name, ...parameters);
  }
  serialize() {
    return {
      wrapped: this.wrapped,
      reference: this.referenceSequenceIndex,
      zoom: {
        residueWidth: this.residueWidth
      },
      metadataColumns: this.metadata?.columns.map(col => col.serialize()),
      sequencesPlugin: this.sequencesPlugin.serialize(),
      ruler: this.ruler?.serialize(),
      metadata: this.metadata?.serialize(),
      graphs: this.graphs?.serialize(),
      wuKabat: this.wuKabat?.serialize(),
      identity: this.identity?.serialize(),
      tree: this.tree?.serialize(),
      labels: this.labels?.serialize(),
      annotations: this.annotations?.serialize(),
      colorByAnnotation: this.colorByAnnotation?.serialize(),
      consensus: this.consensus?.serialize(),
      highlighting: this.highlighting?.serialize(),
      sorting: this.sorting?.serialize(),
      translations: this.translations?.serialize(),
      selection: this.selection?.serialize(),
      export: this.export?.serialize()
    };
  }
  bootstrapCompleted() {
    delete this.rawOptions;
    delete this.options;
    delete this.plugins;
    this._events.emit("ready");
    this.view.dirty = "initialized";
    this.loaded = true;
  }
  zoomBy(delta) {
    return this.channelView.setResidueWidth(this.residueWidth * this.zoomFactor ** delta);
  }
  zoomIn() {
    this.channelView.setResidueWidth(this.residueWidth * this.zoomFactor);
  }
  zoomOut() {
    this.channelView.setResidueWidth(this.residueWidth / this.zoomFactor);
  }
  zoomToShowResidues() {
    this.channelView.setResidueWidth(this.showResiduesWidth);
  }
  zoomToShowAll() {
    this.channelView.setResidueWidth(this.channelView.minResidueWidth);
  }
  changeOffsetBy(delta) {
    return this.channelView.moveOffset(delta);
  }
  registerChannelClass(name, Channel) {
    this.channelClasses.set(name, Channel);
  }
  registerDataCacheClass(name, Cache) {
    this.cacheClasses.set(name, Cache);
  }
  get offset() {
    return this.channelView.offset;
  }
  set offset(value) {
    this.channelView.setOffset(value);
  }
  // noinspection JSUnusedGlobalSymbols
  get focus() {
    return this.channelView.focus;
  }
  // noinspection JSUnusedGlobalSymbols
  set focus(index) {
    this.channelView.focus = index;
  }
  set wrapped(value) {
    this.channelView.wrapped = value;
  }
  get wrapped() {
    return this.channelView.wrapped;
  }
  get residueWidth() {
    return this.channelView.residueWidth;
  }
  // noinspection JSUnusedGlobalSymbols
  get labelColumnWidth() {
    return this.labels?.width ?? 0;
  }
  get range() {
    return this.channelView.viewport.residueRange;
  }
  // noinspection JSUnusedGlobalSymbols
  get indexOfCurrentPosition() {
    return this.channelView.positionIndex;
  }
  get channelView() {
    return this.view.channelView;
  }
  // noinspection JSUnusedGlobalSymbols
  get isActive() {
    return this.view.isActive;
  }
  get isRNA() {
    return this.channelView.sequences.some(sequence => sequence.sequenceCache.isRNA);
  }
  /**
   * @deprecated This should not be used as DNA sequence type was deprecated. Use {@link isNucleotide} instead.
   */
  get isDNA() {
    return this.sequenceType === "Nucleotide";
  }
  /**
   * @deprecated This should not be used as AA sequence type was deprecated. Use {@link isAminoAcid} instead.
   */
  get isAA() {
    return this.sequenceType === "AminoAcid";
  }
  get isNucleotide() {
    return this.sequenceType === "Nucleotide";
  }
  get isAminoAcid() {
    return this.sequenceType === "AminoAcid";
  }
  get isExportMode() {
    return !!this.export?.enabled;
  }
}
export { SequenceViewer as default };