import { Defaults } from "../misc/Merge.js";
class AbstractPlugin {
  constructor(sv) {
    this.sv = sv;
  }
  getInitialOptions(name, defaults) {
    return Defaults(defaults).merge(this.sv.rawOptions?.[name], this.sv, [name]);
  }
  markDirty(reason = "options changed") {
    this.sv.view.dirty = reason;
  }
  setHeightOption(name, raw) {
    const height = Number(raw);
    if (Number.isInteger(height) && height > 0 && height <= 1e4) {
      if (height !== this[name]) {
        this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
          this.setProperty(name, height);
        });
      }
    }
  }
  setNumberOption(name, raw) {
    return this.setProperty(name, Number(raw));
  }
  setBooleanOption(name, raw) {
    return this.setProperty(name, Boolean(raw));
  }
  setStringOption(name, raw) {
    return this.setProperty(name, String(raw));
  }
  setNullableString(name, raw) {
    return this.setProperty(name, raw ? String(raw) : null);
  }
  setProperty(name, value) {
    const changed = value !== this[name];
    if (changed) {
      this[name] = value;
      this.markDirty();
    }
    return changed;
  }
}
export { AbstractPlugin as default };