import { clamp } from "../../../includes/misc/Math.js";
import { isPrimaryButton } from "../../../includes/misc/Utils.js";
const {
  round,
  sign
} = Math;
class Scrollbar {
  sv;
  corner;
  container;
  // The visible part of the scrollbar.
  track;
  // The scrollbar track, minus the padding. This element makes it easier to calculate offsets.
  innerTrack;
  // The draggable part of the scrollbar.
  // @see https://webkit.org/blog/363/styling-scrollbars/.
  thumb;
  dragging = false;
  mouseOffset = 0;
  minLength = 20;
  constructor(sv, corner) {
    this.sv = sv;
    this.corner = corner;
    this.container = document.createElement("div");
    this.container.classList.add("sv-scrollbar", this.className);
    this.container.addEventListener("wheel", e => this.sv.emit("wheel", e));
    this.track = document.createElement("div");
    this.track.classList.add("sv-scrollbar-track");
    this.container.appendChild(this.track);
    this.innerTrack = document.createElement("div");
    this.innerTrack.classList.add("sv-scrollbar-inner-track");
    this.track.appendChild(this.innerTrack);
    this.thumb = document.createElement("div");
    this.thumb.classList.add("sv-scrollbar-thumb");
    this.innerTrack.appendChild(this.thumb);
    this.sv.addEventListenerToDocument("mousemove", e => this.mouseMove(e));
    this.sv.addEventListenerToDocument("mouseup", e => this.mouseUp(e));
    this.thumb.addEventListener("mousedown", e => this.mouseDownOnThumb(e));
    this.track.addEventListener("mousedown", e => this.mouseDownOnTrack(e));
    this.hide();
  }
  hide() {
    this.container.classList.add("sv-scrollbar-hidden");
    this.hideCorner();
  }
  show() {
    this.container.classList.remove("sv-scrollbar-hidden");
    this.showCorner();
  }
  // The vertical scrollbar sets the width of the corner, while the horizontal scrollbar sets the height.
  // This requires both scrollbars to be visible to display the corner.
  showCorner() {
    this.corner.classList.add(this.cornerClassName);
  }
  hideCorner() {
    this.corner.classList.remove(this.cornerClassName);
  }
  updateOffset() {
    this.styleLength = this.thumbLength;
    this.styleOffset = this.offset;
  }
  update() {
    if (this.isFullyVisible) {
      this.hide();
    } else {
      this.show();
      this.updateOffset();
    }
  }
  mouseDownOnThumb(e) {
    if (!isPrimaryButton(e)) {
      return;
    }
    e.preventDefault();
    this.dragging = true;
    this.mouseOffset = this.localPos(e);
    this.thumb.classList.add("sv-scrollbar-pressed");
  }
  /**
   * When the track is clicked, scroll one page (the full width/height of the viewport) in the direction of
   * the mouse relative to the thumb.
   */
  mouseDownOnTrack(e) {
    if (!isPrimaryButton(e)) {
      return;
    }
    if (!(e.target === this.track || e.target === this.innerTrack)) {
      return;
    }
    e.preventDefault();
    const center = this.thumbElementOffset + this.thumbElementLength / 2;
    const direction = sign(this.localPos(e) - center);
    const offsetDelta = this.sv.channelView.toOffsetAxis(this.axis, this.viewportLength * direction);
    this.sv.changeOffsetBy(offsetDelta);
    this.update();
  }
  mouseUp(e) {
    if (!isPrimaryButton(e)) {
      return;
    }
    this.dragging = false;
    this.thumb.classList.remove("sv-scrollbar-pressed");
  }
  mouseMove(e) {
    if (this.dragging) {
      const globalPos = this.globalPos(e);
      const pos = this.relativeToTrack(globalPos) - this.mouseOffset;
      this.setBoundedOffset(pos);
    }
  }
  // Note similarities to ChannelView.setOffset().
  // @see ChannelView.ts.
  setBoundedOffset(offset) {
    const boundedOffset = clamp(offset, this.maxOffset);
    if (boundedOffset !== this.offset) {
      this.sv.channelView.setOffsetFraction(this.axis, boundedOffset / this.maxOffset);
      this.update();
    }
  }
  get thumbLength() {
    const length = this.viewportLength / this.viewLength * this.trackLength;
    return clamp(round(length), this.trackLength, this.minLength);
  }
  get offset() {
    return this.viewOffsetFraction * this.maxOffset;
  }
  get maxOffset() {
    return this.trackLength - this.thumbLength;
  }
  get isFullyVisible() {
    return this.viewLength <= this.viewportLength;
  }
  // Functions that use configuration and depend on the dimension (horizontal or vertical)
  upper(s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  localPos(e) {
    return e["offset" + this.axisUpperCase];
  }
  globalPos(e) {
    return e["page" + this.axisUpperCase];
  }
  relativeToTrack(pos) {
    return pos - this.track.getBoundingClientRect()[this.configuration.offset];
  }
  get className() {
    return "sv-scrollbar-" + this.configuration.direction;
  }
  get cornerClassName() {
    return "sv-scrollbar-corner-" + this.configuration.direction;
  }
  get axis() {
    return this.configuration.axis;
  }
  get axisUpperCase() {
    return this.axis.toUpperCase();
  }
  get thumbElementOffset() {
    return Number(this.thumb.style[this.configuration.offset]?.replace("px", ""));
  }
  get thumbElementLength() {
    return this.thumb["offset" + this.upper(this.configuration.length)];
  }
  get trackLength() {
    return this.innerTrack["client" + this.upper(this.configuration.length)];
  }
  get viewportLength() {
    return this.sv.channelView.viewport[this.configuration.length];
  }
  get viewLength() {
    return this.sv.channelView[this.configuration.length];
  }
  get viewOffsetFraction() {
    return this.sv.channelView.offsetFraction[this.configuration.axis];
  }
  set styleLength(length) {
    this.thumb.style[this.configuration.length] = length + "px";
  }
  set styleOffset(offset) {
    this.thumb.style[this.configuration.offset] = offset + "px";
  }
}
var Scrollbar_default = Scrollbar;
export { Scrollbar_default as default };