import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin";
import { SequenceWrapper } from "../../index";
import CheckboxColumn from "./CheckboxColumn";
class SequenceSelectionPlugin extends AbstractPlugin {
  _selectedSequences;
  selectedSequencesBackground = "#c9efff";
  _enabled = false;
  constructor(sv) {
    super(sv);
    this.sv.sequenceSelection = this;
    this._selectedSequences = /* @__PURE__ */new Set();
    const options = this.getInitialOptions("sequenceSelection", {
      enabled: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      selection: {
        default: [],
        validator: values => Array.isArray(values) && values.every(raw => typeof raw === "number")
      }
    });
    this.initialize(options);
    sv.bind("ready", () => {
      const selection = options.selection;
      for (const sequence of this.sv.channelView.sequences) {
        if (selection.includes(sequence.originalIndex)) {
          this.selectSequence(sequence);
        }
      }
    });
    sv.bind("register sidebar columns", () => new CheckboxColumn(this));
    sv.bindToNode("mousedown", "checkbox container, label", node => this.clickOnCheckbox(node));
    sv.bindToNode("mousedown", "selectAll checkbox", () => this.clickOnSelectAllCheckbox());
  }
  get selectedSequences() {
    return Array.from(this._selectedSequences);
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.setBooleanOption("_enabled", raw);
    this.sv.emit(["column visibility changed", "height changed"]);
  }
  /**
   * Check if a sequence has been selected.
   * @param sequence The sequence wrapper of the sequence (usually obtained from {@link ChannelView.sequences})
   */
  isSequenceSelected(sequence) {
    return this._selectedSequences.has(sequence);
  }
  /**
   * Select a sequence. Set the background of the sequence wrapper to the highlighted background.
   * @param sequence The sequence wrapper of the sequence.
   */
  selectSequence(sequence) {
    this._selectSequence(sequence);
    this.sv.emit("sequences selection changed");
  }
  /**
   * Unselect a sequence. Reset sequence wrapper background to undefined & let wrapper decide on background color.
   * @param sequence The sequence wrapper of the sequence.
   */
  unSelectSequence(sequence) {
    this._unselectSequence(sequence);
    this.sv.emit("sequences selection changed");
  }
  serialize() {
    return void 0;
  }
  initialize(options) {
    this._enabled = options.enabled;
  }
  _selectSequence(sequence) {
    if (!this.enabled) {
      return;
    }
    this._selectedSequences.add(sequence);
    sequence.background = this.selectedSequencesBackground;
  }
  _unselectSequence(sequence) {
    if (!this.enabled) {
      return;
    }
    this._selectedSequences.delete(sequence);
    sequence.background = void 0;
  }
  /**
   * Handle when user click on a checkbox except select all checkbox.
   * @param node The render node of the checkbox
   * @private
   */
  clickOnCheckbox(node) {
    if (!this.enabled) {
      return;
    }
    if (node.reference instanceof SequenceWrapper) {
      const sequence = node.reference;
      if (this._selectedSequences.has(sequence)) {
        this.unSelectSequence(sequence);
      } else {
        this.selectSequence(sequence);
      }
    }
  }
  /**
   * Handle when user click on select all checkbox. The rules are:
   * 1. Select all if there's no selected sequences.
   * 2. Select all if there's one or more selected sequences.
   * 3. Unselect all if all sequences are selected.
   *
   * @public only for testing.
   */
  clickOnSelectAllCheckbox() {
    if (this._selectedSequences.size === 0 || this._selectedSequences.size < this.sv.channelView.sequences.length) {
      for (const sequence of this.sv.channelView.sequences) {
        this._selectSequence(sequence);
      }
      this.sv.emit("sequences selection changed");
      return;
    }
    for (const sequence of this.sv.channelView.sequences) {
      this._unselectSequence(sequence);
    }
    this.sv.emit("sequences selection changed");
  }
}
export { SequenceSelectionPlugin as default };