import { fixReverseComplement, indexRange } from "../../includes/misc/Utils.js";
import UngappedRanges from "../Annotations/UngappedRanges.js";
class AnnotationTranslationsCache {
  constructor(channel) {
    this.channel = channel;
  }
  caches = /* @__PURE__ */new Map();
  clear() {
    this.caches.clear();
  }
  isSet(annotation, index) {
    return index in this.getCache(annotation);
  }
  get(annotation, index) {
    if (!this.channel.wrapper.sequenceCache?.isDefined(index)) {
      return;
    }
    if (!this.isSet(annotation, index)) {
      this.translationAnnotation(annotation).forEach((third, i) => {
        this.getCache(annotation)[i] = third;
      });
    }
    return this.getCache(annotation)[index];
  }
  getCodonRange(index) {
    const annotation = this.channel.sv.annotations.getLastAnnotationAtIndex(this.channel.wrapper, index);
    if (annotation && annotation.intervals.length === 1) {
      const isForwards = annotation.intervals[0].direction >= 0;
      const translationStart = this.channel.rangesCache.toUngapped(isForwards ? annotation.start : annotation.end + 1);
      const codonStart = this.codonStart(annotation);
      const frameOffset = translationStart % 3 + codonStart;
      return this.channel.translationRange(annotation.range, indexRange(index), frameOffset);
    }
  }
  translatableAnnotations(range) {
    return this.channel.sv.annotations.getByRangeFromWrapper(this.channel.wrapper, range).filter(annotation => annotation.isTranslatable).sort((a, b) => a.range.start - b.range.start);
  }
  trimmedUngappedRanges(annotation) {
    const startOffset = this.codonStart(annotation);
    const ranges = new UngappedRanges(annotation);
    const endOffset = (ranges.length - startOffset) % 3;
    if (ranges.length > startOffset + endOffset) {
      return ranges.shrink(startOffset, endOffset);
    } else {
      return [];
    }
  }
  translationAnnotation(annotation) {
    const translation = this.trimmedUngappedRanges(annotation);
    this.checkTranslationLength(translation);
    const sequence = this.getUngappedSequenceForAnnotation(translation);
    const firstInterval = annotation.intervals[0];
    const truncatedStart = firstInterval && firstInterval.isReversed ? firstInterval.truncatedRight : firstInterval.truncatedLeft;
    const thirds = this.channel.translateSequence(sequence, !truncatedStart);
    return this.regapByRanges(translation, thirds);
  }
  checkTranslationLength(translation) {
    const total = translation.map(item => item.range.length).reduce((sum, range) => sum + range, 0);
    if (total > 1e5) {
      throw new Error("Translating annotations more than 100,000 residues long is not yet supported.");
    }
  }
  getUngappedSequenceForAnnotation(ranges) {
    let result = [];
    ranges.forEach(({
      range,
      interval
    }) => {
      const sequence = this.channel.getDNA(range);
      const complement = fixReverseComplement(sequence, interval.isReversed);
      result = result.concat(complement);
    });
    return result;
  }
  regapByRanges(ranges, thirds) {
    const result = [];
    let start = 0;
    ranges.forEach(({
      range,
      interval
    }) => {
      const end = start + range.length;
      const sequence = thirds.slice(start, end);
      start = end;
      const gapped = this.channel.regap(range, interval.fixOrder(sequence));
      gapped.forEach((item, index) => {
        result[index] = item;
      });
    });
    return result;
  }
  codonStart(annotation) {
    const value = annotation.properties.get("codon_start");
    if (value && ["1", "2", "3"].includes(value)) {
      return Number(value) - 1;
    } else {
      return 0;
    }
  }
  getCache(annotation) {
    if (!this.caches.has(annotation)) {
      this.caches.set(annotation, []);
    }
    return this.caches.get(annotation) ?? [];
  }
}
export { AnnotationTranslationsCache as default };