import DataCache from "../../includes/PluginBaseClasses/DataCache.js";
import MultiRange from "../../includes/Range/MultiRange.js";
import RRange from "../../includes/Range/RRange.js";
import RangeCollection from "../../includes/Range/RangeCollection.js";
class UngappedRangesCache extends DataCache {
  /**
   * The RangeCollection is only used if ungapped ranges are provided. Otherwise the channel's whole range is used.
   *
   * @see get items()
   */
  collection = new RangeCollection();
  get hasSuppliedData() {
    return this.collection.length > 0;
  }
  /**
   * Return the range from the start of the first UngappedRange to the end of the last UngappedRange.
   */
  get entireRange() {
    const first = this.items[0];
    const last = this.items[this.items.length - 1];
    return new RRange(first.range.start, last.range.end);
  }
  get ungappedLength() {
    const range = this.entireRange;
    return this.toUngapped(range.end) - this.toUngapped(range.start);
  }
  getInRange(query) {
    this.request(query);
    if (this.hasSuppliedData) {
      const {
        start,
        end
      } = this.collection.indexesOfOverlappingItems(query);
      return this.items.slice(start, end);
    } else {
      return this.items;
    }
  }
  getInRangeAsMultiRange(query) {
    const ranges = this.getInRange(query).map(item => item.range);
    return new MultiRange(ranges);
  }
  set(_, items) {
    let result = false;
    for (const item of items) {
      const changed = this.insert(item);
      result = result || changed;
    }
    return result;
  }
  ungapRange({
    start,
    end
  }) {
    return new RRange(this.toUngapped(start), this.toUngapped(end));
  }
  // Public only for karma tests.
  toUngapped(index) {
    return this.transpose(({
      range,
      start
    }) => {
      if (range.start <= index) {
        return start + Math.min(range.length, index - range.start);
      }
    });
  }
  gapRange({
    start,
    end
  }) {
    return new RRange(this.toGapped(start), this.toGapped(end));
  }
  // TODO Check why gapRange includes gaps on the right edge.
  gapRangeExcludingEdges(range) {
    const {
      start,
      end
    } = range;
    if (start === end) {
      return this.gapRange(range);
    }
    return new RRange(this.toGapped(start), this.toGapped(end - 1) + 1);
  }
  // Public only for karma tests.
  toGapped(index) {
    return this.transpose(({
      range,
      start
    }) => {
      if (start <= index) {
        return range.start + index - start;
      }
    });
  }
  transpose(callback) {
    for (let i = this.items.length - 1; i >= 0; i--) {
      const value = callback(this.items[i]);
      if (value !== void 0) {
        return value;
      }
    }
    return 0;
  }
  get items() {
    return this.hasSuppliedData ? this.collection.items.map(({
      range,
      value
    }) => ({
      start: value ?? 0,
      range
    })) : [{
      start: 0,
      range: this.channel.range
    }];
  }
  insert({
    start,
    range
  }) {
    return this.collection.insertIfFits(new RRange(range.start, range.start + range.length), start);
  }
}
export { UngappedRangesCache as default };