import WrapperChannel from "./WrapperChannel.js";
class ReferenceWrapper extends WrapperChannel {
  margin = 0;
  referenceColor = "#FDFCD9";
  rulerChannel;
  graphsChannel;
  sequenceChannel;
  translationsChannel;
  annotationsChannel;
  constructor(view) {
    super(view);
    const registry = view.sv.channelClasses;
    const type = view.sv.options?.sequenceType;
    const rulerChannel = registry.get("ruler");
    if (rulerChannel) {
      this.rulerChannel = new rulerChannel(this, false);
    }
    const graphsChannel = registry.get("graphs");
    if (graphsChannel) {
      this.graphsChannel = new graphsChannel(this);
    }
    const sequenceChannel = registry.get("sequence");
    if (type && sequenceChannel) {
      this.sequenceChannel = new sequenceChannel(this, type);
    }
    const translationsChannel = registry.get("translations");
    if (translationsChannel) {
      this.translationsChannel = new translationsChannel(this);
    }
    const annotationsChannel = registry.get("annotations");
    if (annotationsChannel) {
      this.annotationsChannel = new annotationsChannel(this, null);
    }
  }
  calculateLayout(context, bounds) {
    const node = super.calculateLayout(context, bounds);
    node.setBackgroundCallback(brush => this.renderBackground(brush, bounds));
    return node;
  }
  renderBackground(brush, bounds) {
    brush.fillStyle = this.referenceColor;
    brush.fillRect(0, 0, bounds.width, bounds.height);
  }
  get isSticky() {
    return !!this.sv.sorting?.pinReference;
  }
  get sequenceCache() {
    return this.view.reference.sequenceCache;
  }
  get rangesCache() {
    return this.view.reference?.rangesCache;
  }
  get qualitiesCache() {
    return this.view.reference.qualitiesCache;
  }
  get chromatogramCache() {
    return this.view.reference?.chromatogramCache;
  }
  get sequence() {
    return this.view.reference?.sequence ?? "";
  }
  get label() {
    return this.view.reference?.label ?? {
      text: ""
    };
  }
  get type() {
    return "reference wrapper";
  }
  useQuality(index) {
    return this.view.reference?.useQuality(index) ?? false;
  }
  get children() {
    const children = [];
    if (this.rulerChannel) {
      children.push(this.rulerChannel);
    }
    if (this.graphsChannel) {
      children.push(this.graphsChannel);
    }
    if (this.sequenceChannel) {
      children.push(this.sequenceChannel);
    }
    if (this.translationsChannel) {
      children.push(this.translationsChannel);
    }
    if (this.annotationsChannel) {
      children.push(this.annotationsChannel);
    }
    return children.concat(...this.sv.invoke("append sequence wrapper children", this));
  }
  get annotationsCache() {
    return this.annotationsChannel?.cache;
  }
}
export { ReferenceWrapper as default };