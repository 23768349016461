import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import Pen from "../../includes/RenderEngine/Pen.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import { boundsOverlap } from "../../includes/misc/Utils.js";
class SortingPlugin extends AbstractPlugin {
  _currentSort = [];
  pen = new Pen(10, "center", "middle", "black");
  // TODO use a global property for ruler height for bottom.
  triangle = {
    width: 6,
    bottom: 18,
    height: 6
  };
  _pinReference = true;
  constructor(sv) {
    super(sv);
    sv.sorting = this;
    if (sv.options?.sequences && sv.options.sequences.length > 1) {
      this.initialize(this.getInitialOptions("sorting", {
        pinReference: {
          default: true,
          validator: raw => typeof raw === "boolean"
        },
        currentSort: {
          default: [],
          validator: raw => this.validateSortBy(raw)
        }
      }));
      sv.bind("alter ruler", (visible, context, isGlobal) => this.alterRuler(visible, context, isGlobal));
      sv.bind("alter sidebar column", (brush, column, yOffset) => this.alterSidebarColumn(brush, column, yOffset));
      sv.bindToNode("mousedown", "ruler channel", (node, event, offset) => this.mouseDownOnRulerChannel(node, event, offset));
      sv.bindToNode("mousedown", "metadata column", (node, event) => this.mouseDownOnMetadataColumn(node, event));
      sv.bindToNode("mousemove", "metadata column", node => this.mouseMoveOnMetadataColumn(node));
    }
  }
  sortBy(criteria) {
    const originalSort = criteria.length === 0;
    if (originalSort) {
      this.sortByOriginalIndex();
    } else {
      const keys = criteria.map(criterion => `${criterion.type}-${criterion.value}`);
      if (keys.length !== new Set(keys).size) {
        throw new Error("Can not sort on the same column multiple times.");
      }
      criteria.slice().reverse().forEach(criterion => this._sortBy(criterion));
    }
    this.currentSort = criteria;
    if (this.sv.tree) {
      this.sv.tree.enabled = originalSort;
    }
    this.markDirty();
    this.sv.emit("sorting changed");
  }
  get currentSort() {
    return this._currentSort;
  }
  set currentSort(criteria) {
    criteria.forEach(criterion => {
      if (criterion.ascending === void 0) {
        criterion.ascending = true;
      }
    });
    this._currentSort = criteria;
  }
  serialize() {
    return {
      pinReference: this.pinReference,
      currentSort: this.currentSort
    };
  }
  validateSortBy(raw) {
    return !!raw && typeof raw === "object" && Object.values(raw).every(sort => typeof sort.type === "string" && sort.value != void 0 && typeof sort.ascending === "boolean" && !(sort.type === "position" && !this.sv.options?.alignment.enabled) && !(sort.type === "metadata" && !this.sv.options?.metadataColumns.some(col => col.name === sort.value)));
  }
  _sortBy({
    type,
    value,
    ascending = true
  }) {
    if (type === "metadata") {
      this.sortByMetadata(String(value), ascending);
    } else if (type === "position") {
      this.sortByPosition(Number(value), ascending);
    }
  }
  sortByOriginalIndex() {
    this.sortSequences((a, b) => a.originalIndex - b.originalIndex);
  }
  sortByPosition(position, ascending) {
    this.sv.channelView.sequences.forEach(wrapper => {
      wrapper.sequenceCache.requestIndex(position);
      wrapper.sendRequests();
    });
    const hasAllData = this.sv.channelView.sequences.every(wrapper => wrapper.sequenceCache.hasData(position));
    if (!hasAllData) {
      throw new Error("Sorting failed because not all sequences have loaded yet.");
    }
    const get = wrapper => {
      return wrapper.sequenceCache.get(position);
    };
    this.sortSequences((a, b) => {
      if (get(a) === void 0 || get(a) === " ") {
        return 1;
      } else if (get(b) === void 0 || get(b) === " ") {
        return -1;
      } else {
        return ascending ? this.compareStrings(get(a), get(b)) : this.compareStrings(get(b), get(a));
      }
    });
  }
  sortByMetadata(name, ascending) {
    const column = this.sv.metadata && this.sv.metadata.getColumn(name);
    if (!column) {
      throw new Error(`Metadata column named ${name} could not be found for sorting`);
    } else if (column.type === "continuous") {
      this.sortContinuousMetadata(column, ascending);
    } else {
      this.sortBooleanMetadata(column, ascending);
    }
  }
  sortContinuousMetadata(column, ascending) {
    const get = sequence => {
      return column.getValue(sequence);
    };
    this.sortSequences((a, b) => {
      if (get(a) == null) {
        return 1;
      } else if (get(b) == null) {
        return -1;
      } else {
        const aNormalized = Number(get(a));
        const bNormalized = Number(get(b));
        return ascending ? aNormalized - bNormalized : bNormalized - aNormalized;
      }
    });
  }
  sortBooleanMetadata(column, ascending) {
    const get = sequence => {
      const value = column.getValue(sequence);
      return value == null ? "" : String(value);
    };
    this.sortSequences((a, b) => {
      if (get(a) === "") {
        return 1;
      } else if (get(b) === "") {
        return -1;
      } else {
        return ascending ? this.compareStrings(get(a), get(b)) : this.compareStrings(get(b), get(a));
      }
    });
  }
  sortSequences(compareFn) {
    this.sv.channelView.sequences.sort(compareFn);
  }
  compareStrings(a, b) {
    return a.localeCompare(b, void 0, {
      // Numeric collation: '1' < '2' < '10'
      numeric: true,
      // Sensitivity for case insensitive base letter matching: a ≠ b, a = á, a = A
      sensitivity: "base"
    });
  }
  alterRuler(visible, context, isGlobal) {
    if (!isGlobal) {
      return;
    }
    const {
      width,
      bottom
    } = this.triangle;
    const center = width / 2;
    this.currentSort.forEach((criterion, index) => {
      if (criterion.type === "position") {
        const xOffset = this.sv.channelView.toPixels(criterion.value - context.row.start + 0.5) - this.sv.channelView.offset.x;
        const x = xOffset - center;
        const bounds = {
          x,
          y: 0,
          width: this.pen.measureWidth(`${criterion.value}`),
          height: bottom
        };
        const overlap = visible.findIndex(other => boundsOverlap(bounds, other.bounds));
        if (overlap >= 0) {
          const criteria = visible[overlap].reference;
          const indices = criteria.map(c => `${this.currentSort.indexOf(c) + 1}`);
          const label = indices.join(",") + "," + (index + 1);
          visible[overlap] = this.rulerRenderNode(label, [...criteria, criterion], {
            x: x - (x - visible[overlap].bounds.x) / 2,
            y: 0,
            width: this.pen.measureWidth(label),
            height: bottom
          });
        } else {
          visible.push(this.rulerRenderNode(`${index + 1}`, [criterion], bounds));
        }
      }
    });
  }
  rulerRenderNode(label, reference, bounds) {
    const {
      width,
      height
    } = this.triangle;
    const node = new RenderNode("sort", reference, bounds);
    node.setRenderCallback(() => {
      const {
        brush,
        graphics
      } = this.sv.view;
      this.pen.write(graphics, brush, label, width / 2, height);
      const ascending = reference[0].ascending ?? false;
      this.drawTriangle(this.sv.view.brush, ascending, 0, 0);
    });
    return node;
  }
  isMetadataColumn(column) {
    return column.nodeType === "metadata";
  }
  alterSidebarColumn(brush, column, yOffset) {
    if (this.isMetadataColumn(column)) {
      if (this._currentSort.length > 0) {
        brush.fillStyle = "white";
        brush.fillRect(0, 0, column.width, 20 + yOffset);
      }
      const criterion = this.getMetadataSortState(column.name);
      if (criterion) {
        const {
          width,
          height
        } = this.triangle;
        column.pen.fontSize = this.pen.fontSize;
        column.pen.color = this.pen.color;
        column.pen.align = "center";
        column.pen.write(this.sv.view.graphics, brush, `${criterion.index + 1}`, column.width / 2, height + yOffset);
        this.drawTriangle(brush, criterion.ascending, (column.width - width) / 2, yOffset);
      }
    }
  }
  mouseDownOnRulerChannel(node, event, offset) {
    const channel = node.reference;
    if (channel.wrapper.type === "globals wrapper" && channel.sequencePainter.printLetter && this.sv.alignment.enabled) {
      const hoveredPosition = Math.floor(this.sv.channelView.toResidues(offset.x + channel.view.offset.x));
      this.updateSorting("position", hoveredPosition, event.shiftKey);
    }
  }
  mouseDownOnMetadataColumn(node, event) {
    const metadataColumn = node.reference;
    if (metadataColumn.labelIsHovered && !this.sv.metadata?.isResizing) {
      const metadataColumnName = metadataColumn.name;
      this.updateSorting("metadata", metadataColumnName, event.shiftKey);
    }
  }
  mouseMoveOnMetadataColumn(node) {
    const metadataColumn = node.reference;
    if (metadataColumn.labelIsHovered) {
      document.body.style.cursor = "pointer";
    }
  }
  updateSorting(type, value, shiftKey) {
    const index = this.currentSort.findIndex(criterion => criterion.type === type && criterion.value === value);
    if (index !== -1) {
      const currentSort = this.currentSort.slice();
      if (currentSort[index].ascending) {
        currentSort[index].ascending = false;
      } else {
        currentSort.splice(index, 1);
      }
      if (currentSort.length === 0 || !shiftKey && currentSort[index] === void 0) {
        this.sortBy([]);
      } else if (!shiftKey) {
        this.sortBy([currentSort[index]]);
      } else {
        this.sortBy(currentSort);
      }
    } else {
      const criterion = {
        type,
        value,
        ascending: true
      };
      if (!shiftKey) {
        this.sortBy([criterion]);
      } else {
        this.sortBy([...this.currentSort, criterion]);
      }
    }
  }
  getMetadataSortState(name) {
    const index = this.currentSort.findIndex(criterion => criterion.type === "metadata" && criterion.value === name);
    if (index >= 0) {
      return {
        ascending: this.currentSort[index].ascending ?? false,
        index
      };
    }
  }
  drawTriangle(brush, ascending, x, y = 0) {
    const {
      width,
      height,
      bottom
    } = this.triangle;
    const top = bottom - height;
    const base = y + (ascending ? bottom : top);
    const tip = y + (ascending ? top : bottom);
    brush.fillStyle = "black";
    brush.beginPath();
    brush.moveTo(x, base);
    brush.lineTo(x + width, base);
    brush.lineTo(x + width / 2, tip);
    brush.fill();
  }
  get pinReference() {
    return this._pinReference;
  }
  set pinReference(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setBooleanOption("_pinReference", raw);
      this.sv.emit("channel visibility changed");
    });
    this.sv.view.dirty = "pin reference changed";
  }
  initialize(options) {
    this._pinReference = options.pinReference;
    this._currentSort = options.currentSort;
    this.sv.bind("ready", () => {
      if (this.currentSort.length > 0) {
        this.sortBy(this.currentSort);
      }
    });
  }
}
export { SortingPlugin as default };