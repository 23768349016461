import StructureMap from "../../includes/misc/StructureMap.js";
import ResidueStamp from "./ResidueStamp.js";
import Pen from "../../includes/RenderEngine/Pen.js";
class StampCache {
  constructor(painter) {
    this.painter = painter;
    painter.view.sv.bind(["ready", "zoom", "resize"], () => {
      this.pen.fontSize = this.painter.fontSize * window.devicePixelRatio;
      this.stamps.clear();
    });
  }
  // Font size is set later.
  pen = new Pen(0, "center", "middle");
  // Only cache the properties of a residue that are actually used at render time.
  // @see ResidueStamp
  stamps = new StructureMap(["letter", "isTrimmed", "foreground", "background", "marginColor"]);
  get(settings, dimensions) {
    const {
      letter,
      foreground,
      isTrimmed,
      marginColor
    } = settings;
    const background = settings.background.toString();
    const key = {
      letter,
      foreground,
      background,
      isTrimmed,
      marginColor
    };
    return this.stamps.getOrCreate(key, () => new ResidueStamp(this, dimensions, settings));
  }
}
export { StampCache as default };