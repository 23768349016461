import DataCache from "../../includes/PluginBaseClasses/DataCache.js";
import RRange from "../../includes/Range/RRange.js";
import CharCache from "../../includes/PluginBaseClasses/CharCache.js";
class SequenceCache extends DataCache {
  // Store the sequence as an array. This comes with several benefits compare to string data structure:
  //  - If there's an unfetched section in the middle of sequence it will automatically be "undefined" so we don't have
  //    to populate it with empty character manually.
  //  - We can use index to set a character at any index. A string wouldn't allow that.
  sequence = [];
  isRNA = false;
  // @see SSelection
  // noinspection JSUnusedGlobalSymbols
  rangeIsSet = CharCache.prototype.rangeIsSet;
  getAsDNA = CharCache.prototype.getAsDNA;
  isDefined = CharCache.prototype.isDefined;
  // noinspection JSUnusedGlobalSymbols
  getRange = CharCache.prototype.getRange;
  getRangeAsDNA = CharCache.prototype.getRangeAsDNA;
  // noinspection JSUnusedGlobalSymbols
  requestIndex = CharCache.prototype.requestIndex;
  get(index) {
    return this.sequence[index];
  }
  maxIndex() {
    return this.sequence.length;
  }
  get wholeSequence() {
    const range = new RRange(0, this.sequence.length);
    if (!range.every(index => this.hasData(index))) {
      throw new Error("Not all of the sequence is available.");
    }
    return this.getRange(range).join("");
  }
  hasData(index) {
    return index in this.sequence;
  }
  isSet(index) {
    return this.hasData(index) && this.get(index) !== " ";
  }
  static charIsSet(char) {
    return char && char !== " ";
  }
  static charIsDefined(char) {
    return this.charIsSet(char) && char !== "-";
  }
  get length() {
    return this.sequence.length;
  }
  replaceSequence(residues) {
    this.sequence = [...residues];
  }
  // NOTE: Very similar to `QualitiesCache.set()`.
  set({
    start,
    end
  }, sequence) {
    let changed = false;
    for (let i = start; i < end; i++) {
      const indexInReplaceSequence = i - start;
      if (indexInReplaceSequence > sequence.length - 1) {
        this.sequence[i] = " ";
        changed = true;
        continue;
      }
      const char = sequence.charAt(indexInReplaceSequence);
      if (char && this.sequence[i] !== char) {
        if (!this.isRNA && char === "U") {
          this.isRNA = true;
        }
        this.sequence[i] = char;
        changed = true;
      }
    }
    return changed;
  }
}
export { SequenceCache as default };