import { getUngappedRanges } from "./Utils.js";
class GeneiousJSONReader {
  static preprocess(rawOptions) {
    let isAlignment = false;
    rawOptions.sequences = rawOptions.sequences.map((data, index) => {
      if (data.sequence || data.annotations) {
        isAlignment = isAlignment || data.sequence.sequence.includes("-");
        return this.convertCompleteData(index, data);
      }
      return data;
    });
    rawOptions.tree = this.mergeTreeOptions(rawOptions.tree, rawOptions.trees);
    rawOptions.alignment = this.mergeAlignmentOptions(isAlignment, rawOptions.alignment);
    if (rawOptions.consensus && rawOptions.consensus.annotations) {
      const longestSequenceLength = rawOptions.sequences.map(data => data?.sequence?.sequence?.length ?? 0).reduce((max, curr) => Math.max(max, curr), 0);
      rawOptions.consensus.dataCallback = (_request, callback) => callback({
        complete: true,
        data: {
          annotations: this.getAnnotations(rawOptions.consensus.annotations ?? [], "consensus", longestSequenceLength)
        }
      });
    }
    return rawOptions;
  }
  /**
   * Processes Geneious JSON into web SV format.
   */
  static convertCompleteData(index, {
    sequence,
    metadata,
    annotations,
    annotationTracks = []
  }) {
    const length = sequence.sequence.length;
    return {
      name: sequence.name,
      length,
      metadata,
      annotationTracks: annotationTracks.map((track, trackIndex) => {
        return {
          name: track.name ?? `Track ${trackIndex + 1}`,
          qualifiers: track.qualifiers,
          dataCallback: (_request, callback) => callback({
            complete: true,
            data: {
              annotations: this.getAnnotations(track.annotations ?? [], `${index}-${trackIndex}`, length)
            }
          })
        };
      }),
      graphs: Boolean(sequence.qualities || sequence.chromatogram),
      pairInformation: sequence.pairInformation,
      dataCallback: (_request, callback) => callback({
        complete: true,
        data: {
          sequence: {
            start: 0,
            length,
            data: this.trimAlignedSequence(sequence.sequence)
          },
          annotations: this.getAnnotations(annotations ?? [], index.toString(), length),
          qualities: {
            start: 0,
            length,
            data: sequence.qualities
          },
          chromatogram: {
            start: 0,
            length,
            data: sequence.chromatogram
          },
          keyTranspositionRanges: {
            start: 0,
            length,
            data: getUngappedRanges(sequence.sequence)
          }
        }
      })
    };
  }
  static getAnnotations(annotations, prefix, length) {
    return {
      start: 0,
      length,
      data: annotations.map((annotation, index) => {
        if (!annotation.id) {
          annotation.id = `${prefix}-${index}`;
        }
        return annotation;
      })
    };
  }
  /**
   * Replaces end gaps (hyphens) with spaces at the beginning and end of an aligned sequences.
   */
  static trimAlignedSequence(fullSequence) {
    return fullSequence.replace(/^-+|-+$/g, match => " ".repeat(match.length));
  }
  static mergeAlignmentOptions(isAlignment, raw) {
    if (raw === null) {
      raw = void 0;
    }
    switch (typeof raw) {
      case "undefined":
        return {
          enabled: isAlignment
        };
      case "boolean":
        return {
          enabled: raw
        };
      case "object":
        raw["enabled"] = this.mergeAlignmentOptions(isAlignment, raw["enabled"]).enabled;
        return raw;
    }
  }
  static mergeTreeOptions(options, trees) {
    const result = {
      ...options
    };
    result.newickData = trees && trees[0];
    return result;
  }
}
export { GeneiousJSONReader as default };