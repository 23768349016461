import RangeCollection from "../Range/RangeCollection.js";
class SequenceLines extends RangeCollection {
  constructor(range, value) {
    super();
    this.items.push({
      range,
      value
    });
  }
}
export { SequenceLines as default };