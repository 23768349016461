import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import LabelsColumn from "./LabelsColumn.js";
class LabelsPlugin extends AbstractPlugin {
  _width;
  _enabled = true;
  constructor(sv) {
    super(sv);
    sv.labels = this;
    sv.bind("register sidebar columns", () => new LabelsColumn(this));
    const options = this.getInitialOptions("labels", {
      width: {
        default: 100,
        validator: raw => typeof raw === "number"
      }
    });
    this._width = options.width;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.setBooleanOption("_enabled", raw);
    this.sv.emit(["column visibility changed", "height changed"]);
  }
  get width() {
    return this._width;
  }
  set width(raw) {
    const changed = this.setNumberOption("_width", raw);
    if (changed) {
      this.sv.emit("column visibility changed");
    }
  }
  serialize() {
    return {
      width: this.width
    };
  }
}
export { LabelsPlugin as default };