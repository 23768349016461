class EventRegistry {
  registry = {};
  bind(names, item) {
    if (typeof names === "string") {
      this.get(names).push(item);
    } else {
      this.getAll(names).forEach(items => items.push(item));
    }
  }
  unbind(name, item) {
    const items = this.get(name);
    const index = items.indexOf(item);
    if (index >= 0) {
      delete items[index];
      return true;
    } else {
      return false;
    }
  }
  invoke(name, callback) {
    return this.get(name).map(item => callback(item));
  }
  clear(name) {
    this.initialize(name);
  }
  initialize(name) {
    this.registry[name] = [];
  }
  getAll(names) {
    return names.map(name => this.get(name));
  }
  get(name) {
    if (!this.registry[name]) {
      this.initialize(name);
    }
    return this.registry[name];
  }
}
export { EventRegistry as default };