import RenderNode from "../RenderEngine/RenderNode.js";
import Layer from "./Layer.js";
import { adjustOffset } from "../misc/DPIRatio.js";
class SidebarColumn {
  constructor(view) {
    this.view = view;
    this.layer = new Layer(this.view);
    view.sv.bind(["column visibility changed", "resize"], () => this.clearCachedColumnWidthWithMargin());
  }
  margin = 0;
  layer;
  cachedColumnWidthWithMargin = null;
  paint() {}
  get enabled() {
    return true;
  }
  get widthWithMargin() {
    return this.view.sv.view.getCacheableValue(this, "cachedColumnWidthWithMargin", () => {
      return adjustOffset(this.width + this.margin);
    });
  }
  get offset() {
    let offset = 0;
    this.view.channelView.viewport.sidebarColumns.some(col => {
      if (col === this) {
        return true;
      }
      offset += col.widthWithMargin;
    });
    return offset;
  }
  calculateLayout(bounds, channels) {
    const children = this.channelNodes(channels, this.width);
    const node = new RenderNode(`${this.nodeType} column`, this, bounds, children);
    return this.setColumnRender(bounds, node);
  }
  setColumnRender(bounds, node, allowedWidth) {
    node.brush = this.layer.brush;
    let yOffset;
    node.setBackgroundCallback(() => {
      const range = this.view.clipToViewport(node);
      if (range.isEmpty) {
        throw new Error("SideBar RenderNode must be in viewport.");
      }
      yOffset = range.start;
      this.layer.reset({
        width: allowedWidth ?? bounds.width,
        // View rows can be taller than the limits of canvas elements.
        // Use the layer only for the part of the column that is in the viewport.
        // @see SV-740
        // @see https://stackoverflow.com/questions/8784405/large-numbers-in-html-canvas-translate-result-in-strange-behavior
        height: range.length
      });
      this.layer.brush.save();
      this.layer.brush.translate(0, 0 - yOffset);
      this.layer.brush.fillStyle = "white";
      this.layer.brush.fillRect(0, 0, bounds.width, bounds.height);
      this.columnRender(bounds);
      this.paint();
    });
    node.setRenderCallback(() => {
      this.view.sv.emit("alter sidebar column", this.layer.brush, this, yOffset);
      this.layer.brush.restore();
      this.layer.applyToBaseLayer(yOffset);
    });
    return node;
  }
  columnRender(_bounds) {}
  get channelView() {
    return this.view.channelView;
  }
  channelNodes(channels, width) {
    return channels.map(channel => {
      const {
        y,
        height
      } = channel.bounds;
      const child = {
        x: 0,
        y,
        width,
        height
      };
      const node = new RenderNode(this.nodeType, channel.reference, child);
      return this.alterWrapperNode(node, channel);
    });
  }
  clearCachedColumnWidthWithMargin() {
    this.cachedColumnWidthWithMargin = null;
  }
}
export { SidebarColumn as default };