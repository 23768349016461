import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import RRange from "../../includes/Range/RRange.js";
import { partitionArray } from "../../includes/misc/Utils.js";
import Annotation from "../Annotations/Cache/Annotation.js";
import EditHistory from "./EditHistory.js";
import { AnnotationType, EDITED_NUCLEOTIDE_QUALITY, EditingAnnotationTypes, GRAPH_GAP_VALUE, QualifierName } from "./EditingConstants.js";
import SequencesState from "./SequencesState.js";
import Range from "../../includes/Range/Range.js";
class EditingPlugin extends AbstractPlugin {
  history = new EditHistory();
  annotationTypes = new EditingAnnotationTypes();
  isDataFetchOnce;
  onlyGapsPattern = /^-+$/;
  sequencesState;
  constructor(sv) {
    super(sv);
    sv.editing = this;
    this.sv.bind("ready", () => this.sequencesState = new SequencesState(sv.channelView, sv.annotations));
    this.isDataFetchOnce = this.sv.options?.fetchDataOnce ?? false;
  }
  /**
   * Edits the specified sequence by replacing the specified range with new
   * residues. Use an empty string for `newResidues` to perform a deletion, or
   * an empty range to perform an insertion.
   *
   * @param sequenceIndex index of the sequence to edit
   * @param range range of residues to replace
   * @param newResidues new residues to insert
   * @returns the result containing the ranges of replaced and inserted residues
   */
  editSequence(sequenceIndex, range, newResidues) {
    if (!this.isDataFetchOnce) {
      throw new Error("Sequence Editing requires fetchDataOnce option to be on");
    }
    const edit = {
      sequenceIndex,
      range,
      newResidues
    };
    this.history.add(edit);
    return this.applyEdit(edit);
  }
  /**
   * Reverts the most recent edit, returning the sequence viewer to its former
   * state.
   *
   * @returns true if an edit was undone, or false if there was no edit to undo
   */
  undoEdit() {
    const latestEdit = this.history.getHistory().at(-1);
    const edits = this.history.undo();
    if (edits === null) {
      return false;
    }
    const storedState = this.sequencesState.getStoredState();
    const newState = edits.reduce((state, edit) => {
      const existingSequenceState = state[edit.sequenceIndex];
      if (!existingSequenceState) {
        throw new Error(`sequenceIndex ${edit.sequenceIndex} is not in sequence state map`);
      }
      const {
        sequenceState
      } = this.getEditedSequenceState(existingSequenceState, edit);
      state[edit.sequenceIndex] = sequenceState;
      return state;
    }, storedState);
    this.sequencesState.applyState(newState);
    this.finalizeEdit(latestEdit);
    return true;
  }
  /**
   * Re-applies an edit that was previously undone with {@link undo}.
   *
   * @returns the edit result, or null if there was no edit to redo
   */
  redoEdit() {
    const edit = this.history.redo();
    if (edit === null) {
      return null;
    }
    return this.applyEdit(edit);
  }
  /**
   * @returns true if there is an edit in history to undo
   */
  canUndo() {
    return this.history.canUndo();
  }
  /**
   * @returns true if there is an edit in history to redo
   */
  canRedo() {
    return this.history.canRedo();
  }
  /**
   * Returns all applied edits. Does not include unapplied redo edits.
   * @returns an array of applied sequence edits
   */
  getHistory() {
    return this.history.getHistory();
  }
  /**
   * Clears all edits from history.
   */
  clearHistory() {
    this.history.clear();
  }
  /**
   * Clears edit history and resets the sequence viewer back to its original
   * state.
   */
  resetEdits() {
    this.clearHistory();
    this.sequencesState.applyStoredState();
    this.finalizeEdit();
    this.sv.selection?.clear();
  }
  /**
   * Returns the current state of all sequences that have been edited. This does
   * not include sequences that have had all of their edits undone. The returned
   * data includes additional details like sequence name and type, and is
   * designed to provide information that external consumers need to send edited
   * sequences to their backend.
   *
   * @returns a map containing extended data on all edited sequences
   */
  getEditedSequenceData() {
    const editedSequenceIndices = new Set(this.getHistory().map(edit => edit.sequenceIndex));
    const data = {};
    for (const index of editedSequenceIndices) {
      data[index] = this.sequencesState.getCurrentSequenceStateExtendedData(index);
    }
    return data;
  }
  /**
   * Applies an edit that reverts the edit that is recorded in the selected
   * editing history annotation. This is not the same as undo - it is only
   * guaranteed to revert the sequence residues to their previous state, but it
   * can be destructive to annotations. However, it's useful for reverting
   * changes that were made in a previous session.
   *
   * @returns the sequence edit result, or null if the number of selected
   *    editing history annotations is more or less than one.
   */
  revertSelectedEditingHistoryAnnotation() {
    if (!this.sv.selection || !this.sv.annotations) {
      return null;
    }
    const selection = this.sv.selection.current[0];
    if (!selection) {
      return null;
    }
    const sequenceWrapper = selection.wrappers[0];
    if (sequenceWrapper?.type !== "sequence") {
      return null;
    }
    const sequenceIndex = sequenceWrapper.originalIndex;
    const selectedAnnotations = this.sv.channelView.sequences[sequenceIndex].annotationsCache?.getInRange(selection.range).filter(annotation => this.annotationTypes.isEditingHistoryAnnotation(annotation));
    if (selectedAnnotations?.length !== 1) {
      return null;
    }
    const {
      range,
      newResidues
    } = this.getRevertEditForEditingHistoryAnnotation(selectedAnnotations[0], sequenceIndex);
    return this.editSequence(sequenceIndex, range, newResidues);
  }
  serialize() {
    return void 0;
  }
  /**
   * Applies the edit to the sequence viewer, and marks the view as dirty.
   * Sequence state is stored before the edit is applied.
   *
   * @param edit the edit to apply
   * @returns the result containing removed and inserted ranges
   */
  applyEdit(edit) {
    const {
      sequenceIndex
    } = edit;
    this.sequencesState.storeSequenceState(sequenceIndex);
    const {
      result,
      sequenceState
    } = this.getEditedSequenceState(this.sequencesState.getCurrentSequenceState(sequenceIndex), edit);
    this.sequencesState.applySequenceState(sequenceIndex, sequenceState);
    this.finalizeEdit({
      sequenceIndex,
      range: result.newRange
    });
    return result;
  }
  /**
   * A mapping function that applies an edit to an object representing a
   * sequence's state, and returns the resulting edited state.
   *
   * @param sequenceState the sequence state
   * @param edit the edit to apply to the sequence state
   * @returns the sequence state after applying the edit
   */
  getEditedSequenceState(sequenceState, edit) {
    if (edit.sequenceIndex !== sequenceState.sequence.index) {
      throw new Error(`The edit targets sequenceIndex ${edit.sequenceIndex}, but the sequence has index ${sequenceState.sequence.index}`);
    }
    const editType = this.getEditType(edit);
    if (editType === null) {
      return {
        sequenceState,
        result: {
          newRange: edit.range,
          oldRange: edit.range
        }
      };
    }
    const newSequence = {
      ...sequenceState.sequence,
      sequence: this.replaceEditRange(sequenceState.sequence.sequence, edit.range, edit.newResidues)
    };
    if (sequenceState.sequence.qualities || sequenceState.sequence.chromatogram) {
      const graph = this.getEditedGraph(sequenceState.sequence, edit);
      if (graph.qualities) {
        newSequence.qualities = graph.qualities;
      }
      if (graph.chromatogram) {
        newSequence.chromatogram = graph.chromatogram;
      }
    }
    const newSequenceState = {
      sequence: newSequence
    };
    if (sequenceState.annotations) {
      newSequenceState.annotations = this.getEditedAnnotations(sequenceState.annotations, edit, sequenceState.sequence.sequence);
    }
    if (sequenceState.annotationTracks) {
      const replacedResidues = sequenceState.sequence.sequence.slice(edit.range.start, edit.range.end);
      newSequenceState.annotationTracks = sequenceState.annotationTracks.map(track => ({
        ...track,
        annotations: track.annotations.map(annotation => this.getEditedAnnotation(annotation, edit, replacedResidues)).filter(annotation => annotation !== null)
      }));
    }
    return {
      sequenceState: newSequenceState,
      result: {
        newRange: this.getNewRangeForEdit(edit),
        oldRange: edit.range
      }
    };
  }
  /**
   * Returns the range that covers the new residues after the provided edit is
   * applied.
   *
   * @param edit the edit
   * @returns the new range
   */
  getNewRangeForEdit(edit) {
    return new RRange(edit.range.start, edit.range.start + edit.newResidues.length);
  }
  /**
   * Returns the type for the edit (deletion, insertion, or replacement).
   * Returns null if the edit is empty (no residues to delete or insert).
   *
   * @param edit the edit
   * @returns the edit type, or null
   */
  getEditType(edit) {
    if (edit.range.length === 0) {
      if (edit.newResidues.length === 0) {
        return null;
      }
      return AnnotationType.EditingHistoryInsertion;
    }
    if (edit.newResidues.length === 0) {
      return AnnotationType.EditingHistoryDeletion;
    }
    return AnnotationType.EditingHistoryReplacement;
  }
  /**
   * A mapping function that calculates the effect of an edit on a sequence's
   * graph properties (chromatogram and quality data).
   *
   * @param graph the sequence's graph properties
   * @param edit the sequence edit
   * @returns the edited graph properties
   */
  getEditedGraph({
    chromatogram,
    qualities
  }, edit) {
    const newGraph = {};
    const validNeighborIndices = this.getNeighboringNonGapIndices(edit.range, chromatogram?.baseCallPositions, qualities);
    if (qualities) {
      newGraph.qualities = this.replaceEditRange(qualities, edit.range, this.getQualitiesToInsert(qualities, edit, validNeighborIndices));
    }
    if (chromatogram) {
      const newPositions = this.replaceEditRange(chromatogram.baseCallPositions, edit.range, this.getPositionsToInsert(chromatogram, edit, validNeighborIndices));
      newGraph.chromatogram = {
        ...chromatogram,
        baseCallPositions: newPositions
      };
    }
    return newGraph;
  }
  /**
   * Finds the nearest non-gap indices in the positions and qualities arrays
   * relative to an edit range. Assumes that at least one of positions &
   * qualities is defined.
   *
   * @param editRange the range covering residues to remove or replace
   * @param positions array of baseCallPositions
   * @param qualities array of qualities
   * @returns the next and previous indices that have non-gap values. If no
   *    valid values were found on a given side, the corresponding property
   *    will be set to -1.
   */
  getNeighboringNonGapIndices(editRange, positions, qualities) {
    const arraysWithValues = [positions, qualities].filter(arr => !!arr);
    const originalLength = arraysWithValues[0].length;
    let previous = -1;
    for (let index = editRange.start - 1; index >= 0; index--) {
      if (arraysWithValues.every(arr => index in arr && arr[index] !== GRAPH_GAP_VALUE)) {
        previous = index;
        break;
      }
    }
    let next = -1;
    for (let index = editRange.end; index < originalLength; index++) {
      if (arraysWithValues.every(arr => index in arr && arr[index] !== GRAPH_GAP_VALUE)) {
        next = index;
        break;
      }
    }
    return {
      previous,
      next
    };
  }
  /**
   * Generates base call positions for a replaced section of graph. The returned
   * array of positions are evenly spread between neighboring values - unless the
   * replacement is the same size as the original, in which case the position values
   * are preserved.
   *
   * @param chromatogram the chromatogram data
   * @param edit the sequence edit
   * @param validNeighborIndices indices of nearest valid positions that neighbor the edit range
   * @returns an array of base call positions with the same length as the new residues
   * @example
   * ```ts
   *  const originalSequence = 'AGGA';
   *  // resulting sequence is 'ATTTTA'
   *  const edit = { range: new RRange(1, 3), newResidues: 'TTTT' };
   *  // returns 4 positions spread between neighbor positions 5 & 30:
   *  const positionsToInsert = this.getPositionsToInsert(
   *    { baseCallPositions: [5, 14, 26, 30], ...traces },
   *    edit,
   *    { previous: 0, next: 3 }
   *  );
   *  // positionsToInsert: [10, 15, 20, 25]
   *  // newPositions:   [5, 10, 15, 20, 25, 30]
   * ```
   */
  getPositionsToInsert(chromatogram, edit, validNeighborIndices) {
    const numBases = edit.newResidues.length;
    if (numBases === 0) {
      return [];
    }
    if (numBases === edit.range.length) {
      return edit.range.map((positionIndex, rangeIndex) => edit.newResidues[rangeIndex] === "-" ? GRAPH_GAP_VALUE : chromatogram.baseCallPositions[positionIndex]);
    }
    const neighboringPositions = this.getValidNeighboringPositionValues(chromatogram, validNeighborIndices);
    if (numBases === 1) {
      if (edit.newResidues === "-") {
        return [GRAPH_GAP_VALUE];
      }
      return [(neighboringPositions.previous + neighboringPositions.next) / 2];
    }
    const numTraces = neighboringPositions.next - neighboringPositions.previous;
    const tracesPerBase = numTraces / (numBases + 1);
    return [...edit.newResidues].map((base, i) => {
      if (base === "-") {
        return GRAPH_GAP_VALUE;
      }
      return neighboringPositions.previous + Math.round((i + 1) * tracesPerBase);
    });
  }
  /**
   * Gets the position values at the specified valid neighboring indices. If
   * there is no valid previous neighbor, the previous position will be set to
   * zero (the index of the first trace). If there is no valid next neighbor,
   * the next position value will be set to the index of the last trace.
   *
   * @param chromatogram chromatogram data
   * @param neighborIndices indices of neighboring non-gap values
   * @returns the position values of neighbors
   */
  getValidNeighboringPositionValues(chromatogram, neighborIndices) {
    const positions = chromatogram.baseCallPositions;
    const previous = neighborIndices.previous === -1 ? 0 : positions[neighborIndices.previous];
    if (neighborIndices.next === -1) {
      const graphLength = Math.max(chromatogram.a?.length ?? 0, chromatogram.c?.length ?? 0, chromatogram.g?.length ?? 0, chromatogram.t?.length ?? 0);
      return {
        previous,
        next: graphLength - 1
      };
    }
    return {
      previous,
      next: positions[neighborIndices.next]
    };
  }
  /**
   * Generates quality values for a replaced section of graph.
   *
   * @param qualities existing quality values
   * @param edit the sequence edit
   * @param validNeighborIndices indices of neighboring non-gap values
   * @returns the quality values to insert
   */
  getQualitiesToInsert(qualities, edit, validNeighborIndices) {
    if (edit.newResidues.length === 0) {
      return [];
    }
    let newQualityValue = EDITED_NUCLEOTIDE_QUALITY;
    if (this.onlyGapsPattern.test(edit.newResidues)) {
      let previousQuality = qualities[validNeighborIndices.previous] ?? 0;
      let nextQuality = qualities[validNeighborIndices.next] ?? 0;
      if (previousQuality <= 0) {
        previousQuality = nextQuality;
      }
      if (nextQuality <= 0) {
        nextQuality = previousQuality;
      }
      newQualityValue = (previousQuality + nextQuality) / 2;
    }
    return Array.from({
      length: edit.newResidues.length
    }, () => newQualityValue);
  }
  /**
   * A mapping function that calculates the effect of an edit on a sequence's
   * annotations. An edit history annotation will also be added (and merged with
   * existing edit history annotations), so this method shouldn't be used for
   * annotation tracks.
   *
   * @param annotations the annotations to apply the edit to
   * @param edit the edit to apply
   * @param uneditedResidues the sequence prior to applying the edit
   * @returns the edited annotations, minus any that were removed by the edit
   */
  getEditedAnnotations(annotations, edit, uneditedResidues) {
    const replacedResidues = uneditedResidues.slice(edit.range.start, edit.range.end);
    const [overlappingHistoryAnnotations, otherAnnotations] = partitionArray(annotations, annotation => this.annotationTypes.isEditingHistoryAnnotation(annotation) && annotation.intervals.some(existingInterval => existingInterval.range.overlaps(edit.range)));
    const editedAnnotations = otherAnnotations.map(annotation => this.getEditedAnnotation(annotation, edit, replacedResidues)).filter(annotation => annotation !== null);
    let editAnnotationData;
    if (overlappingHistoryAnnotations.length === 0) {
      editAnnotationData = this.getEditingHistoryAnnotationData(edit, replacedResidues, editedAnnotations.length);
    } else {
      const {
        mergedEdit,
        originalBases
      } = this.mergeEditWithEditHistoryAnnotations(overlappingHistoryAnnotations, uneditedResidues, edit);
      editAnnotationData = this.getEditingHistoryAnnotationData(mergedEdit, originalBases, editedAnnotations.length);
    }
    if (editAnnotationData === null) {
      return editedAnnotations;
    }
    const channel = this.sv.channelView.sequences[edit.sequenceIndex].annotationsChannel;
    const editAnnotation = new Annotation(channel, editAnnotationData);
    return [...editedAnnotations, editAnnotation];
  }
  /**
   * Constructs AnnotationData for an editing history annotation to record the
   * provided edit.
   *
   * @param edit the edit
   * @param originalBases the original bases that the edit replaced
   * @param annotationNumber the number of existing annotations in the channel.
   *    Used to create the unique annotation ID.
   * @returns the annotation data, or null if the edit is a no-op.
   */
  getEditingHistoryAnnotationData(edit, originalBases, annotationNumber) {
    const editType = this.getEditType(edit);
    if (editType == null || originalBases === edit.newResidues) {
      return null;
    }
    const isDeletion = editType === AnnotationType.EditingHistoryDeletion;
    const interval = {
      min: edit.range.start + 1,
      max: edit.range.start + edit.newResidues.length,
      truncatedMin: isDeletion,
      truncatedMax: isDeletion,
      direction: 0
    };
    return {
      id: `${edit.sequenceIndex}-${annotationNumber}`,
      intervals: [interval],
      name: originalBases,
      type: editType,
      qualifiers: [{
        name: QualifierName.OriginalBases,
        value: originalBases
      }, this.getEditingHistoryQualifier(editType, originalBases, edit.newResidues)]
    };
  }
  /**
   * Builds a qualifier that stores a description of an edit.
   *
   * @param editType the edit type
   * @param oldResidues the residues that were removed by the edit
   * @param newResidues the residues that were inserted by the edit
   * @returns the editing history qualifier
   */
  getEditingHistoryQualifier(editType, oldResidues, newResidues) {
    const name = QualifierName.EditingHistory;
    if (editType === AnnotationType.EditingHistoryReplacement) {
      return {
        name,
        value: `Replaced ${oldResidues} with ${newResidues}`
      };
    }
    if (editType === AnnotationType.EditingHistoryDeletion) {
      return {
        name,
        value: `Deleted ${oldResidues}`
      };
    }
    if (editType === AnnotationType.EditingHistoryInsertion) {
      return {
        name,
        value: `Inserted ${newResidues}`
      };
    }
    throw new Error("Unexpected edit type: " + editType);
  }
  /**
   * Constructs a sequence edit that combines the effect of all of the edits
   * recorded in `existingAnnotations`, plus the new edit.
   *
   * @param existingAnnotations existing editing history annotations. None of
   *    these annotations should overlap with each other, but they should all
   *    overlap with the edit range.
   * @param uneditedResidues the residues prior to applying the new edit
   * @param edit the new edit
   * @returns a sequence edit that combines the effect of all of the edits
   *    recorded in `existingAnnotations`, plus the new edit.
   */
  mergeEditWithEditHistoryAnnotations(existingAnnotations, uneditedResidues, edit) {
    const annotations = [...existingAnnotations].sort((a, b) => b.range.start - a.range.start);
    const initialState = {
      mergedEdit: {
        ...edit
      },
      /** The residues as they were before mergedEdit was applied */
      residues: uneditedResidues
    };
    const {
      mergedEdit,
      residues
    } = annotations.reduce((acc, annotation) => {
      const revertAnnotationEdit = this.getRevertEditForEditingHistoryAnnotation(annotation, edit.sequenceIndex);
      const preEditResidues = this.replaceEditRange(acc.residues, revertAnnotationEdit.range, revertAnnotationEdit.newResidues);
      const originalAnnotationEdit = this.getInverseEdit(revertAnnotationEdit, acc.residues);
      const mergedEdit2 = this.mergeEdits(preEditResidues, originalAnnotationEdit, acc.mergedEdit);
      return {
        mergedEdit: mergedEdit2,
        residues: preEditResidues
      };
    }, initialState);
    const originalBases = residues.slice(mergedEdit.range.start, mergedEdit.range.end);
    return {
      mergedEdit,
      originalBases
    };
  }
  /**
   * Returns an edit that does the opposite to the provided edit. If applied,
   * the inverse edit would revert the changes that the provided edit made to
   * the residues.
   *
   * @param edit the provided edit
   * @param uneditedResidues the residues as they were before applying `edit`.
   * @returns the inverse edit
   */
  getInverseEdit(edit, uneditedResidues) {
    const range = this.getNewRangeForEdit(edit);
    return {
      range,
      newResidues: uneditedResidues.slice(edit.range.start, edit.range.end),
      sequenceIndex: edit.sequenceIndex
    };
  }
  /**
   * Merges two subsequent overlapping edits into one.
   *
   * @param sequence the residues, prior to any edit1 or edit2 being applied
   * @param edit1 the first edit to apply to `sequence`
   * @param edit2 the second edit to apply to `sequence`. This edit must overlap
   *    with the result range of `edit1`.
   * @returns an edit that has the same effect as first applying `edit1` to
   *    `sequence`, and then applying `edit2` to the result.
   */
  mergeEdits(sequence, edit1, edit2) {
    const edit1LengthDiff = edit1.newResidues.length - edit1.range.length;
    const edit2LengthDiff = edit2.newResidues.length - edit2.range.length;
    const edit1ResultRange = edit1.range.grow(0, edit1LengthDiff);
    if (!edit1ResultRange.overlaps(edit2.range)) {
      throw new Error("Ranges must overlap, received " + edit1ResultRange + " and " + edit2.range);
    }
    const startShiftLeft = Math.max(0, edit1ResultRange.start - edit2.range.start);
    const endShiftRight = Math.max(0, edit2.range.end - edit1ResultRange.end);
    const mergedEditRange = edit1.range.grow(startShiftLeft, endShiftRight);
    const sequenceAfterEdit1 = this.replaceEditRange(sequence, edit1.range, edit1.newResidues);
    const sequenceAfterEdit2 = this.replaceEditRange(sequenceAfterEdit1, edit2.range, edit2.newResidues);
    const mergedEditResultRange = mergedEditRange.grow(0, edit1LengthDiff + edit2LengthDiff);
    const newResidues = sequenceAfterEdit2.slice(mergedEditRange.start, mergedEditResultRange.end);
    return {
      range: mergedEditRange,
      newResidues,
      sequenceIndex: edit1.sequenceIndex
    };
  }
  /**
   * Generates an edit that has the effect of reverting the edit stored in the
   * provided editing history annotation. Note that applying the returned edit
   * will only revert the residues to their former state. Other data (like
   * annotations) may be lost.
   *
   * @param annotation the editing history annotation
   * @param sequenceIndex the index of the sequence that the annotation is on
   * @returns a sequence edit
   */
  getRevertEditForEditingHistoryAnnotation(annotation, sequenceIndex) {
    if (!this.annotationTypes.isEditingHistoryAnnotation(annotation)) {
      throw new Error("Provided annotation is not an Editing History annotation: " + annotation.getData());
    }
    const range = annotation.intervals[0]?.range;
    if (!range) {
      return {
        sequenceIndex,
        newResidues: "",
        range: RRange.empty
      };
    }
    const type = annotation.normalType;
    if (type === AnnotationType.EditingHistoryInsertion.toLowerCase()) {
      return {
        sequenceIndex,
        newResidues: "",
        range
      };
    }
    const originalBases = annotation.qualifiers.find(({
      name
    }) => name === QualifierName.OriginalBases)?.value;
    if (originalBases == null) {
      throw new Error("Editing History annotation is missing Original Bases qualifier: " + annotation.getData());
    }
    if (type === AnnotationType.EditingHistoryDeletion.toLowerCase()) {
      return {
        sequenceIndex,
        newResidues: originalBases,
        range: new RRange(range.start, range.start)
      };
    }
    if (type === AnnotationType.EditingHistoryReplacement.toLowerCase()) {
      return {
        sequenceIndex,
        newResidues: originalBases,
        range
      };
    }
    throw new Error("Unhandled Editing History annotation type: " + type);
  }
  /**
   * A mapping function that calculates the effect of an edit on an annotation.
   *
   * @param annotation the annotation to edit
   * @param edit the edit to apply
   * @param replacedResidues the replaced residues
   * @returns a new annotation with the edit applied, or null if the annotation
   *    should be removed as a result of the edit
   */
  getEditedAnnotation(annotation, edit, replacedResidues) {
    const editOverlapsInterval = annotation.intervals.some(interval => interval.range.overlaps(edit.range));
    if (editOverlapsInterval && this.annotationTypes.removeWhenEdited(annotation)) {
      return null;
    }
    const lengthDifference = edit.newResidues.length - replacedResidues.length;
    const intervals = annotation.intervals.map(interval => this.getEditedInterval(interval, edit.range, lengthDifference)).filter(interval => interval !== null);
    if (intervals.length === 0) {
      return null;
    }
    const qualifiers = this.getEditedQualifiers(annotation, edit, editOverlapsInterval);
    return new Annotation(annotation.channel, {
      id: annotation.id,
      intervals: intervals.map(i => i.getData()),
      qualifiers,
      name: annotation.name,
      type: annotation.rawType
    });
  }
  /**
   * A mapping function that calculates the effect of an edit on an annotation
   * interval.
   *
   * @param interval the interval to edit
   * @param editRange the range that is replaced by the edit
   * @param lengthDifference the difference in sequence length after the edit
   *    is applied
   * @returns a new interval with the edit applied, or null if the interval
   *    should be removed as a result of the edit
   */
  getEditedInterval(interval, editRange, lengthDifference) {
    if (interval.range.end <= editRange.start) {
      return interval;
    }
    if (editRange.end <= interval.range.start) {
      return interval.shift(lengthDifference);
    }
    if (editRange.contains(interval.range)) {
      return null;
    }
    if (interval.range.start < editRange.start && interval.range.end > editRange.end) {
      return interval.grow(lengthDifference, true);
    }
    if (interval.range.start >= editRange.start) {
      return interval.truncateLeft(editRange.end).shift(lengthDifference);
    }
    return interval.truncateRight(editRange.start);
  }
  /**
   * A mapping function that calculates the effect of an edit on an annotation's
   * qualifiers.
   *
   * @param annotation the parent annotation
   * @param edit the sequence edit
   * @param editOverlapsInterval whether the edit range overlaps any of the
   *    annotation's intervals
   * @returns the edited qualifiers
   */
  getEditedQualifiers(annotation, edit, editOverlapsInterval) {
    const qualifiers = annotation.qualifiers;
    if (!editOverlapsInterval) {
      return [...qualifiers];
    }
    const newQualifiers = qualifiers.map(qualifier => {
      if (qualifier.name === QualifierName.Translation) {
        return {
          name: QualifierName.OriginalTranslation,
          value: qualifier.value
        };
      }
      return qualifier;
    });
    if (!this.annotationTypes.checkCodonStart(annotation)) {
      return newQualifiers;
    }
    const numBasesDeleted = this.numBasesDeletedFromIntervalStart(annotation.intervals[0], edit.range);
    if (numBasesDeleted <= 0) {
      return newQualifiers;
    }
    const codonStartIndex = qualifiers.findIndex(qualifier => qualifier.name === QualifierName.CodonStart);
    let codonStart = parseInt(qualifiers[codonStartIndex]?.value);
    if (isNaN(codonStart) || codonStart < 1 || codonStart > 3) {
      codonStart = 1;
    }
    const newCodonStart = this.getAdjustedCodonStart(codonStart, numBasesDeleted);
    if (newCodonStart !== codonStart) {
      const codonStartQualifier = {
        name: QualifierName.CodonStart,
        value: newCodonStart.toString()
      };
      if (codonStartIndex === -1) {
        newQualifiers.push(codonStartQualifier);
      } else {
        newQualifiers[codonStartIndex] = codonStartQualifier;
      }
    }
    return newQualifiers;
  }
  numBasesDeletedFromIntervalStart(firstInterval, editRange) {
    const firstIntervalStart = firstInterval.isReversed ? firstInterval.right : firstInterval.left;
    if (editRange.includes(firstIntervalStart)) {
      return editRange.intersection(firstInterval.range).length;
    }
    return 0;
  }
  /**
   * Calculates the new codon start given the number of bases that were deleted.
   *
   * @param codonStart 1-indexed codon_start value. Either 1, 2, or 3.
   * @param numBasesDeleted number of bases deleted
   * @return adjusted codon_start value
   */
  getAdjustedCodonStart(codonStart, numBasesDeleted) {
    const zeroIndexedCodonStart = codonStart - 1;
    const deletionShift = numBasesDeleted % 3;
    const shiftedCodonStart = zeroIndexedCodonStart - deletionShift + 3;
    return shiftedCodonStart % 3 + 1;
  }
  /**
   * Replaces the edit range in the input string or array with the new content.
   *
   * @param input the original string or array
   * @param editRange the range to replace. Can be empty for an insertion.
   * @param replacement the string or array to insert. Can be empty for a deletion.
   * @returns a new string or array with the edit range replaced with the new content
   */
  replaceEditRange(input, editRange, replacement) {
    return input.slice(0, editRange.start).concat(replacement, input.slice(editRange.end));
  }
  /**
   * Performs costly recalculation tasks, notifies Sequence Viewer that the
   * view is dirty, and sets the selection to new range.
   * To be called after the plugin has finished altering sequence state.
   *
   * @param newSelection optional selection to set on the sequence viewer
   */
  finalizeEdit(newSelection) {
    this.sv.view.channelView.recalculateLength();
    this.sv.view.dirty = "edit";
    if (newSelection && this.sv.selection) {
      const sequenceWrapper = this.sv.channelView.sequences[newSelection.sequenceIndex];
      this.sv.selection.setSelection(Range.fromData(newSelection.range, sequenceWrapper.sequence.length), sequenceWrapper);
    }
  }
}
export { EditingPlugin as default };