import { createAction, props } from '@ngrx/store';
import { NGSSequenceData } from './index';

export const fetchSequences = createAction(
  '[NGS] Fetch Sequences',
  props<{ documentID: string; ids: number[] }>(),
);
export const fetchSequencesSuccess = createAction(
  '[NGS] Fetch Sequences success',
  props<{ documentID: string; sequences: NGSSequenceData[] }>(),
);
export const fetchSequencesFailure = createAction(
  '[NGS] Fetch Sequences failure',
  props<{ documentID: string }>(),
);
export const clearNGSState = createAction('[NGS] Clear State');
