import SequenceWrapper from "../../includes/Channels/SequenceWrapper.js";
class SSelection {
  constructor(range, wrappers) {
    this.range = range;
    this.wrappers = wrappers;
    if (wrappers.length < 1) {
      throw new Error("SSelection must include at least one sequence.");
    }
  }
  // noinspection JSUnusedGlobalSymbols
  get indexes() {
    return this.wrappers.map(wrapper => wrapper.currentIndex);
  }
  get residues() {
    return this.sequenceCaches.map(cache => cache.getRange(this.range));
  }
  toString() {
    return this.residues.map(sequence => sequence.join("")).join("\n");
  }
  get length() {
    return this.range.length * this.wrappers.length;
  }
  requestData() {
    this.sequenceCaches.forEach(cache => cache.request(this.range));
    this.wrappers.forEach(wrapper => {
      if (wrapper instanceof SequenceWrapper) {
        wrapper.sendRequests();
      }
    });
  }
  checkCaches() {
    return this.sequenceCaches.every(cache => this.range.every(index => {
      if (index < cache.maxIndex()) {
        return cache.hasData(index);
      }
      return true;
    }));
  }
  get sequenceCaches() {
    return this.wrappers.map(wrapper => wrapper.sequenceCache).filter(cache => !!cache);
  }
  serialize() {
    const wrapperChannel = this.wrappers[0];
    if (wrapperChannel instanceof SequenceWrapper) {
      return {
        positionStart: this.range.start,
        positionEnd: this.range.end,
        sequenceStart: wrapperChannel.originalIndex,
        sequenceEnd: wrapperChannel.originalIndex + 1
      };
    }
  }
}
export { SSelection as default };