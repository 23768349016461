import { sum } from "../../../includes/misc/Math.js";
import RangeCollection from "../../../includes/Range/RangeCollection.js";
import { notEmpty } from "../../../includes/misc/Utils.js";
class AnnotationRow {
  constructor(group) {
    this.group = group;
  }
  ranges = new RangeCollection();
  get annotations() {
    return this.ranges.values.filter(notEmpty);
  }
  canFit(annotation) {
    return this.ranges.fits(annotation.range);
  }
  insert(annotation) {
    this.ranges.insertIfFits(annotation.range, annotation);
  }
  annotationsIn(range) {
    return this.annotations.filter(notEmpty).filter(annotation => annotation?.overlaps(range));
  }
  annotationStartingAt(index) {
    return this.annotations.find(annotation => annotation?.start === index);
  }
  get intervalCount() {
    return this.intervalRanges.length;
  }
  get coveredLength() {
    const lengths = this.intervalRanges.map(range => range.length);
    return sum(lengths);
  }
  get index() {
    return this.group.rows.indexOf(this);
  }
  get intervalRanges() {
    return this.annotations.reduce((ranges, annotation) => ranges.concat(...annotation.intervalRanges), []);
  }
  canMerge(other) {
    return other.annotations.every(annotation => this.canFit(annotation));
  }
  merge(other) {
    const copy = this.copy();
    other.annotations.forEach(annotation => copy.insert(annotation));
    return copy;
  }
  copy() {
    const copy = new AnnotationRow(this.group);
    copy.setAnnotations(this.annotations);
    return copy;
  }
  setAnnotations(annotations) {
    annotations.forEach(annotation => this.insert(annotation));
  }
}
export { AnnotationRow as default };