import WrapperChannel from "./WrapperChannel.js";
import RulerChannel from "../../plugins/Ruler/RulerChannel.js";
import { bottomAlignVertically, centerVertically } from "../misc/Utils.js";
class GlobalsWrapper extends WrapperChannel {
  rulerChannel;
  consensusChannel;
  translationsChannel;
  annotationsChannel;
  identityChannel;
  wuKabatChannel;
  _isSticky = true;
  constructor(view) {
    super(view);
    const registry = view.sv.channelClasses;
    const type = view.sv.options?.sequenceType;
    const sequences = view.sequences.map(wrapper => wrapper.sequenceChannel);
    const rulerChannel = registry.get("ruler");
    if (rulerChannel) {
      this.rulerChannel = new rulerChannel(this, true);
    }
    const consensusChannel = registry.get("consensus");
    if (consensusChannel) {
      this.consensusChannel = new consensusChannel(this, type, sequences);
    }
    const translationsChannel = registry.get("translations");
    if (translationsChannel) {
      this.translationsChannel = new translationsChannel(this);
    }
    const consensusCallback = this.sv.consensus && this.sv.consensus.dataCallback;
    if (consensusCallback) {
      const annotationsChannel = registry.get("annotations");
      if (annotationsChannel) {
        this.annotationsChannel = new annotationsChannel(this, consensusCallback);
      }
    }
    const identityChannel = registry.get("identity");
    if (identityChannel) {
      this.identityChannel = new identityChannel(this, type, sequences);
    }
    const wuKabatChannel = registry.get("wuKabat");
    if (wuKabatChannel) {
      this.wuKabatChannel = new wuKabatChannel(this, type, sequences);
    }
    this.sv.bind("idle", () => this.sv.emit("data request", this));
  }
  get height() {
    return this.sv.invoke("alter globals wrapper height").reduce((prev, curr) => Math.max(prev, curr), this.childrenHeight);
  }
  get type() {
    return "globals wrapper";
  }
  get isSticky() {
    return this._isSticky;
  }
  set isSticky(isSticky) {
    this._isSticky = isSticky;
    this.sv.view.dirty = "globals isSticky changed";
  }
  get qualitiesCache() {
    return null;
  }
  useQuality() {
    return false;
  }
  get children() {
    const children = [];
    if (this.rulerChannel) {
      children.push(this.rulerChannel);
    }
    if (this.consensusChannel) {
      children.push(this.consensusChannel);
    }
    if (this.consensusChannel && this.translationsChannel) {
      children.push(this.translationsChannel);
    }
    if (this.annotationsChannel) {
      children.push(this.annotationsChannel);
    }
    if (this.identityChannel) {
      children.push(this.identityChannel);
    }
    if (this.wuKabatChannel) {
      children.push(this.wuKabatChannel);
    }
    return children;
  }
  get sequence() {
    throw new Error("Getting the whole sequence for a consensus sequence is not implemented yet");
  }
  get rangesCache() {
    return this.consensusChannel?.rangesCache;
  }
  get sequenceCache() {
    return this.consensusChannel?.cache;
  }
  get annotationsCache() {
    return this.annotationsChannel?.cache;
  }
  get sequenceChannel() {
    if (this.consensusEnabled) {
      return this.consensusChannel;
    }
  }
  get consensusEnabled() {
    const consensus = this.view.sv.consensus;
    return !!consensus?.enabled;
  }
  get minHeight() {
    if (this.sv.view.channelView.viewport.sidebarColumns.some(column => column.nodeType === "metadata")) {
      return 60;
    } else {
      return this._minHeight;
    }
  }
  get margin() {
    return this.rulerIsOnlyChild ? 1 : 12;
  }
  get rulerIsOnlyChild() {
    const children = this.visibleChildren;
    return children.length === 1 && children[0] instanceof RulerChannel;
  }
  innerVerticalAlignment(height, bounds) {
    return this.rulerIsOnlyChild || this.childrenHeight < this.height ? bottomAlignVertically(height, bounds) : centerVertically(height, bounds);
  }
  get length() {
    return this.view.length;
  }
}
export { GlobalsWrapper as default };