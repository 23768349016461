class GraphicsTransformer {
  constructor(sv) {
    this.sv = sv;
  }
  get scrollX() {
    return this.sv.offset.x;
  }
  /**
   * Since SV works in relative position & translate everytime before painting, we need to translate absolute
   * position in circular view to take the translation into account
   * @param points the points that needed mapping
   * @param brush the brush containing current transformation
   * @private
   */
  mapPointsToAbsolute(brush, points) {
    return points.map(point => this.mapPointToAbsolute(brush, point));
  }
  /**
   * Since SV works in relative position & translate everytime before painting, we need to translate absolute
   * position in circular view to take the translation into account
   * @param point the point that needed mapping
   * @param brush the brush containing current transformation
   * @private
   */
  mapPointToAbsolute(brush, point) {
    const {
      e: translateX,
      f: translatedY
    } = brush.getTransform();
    return {
      x: point.x + translateX / window.devicePixelRatio,
      y: point.y + translatedY / window.devicePixelRatio
    };
  }
  /**
   * Use setTransform to reset canvas transformation to the initial transformation (keeping the current scale factor).
   * Avoid using save() + resetTransformation() + restore() for better performance.
   * @param brush the brush to apply transformation reset
   * @param callback the action to perform after the transformation reset & before applying the original transformation
   */
  withInitialTransform(brush, callback) {
    const oldTransform = brush.getTransform();
    brush.setTransform(oldTransform.a, 0, 0, oldTransform.d, 0, 0);
    callback();
    brush.setTransform(oldTransform);
  }
  transformPoints(points) {
    return points.map(point => this.transformPoint(point));
  }
  get logicalWidth() {
    return this.sv.channelView.width;
  }
  get logicalHeight() {
    return this.sv.channelView.height;
  }
  get viewportWidth() {
    return this.sv.view.width;
  }
  get viewportHeight() {
    return this.sv.view.height;
  }
}
export { GraphicsTransformer };