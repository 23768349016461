import RRange from "./RRange.js";
import MultiRange from "./MultiRange.js";
class RangeCollection {
  // Items are sorted by range.
  // Ranges may touch, but not overlap.
  items = [];
  get length() {
    return this.items.length;
  }
  get values() {
    return this.items.map(a => a.value);
  }
  get debug() {
    return this.items.map(({
      range,
      value
    }) => {
      return [range.toString(), value];
    });
  }
  indexesOfOverlappingItems({
    start,
    end
  }) {
    const first = this.findIndex(({
      range
    }) => range.end > start);
    const last = this.findIndex(({
      range
    }) => range.start >= end);
    return new RRange(first, last);
  }
  // Insert items if they fit APIs and helpers:
  insertIfFits(range, value) {
    const fits = this.fits(range);
    if (fits) {
      this.insert({
        range,
        value
      });
    }
    return fits;
  }
  fits(range) {
    return !this.items.some(item => range.overlaps(item.range));
  }
  insert(candidate) {
    const index = this.findIndex(({
      range
    }) => range.start >= candidate.range.end);
    this.items.splice(index, 0, candidate);
  }
  // Insert and divide APIs and helpers:
  // TODO Move these to `SequenceLines` class or their own intermediary class. Perhaps `DivisibleRangeCollection`.
  insertMultipleAndReplace(ranges, value) {
    this.insertMultiple(ranges, value, () => value);
  }
  insertMultiple(ranges, value, merge) {
    ranges.forEach(range => this.insertAndDivide(range, value, merge));
  }
  insertAndDivide(range, value, merge) {
    const {
      start,
      end,
      length
    } = this.indexesOfOverlappingItems(range);
    const item = {
      range,
      value
    };
    const items = length === 0 ? [item] : this.newItems(item, start, end, merge);
    this.items.splice(start, length, ...items);
  }
  newItems(item, start, end, merge) {
    const overlaps = this.items.slice(start, end);
    const results = this.mergeOverlapsAndFillGaps(item, overlaps, merge);
    const before = this.precedingItem(item, overlaps[0]);
    if (before) {
      results.unshift(before);
    }
    const after = this.followingItem(item, overlaps[overlaps.length - 1]);
    if (after) {
      results.push(after);
    }
    return results;
  }
  mergeOverlapsAndFillGaps(item, overlaps, merge) {
    const mergedItems = overlaps.map(overlap => ({
      range: item.range.intersection(overlap.range),
      value: merge(overlap.value)
    }));
    const overlappingRanges = overlaps.map(({
      range
    }) => range);
    const {
      ranges
    } = new MultiRange(item.range.cut(overlappingRanges));
    const gaps = ranges.map(range => ({
      range,
      value: item.value
    }));
    return [...mergedItems, ...gaps].sort((a, b) => a.range.start - b.range.start);
  }
  precedingItem(item, first) {
    if (first) {
      const neu = item.range;
      const overlap = first.range;
      if (overlap.start < neu.start) {
        return {
          range: new RRange(overlap.start, neu.start),
          value: first.value
        };
      }
    }
  }
  followingItem(item, last) {
    if (last) {
      const neu = item.range;
      const overlap = last.range;
      if (neu.end < overlap.end) {
        return {
          range: new RRange(neu.end, overlap.end),
          value: last.value
        };
      }
    }
  }
  findIndex(predicate) {
    const index = this.items.findIndex(predicate);
    return index === -1 ? this.items.length : index;
  }
}
export { RangeCollection as default };