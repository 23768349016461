import MultiRange from "../../includes/Range/MultiRange.js";
import mappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import { indexRange, RNAtoDNA } from "../../includes/misc/Utils.js";
class DeemphasizedRanges {
  constructor(plugin, channel) {
    this.plugin = plugin;
    this.channel = channel;
  }
  complete = new MultiRange();
  deemphasized = new MultiRange();
  isDeemphasized(residue) {
    if (!this.complete.containsIndex(residue.index)) {
      this.calculateAndSet(residue);
    }
    return this.deemphasized.containsIndex(residue.index);
  }
  getInRange(query) {
    if (!this.channel.sequenceCache) {
      return new MultiRange();
    }
    query.forEach(index => {
      const incomplete = !this.complete.containsIndex(index);
      if (incomplete) {
        this.channel.sequenceCache.request(indexRange(index));
        const residue = this.channel.getResidue(index);
        if (residue) {
          this.calculateAndSet(residue);
        }
      }
    });
    return this.deemphasized.intersection(query);
  }
  calculateAndSet(residue) {
    const deemphasized = this.calculate(residue);
    if (deemphasized != null) {
      this.set(residue.index, deemphasized);
    }
  }
  set(index, deemphasized) {
    this.complete = this.complete.addIndex(index);
    if (deemphasized) {
      this.deemphasized = this.deemphasized.addIndex(index);
    }
  }
  calculate({
    index,
    letter,
    isTrimmed
  }) {
    if (letter === " ") {
      return false;
    } else if (isTrimmed) {
      return true;
    } else {
      const reference = this.referenceLetter(index);
      if (reference) {
        return this.match(reference, letter, this.channel.sequenceType);
      }
    }
  }
  match(reference, letter, sequenceType) {
    reference = RNAtoDNA(reference);
    letter = RNAtoDNA(letter);
    const same = reference === letter;
    switch (this.plugin.highlight) {
      case "disagreements":
        return same || this.matchesAmbiguity(reference, letter, sequenceType);
      case "allDisagreements":
        return same;
      case "agreements":
        return !same;
    }
  }
  matchesAmbiguity(reference, other, sequenceType) {
    const mapper = mappers[sequenceType];
    return mapper.residuesMatch(reference, other);
  }
  referenceLetter(index) {
    const cache = this.plugin.referenceCache;
    if (cache?.isSet(index)) {
      return cache?.get(index);
    }
  }
}
export { DeemphasizedRanges as default };