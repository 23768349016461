import RenderNode from "../RenderEngine/RenderNode.js";
import SidebarColumn from "./SidebarColumn.js";
class ResizableSidebarColumn extends SidebarColumn {
  constructor(view) {
    super(view);
    this.view = view;
    this.initializeEvents();
  }
  adjustingColumnWidth = false;
  handleWidth = 10;
  get width() {
    if (this.view.sv.isExportMode) {
      return this.columnWidth;
    }
    return this.columnWidth + this.handleWidth;
  }
  calculateLayout(bounds, channels, allowedWidth) {
    const node = new RenderNode(`${this.nodeType} group`, this, bounds, this.calculateChildren(bounds, channels));
    return this.setColumnRender(bounds, node, allowedWidth);
  }
  calculateChildren(bounds, channels) {
    const children = [];
    if (!this.view.sv.export || !this.view.sv.export.enabled) {
      children.push(this.renderResizeHandle(bounds.height));
    }
    const columnBounds = {
      x: 0,
      y: 0,
      width: this.columnWidth,
      height: bounds.height
    };
    children.push(new RenderNode(`${this.nodeType} column`, this, columnBounds, this.channelNodes(channels, this.columnWidth)));
    return children;
  }
  renderResizeHandle(height) {
    const bounds = {
      x: this.columnWidth,
      y: 0,
      width: this.handleWidth,
      height
    };
    const node = new RenderNode(`${this.nodeType} resize`, this, bounds);
    node.setRenderCallback(() => {
      const range = this.view.clipToViewport(node);
      if (range.isEmpty) {
        throw new Error("SideBar RenderNode must be in viewport.");
      }
      this.layer.brush.beginPath();
      this.layer.brush.rect(0, 0, bounds.width, bounds.height);
      this.layer.brush.fillStyle = "#fbfbfb";
      this.layer.brush.fill();
      this.layer.brush.strokeStyle = "rgba(0, 0, 0, 0.12)";
      this.layer.brush.stroke();
      this.layer.brush.beginPath();
      this.layer.brush.arc(bounds.width / 2, (range.start + range.end) / 2 - 6, 2, 0, 2 * Math.PI, false);
      this.layer.brush.arc(bounds.width / 2, (range.start + range.end) / 2, 2, 0, 2 * Math.PI, false);
      this.layer.brush.arc(bounds.width / 2, (range.start + range.end) / 2 + 6, 2, 0, 2 * Math.PI, false);
      this.layer.brush.fillStyle = "#969696";
      this.layer.brush.fill();
    });
    return node;
  }
  initializeEvents() {
    this.view.sv.bindToNode("mousedown", `${this.nodeType} resize`, () => this.adjustingColumnWidth = true);
    this.view.sv.bindToNode("mousemove", `${this.nodeType} resize`, () => document.body.style.cursor = "col-resize");
    this.view.sv.bindToNode("mouseleave", `${this.nodeType} resize`, () => document.body.style.cursor = "auto");
    this.view.sv.addEventListenerToDocument("mouseup", () => this.mouseUpOnDocument());
    this.view.sv.addEventListenerToDocument("mousemove", e => this.mouseMoveOnDocument(e));
  }
}
export { ResizableSidebarColumn as default };