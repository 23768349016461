import Annotation from "./Cache/Annotation.js";
class AnnotationType {
  constructor(data, rawType, _visible) {
    this.data = data;
    this.rawType = rawType;
    this._visible = _visible;
    if (this._visible == null) {
      this._visible = rawType == null || this.typeIsVisible();
    }
  }
  _count = 0;
  /**
   * Returns the standard machine-friendly name for this type.
   */
  get normalName() {
    return Annotation.normalizeType(this.rawType);
  }
  /**
   * Alias for the public API.
   */
  get key() {
    return this.normalName;
  }
  /**
   * Returns standard human-friendly label for this type, regardless of any unusual capitalisation used in the
   * raw type in the annotation.
   */
  get label() {
    return this.data.typeNameMap[this.normalName] || this.rawType;
  }
  // This is a public API. It is not used internally.
  get count() {
    return this._count;
  }
  increment() {
    this._count++;
  }
  decrement() {
    this._count--;
  }
  get visible() {
    return this._visible ?? false;
  }
  set visible(value) {
    this.data.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this._visible = !!value;
      this.data.sv.channelView.channels.forEach(sequence => {
        this.data.sv.annotations.getChannels(sequence).forEach(channel => channel.clearCache());
      });
      this.data.sv.view.dirty = "annotation type visibility";
    });
  }
  typeIsVisible() {
    return !this.normalName.startsWith("invisible_") && !this.data.invisibleTypes.includes(this.normalName);
  }
}
export { AnnotationType as default };