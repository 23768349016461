import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import WuKabatChannel from "./WuKabatChannel.js";
const {
  floor,
  round
} = Math;
class WuKabatPlugin extends AbstractPlugin {
  _enabled;
  _isHeatmap;
  _height;
  constructor(sv) {
    super(sv);
    sv.wuKabat = this;
    sv.registerChannelClass("wuKabat", WuKabatChannel);
    sv.bindToNode("mousemove", "wuKabat", (node, e, offset) => this.onMouseMove(node, e, offset));
    const options = this.getInitialOptions("wuKabat", {
      enabled: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      height: {
        default: 15,
        validator: raw => typeof raw === "number"
      },
      isHeatmap: {
        default: false,
        validator: raw => typeof raw === "boolean"
      }
    });
    this._enabled = options.enabled;
    this._isHeatmap = options.isHeatmap;
    this._height = options.height;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.setBooleanOption("_enabled", raw);
    this.sv.emit("channel visibility changed");
  }
  get isHeatmap() {
    return this._isHeatmap;
  }
  set isHeatmap(raw) {
    this.setBooleanOption("_isHeatmap", raw);
  }
  get height() {
    return this._height;
  }
  set height(raw) {
    this.setHeightOption("_height", raw);
  }
  get maxPossibleScore() {
    return this.sv.channelView.globals.wuKabatChannel.cache.maxPossibleScore;
  }
  serialize() {
    return {
      enabled: this.enabled,
      isHeatmap: this.isHeatmap,
      height: this.height
    };
  }
  onMouseMove(node, event, offset) {
    const channel = node.reference;
    this.sv.tooltip?.watch(node, event, () => {
      const index = floor(channel.view.toResidues(offset.x + channel.view.offset.x));
      const score = channel.cache.get(index) ?? 0;
      const rounded = round(score * 100) / 100;
      return "Wu-Kabat variability: " + rounded;
    });
  }
}
export { WuKabatPlugin as default };