import RRange from "../../includes/Range/RRange.js";
import Pen from "../../includes/RenderEngine/Pen.js";
import { darken } from "../../includes/misc/Utils.js";
import LeftEnding from "./IntervalEnding/LeftEnding.js";
import RightEnding from "./IntervalEnding/RightEnding.js";
const {
  max,
  min
} = Math;
class IntervalPainter {
  constructor(view) {
    this.view = view;
  }
  left;
  right;
  arrowWidth = 12;
  arrowMargin = 6;
  strokeWidth = 1;
  interval;
  context;
  bounds;
  center;
  pen;
  render(interval, bounds, center, brush, context) {
    this.interval = interval;
    this.context = context;
    this.bounds = bounds;
    this.center = center;
    this.left = new LeftEnding(this, interval);
    this.right = new RightEnding(this, interval);
    const colors = this.globals.colorFromType(interval.annotation.normalType);
    const points = this.polygonPoints.map(p => ({
      x: p[0],
      y: p[1]
    }));
    this.pen = new Pen(this.height - 4, "left", "middle", colors.text);
    let fillColor;
    if (this.selected) {
      fillColor = darken(colors.background, 7).toRgbString();
    } else if (this.hovered) {
      fillColor = darken(colors.background, 10).toRgbString();
    } else {
      fillColor = colors.background;
    }
    this.graphics.fillPolygon(brush, points, fillColor);
    if (this.view.sv.annotations.internalArrowsEnabled) {
      const showInternalArrows = this.showInternalArrows;
      if (showInternalArrows.left) {
        this.drawInternalArrowPath(brush, this.visibleRange.start, colors);
      }
      if (showInternalArrows.right) {
        this.drawInternalArrowPath(brush, this.visibleRange.end - this.arrowOuterWidth, colors);
      }
    }
    this.graphics.strokePolygon(brush, points, colors.stroke, this.strokeWidth);
    if (this.selected) {
      this.graphics.strokePolygon(brush, points, this.view.sv.selection.colors.outline, 3);
    }
    if (this.labelFits && this.labelOffset !== void 0) {
      this.pen.write(this.graphics, brush, this.label, this.labelOffset, this.height / 2);
    }
  }
  drawInternalArrowPath(brush, x, colors) {
    brush.save();
    const left = x + this.arrowMargin;
    const top = 0;
    const width = this.arrowWidth;
    const height = this.height;
    if (this.interval.isForwards) {
      brush.transform(width, 0, 0, height, left, top);
    } else if (this.interval.isReversed) {
      brush.transform(-width, 0, 0, height, left + width, top);
    } else {
      throw new Error("A directionless interval cannot have an internal arrow.");
    }
    this.view.graphics.fillPolygon(brush, [{
      x: 0,
      y: 0
    }, {
      x: 0.5,
      y: 0
    }, {
      x: 1,
      y: 0.5
    }, {
      x: 0.5,
      y: 1
    }, {
      x: 0,
      y: 1
    }, {
      x: 0.5,
      y: 0.5
    }], colors.arrow);
    brush.restore();
  }
  get width() {
    return this.bounds.width ?? 0;
  }
  get height() {
    return this.bounds.height ?? 0;
  }
  get globals() {
    return this.view.sv.annotations.data;
  }
  get label() {
    return this.interval.annotation.name;
  }
  /**
   * Does the label fit inside the main part of the interval's polygon?
   */
  get labelFits() {
    return !this.availableRange.isEmpty && this.labelWidth <= this.availableRange.length;
  }
  /**
   * Width of the label in pixels.
   */
  get labelWidth() {
    return this.pen?.measureWidth(this.label) ?? 0;
  }
  /**
   * Offsets describing the segment of the interval which is both:
   *   - in the current rendering context, and
   *   - in the viewport.
   *
   * @returns In pixels, relative to this interval's left end.
   */
  get visibleRange() {
    return this.viewportRange.clip(this.intervalRange);
  }
  /**
   * Offsets describing the bounds of the viewport, relative to this interval's left end.
   *
   * @returns In pixels, relative to this interval's left end.
   */
  get viewportRange() {
    if (!this.interval) {
      return new RRange(0, 0);
    }
    const left = 0 - this.bounds.x;
    let length = min(this.context.width, this.view.viewport.width);
    if (this.view.sv.view.circular) {
      length = this.context.width;
    }
    const right = left + length;
    return new RRange(left, right);
  }
  /**
   * The bounds of the entire interval, including the ending.
   */
  get intervalRange() {
    return new RRange(0, this.width);
  }
  /**
   * The segment of the interval which is the main part.
   * The "main part" excludes any endings like arrows, primers and zigzags.
   */
  get bodyRange() {
    if (!this.interval) {
      return new RRange(0, 0);
    }
    return this.intervalRange.shrink(this.left.width, this.right.width);
  }
  /**
   * The segment of the interval within which the label can be drawn, if padded at the left and right ends of
   * the interval and excluding arrows.
   */
  get availableRange() {
    return this.bodyRange.shrink(this.paddingLeft, this.paddingRight);
  }
  /**
   * The segment of the visible region of the interval within which the label can be drawn, if padded at the left
   * and right ends of the viewport and excluding arrows.
   */
  get paddedViewportRange() {
    return this.visibleRange.clip(this.bodyRange).shrink(this.paddingLeft, this.paddingRight);
  }
  /**
   * Offset of the label in pixels, relative to the rendering context.
   */
  get labelOffset() {
    const visible = this.paddedViewportRange;
    const labelWidth = this.labelWidth;
    if (this.view.wrapped) {
      return visible.center - labelWidth / 2;
    } else if (this.labelFits) {
      const center = this.center + (this.left.width - this.right.width - labelWidth) / 2;
      if (this.view.sv.annotations.showPartialAnnotationLabels) {
        const left = visible.start;
        const right = visible.end - labelWidth;
        if (center < left) {
          return min(left, right);
        } else if (center > right) {
          return max(left, right);
        }
      }
      return center;
    }
    return 0;
  }
  get polygonPoints() {
    if (this.innerWidth === 0 || !this.interval) {
      return this.linePoints;
    } else {
      const left = this.left.path.clone();
      const right = this.right.path.clone();
      const endingsWidth = left.width + right.width;
      const width = this.innerWidth;
      if (width < endingsWidth) {
        left.multiplyX(width / endingsWidth);
        right.multiplyX(width / endingsWidth);
      }
      right.add(width, 0);
      const halfOffset = this.strokeWidth / 2;
      return left.extend(right).add(halfOffset, halfOffset).points;
    }
  }
  get graphics() {
    return this.view.graphics;
  }
  get linePoints() {
    const halfOffset = this.strokeWidth / 2;
    return [[halfOffset, halfOffset], [halfOffset, this.height - halfOffset], [halfOffset, halfOffset]];
  }
  get innerWidth() {
    return max(0, this.width - this.strokeWidth);
  }
  get innerHeight() {
    return this.height - this.strokeWidth;
  }
  get paddingLeft() {
    return this.showInternalArrows.left ? this.maxPadding : this.minPadding;
  }
  get paddingRight() {
    return this.showInternalArrows.right ? this.maxPadding : this.minPadding;
  }
  get minPadding() {
    return 2;
  }
  // Padding when an internal arrow is visible.
  get maxPadding() {
    return this.interval?.hasDirection ? this.minPadding + this.arrowOuterWidth : this.minPadding;
  }
  /**
   * For both left and right, return true if the arrow should be shown on that side.
   * An arrow should be shown if:
   *  - There is enough room to render the arrow after rendering the label.
   *  - The respective end of the interval is off the screen.
   * @returns {{left: boolean, right: boolean}}
   */
  get showInternalArrows() {
    if (!this.interval || this.interval.undirected) {
      return {
        left: false,
        right: false
      };
    }
    const arrowRange = this.visibleRange.clip(this.bodyRange);
    const availablePadding = arrowRange.length - this.labelWidth - this.minPadding * 2;
    if (availablePadding < this.maxPadding) {
      return {
        left: false,
        right: false
      };
    } else {
      return {
        left: this.bodyRange.start < arrowRange.start,
        right: this.bodyRange.end > arrowRange.end
      };
    }
  }
  get arrowOuterWidth() {
    return this.arrowWidth + this.arrowMargin * 2;
  }
  get selected() {
    return this.usesNode(this.view.sv.view.focused);
  }
  get hovered() {
    return this.usesNode(this.view.sv.view.hovered);
  }
  usesNode(node) {
    if (node && node.type === "interval") {
      const interval = node.reference;
      return interval.annotation === this.interval?.annotation;
    }
    return false;
  }
}
export { IntervalPainter as default };