import { darken } from "../misc/Utils.js";
import { adjustRange } from "../misc/DPIRatio.js";
const {
  round,
  floor,
  ceil
} = Math;
class GraphPainter {
  constructor(view) {
    this.view = view;
  }
  // Multi-hue sequential color scheme from yellow to red
  // @see http://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9
  colors = ["#ffffcc", "#fff6b5", "#ffeda0", "#ffe38b", "#fed976", "#ffc661", "#feb24c", "#fea044", "#fd8d3c", "#fd7033", "#fc4e2a", "#f03823", "#e31a1c", "#d00e22", "#bd0026", "#9e0026", "#800026"];
  render(channel, context, y) {
    const view = this.view;
    const visible = context.visible;
    channel.cache.request(visible);
    if (view.residueWidth >= 1) {
      for (let i = visible.start; i < visible.end; i++) {
        this.renderResidue(channel, context, y, i);
      }
    } else {
      const start = floor(view.toPixels(visible.start));
      const end = ceil(view.toPixels(visible.end));
      for (let i = start; i < end; i++) {
        this.renderPixel(channel, context, y, i);
      }
    }
  }
  heatmapColor(fraction) {
    const colors = this.colors;
    const index = floor(fraction * (colors.length - 1));
    return colors[index];
  }
  renderResidue(channel, context, maxY, index) {
    const score = channel.cache.get(index) || 0;
    const x = context.toViewportOffset(index);
    this.renderSection(channel, maxY, x, context.residueWidth, score);
  }
  renderPixel(channel, context, y, index) {
    const first = floor(this.view.toResidues(index));
    const last = ceil(this.view.toResidues(index + 1)) - 1;
    let scores = 0;
    for (let i = first; i < last + 1; i++) {
      scores += channel.cache.get(i);
    }
    const score = scores / (last - first + 1);
    const x = index - context.offset;
    this.renderSection(channel, y, x, 1, score);
  }
  renderSection(channel, maxY, x, width, score) {
    const {
      start,
      length
    } = adjustRange(x, width);
    this.paint(channel, score / maxY, start, length);
  }
  paint(channel, fraction, x, width) {
    const brush = this.view.brush;
    if (channel.plugin.isHeatmap) {
      this.paintColumn(x, 0, width, channel.height, this.heatmapColor(fraction));
      this.paintLine(x, 0, width);
      brush.fillRect(x, channel.height - 1, width, 1);
    } else {
      const y = round((1 - fraction) * (channel.height - 1));
      this.paintColumn(x, y, width, channel.height - y, channel.histogramColor(fraction));
      this.paintLine(x, y, width);
    }
  }
  paintColumn(start, y, width, height, color) {
    const brush = this.view.brush;
    brush.fillStyle = color;
    brush.fillRect(start, y, width, height);
  }
  paintLine(start, y, width) {
    const brush = this.view.brush;
    brush.fillStyle = darken(brush.fillStyle.toString(), 20).toString();
    brush.fillRect(start, y, width, 1);
  }
}
export { GraphPainter as default };