import TranslationsChannel from "./TranslationsChannel.js";
import TranslationsData from "./TranslationsData.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { GeneticCodes, SequenceColors } from "@geneious/shared-constants";
class TranslationsPlugin extends AbstractPlugin {
  constructor(sv) {
    super(sv);
    this.sv = sv;
    sv.registerChannelClass("translations", TranslationsChannel);
    sv.translations = this;
    this.data = new TranslationsData(sv);
    const options = this.getInitialOptions("translations", {
      enabled: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      frames: {
        default: null,
        validator: raw => typeof raw === "string" && this.framesAreValid(raw)
      },
      table: {
        default: "transl_table=1",
        validator: raw => typeof raw === "string" && this.validateTable(raw)
      },
      showNucleotides: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      colorScheme: {
        default: "geneiousBackground",
        validator: raw => typeof raw === "string" && this.validateColorScheme(raw)
      }
    });
    this.table = options.table;
    this.frames = options.frames;
    this._enabled = options.enabled;
    this._colorScheme = options.colorScheme;
    this._showNucleotides = options.showNucleotides;
    setTimeout(() => {
      if (this.enabled) {
        this.updateSequenceVisibility();
      }
    });
  }
  data;
  _enabled = true;
  _showNucleotides = true;
  _colorScheme;
  selectedTranslation = null;
  hoveredTranslation = null;
  set enabled(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setBooleanOption("_enabled", raw);
      this.sv.sequencesPlugin.visible = this.showNucleotides || !this._enabled;
      this.sv.emit("channel visibility changed");
    });
    this.markDirty();
  }
  // A public API.
  get enabled() {
    return this._enabled;
  }
  set table(name) {
    this.data.tableName = name;
    this.sv.emit("translation table changed");
  }
  get table() {
    return this.data.tableName;
  }
  get frames() {
    return this.data.frames ? this.data.frames.join(",") : "";
  }
  set frames(raw) {
    if (!this.sv.loaded) {
      this.setFrames(raw);
    } else {
      this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
        this.setFrames(raw);
        this.sv.emit("channel visibility changed");
      });
      this.markDirty();
      this.sv.emit("data row count changed");
    }
  }
  set showNucleotides(raw) {
    this.setBooleanOption("_showNucleotides", raw);
    if (this.enabled) {
      this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
        this.updateSequenceVisibility();
      });
    }
  }
  get showNucleotides() {
    return this._showNucleotides;
  }
  get colorScheme() {
    return this._colorScheme;
  }
  set colorScheme(raw) {
    this.setNullableString("_colorScheme", raw);
  }
  serialize() {
    return {
      enabled: this.enabled,
      table: this.table,
      frames: this.frames,
      colorScheme: this.colorScheme,
      showNucleotides: this.showNucleotides
    };
  }
  setFrames(raw) {
    if (raw && typeof raw === "string" && raw !== "") {
      this.data.frames = this.validateFrames(raw);
    } else {
      this.data.frames = null;
    }
  }
  updateSequenceVisibility() {
    this.sv.sequencesPlugin.visible = this._showNucleotides;
    this.sv.emit("channel visibility changed");
  }
  framesAreValid(raw) {
    try {
      this.validateFrames(raw);
      return true;
    } catch (error) {
      return false;
    }
  }
  validateFrames(raw) {
    const numbers = raw.split(",").map(input => {
      const frame = Number(input.trim());
      if ([1, 2, 3, -1, -2, -3].includes(frame)) {
        return frame;
      } else {
        throw new Error(`Frame "${raw}" is invalid. Valid frames are 1, 2, 3, -1, -2 and -3`);
      }
    });
    return numbers;
  }
  validateTable(raw) {
    return Object.keys(GeneticCodes).some(table => table === raw);
  }
  validateColorScheme(raw) {
    return Object.keys(SequenceColors["AminoAcid"]).some(scheme => scheme === raw);
  }
}
export { TranslationsPlugin as default };