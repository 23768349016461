import { adjustRange } from "../../includes/misc/DPIRatio.js";
import Layer from "../../includes/PluginBaseClasses/Layer.js";
import { clipBrush, distanceBetweenIndexes } from "../../includes/misc/Utils.js";
import Range from "../../includes/Range/Range.js";
class SelectionLayer extends Layer {
  constructor(plugin, anchor, endAnchor) {
    super(plugin.sv.view);
    this.plugin = plugin;
    this.anchor = anchor;
    this.endAnchor = endAnchor;
    plugin.sv.bind("channel column render", node => this.render(node));
  }
  cursor = null;
  // TODO Is this grey intentionally different to SequencePainter's?
  grey = "grey";
  get range() {
    if (this.anchor == null || this.endAnchor == null) {
      return;
    }
    const sequenceLength = this.anchorWrapper.sequenceChannel.length;
    const [start, end] = this.plugin.isForwardSelection ? [this.anchor, this.endAnchor] : [this.endAnchor, this.anchor];
    return new Range(start, distanceBetweenIndexes(start, end, sequenceLength), sequenceLength);
  }
  paint(node) {
    clipBrush(this.graphics, this.brush, node.bounds);
    this.fadeBackground(node);
    this.renderVerticalLines(node);
    node.children.forEach(child => {
      child.translate(this.brush, () => {
        this.renderFatMarkers(child);
        this.renderBoxAroundSelection(child);
      });
    });
  }
  renderVerticalLines(node) {
    const {
      height
    } = node.bounds;
    const row = node.rowRange;
    if (row === void 0) {
      return;
    }
    if (this.cursorWrapper) {
      const index = this.cursor;
      if (index && row.touchesIndex(index)) {
        this.renderIndex(index - row.start, height);
      }
    }
    if (this.range) {
      const {
        start,
        end
      } = this.range;
      if (row.includes(start)) {
        this.renderIndex(start - row.start, height);
      }
      if (row.includes(end)) {
        this.renderIndex(end - row.start, height);
      }
    }
  }
  renderIndex(index, height, color = this.grey) {
    const x = this.toOffset(index);
    const {
      start,
      length
    } = adjustRange(x, 1);
    this.graphics.fillRect(this.brush, start, 0, length, height, color);
  }
  renderFatMarkers(node) {
    const {
      blue
    } = this.plugin.colors;
    const wrapper = node.reference;
    const row = node.rowRange;
    if (row === void 0) {
      return;
    }
    if (this.cursorWrapper === wrapper && this.cursor !== null && row.touchesIndex(this.cursor)) {
      this.markFatIndex(node, this.cursor - row.start);
    }
    const anchor = this.anchor;
    if (this.hasAnchor(node, anchor)) {
      this.markFatIndex(node, anchor - row.start, blue);
      this.renderIndex(this.anchor, node.bounds.height, blue);
    }
  }
  hasAnchor(node, anchor) {
    const wrapper = node.reference;
    return this.anchorWrapper === wrapper && anchor != null && anchor === this.endAnchor && node.rowRange !== void 0 && node.rowRange.touchesIndex(anchor);
  }
  markFatIndex(node, index, color = this.grey) {
    const wrapper = node.reference;
    const sequence = wrapper.sequenceChannel;
    const translations = wrapper.translationsChannel;
    if (!sequence) {
      return;
    }
    const height = translations && translations.visible ? sequence.heightWithMargin + translations.height : sequence.height;
    this.renderFatIndex(index, this.yOffset(node), height, color);
  }
  yOffset(node) {
    const wrapper = node.reference;
    let y = 0;
    for (const channel of wrapper.visibleChildren) {
      if (channel !== wrapper.sequenceChannel) {
        y += channel.heightWithMargin;
      } else {
        return y + node.children[0].bounds.y;
      }
    }
  }
  renderFatIndex(index, y, height, color) {
    const x = this.toOffset(index);
    const {
      start,
      length
    } = adjustRange(x - 1, 3);
    this.graphics.fillRect(this.brush, start, y, length, height, color);
  }
  fadeBackground(node) {
    if (this.anchor !== null && this.endAnchor !== null && this.anchor !== this.endAnchor) {
      const view = this.channelView;
      const height = node.bounds.height;
      const selection = this.range;
      if (!selection?.overlaps(view.viewport.residueRange)) {
        this.paintBackground(0, view.width, height);
      } else if (node.rowRange) {
        const {
          start,
          end
        } = node.rowRange;
        if (selection.start < selection.end) {
          this.paintBackground(0, selection.start - start, height);
          this.paintBackground(selection.end - start, end - selection.end, height);
        } else {
          this.paintBackground(selection.end, selection.start - selection.end, height);
        }
      }
    }
  }
  paintBackground(start, length, height) {
    const {
      x,
      width
    } = this.rangeToPixels(start, length);
    this.graphics.fillRect(this.brush, x, 0, width, height, "rgba(255, 255, 255, 0.4)");
  }
  renderBoxAroundSelection(node) {
    const selection = this.range;
    if (this.anchor == null || this.endAnchor == null || !selection) {
      return;
    }
    if (this.anchorWrapper === node.reference && node.rowRange) {
      const {
        start,
        end
      } = node.rowRange;
      if (this.channelView.sv.view.isCircular) {
        this.paintBox(selection.start, selection.length, node.bounds.height);
      } else {
        if (selection.start > selection.end) {
          this.paintBox(start, selection.end - start, node.bounds.height);
          this.paintBox(selection.start, end - selection.start, node.bounds.height);
        } else {
          this.paintBox(selection.start, selection.length, node.bounds.height);
        }
      }
    }
  }
  paintBox(start, length, height) {
    const {
      x,
      width
    } = this.rangeToPixels(start, length);
    this.graphics.strokeRect(this.brush, x, 0, width + 1, height, "black", 1);
  }
  rangeToPixels(start, length) {
    const x = this.toOffset(start);
    const width = this.channelView.toPixels(length);
    const range = adjustRange(x, width);
    return {
      x: range.start,
      width: range.length
    };
  }
  toOffset(index) {
    const viewOffset = this.channelView.offset.x;
    return this.channelView.toPixels(index) - viewOffset;
  }
  get channelView() {
    return this.plugin.sv.channelView;
  }
  get anchorWrapper() {
    return this.plugin.anchorWrapper;
  }
  get cursorWrapper() {
    return this.plugin.cursorWrapper;
  }
}
export { SelectionLayer as default };