import ChannelView from "./ChannelView.js";
import RRange from "../Range/RRange.js";
class ChannelViewport {
  constructor(sv) {
    this.sv = sv;
    this.view = new ChannelView(this);
    sv.bind("ready", () => {
      sv.bind(["column visibility changed", "resize"], () => this.resize());
    });
  }
  view;
  // Dimensions exclude the sidebar.
  _dimensions;
  _sidebarColumns;
  get sidebarColumns() {
    if (!this._sidebarColumns) {
      return [];
    }
    return this._sidebarColumns.filter(column => column.enabled);
  }
  get sidebarWidth() {
    return this.sidebarColumns.reduce((total, column) => total + column.widthWithMargin, 0);
  }
  sidebarColumnNodes(height, channels) {
    let xOffset = 0;
    const nodes = this.sidebarColumns.map(column => {
      const bounds = {
        x: xOffset,
        y: 0,
        width: column.widthWithMargin,
        height
      };
      xOffset += column.widthWithMargin;
      return column.calculateLayout(bounds, channels);
    });
    nodes.reverse();
    return nodes;
  }
  resize() {
    const old = this._dimensions;
    const neu = this.dimensions;
    if (old?.width !== neu.width || old?.height !== neu.height) {
      const zoomedOut = this.view.residueWidth === this.view.minResidueWidth;
      this._dimensions = neu;
      if (!this.view.wrapped && zoomedOut) {
        this.sv.zoomToShowAll();
      } else {
        this.view.clampOffset();
      }
    }
  }
  initializeDimensions() {
    const columns = this.sv.view.circular ? [] : this.sv.invoke("register sidebar columns");
    this._sidebarColumns = columns.sort((a, b) => a.weight - b.weight);
    this._dimensions = this.dimensions;
  }
  // Dimensions exclude the sidebar.
  get dimensions() {
    let width = this.sv.view.width;
    const height = this.sv.view.height;
    if (this.sidebarFits) {
      width -= this.sidebarWidth;
    }
    return {
      width,
      height
    };
  }
  get sidebarFits() {
    return this.sv.view.width > this.sidebarWidth;
  }
  get residueRange() {
    if (this.view.wrapped) {
      const {
        rowIndexes,
        rowLength
      } = this.view;
      return rowIndexes.multiply(rowLength);
    } else {
      const {
        offset,
        residueWidth
      } = this.view;
      let width = this.width;
      if (this.view.sv.view.circular) {
        width = this.view.width;
      }
      const range = new RRange(offset.x, offset.x + width);
      return range.divide(residueWidth);
    }
  }
  // Dimensions exclude the sidebar.
  get width() {
    return this._dimensions?.width ?? 0;
  }
  get height() {
    return this._dimensions?.height ?? 0;
  }
  get brush() {
    return this.sv.view.brush;
  }
  get graphics() {
    return this.sv.view.graphics;
  }
  get centerOffset() {
    if (!this._dimensions) {
      return 0;
    }
    const key = this.sv.wrapped ? "height" : "width";
    return this._dimensions[key] / 2;
  }
}
export { ChannelViewport as default };