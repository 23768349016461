import SequenceViewer from "./SequenceViewer.js";
import LabelsPlugin from "./plugins/LabelsColumn/LabelsPlugin.js";
import SequencesPlugin from "./plugins/Sequences/SequencesPlugin.js";
import TranslationsPlugin from "./plugins/Translations/TranslationsPlugin.js";
import AnnotationsPlugin from "./plugins/Annotations/AnnotationsPlugin.js";
import ColorByAnnotationPlugin from "./plugins/ColorByAnnotation/ColorByAnnotationPlugin.js";
import GraphsPlugin from "./plugins/Graphs/GraphsPlugin.js";
import RulerPlugin from "./plugins/Ruler/RulerPlugin.js";
import ConsensusPlugin from "./plugins/Consensus/ConsensusPlugin.js";
import HighlightingPlugin from "./plugins/Highlighting/HighlightingPlugin.js";
import IdentityPlugin from "./plugins/Identity/IdentityPlugin.js";
import WuKabatPlugin from "./plugins/WuKabat/WuKabatPlugin.js";
import TreePlugin from "./plugins/TreeViewer/TreePlugin.js";
import TooltipPlugin from "./plugins/Tooltip/TooltipPlugin.js";
import ScrollPlugin from "./plugins/Scroll/ScrollPlugin.js";
import ZoomPlugin from "./plugins/ZoomPlugin.js";
import SelectionPlugin from "./plugins/Selection/SelectionPlugin.js";
import ProgressPlugin from "./plugins/Progress/ProgressPlugin.js";
import DeveloperPlugin from "./plugins/Developer/DeveloperPlugin.js";
import SearchPlugin from "./plugins/Search/SearchPlugin.js";
import MetadataPlugin from "./plugins/Metadata/MetadataPlugin.js";
import SortingPlugin from "./plugins/Sorting/SortingPlugin.js";
import PairedReadsPlugin from "./plugins/PairedReads/PairedReadsPlugin.js";
import ExportPlugin from "./plugins/Export/ExportPlugin.js";
import SequenceSelectionPlugin from "./plugins/SequenceSelection/SequenceSelectionPlugin.js";
import EditingPlugin from "./plugins/Editing/EditingPlugin.js";
class FullSequenceViewer extends SequenceViewer {
  /**
   * Creating an instance of {@link SequenceViewer} with all available plugins included.
   *
   * @param elementOrSelector (required) the element or the selector of the element that will contain the sequence viewer.
   * @param options (required) the options to initialise sequence viewer with.
   */
  constructor(elementOrSelector, options) {
    super(elementOrSelector, options, [TooltipPlugin, SequencesPlugin, TranslationsPlugin, AnnotationsPlugin, RulerPlugin, GraphsPlugin, ScrollPlugin, ZoomPlugin, ProgressPlugin, DeveloperPlugin, ConsensusPlugin, HighlightingPlugin, IdentityPlugin, WuKabatPlugin, ColorByAnnotationPlugin, SelectionPlugin, SearchPlugin, MetadataPlugin, LabelsPlugin, TreePlugin, SortingPlugin, PairedReadsPlugin, ExportPlugin, EditingPlugin, SequenceSelectionPlugin]);
  }
}
export { FullSequenceViewer };