import { GraphicsTransformer } from "./GraphicsTransformer.js";
class CircularGraphicsTransformer extends GraphicsTransformer {
  MINIMUM_RADIUS = 80;
  EMPTY_INTERIOR_RADIUS = 25;
  //  minimum interior region in which nothing is drawn
  constructor(sv) {
    super(sv);
  }
  /**
   * Transform a point in linear/cartesian coordinate to circular/polar coordinate.
   * @param point a single point to transform
   */
  transformPoint(point) {
    let radius = this.radius;
    const angleFraction = this.getAngleFraction(point.x);
    const angle = angleFraction * Math.PI * 2;
    const centerX = this.viewportWidth / 2;
    const centerY = this.centerY;
    radius -= this.getScaledY(point.y);
    const tx = centerX + Math.sin(angle) * radius;
    const ty = centerY - Math.cos(angle) * radius;
    return {
      x: tx,
      y: ty
    };
  }
  /**
   * Transform the point from circular/polar coordinate to linear/cartesian coordinate.
   * @param point a single point to transform
   */
  reverseTransformPoint(point) {
    const radius = this.radius;
    const centerX = this.scrollX + this.viewportWidth / 2;
    const centerY = this.centerY;
    let x = point.x;
    let y = point.y;
    x -= centerX;
    y -= centerY;
    let angle = Math.atan2(x, -y);
    if (angle < 0) {
      angle += Math.PI * 2;
    }
    const angleFraction = angle / (Math.PI * 2);
    const thisRadius = Math.sqrt(x * x + y * y);
    let resultX = this.logicalWidth * angleFraction;
    const resultY = radius - thisRadius;
    resultX += this.scrollX;
    if (resultX >= this.logicalWidth) {
      resultX -= this.logicalWidth;
    }
    return {
      x: resultX,
      y: this.getUnscaledY(resultY)
    };
  }
  /**
   * Draw a rectangle in polar coordinate with a transparent background.
   * This will actually take shape of an arc in reality.
   *
   * @param brush The canvas context 2d
   * @param x
   * @param y
   * @param width
   * @param height
   * @param color color of the border
   * @param strokeSize the size of the border.
   */
  strokeRect(brush, x, y, width, height, color, strokeSize) {
    this.strokePolygon(brush, [{
      x,
      y
    }, {
      x: x + width,
      y
    }, {
      x: x + width,
      y: y + height
    }, {
      x,
      y: y + height
    }], color, strokeSize);
  }
  /**
   * Draw polygon with a transparent background in polar coordinate.
   *
   * @param brush The canvas context 2d
   * @param points the vertices of the polygon
   * @param color color of the border
   * @param strokeSize the size of the border
   */
  strokePolygon(brush, points, color, strokeSize) {
    const path = this.generatePolygonPath(this.mapPointsToAbsolute(brush, points));
    this.withInitialTransform(brush, () => {
      brush.strokeStyle = color;
      brush.lineWidth = strokeSize;
      brush.stroke(path);
    });
  }
  /**
   * Draw a rectangle with a background in polar coordinate. This will actually render as an arc.
   *
   * @param brush The canvas context 2d
   * @param x
   * @param y
   * @param width
   * @param height
   * @param color color of the background
   */
  fillRect(brush, x, y, width, height, color) {
    this.fillPolygon(brush, [{
      x,
      y
    }, {
      x: x + width,
      y
    }, {
      x: x + width,
      y: y + height
    }, {
      x,
      y: y + height
    }], color);
  }
  /**
   * Draw polygon with a background in polar coordinate.
   *
   * @param brush The canvas context 2d
   * @param points the vertices of the polygon
   * @param color color of the border
   */
  fillPolygon(brush, points, color) {
    const path = this.generatePolygonPath(this.mapPointsToAbsolute(brush, points));
    this.withInitialTransform(brush, () => {
      brush.fillStyle = color;
      brush.fill(path);
    });
  }
  /**
   * Translate the x position in linear coordinate to a fraction with the sv logical width.
   * This is used to calculate the angle of a linear point when converted to polar point.
   * @param x
   */
  getAngleFraction(x) {
    return x / this.logicalWidth;
  }
  /**
   * Fill a text in polar coordinate.
   * @param brush The canvas context 2d
   * @param content The content of the text
   * @param x
   * @param y
   */
  fillText(brush, content, x, y) {
    const characters = content.split("");
    const characterBoxes = [];
    const textWidth = brush.measureText(content).width;
    const textAlign = brush.textAlign;
    brush.textAlign = "left";
    let currentX = x;
    if (textAlign === "center") {
      currentX = x - textWidth / 2;
    } else if (textAlign === "right") {
      currentX = x - textWidth;
    }
    for (const char of characters) {
      const charSize = brush.measureText(char);
      const absolutePosition = this.mapPointToAbsolute(brush, {
        x: currentX,
        y
      });
      characterBoxes.push({
        character: char,
        box: {
          x: absolutePosition.x,
          y: absolutePosition.y,
          width: charSize.width,
          height: charSize.actualBoundingBoxAscent + charSize.actualBoundingBoxDescent
        }
      });
      currentX += charSize.width;
    }
    let scaleX = 1;
    for (let i = 0; i < characterBoxes.length; i++) {
      const characterBox = characterBoxes[i];
      const char = characterBox.character;
      const box = characterBox.box;
      const transformedPosition = this.transformPoint({
        x: box.x,
        y: box.y
      });
      const angle = this.getAngleFraction(box.x) * 2 * Math.PI;
      if (i < characterBoxes.length - 1) {
        const nextBox = characterBoxes[i + 1];
        const transformedNextBoxPosition = this.transformPoint({
          x: nextBox.box.x,
          y: nextBox.box.y
        });
        const distanceBetweenTwoBox = Math.sqrt(Math.pow(transformedNextBoxPosition.x - transformedPosition.x, 2) + Math.pow(transformedNextBoxPosition.y - transformedPosition.y, 2));
        scaleX = distanceBetweenTwoBox < box.width ? distanceBetweenTwoBox / box.width : 1;
      }
      this.withInitialTransform(brush, () => {
        brush.translate(transformedPosition.x, transformedPosition.y);
        brush.rotate(angle);
        brush.scale(scaleX, 1);
        brush.fillText(char, 0, 0);
      });
    }
  }
  /**
   * Draw a line in polar coordinate.
   * @param brush The canvas context 2d
   * @param pointA The starting point of the line
   * @param pointB The ending point of the line
   * @param color The color of the line
   * @param lineWidth The width of the line
   */
  drawLine(brush, pointA, pointB, color, lineWidth) {
    const path = new Path2D();
    const [absoluteA, absoluteB] = this.mapPointsToAbsolute(brush, [pointA, pointB]);
    const transformedA = this.transformPoint(absoluteA);
    const transformedB = this.transformPoint(absoluteB);
    path.moveTo(transformedA.x, transformedA.y);
    path.lineTo(transformedB.x, transformedB.y);
    brush.lineWidth = lineWidth;
    brush.strokeStyle = color;
    this.withInitialTransform(brush, () => {
      brush.stroke(path);
    });
  }
  /**
   * Get a rectangle shape in polar coordinate. This will actually be an arc.
   * @param brush The canvas context 2d.
   * @param x
   * @param y
   * @param width
   * @param height
   */
  getRect(brush, x, y, width, height) {
    return this.generatePolygonPath(this.mapPointsToAbsolute(brush, [{
      x,
      y
    }, {
      x: x + width,
      y
    }, {
      x: x + width,
      y: y + height
    }, {
      x,
      y: y + height
    }]));
  }
  get radius() {
    let radius = this.logicalWidth / Math.PI / 2;
    if (radius <= this.MINIMUM_RADIUS) {
      radius = this.MINIMUM_RADIUS;
    }
    return radius;
  }
  /**
   * Get the center y position of the circle in circular mode. Normally it's the center of SV but as you zoom in,
   * it gradually shifted downward to fit the top part of SV.
   */
  get centerY() {
    const radius = this.radius;
    let centerY = radius;
    const extraViewportHeightAvailable = this.viewportHeight - radius * 2;
    if (extraViewportHeightAvailable > 0) {
      centerY += extraViewportHeightAvailable / 2;
    } else if (this.viewportWidth / 2 < radius) {
      const dx = this.viewportWidth / 2;
      const dy = Math.sqrt(radius * radius - dx * dx);
      const heightRequired = radius - dy;
      let extraHeight = this.viewportHeight - heightRequired;
      extraHeight = Math.min(extraHeight, this.viewportHeight - this.logicalHeight);
      if (extraHeight > 0) {
        centerY = radius + extraHeight / 2;
      }
    }
    return centerY;
  }
  getScaledY(originalY) {
    return originalY * this.getYScaleFactor();
  }
  getUnscaledY(originalY) {
    return originalY / this.getYScaleFactor();
  }
  getYScaleFactor() {
    const radius = this.radius;
    if (this.logicalHeight > radius - this.EMPTY_INTERIOR_RADIUS) {
      return (radius - this.EMPTY_INTERIOR_RADIUS) / this.logicalHeight;
    }
    return 1;
  }
  degreeToRadian(degree) {
    return degree * Math.PI / 180;
  }
  /**
   * Take in a list of points (in linear coordinate) in a polygon, transform into polar coordinate using transformPoints,
   * then create a path using those points. For path that have the same x but different y (a vertical line) the path
   * will render this as a straight line, but otherwise, it's rendered as an arc to fit with the circular view.
   *
   * @param points set of points in polygon.
   * @private
   */
  generatePolygonPath(points) {
    const path = new Path2D();
    const numberOfPoints = points.length;
    const transformedPoints = this.transformPoints(points);
    let previousPoint = points[numberOfPoints - 1];
    const startPointTransformed = transformedPoints[numberOfPoints - 1];
    path.moveTo(startPointTransformed.x, startPointTransformed.y);
    const maxRadius = this.radius;
    const centerX = this.viewportWidth / 2;
    const centerY = this.centerY;
    for (let i = 0; i < numberOfPoints; i++) {
      const currentPoint = points[i];
      const currentPointTransformed = transformedPoints[i];
      if (currentPoint.y == previousPoint.y) {
        const radius = maxRadius - this.getScaledY(currentPoint.y);
        const arcX = centerX;
        const arcY = centerY;
        const startAngle = this.getAngleFraction(previousPoint.x) * 360 - 90;
        const endAngle = this.getAngleFraction(currentPoint.x) * 360 - 90;
        path.arc(arcX, arcY, radius, this.degreeToRadian(startAngle), this.degreeToRadian(endAngle), startAngle > endAngle);
      } else {
        path.lineTo(currentPointTransformed.x, currentPointTransformed.y);
      }
      previousPoint = currentPoint;
    }
    return path;
  }
}
export { CircularGraphicsTransformer };