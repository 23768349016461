import { clipBrush, notEmpty } from "../../includes/misc/Utils.js";
import ResizableSidebarColumn from "../../includes/PluginBaseClasses/ResizableSidebarColumn.js";
const {
  min,
  max,
  round
} = Math;
class TreeColumn extends ResizableSidebarColumn {
  constructor(plugin) {
    super(plugin.sv.view);
    this.plugin = plugin;
    this.maxWidth = this.plugin.levels * 8;
    this.updateBranchWidth();
    plugin.sv.addEventListenerToDocument("mouseup", () => this.mouseUpOnDocument());
    plugin.sv.addEventListenerToDocument("mousemove", e => this.mouseMoveOnDocument(e));
  }
  branchWidth;
  anchors = [];
  referenceRendered = false;
  lineThickness = 2;
  maxWidth;
  minWidth = 10;
  get weight() {
    return -10;
  }
  get enabled() {
    return this.plugin.enabled;
  }
  get nodeType() {
    return "tree";
  }
  get columnWidth() {
    if (this.plugin.width < this.minWidth) {
      return this.minWidth;
    } else if (this.plugin.width > this.maxWidth) {
      return this.maxWidth;
    } else {
      return this.plugin.width;
    }
  }
  mouseMoveOnDocument(event) {
    if (this.adjustingColumnWidth) {
      this.plugin.width = event.pageX - this.view.element.getBoundingClientRect().left;
      this.updateBranchWidth();
      document.body.style.cursor = "col-resize";
    }
  }
  mouseUpOnDocument() {
    if (this.adjustingColumnWidth) {
      this.plugin.width = this.columnWidth;
      this.adjustingColumnWidth = false;
      document.body.style.cursor = "auto";
    }
  }
  alterWrapperNode(node) {
    const wrapper = node.reference;
    if (this.hideWrapperTree(wrapper)) {
      this.referenceRendered = true;
      node.setRenderCallback(() => {
        const brush = this.layer.brush;
        brush.fillStyle = "white";
        brush.fillRect(0, 0, this.columnWidth, wrapper.heightWithMargin);
      });
    }
    return node;
  }
  hideWrapperTree(wrapper) {
    return wrapper.isSticky && (this.referenceRendered || wrapper.type === "globals wrapper");
  }
  paint() {
    this.anchors = this.calculateAnchors();
    clipBrush(this.layer.graphics, this.layer.brush, {
      width: this.width,
      height: this.plugin.sv.channelView.height
    });
    this.renderTree(this.plugin.tree);
    this.referenceRendered = false;
  }
  // TODO Cache this.
  calculateAnchors() {
    const {
      globals,
      sequences,
      reference,
      referenceWrapper
    } = this.channelView;
    let sum = globals.heightWithMargin + (reference && reference.isSticky ? referenceWrapper.heightWithMargin : 0);
    return sequences.map(wrapper => {
      const anchor = round(sum + wrapper.minHeight / 2) - 1;
      sum += wrapper.heightWithMargin;
      return anchor;
    });
  }
  renderTree(tree) {
    if (!tree) {
      return;
    }
    const root = this.initializeTree(tree);
    this.drawTree(root, 1 + root.level);
  }
  initializeTree(tree) {
    const children = tree.children?.map(child => {
      if (child.children) {
        return this.initializeTree(child);
      } else if (child.index !== void 0) {
        return {
          offset: this.anchors[child.index],
          level: child.level
        };
      } else {
        throw new Error("Unexpected error occurred while initializing tree.");
      }
    });
    return {
      level: tree.level,
      children
    };
  }
  drawTree(tree, parentLevel) {
    const {
      level
    } = tree;
    if (!tree.children) {
      if (tree.offset === void 0) {
        throw new Error("Unexpected error occurred while drawing tree.");
      }
      this.drawHorizontal(tree.offset, level, parentLevel);
      return tree;
    } else {
      const children = tree.children.map(child => this.drawTree(child, level));
      const offset = this.setCenter(children);
      this.drawVertical(children, level);
      this.drawHorizontal(offset, level, parentLevel);
      return {
        level,
        offset
      };
    }
  }
  drawHorizontal(y, level, parentLevel) {
    const right = this.xOffset(level);
    const left = this.xOffset(parentLevel);
    this.drawRect({
      x: left,
      y,
      width: right - left,
      height: this.lineThickness
    });
  }
  drawVertical(children, level) {
    const offsets = children.map(child => child.offset).filter(notEmpty);
    const top = min(...offsets);
    const bottom = max(...offsets);
    this.drawRect({
      x: this.xOffset(level),
      y: top,
      width: this.lineThickness,
      height: bottom - top
    });
  }
  xOffset(level) {
    return this.columnWidth - level * this.branchWidth;
  }
  drawRect({
    x,
    y,
    width,
    height
  }) {
    const brush = this.layer.brush;
    brush.fillStyle = "purple";
    brush.fillRect(x, y, width, height);
  }
  setCenter(children) {
    return round(children.reduce((sum, current) => sum + (current.offset ?? 0), 0) / children.length);
  }
  updateBranchWidth() {
    this.branchWidth = Math.max(3, this.columnWidth / this.plugin.levels);
  }
}
export { TreeColumn as default };