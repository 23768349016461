import { from as observableFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

/**
 * Parse id string of format 1##2#3#4 and return an array [2, 3, 4].
 *
 * @returns {(source: Observable<string>) => Observable<number>}
 */
export function parseSequenceIdString() {
  return (source: Observable<string>): Observable<number> => {
    return source.pipe(
      mergeMap((id) => observableFrom((id + '').replace(/\d+##/, '').split('#'))),
      map((id: string) => parseInt(id + '', 10)),
    );
  };
}

export function parseSequenceId(idString: string): number[] {
  const ids = (idString + '').replace(/\d+##/, '').split('#');
  return ids.map((id) => parseInt(id + '', 10));
}
