import ChromatogramCache from "./ChromatogramCache.js";
import QualitiesCache from "./QualitiesCache.js";
import GraphsChannel from "./GraphsChannel.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
const {
  floor
} = Math;
class GraphsPlugin extends AbstractPlugin {
  constructor(sv) {
    super(sv);
    this.sv = sv;
    sv.graphs = this;
    sv.registerChannelClass("graphs",
    // GraphsChannel requires a SequenceWrapper param, which is a subtype of WrapperChannel,
    // meaning it doesn't satisfy ChannelConstructor. This is because the the channel class
    // registry typing is bad, not because GraphsChannel is bad
    GraphsChannel);
    sv.registerDataCacheClass("qualities", QualitiesCache);
    sv.registerDataCacheClass("chromatogram", ChromatogramCache);
    const options = this.getInitialOptions("graphs", {
      maxQuality: {
        default: 60,
        validator: raw => typeof raw === "number"
      },
      qualities: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      chromatogram: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      height: {
        default: 40,
        validator: raw => typeof raw === "number"
      }
    });
    this._maxQuality = options.maxQuality;
    this.qualitiesEnabled = options.qualities;
    this.chromatogramEnabled = options.chromatogram;
    this._height = options.height;
    sv.bindToNode("mousemove", "graphs channel", (node, e, offset) => this.onMouseMove(node, e, offset));
  }
  _maxQuality;
  _height = 40;
  qualitiesEnabled;
  chromatogramEnabled;
  get qualities() {
    return this.qualitiesEnabled;
  }
  set qualities(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setBooleanOption("qualitiesEnabled", raw);
      this.sv.emit("channel visibility changed");
    });
  }
  get chromatogram() {
    return this.chromatogramEnabled;
  }
  set chromatogram(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setBooleanOption("chromatogramEnabled", raw);
      this.sv.emit("channel visibility changed");
    });
  }
  get maxQuality() {
    return this._maxQuality;
  }
  set maxQuality(raw) {
    if (Number.isInteger(raw) && raw > 0 && raw !== Infinity) {
      if (raw !== this._maxQuality) {
        this._maxQuality = raw;
        this.sv.view.dirty = "maxQuality changed";
      }
    }
  }
  get height() {
    return this._height;
  }
  set height(raw) {
    this.setHeightOption("_height", raw);
  }
  serialize() {
    return {
      qualities: this.qualities,
      chromatogram: this.chromatogram,
      maxQuality: this.maxQuality,
      height: this.height
    };
  }
  onMouseMove(node, event, offset) {
    if (this.qualitiesEnabled) {
      const channel = node.reference;
      this.sv.tooltip?.watch(node, event, () => {
        const index = floor(channel.view.toResidues(offset.x + channel.view.offset.x));
        const score = channel.qualitiesCache.get(index);
        return `Quality score: ${score}`;
      });
    }
  }
}
export { GraphsPlugin as default };