import SequenceCache from "./SequenceCache.js";
import SequenceChannel from "./SequenceChannel.js";
import UngappedRangesCache from "./UngappedRangesCache.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { SequenceColors } from "@geneious/shared-constants";
class SequencesPlugin extends AbstractPlugin {
  _DNAColorScheme;
  _proteinColorScheme;
  _visible;
  constructor(sv) {
    super(sv);
    sv.sequencesPlugin = this;
    sv.registerChannelClass("sequence", SequenceChannel);
    sv.registerDataCacheClass("sequence", SequenceCache);
    sv.registerDataCacheClass("keyTranspositionRanges", UngappedRangesCache);
    const options = this.getInitialOptions("sequencesPlugin", {
      DNAColorScheme: {
        default: this.defaultColorScheme,
        validator: raw => typeof raw === "string" && this.validateColorScheme(raw, SequenceColors.Nucleotide)
      },
      proteinColorScheme: {
        default: this.defaultColorScheme,
        validator: raw => typeof raw === "string" && this.validateColorScheme(raw, SequenceColors.AminoAcid)
      },
      visible: {
        default: true,
        validator: raw => typeof raw === "boolean"
      }
    });
    this._DNAColorScheme = options.DNAColorScheme;
    this._proteinColorScheme = options.proteinColorScheme;
    this._visible = options.visible;
  }
  get DNAColorScheme() {
    return this._DNAColorScheme;
  }
  set DNAColorScheme(raw) {
    this.setNullableString("_DNAColorScheme", raw);
  }
  get proteinColorScheme() {
    return this._proteinColorScheme;
  }
  set proteinColorScheme(raw) {
    this.setNullableString("_proteinColorScheme", raw);
  }
  get defaultColorScheme() {
    return this.sv.alignment.enabled ? "geneiousBackground" : "geneiousForeground";
  }
  get visible() {
    return this._visible;
  }
  set visible(raw) {
    this.setBooleanOption("_visible", raw);
  }
  serialize() {
    return {
      DNAColorScheme: this.DNAColorScheme,
      proteinColorScheme: this.proteinColorScheme,
      visible: this.visible
    };
  }
  validateColorScheme(raw, schemes) {
    return Object.keys(schemes).some(scheme => raw === scheme);
  }
}
export { SequencesPlugin as default };