import InwardZigZag from "../EndingShapes/InwardZigZag.js";
import BaseEnding from "./BaseEnding.js";
class LeftEnding extends BaseEnding {
  index;
  constructor(painter, interval) {
    super(painter, interval);
    this.index = this.interval.left;
    this.path.reverse();
  }
  nudgeForZeroLengthInterval() {
    this.index -= 0.5;
  }
  zeroLengthPath() {
    return new InwardZigZag();
  }
  // BaseEnding uses these.
  get isTruncated() {
    return this.interval.truncatedLeft;
  }
  get isTerminal() {
    return this.interval.isReversed;
  }
}
export { LeftEnding as default };