class EditHistory {
  /** Stores the sequence edits that have been applied */
  history = [];
  /** Index of the last applied edit */
  cursor = -1;
  /**
   * Adds a new edit to history. This clears any redo edits.
   *
   * @param edit to add to history
   */
  add(edit) {
    this.cursor++;
    this.history.splice(this.cursor, this.history.length - this.cursor, {
      ...edit
    });
  }
  /**
   * Navigates back one step in history, and returns the edits that came before
   * the most recent edit. The caller must then apply these edits to the
   * original (unedited) state, and the sequences will then be in the state they
   * were in prior to the most recent edit - thus "undoing" it.
   *
   * @returns the edits to apply, or null if there are no edits to undo.
   */
  undo() {
    if (this.canUndo()) {
      this.cursor--;
      return this.getHistory();
    }
    return null;
  }
  /**
   * Navigates one step forward in history, and returns the edit that previously
   * undone. Returns null if there is no edit in history to redo.
   *
   * @returns the redo edit to apply, or null if there is no edit to redo
   */
  redo() {
    if (this.canRedo()) {
      this.cursor++;
      return {
        ...this.history[this.cursor]
      };
    }
    return null;
  }
  /**
   * @returns true if there is an edit in history to undo
   */
  canUndo() {
    return this.cursor >= 0 && this.history.length > 0;
  }
  /**
   * @returns true if there is an edit in history to redo
   */
  canRedo() {
    return this.cursor < this.history.length - 1;
  }
  /**
   * Returns all applied edits. Does not include unapplied redo edits.
   * @returns an array of applied sequence edits
   */
  getHistory() {
    return this.history.slice(0, this.cursor + 1).map(edit => ({
      ...edit
    }));
  }
  /**
   * Clears all edits from history.
   */
  clear() {
    this.history = [];
    this.cursor = -1;
  }
}
export { EditHistory as default };