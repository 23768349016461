import GlobalsWrapper from "../Channels/GlobalsWrapper.js";
import ReferenceWrapper from "../Channels/ReferenceWrapper.js";
import SequenceWrapper from "../Channels/SequenceWrapper.js";
import RRange from "../Range/RRange.js";
import GraphPainter from "../RenderEngine/GraphPainter.js";
import RenderContext from "../RenderEngine/RenderContext.js";
import RenderNode from "../RenderEngine/RenderNode.js";
import SequencePainter from "../RenderEngine/SequencePainter.js";
import { clamp, sum } from "../misc/Math.js";
const {
  min,
  max,
  ceil,
  floor,
  round
} = Math;
class ChannelView {
  constructor(viewport) {
    this.viewport = viewport;
    this.sequences = this.initializeSequenceWrappers();
    const {
      range,
      wrapped,
      reference
    } = this.sv.options ?? {
      range: {
        start: 0
      },
      wrapped: false
    };
    this._wrapped = wrapped;
    this.initializeLength();
    this.globals = new GlobalsWrapper(this);
    this.referenceWrapper = new ReferenceWrapper(this);
    this._setReference(reference);
    this.sequencePainter = new SequencePainter(this);
    this.graphPainter = new GraphPainter(this);
    setTimeout(() => {
      try {
        this.initializeViewPort(range);
      } catch (e) {
        this.sv.view.displayError(e);
      }
    });
    this.sv.bind("height changed", () => viewport.sv.emit("resize"));
    this.sv.bindOnce("ready", () => {
      if (this.sv.view.circular) {
        this.setResidueWidth(this.calculatedMinResidueWidth);
        this.setOffset({
          x: 0,
          y: 0
        });
      }
    });
    this.sv.bind("view mode changed", () => {
      this.viewport.initializeDimensions();
      this.initializeViewportCoordinates(range);
      this.setResidueWidth(this.calculatedMinResidueWidth);
      if (this.sv.view.circular) {
        this.setOffset({
          x: 0,
          y: 0
        });
      }
    });
    this.sv.bind("resize", () => {
      if (this.sv.view.circular) {
        this.setResidueWidth(this.calculatedMinResidueWidth);
        this.setOffset({
          x: 0,
          y: 0
        });
      }
    });
  }
  sequencePainter;
  graphPainter;
  globals;
  sequences;
  referenceWrapper;
  length;
  wrappedMargin = 5;
  maxResidueWidth = 100;
  _wrapped;
  // x/y offset of the viewport relative to the entire view in pixels.
  _offset;
  _residueWidth;
  _initialResidueWidth;
  _focus = null;
  _reference = null;
  // True if the current/last zoom event is zooming out.
  zoomingOut = false;
  get sv() {
    return this.viewport.sv;
  }
  get brush() {
    return this.sv.view.brush;
  }
  get graphics() {
    return this.sv.view.graphics;
  }
  get rowIndexes() {
    const height = this.rowHeightWithMargin;
    const first = floor(this.offset.y / height);
    const byViewport = ceil((this.offset.y + this.viewport.height) / height);
    const last = min(this.rowCount, byViewport);
    return new RRange(first, last);
  }
  calculateLayout(bounds) {
    return this.wrapped ? this.rowIndexes.map(index => this.rowNode(index, bounds)) : [this.rowNode(0, bounds)];
  }
  rowNode(index, bounds) {
    const context = this.renderContext(index);
    const height = this.wrapped ? this.rowHeightWithMargin : this.rowHeight;
    const viewRowBounds = {
      x: 0,
      y: index * height - this.offset.y,
      width: bounds.width,
      height
    };
    return new RenderNode("view row", context, viewRowBounds, this.rowColumns(context, viewRowBounds));
  }
  rowColumns(context, row) {
    const column = {
      x: this.viewport.sidebarWidth,
      y: 0,
      width: row.width,
      height: row.height
    };
    const channels = this.channelNodes(context, row, column);
    const node = new RenderNode("channels column", this, column, channels);
    node.setRenderCallback(() => this.sv.emit("channel column render", node));
    return [node].concat(this.viewport.sidebarColumnNodes(row.height, channels));
  }
  channelNodes(context, row, column) {
    return this.renderChannelsInViewport(row, (offset, channel) => {
      return channel.calculateLayout(context, {
        x: 0,
        y: offset,
        width: column.width,
        height: channel.height
      });
    });
  }
  /**
   * Calls renderCallback(), but only for channels that are actually in the viewport.
   *
   * Clips out channels in the vertical dimension.
   *
   * TODO Use this for data channels as well as wrapper channels.
   */
  renderChannelsInViewport(row, renderCallback) {
    let offset = 0;
    let previousHeight = 0;
    const nodes = [];
    const sticky = [];
    this.outputSortedWrappers.some((channel, index) => {
      if (channel.isSticky && this.viewportTopOrAbove(offset, previousHeight, row.y) && this.isStickyWrapper(index)) {
        previousHeight = offset + channel.heightWithMargin;
        const y = min(0 - row.y, row.height - channel.heightWithMargin) + offset;
        const node = renderCallback(y, channel);
        if (node) {
          sticky.push(node);
        }
      } else {
        const gap = this.distanceFromViewport(row.y + offset, channel.height);
        if (gap < 0) {} else if (gap > 0) {
          return true;
        } else {
          const node = renderCallback(offset, channel);
          if (node) {
            nodes.push(node);
          }
        }
      }
      offset += channel.heightWithMargin;
    });
    return [...nodes, ...sticky];
  }
  get outputSortedWrappers() {
    return this.stickyWrappers.concat(this.sequences);
  }
  get stickyWrappers() {
    return this.reference && this.reference.isSticky ? [this.globals, this.referenceWrapper] : [this.globals];
  }
  isStickyWrapper(index) {
    return index < this.stickyWrappers.length;
  }
  viewportTopOrAbove(viewportOffset, previousHeight, scrollOffset) {
    return viewportOffset <= previousHeight - scrollOffset;
  }
  distanceFromViewport(top, height) {
    const bottom = top + height;
    if (bottom < 0) {
      return bottom;
    } else if (top > this.viewport.height) {
      return top - this.viewport.height;
    } else {
      return 0;
    }
  }
  renderContext(index) {
    const start = index * this.rowLength;
    const renderStart = start + floor(this.toResidues(this.offset.x));
    const row = new RRange(start, min(start + this.rowLength, this.length));
    const visible = new RRange(renderStart, renderStart + this.visibleResidues(renderStart));
    return new RenderContext(this, index, row, visible);
  }
  /**
   * Gets the number of visible residues.
   *
   * @param offset {number} The x offset of first visible residue in pixels.
   * @returns {number}
   */
  visibleResidues(offset) {
    if (this.wrapped) {
      return min(this.rowLength, this.length - offset);
    }
    if (this.sv.view.circular) {
      return this.rowLength;
    }
    const index = this.toResidues(this.offset.x + this.viewport.width);
    return min(this.rowLength, ceil(index) - offset);
  }
  toResidues(pixels) {
    return pixels / this.residueWidth;
  }
  toPixels(residues) {
    return residues * this.residueWidth;
  }
  get wrapped() {
    return this._wrapped;
  }
  set wrapped(newValue) {
    if (this._wrapped !== newValue) {
      const focus = this.focus;
      this._wrapped = newValue;
      const offset = {
        ...this.offset
      };
      offset[this.secondaryAxis] = 0;
      this.setOffset(offset);
      this.clampResidueWidth();
      this.focus = focus;
      this.sv.emit("wrapped changed", this.wrapped);
      this.sv.view.dirty = "wrapped changed";
    }
  }
  /**
   * Getter methods for primitive pseudo-properties that are dependant on real properties (the single sources of
   * truth, set in in the constructor).
   */
  get rowCount() {
    return ceil(this.length / this.rowLength);
  }
  // @return {number} length of a row in residues.
  get rowLength() {
    if (this.wrapped) {
      return floor(this.viewport.width / this.residueWidth);
    } else {
      return this.length;
    }
  }
  get rowHeightWithMargin() {
    return this.wrappedMargin + this.rowHeight;
  }
  get rowHeight() {
    const channels = this.outputSortedWrappers;
    const heightWithMargin = sum(channels.map(channel => channel.heightWithMargin));
    const last = channels[channels.length - 1];
    return heightWithMargin - last.margin;
  }
  /**
   * Gets the minimum number of rows allowed in wrapped view, i.e. the largest number of rows that can be displayed
   * without needing to scroll.
   * If one row is taller than the viewport, return 1 (since we can't show 0 rows).
   * @returns {number}
   */
  get minRows() {
    return floor((this.viewport.height + this.wrappedMargin) / this.rowHeightWithMargin) || 1;
  }
  get primaryAxis() {
    return this.wrapped ? "y" : "x";
  }
  get secondaryAxis() {
    return this.wrapped ? "x" : "y";
  }
  get channels() {
    const result = [this.globals];
    return result.concat(this.sequences);
  }
  get width() {
    return this.rowLength * this.residueWidth;
  }
  get height() {
    return this.wrapped ? this.wrappedHeight : this.rowHeight;
  }
  get wrappedHeight() {
    return this.rowHeightWithMargin * ceil(this.rowCount) - this.wrappedMargin;
  }
  get minResidueWidth() {
    return min(this.calculatedMinResidueWidth, this._initialResidueWidth, this.maxResidueWidth, this.sv.showResiduesWidth);
  }
  get calculatedMinResidueWidth() {
    if (this.wrapped) {
      const residuesPerRow = this.length / this.minRows;
      const rowWidth = this.rowLength * this.residueWidth;
      const residueWidth = rowWidth / residuesPerRow;
      return this.residueWidthIsVisible(residueWidth) ? floor(residueWidth) : residueWidth;
    } else if (this.sv.view.circular) {
      const smallestDimension = Math.min(this.viewport.width, this.viewport.height);
      return smallestDimension * Math.PI / this.length;
    } else {
      return this.viewport.width / this.length;
    }
  }
  get residuesAreVisible() {
    return this.residueWidthIsVisible(this.residueWidth);
  }
  residueWidthIsVisible(width) {
    return width >= 1;
  }
  get positionIndex() {
    return this.toResidues(this.offset.x);
  }
  /**
   * Adjust current residue width to fit within bounds, and flag the canvas as dirty if the residue width has
   * been adjusted.
   */
  clampResidueWidth() {
    this.setResidueWidth(this.residueWidth);
  }
  get residueWidth() {
    return this.sv.invoke("get residue width").reduce((prev, curr) => max(prev, curr), this._residueWidth);
  }
  /**
   * Sets a new zoom level, ensuring that the new value is in range. Returns true if the zoom level changed.
   */
  setResidueWidth(newValue) {
    if (this.sv.view.circular) {
      newValue = this.calculatedMinResidueWidth;
    }
    newValue = clamp(newValue, this.maxResidueWidth, this.minResidueWidth);
    const oldValue = this.residueWidth;
    if (newValue == oldValue) {
      return false;
    } else {
      const height = this.sequencePainter.channelHeight;
      this.zoomingOut = newValue < oldValue;
      const offset = this.offsetFraction.y;
      this._setResidueWidth(newValue);
      if (height !== this.sequencePainter.channelHeight) {
        this.sv.emit("height changed");
        if (!this.wrapped) {
          this.setOffsetFraction("y", offset);
        }
      }
      this.sv.view.dirty = "residueWidth";
      this.sv.emit("zoom");
      return true;
    }
  }
  _setResidueWidth(newValue) {
    const focus = this.focus;
    const offset = this.focusOffset;
    const selection = this.selection;
    this._residueWidth = newValue;
    this.focusAt(focus, offset);
    if (selection && focus != this.focus) {
      this.focus = selection.center;
    }
  }
  // Returns a Vec2 set to n in the primary dimension and 0 in the secondary dimension.
  toPrimaryOffset(n) {
    return this.wrapped ? {
      x: 0,
      y: n
    } : {
      x: n,
      y: 0
    };
  }
  toOffsetAxis(axis, n) {
    return axis === "x" ? {
      x: n,
      y: 0
    } : {
      x: 0,
      y: n
    };
  }
  get offset() {
    return this._offset;
  }
  get maxOffset() {
    if (this.wrapped) {
      return {
        x: 0,
        y: this.height - this.viewport.height
      };
    } else {
      return {
        x: this.width - this.viewport.width,
        y: max(0, this.rowHeight - this.viewport.height)
      };
    }
  }
  /**
   * Sets the XY offset of the viewport relative to the view in pixels.
   * It returns true if the offset has changed.
   */
  setOffset(newOffset) {
    this._focus = null;
    return this._setOffset(newOffset);
  }
  moveOffset(delta) {
    return this.setOffset({
      x: this.offset.x + delta.x,
      y: this.offset.y + delta.y
    });
  }
  /**
   * Adjust current offset to fit within bounds, and flag the canvas as dirty if the offset was adjusted.
   */
  clampOffset() {
    this._setOffset(this.offset);
  }
  /**
   * Adjusts the viewport's offset to remain in the same location (focused) when row height changes.
   * TODO Should not use offsetFraction as this results in restoring the view at the wrong position because of the way annotations load.
   */
  maintainFocusWhileChangingRowHeight(callback) {
    if (this.wrapped) {
      const focus = this.focus;
      const offset = this.focusOffset;
      callback();
      this.sv.emit("height changed");
      this.focusAt(focus, offset);
    } else {
      const offset = this.offsetFraction.y;
      callback();
      this.sv.emit("height changed");
      this.setOffsetFraction("y", offset);
    }
  }
  _setOffset(newOffset) {
    const maximum = this.maxOffset;
    newOffset = {
      x: clamp(newOffset.x, maximum.x),
      y: clamp(newOffset.y, maximum.y)
    };
    Object.freeze(newOffset);
    if (newOffset.x !== this.offset.x || newOffset.y !== this.offset.y) {
      this._offset = newOffset;
      this.sv.view.dirty = "offset";
      this.sv.emit("offset changed");
      return true;
    } else {
      return false;
    }
  }
  get offsetFraction() {
    const maxOffset = this.maxOffset;
    return {
      x: maxOffset.x ? this.offset.x / maxOffset.x : 0,
      y: maxOffset.y ? this.offset.y / maxOffset.y : 0
    };
  }
  /**
   * Sets the offset along an axis to a given fraction of the entire width/height of the view.
   */
  setOffsetFraction(axis, fraction) {
    const maxOffset = this.maxOffset[axis];
    const newOffset = round(maxOffset * clamp(fraction, 1));
    const offset = {
      x: this.offset.x,
      y: this.offset.y
    };
    offset[axis] = newOffset;
    this.setOffset(offset);
  }
  get focusOffset() {
    const anchor = this.anchoredFocus;
    if (!anchor) {
      return this.viewport.centerOffset;
    } else if (this.wrapped) {
      const height = this.rowHeightWithMargin;
      const rowIndex = floor(anchor / this.rowLength);
      return height * rowIndex - this.offset.y;
    } else {
      return this.toPixels(anchor) - this.offset.x;
    }
  }
  focusOn({
    indexes,
    range
  }) {
    const row = floor(range.center / this.rowLength);
    this.scrollToSequences(indexes, row);
    const context = this.renderContext(row);
    if (!context.visible.containsRange(range)) {
      const width = this.viewport.width;
      if (width / range.length < this.residueWidth) {
        this.setResidueWidth(0.9 * width / range.length);
      }
      if (!this.wrapped) {
        this.focus = range.center;
      }
    }
  }
  scrollToSequences(sequences, focus) {
    const offset = {
      ...this.offset
    };
    const range = this.getChannelOffsetRange(sequences[0], sequences[sequences.length - 1], focus);
    const viewport = new RRange(offset.y, offset.y + this.viewport.height);
    if (!viewport.containsRange(range)) {
      offset.y = range.length > this.viewport.height ? range.start : round((range.start + range.end - this.viewport.height) / 2);
      this.setOffset(offset);
    }
  }
  getChannelOffsetRange(first, last, row) {
    let height = row * this.rowHeightWithMargin;
    let top;
    let bottom;
    for (let i = 0; i <= last; i++) {
      if (i === first) {
        top = height;
      }
      height += this.channels[i].heightWithMargin;
      if (i === last) {
        bottom = height;
      }
    }
    return new RRange(top, bottom);
  }
  get focus() {
    const anchor = this.anchoredFocus;
    if (anchor != null) {
      this._focus = null;
      return anchor;
    }
    if (this._focus == null) {
      this._focus = this.getFocusIndex();
    }
    return this._focus;
  }
  set focus(focus) {
    this._focus = focus;
    this.focusAt(focus, this.viewport.centerOffset);
  }
  focusAt(focus, offset) {
    const pixels = this.wrapped ? floor(focus / this.rowLength) * this.rowHeightWithMargin : this.toPixels(focus);
    const newOffset = {
      ...this.offset
    };
    newOffset[this.primaryAxis] = round(pixels - offset);
    this._setOffset(newOffset);
  }
  getFocusIndex() {
    const axis = this.primaryAxis;
    const offset = this.offset[axis];
    const maxOffset = this.maxOffset[axis];
    if (offset == maxOffset && maxOffset !== 0) {
      return this.length - 1;
    } else if (offset == 0) {
      return 0;
    } else {
      return this.getFocalPointAtCoordinate(offset + this.viewport.centerOffset);
    }
  }
  get anchoredFocus() {
    const selection = this.selection;
    const viewport = this.viewport.residueRange;
    if (!selection) {
      return;
    } else if (selection.isEmpty) {
      return selection.start;
    } else if (this.zoomingOut || viewport.center === selection.center) {
      return selection.center;
    } else if (viewport.center > selection.center) {
      return selection.start;
    } else {
      return selection.end;
    }
  }
  get selection() {
    if (this.sv.selection) {
      const {
        current
      } = this.sv.selection;
      if (current.length === 1) {
        const selection = current[0].range;
        if (this.viewport.residueRange.contains(selection)) {
          return selection;
        }
      }
    }
  }
  getFocalPointAtCoordinate(offset) {
    if (this.wrapped) {
      const row = floor(offset / this.rowHeightWithMargin);
      const len = this.rowLength;
      return len * row + len / 2;
    } else {
      return round(this.toResidues(offset));
    }
  }
  get options() {
    return this.sv.options;
  }
  /******************************************************************************************
   * Initialization methods, called from constructor.
   */
  initializeSequenceWrappers() {
    const data = this.options?.sequences;
    if (!data || !data.length) {
      throw new Error("At least one sequence must be configured");
    }
    this.initializeLength();
    return data.map((sequence, index) => {
      if (Number.isInteger(sequence.length)) {
        return new SequenceWrapper(this, sequence, index);
      } else {
        throw new Error(`Expected "length" to be an integer, got type ${typeof sequence.length}.`);
      }
    });
  }
  initializeViewPort(range) {
    this.viewport.initializeDimensions();
    const coords = this.initializeViewportCoordinates(range);
    this.sv.bootstrapCompleted();
    if (range.start > 0 || range.length || range.end) {
      this.completeInitialization(coords);
    }
  }
  /**
   * Updates focal point on every render frame until all initial data requests are resolved.
   *
   * NOTE There are probably race conditions here if the user starts scrolling before all
   * the initial data requests have resolved.
   */
  completeInitialization(coords) {
    const hook = "postrender";
    let dataResolved = false;
    this.sv.bind("all data requests resolved", () => dataResolved = true);
    const completer = () => {
      this.initializeFocalPoint(coords);
      if (dataResolved) {
        this.sv.unbind(hook, completer);
      }
    };
    this.sv.bind(hook, completer);
  }
  initializeFocalPoint({
    focus,
    length
  }) {
    if (this.wrapped) {
      const rows = this.minRows - 2;
      this.setResidueWidth(this.viewport.width * rows / length);
      this.focus = focus;
    }
  }
  get reference() {
    return this.sequences.find(sequence => sequence.originalIndex === this._reference);
  }
  setReference(originalIndex) {
    if (this.reference) {
      this.reference.isReference = false;
    }
    this._setReference(originalIndex);
    this.sv.emit(["channel visibility changed", "reference changed"]);
    this.sv.view.dirty = "reference changed";
  }
  _setReference(originalIndex) {
    if (Number.isInteger(originalIndex)) {
      const sequence = this.sequences.find(wrapper => wrapper.originalIndex === originalIndex);
      if (sequence) {
        sequence.isReference = true;
        this._reference = originalIndex;
      } else {
        throw new Error(`Sequence reference index ${originalIndex} is invalid.`);
      }
    } else {
      this._reference = null;
    }
  }
  initializeLength() {
    const lengths = this.options?.sequences.map(group => group.length) ?? [];
    this.length = this._validateLengths(lengths);
    if (!Number.isInteger(this.length) || this.length < 0) {
      throw new Error("An invalid length was provided.");
    }
  }
  /**
   * Re-calculates the view length based on sequence lengths.
   */
  recalculateLength() {
    this.length = this._validateLengths(this.sequences.map(sequence => sequence.sequenceCache.length));
  }
  initializeViewportCoordinates(range) {
    const length = this._initialViewportLength();
    this.initializeResidueWidth(this.viewport.width / length);
    const start = range.start - 1;
    this.initializeOffset(start);
    const focus = start + length / 2;
    this.focus = focus;
    return {
      focus,
      length
    };
  }
  initializeResidueWidth(rw) {
    this._residueWidth = rw > 1 ? floor(rw) : rw;
    this._initialResidueWidth = this.options?.zoom.residueWidth || this._residueWidth;
  }
  initializeOffset(index) {
    this._offset = this.toPrimaryOffset(this._initialOffset(index));
    this.clampOffset();
  }
  _validateLengths(lengths) {
    const length = max(...lengths);
    const limit = 2 ** 32 - 1;
    if (length > limit) {
      const formatted = limit.toLocaleString();
      throw new Error(`Sequence is too long. Sequences up to ${formatted} are supported.`);
    }
    return length;
  }
  _initialOffset(index) {
    if (this.wrapped) {
      return round(this.rowHeightWithMargin * floor(index / this.rowLength));
    } else {
      return round(this.toPixels(index));
    }
  }
  _initialViewportLength() {
    const {
      start,
      end,
      length
    } = this.options?.range ?? {
      start: 0
    };
    const zoom = this.options?.zoom ?? {
      residueWidth: 1,
      sequenceFraction: 1
    };
    return length || (end ? end - start : this._initialViewportLengthFromZoomOptions(zoom));
  }
  _initialViewportLengthFromZoomOptions({
    residueWidth,
    sequenceFraction
  }) {
    if (residueWidth) {
      return this.viewport.width / residueWidth;
    } else if (sequenceFraction) {
      return sequenceFraction * this.length;
    } else {
      throw new Error("Invalid zoom.sequenceFraction");
    }
  }
}
export { ChannelView as default };