import { GeneticCodes } from "@geneious/shared-constants";
import mappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import SequenceCache from "../Sequences/SequenceCache.js";
class TranslationsData {
  constructor(sv) {
    this.sv = sv;
    this.addTables(GeneticCodes);
  }
  frames = null;
  tables = {};
  _tableName;
  _indices = {
    T: 0,
    U: 0,
    C: 1,
    A: 2,
    G: 3
  };
  // How many unique digits there are in an index.
  indexBase = 4;
  _toIndex(s) {
    const length = this.indexBase;
    return this._indices[s[0]] * length * length + this._indices[s[1]] * length + this._indices[s[2]];
  }
  /**
   * Translates codons.
   *
   * @param {string[]} residues  3 characters representing the codon to translate.
   * @param {boolean} isFirst True to start as a start codon. False to translate as a non-start codon.
   *                          Anything else to translate ambiguously as either. E.g. "L / M"
   * @returns {TranslatedCodon}
   */
  translateCodon(residues, isFirst) {
    residues.length = 3;
    const codon = Array.from(residues);
    if (codon.some(char => !SequenceCache.charIsDefined(char))) {
      return null;
    } else {
      const isAmbiguous = char => !(char in this._indices);
      const ambiguities = codon.reduce((count, char) => isAmbiguous(char) ? count + 1 : count, 0);
      if (ambiguities > 1) {
        return this.wrapSingleChar("X");
      } else if (typeof isFirst != "boolean" || ambiguities === 1) {
        const index = codon.findIndex(isAmbiguous);
        return this.translateAmbiguousCodon(codon, index, isFirst);
      } else {
        const char = isFirst ? this.startCodonToAminoAcid(codon) : this.toAminoAcid(codon);
        if (!char) {
          return null;
        }
        return this.wrapSingleChar(char);
      }
    }
  }
  translateAmbiguousCodon(codon, ambiguousIndex, isFirst) {
    if (!this.table) {
      return null;
    }
    function setAmbiguity(base) {
      const newCodon = [...codon];
      newCodon[ambiguousIndex] = base;
      return newCodon;
    }
    const canonicals = ambiguousIndex === -1 ? ["A", "C", "G", "T"] : Array.from(mappers.Nucleotide.toCanonical(codon[ambiguousIndex]) ?? []);
    let ordinary = [];
    let starts = [];
    if (isFirst !== true) {
      ordinary = canonicals.map(base => this.toAminoAcid(setAmbiguity(base)));
    }
    if (isFirst !== false) {
      starts = canonicals.map(base => this.startCodonToAminoAcid(setAmbiguity(base)));
    }
    const ordinaryTranslations = Array.from(new Set(ordinary));
    const startTranslations = Array.from(new Set(starts));
    const uniqueTranslations = Array.from(/* @__PURE__ */new Set([...ordinary, ...starts]));
    switch (uniqueTranslations.length) {
      case 1:
        return this.wrapSingleChar(uniqueTranslations[0]);
      case 2:
        return this.joinTwoPossibleTranslations(ordinaryTranslations, uniqueTranslations, startTranslations);
      default:
        return this.wrapSingleChar("X");
    }
  }
  joinTwoPossibleTranslations(ordinary, unique, start) {
    const hasStart = ordinary.length === 1;
    if (hasStart) {
      return this.translationObjectFromLetters(ordinary[0], start[0], ordinary[0]);
    } else {
      return this.translationObjectFromLetters(unique[0], unique[1], "?");
    }
  }
  toAminoAcid(codon) {
    return this.table?.translations[this._toIndex(codon)];
  }
  startCodonToAminoAcid(codon) {
    const start = this.table?.startAndStop[this._toIndex(codon)];
    return start && start !== "-" ? start : this.toAminoAcid(codon);
  }
  wrapSingleChar(char) {
    return {
      text: " " + char + " ",
      colorCode: char
    };
  }
  translationObjectFromLetters(first, second, color) {
    return {
      text: `${first}/${second}`,
      colorCode: color
    };
  }
  addTables(tables) {
    Object.entries(tables).forEach(([name, {
      translations,
      startAndStop
    }]) => {
      this.tables[name] = {
        translations,
        startAndStop
      };
    });
  }
  set tableName(name) {
    if (!(name in this.tables)) {
      const tables = Object.keys(this.tables).join(", ");
      throw new Error(`Translation table '${name}' is not in available translation tables: ${tables}`);
    } else {
      const {
        translations,
        startAndStop
      } = this.tables[name];
      if (!(translations && startAndStop && translations.length === 64 || startAndStop.length === 64)) {
        throw new Error(`Translation table ${name} requires properties 'translations' and 'startAndStop', and they must each be 64 characters.`);
      } else {
        this._tableName = name;
        if (this.sv.view) {
          this.sv.view.dirty = "translation table changed";
        }
      }
    }
  }
  get tableName() {
    return this._tableName;
  }
  get table() {
    if (!this._tableName) {
      return null;
    }
    return this.tables[this._tableName];
  }
}
export { TranslationsData as default };