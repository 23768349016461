import OutwardZigZag from "../EndingShapes/OutwardZigZag.js";
import BaseEnding from "./BaseEnding.js";
class RightEnding extends BaseEnding {
  index;
  constructor(painter, interval) {
    super(painter, interval);
    this.index = this.interval.right;
    this.path.mirror();
  }
  nudgeForZeroLengthInterval() {
    this.index += 0.5;
  }
  zeroLengthPath() {
    return new OutwardZigZag();
  }
  // BaseEnding uses these.
  get isTruncated() {
    return this.interval.truncatedRight;
  }
  get isTerminal() {
    return this.interval.isForwards;
  }
}
export { RightEnding as default };