import { MetadataColumnGroup, MetadataColumnOrGroup } from './sequence-viewer.interfaces';

export function isMetadataColumnGroup(group: MetadataColumnOrGroup): group is MetadataColumnGroup {
  return (<MetadataColumnGroup>group).columns !== undefined;
}

export function isNumeric(value: any) {
  if (value == null) {
    return false;
  } else if (typeof value === 'string' && value.trim().length === 0) {
    return false;
  } else {
    return !isNaN(value);
  }
}
