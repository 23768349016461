import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
class ProgressPlugin extends AbstractPlugin {
  element;
  timer;
  delay = 100;
  // Tracks how many data requests are in progress.
  counter = 0;
  constructor(sv) {
    super(sv);
    sv.bind("ready", () => {
      const canvas = sv.view.canvasElement;
      canvas.insertAdjacentHTML("afterend", `<div class="loading-bar-container"><div class="loading-bar"></div></div>`);
      this.element = canvas.parentElement.querySelector("div.loading-bar-container");
      this.hide();
      sv.bind("postrender", (_time, flags) => this.startTimer(flags));
      sv.bind("data request started", () => this.increment());
      sv.bind("data request resolved", () => this.decrement());
    });
  }
  startTimer(flags) {
    if (flags.has("initialized") || flags.has("offset") || flags.has("residueWidth")) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = window.setTimeout(() => this.show(), this.delay);
    }
  }
  increment() {
    this.counter++;
  }
  decrement() {
    this.counter--;
    if (this.counter < 1) {
      this.sv.emit("all data requests resolved");
      this.hide();
    }
  }
  show() {
    if (this.counter > 0) {
      this.display = "";
    }
  }
  hide() {
    this.display = "none";
  }
  set display(value) {
    if (this.element) {
      this.element.style.display = value;
    }
  }
  serialize() {
    return void 0;
  }
}
export { ProgressPlugin as default };