import DataCache from "../../includes/PluginBaseClasses/DataCache.js";
const {
  round
} = Math;
class QualitiesCache extends DataCache {
  qualities = [];
  get(index) {
    return this.qualities[index];
  }
  get maxQuality() {
    return this.sv.graphs.maxQuality;
  }
  getScaled(index, scale) {
    const value = Math.min(this.get(index) ?? 0, this.maxQuality);
    return round(scale * (this.scaleSize - value) / this.scaleSize);
  }
  get scaleSize() {
    return this.maxQuality + 1;
  }
  isSet(index) {
    return index in this.qualities;
  }
  // NOTE: Very similar to `SequenceCache.set()`.
  set({
    start
  }, qualities) {
    if (!qualities) {
      return false;
    }
    let changed = false;
    for (let i = 0; i < qualities.length; i++) {
      const index = i + start;
      const value = qualities[i];
      if (this.qualities[index] != value) {
        this.qualities[index] = value;
        changed = true;
      }
    }
    return changed;
  }
  get hasData() {
    return this.qualities.length > 0;
  }
  /**
   * Gets all data in the cache. If the cache has not been fully populated
   * (i.e. if it contains null values) an error will be thrown.
   *
   * @returns a copy of all quality data
   */
  getAllData() {
    if (this.qualities.some(value => value === null)) {
      throw new Error("The qualities cache is not fully populated");
    }
    return [...this.qualities];
  }
  /**
   * Replaces all qualities in the cache with the provided array.
   *
   * @param qualities the new qualities
   */
  replaceAll(qualities) {
    this.qualities = qualities;
  }
}
export { QualitiesCache as default };