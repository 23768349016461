import { clamp } from "../../includes/misc/Math.js";
const {
  min
} = Math;
class TooltipPlugin {
  sv;
  tooltip;
  node = null;
  timer = null;
  mouse = {
    x: 0,
    y: 0
  };
  mouseMargin = 20;
  constructor(sv) {
    this.sv = sv;
    sv.tooltip = this;
    this.tooltip = document.createElement("div");
    this.tooltip.classList.add("sv-tooltip");
    this.tooltip.classList.add("hidden");
    sv.addEventListenerToElement(this.tooltip, "transitionend", () => this.transitionend());
    document.body.appendChild(this.tooltip);
    sv.addEventListenerToElement(document.body, "click", () => this.click());
    sv.bind("cleanup", () => this.tooltip.remove());
    sv.bind("mouseleave", () => this.mouseleave());
    sv.bind("resize", () => {
      if (!this.hidden) {
        this.moveWithinWindow();
      }
    });
  }
  mouseleave() {
    if (this.timer) {
      this.cancelTimer();
    }
    this.startHiding();
    this.node = null;
  }
  transitionend() {
    if (this.tooltip.classList.contains("hiding")) {
      this.hide();
    }
  }
  click() {
    this.cancelTimer();
    this.hide();
  }
  /**
   * Watches a node to determine whether, when and how to display the tooltip.
   */
  watch(node, position, contentCallback) {
    this.node = node;
    this.mouse = position;
    if (this.timer) {
      this.cancelTimer();
    }
    this.timer = window.setTimeout(() => {
      if (this.hidden) {
        this.timer = window.setTimeout(() => {
          this.display(contentCallback());
        }, 800);
      } else {
        this.tooltip.classList.remove("hiding");
        this.display(contentCallback());
      }
    }, 500);
  }
  display(html) {
    this.cancelTimer();
    this.tooltip.classList.remove("hidden");
    this.tooltip.classList.remove("hiding");
    this.tooltip.innerHTML = html;
    this.moveWithinWindow();
  }
  moveWithinWindow() {
    this.tooltip.style.left = "0";
    this.tooltip.style.top = "0";
    this.tooltip.style.maxWidth = min(500, document.body.clientWidth) + "px";
    const {
      x,
      y
    } = this.getOffset();
    this.tooltip.style.left = x.toFixed(2) + "px";
    this.tooltip.style.top = y.toFixed(2) + "px";
  }
  getOffset() {
    const verticalY = this.verticalY;
    if (verticalY !== null) {
      return {
        x: this.verticalX,
        y: verticalY
      };
    }
    const horizontalX = this.horizontalX;
    if (horizontalX !== null) {
      return {
        x: horizontalX,
        y: this.horizontalY
      };
    }
    return {
      x: 0,
      y: 0
    };
  }
  get verticallyAlignedCoordinates() {
    return [this.verticalX, this.verticalY];
  }
  get horizontallyAlignedCoordinates() {
    return [this.horizontalX, this.horizontalY];
  }
  get verticalX() {
    const left = document.body.scrollLeft;
    const right = document.body.scrollLeft + document.body.clientWidth - this.tooltip.offsetWidth;
    return clamp(this.mouse.x, right, left);
  }
  get verticalY() {
    const top = document.body.scrollTop;
    const midTop = this.mouse.y - this.mouseMargin;
    const midBotton = this.mouse.y + this.mouseMargin;
    const bottom = document.body.scrollTop + document.body.clientHeight;
    if (this.tooltip.offsetHeight <= bottom - midBotton) {
      return midBotton;
    } else if (this.tooltip.offsetHeight <= midTop - top) {
      return midTop - this.tooltip.offsetHeight;
    } else {
      return null;
    }
  }
  get horizontalX() {
    const left = document.body.scrollLeft;
    const midLeft = this.mouse.x - this.mouseMargin;
    const midRight = this.mouse.x + this.mouseMargin;
    const right = document.body.scrollLeft + document.body.clientWidth;
    if (this.tooltip.offsetWidth <= right - midRight) {
      return midRight;
    } else if (this.tooltip.offsetWidth <= midLeft - left) {
      return midLeft - this.tooltip.offsetWidth;
    } else {
      return null;
    }
  }
  get horizontalY() {
    const top = document.body.scrollTop;
    const bottom = document.body.scrollTop + document.body.clientHeight - this.tooltip.offsetHeight;
    return clamp(this.mouse.y, bottom, top);
  }
  cancelTimer() {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }
  startHiding() {
    this.tooltip.classList.add("hiding");
  }
  hide() {
    this.tooltip.classList.remove("hiding");
    this.tooltip.classList.add("hidden");
  }
  get hidden() {
    return this.tooltip.classList.contains("hidden");
  }
}
export { TooltipPlugin as default };