import AnnotationRow from "./AnnotationRow.js";
import { sum } from "../../../includes/misc/Math.js";
import { lowestString } from "../../../includes/misc/Utils.js";
class RowGroup {
  constructor(type) {
    this.type = type;
  }
  rows = [];
  get length() {
    return this.rows.length;
  }
  get first() {
    return this.rows[0];
  }
  get meanLength() {
    return this.totalIntervalLength / this.totalIntervalCount;
  }
  get totalIntervalLength() {
    const lengths = this.rows.map(row => row.coveredLength);
    return sum(lengths);
  }
  get totalIntervalCount() {
    const counts = this.rows.map(row => row.intervalCount);
    return sum(counts);
  }
  findRow(predicate) {
    return this.rows.find(predicate);
  }
  /**
   * This is the only mutable public method.
   *
   * AnnotationsCache gets pretty ugly without it.
   */
  append(...rows) {
    this.rows.push(...rows);
    return this;
  }
  merge(other) {
    const start = this.findRowToMergeGroup(other);
    const result = this.copy();
    if (start < 0) {
      result.union(other);
    } else {
      result.mergeAt(start, other);
    }
    result.type = lowestString([this.type, other.type]);
    return result;
  }
  addAnnotation(annotation) {
    const availableRow = this.findRow(row => row.canFit(annotation));
    if (availableRow) {
      availableRow.insert(annotation);
    } else {
      const newRow = new AnnotationRow(this);
      newRow.insert(annotation);
      this.append(newRow);
    }
  }
  sortRows() {
    this.rows.sort((a, b) => b.coveredLength - a.coveredLength);
  }
  union(other) {
    this.append(...other.rows);
  }
  copy() {
    return new RowGroup(this.type).append(...this.rows.map(row => row.copy()));
  }
  replace(index, newRow) {
    this.rows[index] = newRow;
  }
  mergeAt(start, other) {
    other.rows.forEach((otherRow, index) => {
      index += start;
      const existingRow = this.rows[index];
      if (existingRow) {
        this.replace(index, existingRow.merge(otherRow));
      } else {
        this.append(otherRow);
      }
    });
  }
  findRowToMergeGroup(other) {
    const index = this.findRowThatCanMerge(other.first);
    if (index < 0) {
      return -1;
    } else {
      if (this.canMergeGroupAt(index, other)) {
        return index;
      } else {
        return -1;
      }
    }
  }
  findRowThatCanMerge(other) {
    return this.rows.findIndex(row => row.canMerge(other));
  }
  canMergeGroupAt(start, other) {
    return other.rows.every((row, index) => {
      index += start;
      const existingRow = this.rows[index];
      return existingRow ? this.rows[index].canMerge(row) : true;
    });
  }
}
export { RowGroup as default };