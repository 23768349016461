class FPSLogger {
  constructor(plugin) {
    this.plugin = plugin;
    plugin.sv.bind("prerender", time => {
      if (!this.seconds) {
        console.info("SV 5.1.2: Rendering");
      } else {
        this.calculate(time, this.seconds);
      }
    });
  }
  startTime = null;
  seconds = null;
  counter = null;
  calculate(time, seconds) {
    if (this.startTime === null || this.counter === null) {
      this.startTime = time;
      this.counter = 0;
    } else {
      this.counter += 1;
      const dt = (time - this.startTime) / 1e3;
      if (dt >= seconds) {
        const fps = (this.counter / dt).toFixed(2);
        console.log(`SV: ${fps} fps`);
        this.startTime = null;
      }
    }
  }
  setTimer(seconds) {
    this.seconds = seconds || null;
    this.startTime = null;
    this.counter = 0;
    if (seconds) {
      console.log(`SV: Now logging frame rates over ${seconds} seconds.`);
    } else {
      console.log("SV: Frame rate logging is now turned off.");
    }
    this.plugin.stayAwake = Boolean(seconds);
  }
}
export { FPSLogger as default };