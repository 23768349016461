import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import MultiRange from "../../includes/Range/MultiRange.js";
import HighlightingCache from "./HighlightingCache.js";
class HighlightingPlugin extends AbstractPlugin {
  _enabled;
  _highlight;
  _useConsensus;
  _useDots;
  // Cache highlights per channel.
  caches = /* @__PURE__ */new Map();
  constructor(sv) {
    super(sv);
    sv.highlighting = this;
    const options = this.getInitialOptions("highlighting", {
      enabled: {
        default: sv.options?.alignment.enabled,
        validator: raw => typeof raw === "boolean"
      },
      highlight: {
        default: "disagreements",
        validator: raw => typeof raw === "string" && this.validateHighlightBy(raw)
      },
      useConsensus: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      useDots: {
        default: false,
        validator: raw => typeof raw === "boolean"
      }
    });
    this._enabled = options.enabled;
    this._highlight = options.highlight;
    this._useConsensus = options.useConsensus;
    this._useDots = options.useDots;
    sv.bind("alter residue data", (residue, channel) => this.alterResidueData(residue, channel));
    sv.bind("alter residue colors", residue => this.alterResidueColors(residue));
    sv.bind("alter sequence lines", (context, channel, lines) => this.alterSequenceLines(context, channel, lines));
    sv.bind("alter translation data", (codonThird, index, channel, frame) => this.alterTranslationData(codonThird, index, channel, frame));
    sv.bind("alter translation colors", codonThird => this.alterTranslationColors(codonThird));
    sv.bind("prerender", () => {
      if (this.enabled) {
        this.referenceCache?.request(sv.range);
      }
    });
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.setBooleanOption("_enabled", raw);
  }
  get highlight() {
    return this._highlight;
  }
  set highlight(raw) {
    this.setStringOption("_highlight", raw);
  }
  get useConsensus() {
    const plugin = this.sv.consensus;
    return !!plugin && this._useConsensus;
  }
  set useConsensus(raw) {
    this.setBooleanOption("_useConsensus", raw);
  }
  get useDots() {
    return this._useDots;
  }
  set useDots(raw) {
    this.setBooleanOption("_useDots", raw);
  }
  get highlightByConsensus() {
    return this.useConsensus || !this.sv.channelView.reference;
  }
  get referenceCache() {
    return this.referenceWrapper?.sequenceCache;
  }
  get referenceWrapper() {
    const {
      globals,
      referenceWrapper
    } = this.sv.channelView;
    return this.highlightByConsensus ? globals : referenceWrapper;
  }
  serialize() {
    return {
      enabled: this.enabled,
      highlight: this.highlight,
      useConsensus: this.useConsensus,
      useDots: this.useDots
    };
  }
  getInRange(channel, query) {
    if (this.highlightingEnabled(channel)) {
      return this.getCache(channel)?.getInRange(query);
    } else {
      return new MultiRange();
    }
  }
  getCache(channel) {
    if (!this.caches.has(channel)) {
      this.caches.set(channel, new HighlightingCache(this, channel));
    }
    return this.caches.get(channel);
  }
  alterResidueData(residue, channel) {
    if (this.highlightingEnabled(channel) && this.getCache(channel)?.isDeemphasized(residue)) {
      residue.isDeemphasized = true;
      if (this.useDots && residue.letter !== "-") {
        residue.letter = "\xB7";
      }
    }
  }
  alterResidueColors(residue) {
    if (this.enabled && residue.isDeemphasized) {
      const {
        printLetter,
        grey
      } = this.sv.channelView.sequencePainter;
      if (printLetter && this.useDots) {
        residue.foreground = "black";
      } else {
        residue.foreground = grey;
      }
    }
  }
  alterSequenceLines(context, channel, lines) {
    if (this.enabled) {
      const ranges = this.getInRange(channel, context.visible)?.ranges;
      if (ranges) {
        const deemphasized = {
          colors: [this.sv.channelView.sequencePainter.grey],
          size: null,
          priority: 1
        };
        lines.insertMultiple(ranges, deemphasized, overlap => ({
          colors: deemphasized.colors,
          size: overlap?.size ?? null,
          priority: deemphasized.priority,
          background: overlap?.background
        }));
      }
    }
  }
  alterTranslationData(third, index, channel, frame) {
    if (this.highlightingEnabled(channel)) {
      const codon = this.referenceCodonThird(index, frame);
      if (this.compareCodons(third, codon)) {
        third.isDeemphasized = true;
        if (this.useDots && third.letter !== "") {
          third.letter = "\xB7";
        }
      }
    }
  }
  referenceCodonThird(index, frame) {
    return this.referenceWrapper?.translationsChannel?.getThird(index, frame);
  }
  compareCodons(a, b) {
    return (a?.letter === b?.letter && a?.position === b?.position) ?? false;
  }
  alterTranslationColors(third) {
    if (this.enabled && third.isDeemphasized) {
      const {
        printLetter,
        grey
      } = this.sv.channelView.sequencePainter;
      if (printLetter) {
        third.color = {
          background: null,
          text: this.useDots ? "black" : grey
        };
      } else {
        third.color = {
          background: grey,
          text: null
        };
      }
    }
  }
  highlightingEnabled(channel) {
    return this.enabled && this.referenceWrapper !== channel;
  }
  validateHighlightBy(raw) {
    return ["disagreements", "allDisagreements", "agreements"].includes(raw);
  }
}
export { HighlightingPlugin as default };