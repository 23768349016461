import Range from "./Range.js";
const {
  min,
  max
} = Math;
class RRange {
  constructor(start, end) {
    this.start = start;
    this.end = end;
    if (start > end) {
      throw new Error(`RRange start and end are reversed: [${start}, ${end}]`);
    }
    this.length = this.end - this.start;
    Object.freeze(this);
  }
  static empty = new RRange(0, 0);
  length;
  get isEmpty() {
    return this.length === 0;
  }
  /**
   * The index of the last value in an integer sequence bounded by this range
   */
  get last() {
    return this.end - 1;
  }
  /**
   * The average of the two endpoints.
   */
  get center() {
    return (this.start + this.end) / 2;
  }
  /******************************************************************
   * Iterator methods for RRange.
   */
  *[Symbol.iterator]() {
    for (let value = this.start; value < this.end; value++) {
      yield value;
    }
  }
  forEach(callback) {
    for (let index = 0; index < this.length; index++) {
      callback(index + this.start, index);
    }
  }
  map(callback) {
    const result = [];
    for (let index = 0; index < this.length; index++) {
      result.push(callback(index + this.start, index));
    }
    return result;
  }
  some(callback) {
    for (let index = 0; index < this.length; index++) {
      if (callback(index + this.start, index)) {
        return true;
      }
    }
    return false;
  }
  every(callback) {
    return !this.some((value, index) => !callback(value, index));
  }
  /**
   * Returns the value of the first number in the range where predicate is
   * true, or undefined if no numbers in the range match the predicate.
   *
   * @param predicate the predicate
   * @returns the first matching number or undefined
   */
  find(predicate) {
    for (let index = 0; index < this.length; index++) {
      if (predicate(index + this.start, index)) {
        return index + this.start;
      }
    }
    return void 0;
  }
  multiply(factor) {
    return new RRange(this.start * factor, this.end * factor);
  }
  divide(factor) {
    return this.multiply(1 / factor);
  }
  /**
   * Returns true if this range contains the given value.
   */
  includes(value) {
    return this.start <= value && value < this.end;
  }
  /**
   * Returns true if this range touches the given value.
   *
   * TODO Write tests.
   */
  touchesIndex(value) {
    return this.start <= value && value <= this.end;
  }
  /**
   * Returns true if the two ranges are overlapping (a zero-length range overlaps any range that it touches).
   */
  overlaps(range) {
    if (range instanceof Range) {
      return range.overlaps(this);
    }
    return this.start < range.end && range.start < this.end || this.zeroLengthOverlap(range);
  }
  zeroLengthOverlap(range) {
    return (this.isEmpty || range.isEmpty) && this.touches(range);
  }
  /**
   * Returns true if the two ranges are overlapping or adjacent (start of one == end of other).
   */
  touches(range) {
    if (range instanceof Range) {
      return range.touches(this);
    }
    return this.start <= range.end && range.start <= this.end;
  }
  isAfter(range) {
    return this.start > range.end;
  }
  /**
   * True if this range fully contains the second range, even if it is empty.
   */
  contains(range) {
    if (range instanceof Range) {
      if (range.wrapAround) {
        return false;
      }
    }
    return range.start >= this.start && range.end <= this.end;
  }
  /**
   * True if this range fully contains the second range and it isn't empty.
   */
  containsRange(range) {
    return !range.isEmpty && this.contains(range);
  }
  /**
   * Returns true if this range is equivalent to the given range.
   */
  equals(range) {
    return this.start === range.start && this.end === range.end;
  }
  /**
   * Returns the largest range fully contained by both ranges.
   */
  union(range) {
    if (range.isEmpty) {
      return this;
    } else if (this.isEmpty) {
      return range;
    } else {
      return new RRange(min(this.start, range.start), max(this.end, range.end));
    }
  }
  /**
   * Returns the smallest range that fully contains both ranges.
   */
  intersection(range) {
    return this.newOrEmpty(max(this.start, range.start), min(this.end, range.end));
  }
  /**
   * Returns a copy of the range with the start and end adjusted so they fit
   * inside the specified start and end values.
   *
   * @param range the start and end values to clip the range to. You can omit
   *    either property and it will default to the range's existing value.
   * @returns the new range
   */
  clip({
    start = this.start,
    end = this.end
  }) {
    return this.intersection({
      start,
      end
    });
  }
  /**
   * Cuts out the ranges, returning N ranges that do not overlap them.
   */
  cut(ranges) {
    const result = [this];
    for (const range of ranges) {
      const index = result.length - 1;
      const replacements = result[index]._cut(range);
      result.splice(index, 1, ...replacements);
      if (result.length < 1) {
        break;
      }
    }
    return result;
  }
  // This would be private but it has karma tests.
  // Returns 0, 1 or 2 ranges.
  _cut(range) {
    const result = [];
    if (this.overlaps(range)) {
      if (this.start < range.start) {
        result.push(new RRange(this.start, range.start));
      }
      if (range.end < this.end) {
        result.push(new RRange(range.end, this.end));
      }
    } else {
      result.push(this);
    }
    return result;
  }
  /**
   * Increases the start value and decreases the end value by given amounts.
   */
  shrink(start, end) {
    return this.newOrEmpty(this.start + start, this.end - end);
  }
  /**
   * Trims the end by `offset` if `atEnd` is true. Otherwise trims the start.
   */
  shrinkAt(offset, atEnd) {
    const start = atEnd ? 0 : offset;
    const end = atEnd ? offset : 0;
    return this.shrink(start, end);
  }
  /**
   * Decreases the start value and increases the end value by given amounts.
   */
  grow(start, end) {
    return new RRange(this.start - start, this.end + end);
  }
  /**
   * Grows the end by `offset` if `atEnd` is true. Otherwise grows the start.
   */
  growAt(offset, atEnd) {
    const start = atEnd ? 0 : offset;
    const end = atEnd ? offset : 0;
    return this.grow(start, end);
  }
  /**
   * Translates the range by the given amount.
   */
  shift(amount) {
    return new RRange(this.start + amount, this.end + amount);
  }
  /**
   * Scales the range by the given factor.
   */
  scale(factor) {
    return new RRange(this.start * factor, this.end * factor);
  }
  /**
   * Return the interval as a string in interval notation; Displays as [start, end).
   *
   * The brackets represent an interval that is inclusive on the lower bound and exclusive on the upper bound.
   *
   * @see https://en.wikipedia.org/wiki/Interval_(mathematics)#Notations_for_intervals
   */
  toString() {
    return `[${this.start}, ${this.end})`;
  }
  /**
   * RRange uses 0-based indexing with an intervals that is inclusive on the
   * lower bound and exclusive on the upper bound. Interval data for sequence
   * annotations use 1-based indexing with intervals that are inclusive on both
   * bounds.
   *
   * @returns a 1-based inclusive range - the type used for raw interval data
   */
  to1BasedInclusiveInterval() {
    return {
      min: this.start + 1,
      max: this.end
    };
  }
  newOrEmpty(start, end) {
    if (start > end) {
      return RRange.empty;
    } else {
      return new RRange(start, end);
    }
  }
}
export { RRange as default };