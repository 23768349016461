import RRange from "../../../includes/Range/RRange.js";
import Interval from "./Interval.js";
import SearchPlugin from "../../Search/SearchPlugin.js";
const {
  min,
  max
} = Math;
class Annotation {
  constructor(channel, data) {
    this.channel = channel;
    const left = data.intervals.reduce((previous, i) => min(previous, i.min), Infinity) - 1;
    const right = data.intervals.reduce((previous, i) => max(previous, i.max), 0);
    this.range = new RRange(left, right);
    this.name = data.name;
    this.id = data.id;
    this.rawType = data.type;
    this.normalType = Annotation.normalizeType(data.type);
    this.intervals = data.intervals.map(intervalData => Interval.fromData(intervalData, this));
    this.qualifiers = data.qualifiers || [];
    const keyValuePairs = this.qualifiers.map(prop => [prop.name, prop.value]);
    this.properties = new Map(keyValuePairs);
  }
  id;
  name;
  rawType;
  normalType;
  qualifiers;
  properties;
  range;
  intervals;
  /**
   * Returns an AnnotationData object that represents the Annotation's current
   * state. This can be used to make a new Annotation with the same values.
   *
   * @returns a copy of the raw AnnotationData
   */
  getData() {
    return {
      id: this.id,
      name: this.name,
      type: this.rawType,
      intervals: this.intervals.map(interval => interval.getData()),
      qualifiers: this.qualifiers.map(qualifier => ({
        ...qualifier
      }))
    };
  }
  matches(query) {
    const search = this.channel.sv.search;
    return search && search.queryMatchesIndex(query, this.searchIndexKeywords);
  }
  get searchIndexKeywords() {
    const add = words => {
      words.forEach(word => index.add(word));
    };
    const index = /* @__PURE__ */new Set();
    add(this.pairKeywords("type", this.normalType));
    if (this.name) {
      add(this.pairKeywords("name", this.name));
    }
    this.nonSystemProperties.forEach(prop => {
      add(this.pairKeywords(prop.name, prop.value));
      add(SearchPlugin.toKeywords(prop.name));
    });
    return index;
  }
  // Generates list of searchable words from a pair of name-value phrases.
  pairKeywords(prefix, value) {
    const split = SearchPlugin.toKeywords;
    prefix = split(prefix).join("-");
    const result = split(value);
    return result.concat(result.map(word => `${prefix}:${word}`));
  }
  get nonSystemProperties() {
    return this.qualifiers.filter(prop => !prop.name.startsWith(".hidden_"));
  }
  static normalizeType(raw = "") {
    return String(raw).toLowerCase();
  }
  delete() {
    return this.channel.cache.delete(this);
  }
  getIntervalsAt(range) {
    return this.intervals.filter(interval => interval.range.touches(range));
  }
  addInterval(data) {
    this.intervals.push(Interval.fromData(data, this));
  }
  get start() {
    return this.range.start;
  }
  get end() {
    return this.range.end;
  }
  get length() {
    return this.range.length;
  }
  get isTranslatable() {
    return this.typeIsIn(["cds", "orf"]);
  }
  get isPrimer() {
    return this.typeIsIn(["primer_bind", "primer_bind_reverse"]);
  }
  typeIsIn(types) {
    return types.includes(this.normalType);
  }
  get intervalRanges() {
    return this.intervals.map(interval => interval.range);
  }
  get wrapper() {
    return this.channel.wrapper;
  }
  /**
   * Allows providers to know which sequence an annotation belongs to.
   *
   * This is important for persisting new/edited annotations.
   *
   * TODO Add support for consensus sequence.
   * How to refer to the consensus sequence when the provider hasn't given an original index?
   */
  get originalIndex() {
    return this.wrapper["originalIndex"];
  }
  // noinspection JSUnusedGlobalSymbols
  get currentChannelIndex() {
    return this.wrapper.currentIndex;
  }
  get rowIndex() {
    return this.channel.cache.rows.findIndex(row => row.annotations.includes(this));
  }
  get rangesCache() {
    return this.wrapper.rangesCache;
  }
  overlaps(range) {
    return this.range.overlaps(range);
  }
  isAfter(range) {
    return this.range.isAfter(range);
  }
}
export { Annotation as default };