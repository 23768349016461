import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { exportInSlices } from "./export.js";
class ExportPlugin extends AbstractPlugin {
  _enabled;
  _width;
  _height;
  _debugEnabled;
  DEFAULT_DIMENSION = 5e3;
  constructor(sv) {
    super(sv);
    sv.export = this;
    const options = this.getInitialOptions("export", {
      enabled: {
        default: false,
        validator: raw => typeof raw === "boolean"
      },
      width: {
        default: this.DEFAULT_DIMENSION,
        validator: raw => typeof raw === "number"
      },
      height: {
        default: this.DEFAULT_DIMENSION,
        validator: raw => typeof raw === "number"
      },
      debugEnabled: {
        default: false,
        validator: raw => typeof raw === "boolean"
      }
    });
    this._enabled = options.enabled;
    this._width = options.width;
    this._height = options.height;
    this._debugEnabled = options.debugEnabled;
    if (this.enabled) {
      this.startExportOnReady(sv);
    }
  }
  get enabled() {
    return this._enabled;
  }
  get width() {
    return this._width;
  }
  set width(width) {
    this.maintainResideWidth(() => {
      this.setNumberOption("_width", width);
      this.sv.view.updateSize();
    });
  }
  get height() {
    return this._height;
  }
  set height(height) {
    this.maintainResideWidth(() => {
      this.setNumberOption("_height", height);
      this.sv.view.updateSize();
    });
  }
  serialize() {
    return {
      enabled: this.enabled,
      width: this.width,
      height: this.height
    };
  }
  maintainResideWidth(fn) {
    const residueWidth = this.sv.residueWidth;
    fn();
    this.sv.channelView.setResidueWidth(residueWidth);
  }
  setupAnnotationsPlugin() {
    if (!this.sv.annotations) {
      return;
    }
    this.sv.annotations.internalArrowsEnabled = false;
    this.sv.annotations.showPartialAnnotationLabels = false;
  }
  startExportOnReady(sv) {
    const residueWidth = sv.options?.zoom.residueWidth;
    if (residueWidth) {
      sv.bind("get residue width", () => residueWidth);
    }
    sv.bindOnce("ready", () => {
      sv.channelView.globals.isSticky = false;
      sv.channelView.sequences.forEach(channel => channel.requestAllData());
      sv.wrapped = false;
      if (sv.metadata) {
        sv.metadata.offset = 0;
      }
      this.setupAnnotationsPlugin();
      const debugEnabled = this._debugEnabled;
      function exportInSlicesWrapper() {
        exportInSlices(sv, {
          width: sv.export.width,
          height: sv.export.height
        }, debugEnabled);
      }
      sv.bindOnce("export start", exportInSlicesWrapper);
    });
  }
}
export { ExportPlugin as default };