import ReferenceWrapper from "../../includes/Channels/ReferenceWrapper.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import { indexRange } from "../../includes/misc/Utils.js";
import AnnotationType from "./AnnotationType.js";
import AnnotationsChannel from "./AnnotationsChannel.js";
import AnnotationsTrack from "./AnnotationsTrack.js";
import Annotation from "./Cache/Annotation.js";
import GlobalData from "./GlobalData.js";
class AnnotationsPlugin extends AbstractPlugin {
  data;
  _enabled = true;
  _filter;
  _internalArrowsEnabled = true;
  _showPartialAnnotationLabels = true;
  tracks = /* @__PURE__ */new Map();
  constructor(sv) {
    super(sv);
    this.data = new GlobalData(sv);
    sv.annotations = this;
    sv.registerChannelClass("annotations", AnnotationsChannel);
    sv.bindToNode("mousemove", "interval", (node, e) => this.onMouseMove(node, e));
    sv.bind("initialize sequence wrapper children", (wrapper, {
      annotationTracks
    }) => this.initializeTracks(wrapper, annotationTracks));
    sv.bind("append sequence wrapper children", wrapper => this.getTracks(wrapper));
    sv.bind("reference changed", () => this.initializeReferenceTracks());
    sv.bind("data request", wrapper => this.getChannels(wrapper).forEach(channel => channel.requestData()));
    sv.bind("request all data", wrapper => {
      const range = wrapper.rangesCache?.entireRange;
      if (range) {
        this.requestInRangeForWrapper(wrapper, range);
      }
    });
    const options = this.getInitialOptions("annotations", {
      enabled: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      filter: {
        default: "all",
        validator: raw => typeof raw === "string"
      },
      internalArrowsEnabled: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      showPartialAnnotationLabels: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      types: {
        default: {},
        validator: values => !!values && typeof values === "object" && Object.values(values).every(raw => typeof raw === "boolean")
      }
    });
    this._enabled = options.enabled;
    this._filter = options.filter;
    this._internalArrowsEnabled = options.internalArrowsEnabled;
    this._showPartialAnnotationLabels = options.showPartialAnnotationLabels;
    Object.entries(options.types).forEach(([name, visible]) => {
      const type = this.normalizeType(name);
      this.types[type] = new AnnotationType(this.data, name, visible);
    });
  }
  normalizeType(raw) {
    return Annotation.normalizeType(raw);
  }
  typeIsVisible(raw) {
    const type = this.normalizeType(raw);
    return this.types[type].visible;
  }
  get types() {
    return this.data.types;
  }
  get typesArray() {
    return Object.keys(this.types).map(key => this.types[key]);
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setBooleanOption("_enabled", raw);
      this.sv.emit("channel visibility changed");
    });
    const focused = this.sv.view.focused;
    if (focused && focused.type === "interval") {
      this.sv.view.focused = null;
    }
  }
  get filter() {
    return this._filter;
  }
  set filter(raw) {
    this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
      this.setStringOption("_filter", raw);
      this.sv.emit("channel visibility changed");
    });
  }
  get internalArrowsEnabled() {
    return this._internalArrowsEnabled;
  }
  set internalArrowsEnabled(raw) {
    this.setBooleanOption("_internalArrowsEnabled", raw);
    this.sv.emit("channel visibility changed");
  }
  get showPartialAnnotationLabels() {
    return this._showPartialAnnotationLabels;
  }
  set showPartialAnnotationLabels(raw) {
    this.setBooleanOption("_showPartialAnnotationLabels", raw);
    this.sv.emit("channel visibility changed");
  }
  get(id) {
    return this.getAnnotation(id);
  }
  delete(id) {
    const annotation = this.getAnnotation(id);
    if (annotation) {
      this.blurAnnotation(annotation);
      return annotation.delete();
    }
    return false;
  }
  add(currentChannelIndex, data, track = 0) {
    const wrapper = this.sv.channelView.channels[currentChannelIndex];
    const channel = this.getChannels(wrapper)[track];
    if (channel) {
      const annotation = new Annotation(channel, data);
      return channel.cache.add(annotation);
    }
    return false;
  }
  addFromSelection(id, type, name, direction) {
    const intervals = this.getIntervalsFromSelection(direction);
    if (this.sv.selection?.anchorWrapper) {
      const index = this.sv.selection.anchorWrapper.currentIndex;
      return this.add(index, {
        id,
        type,
        name,
        intervals
      });
    }
    return false;
  }
  getTracks(wrapper) {
    return this.tracks.get(wrapper) || [];
  }
  getChannels(wrapper) {
    const channels = [];
    if (wrapper.annotationsChannel) {
      channels.push(wrapper.annotationsChannel);
    }
    if (wrapper instanceof ReferenceWrapper) {
      channels.push(...this.getTracks(this.sv.channelView.reference));
    } else {
      channels.push(...this.getTracks(wrapper));
    }
    return channels;
  }
  getAll() {
    let result = [];
    for (const wrapper of this.sv.channelView.channels) {
      const channels = this.getChannels(wrapper);
      channels.forEach(channel => {
        result = result.concat(channel.cache.getAll());
      });
    }
    return result;
  }
  // Used by TranslationsChannel.
  getByRangeFromWrapper(wrapper, range) {
    let result = [];
    const channels = this.getChannels(wrapper);
    channels.forEach(channel => {
      result = result.concat(channel.cache.getInRange(range));
    });
    return result;
  }
  getIntervalsByIndex(wrapper, index) {
    let result = [];
    const channels = this.getChannels(wrapper);
    channels.forEach(channel => {
      result = result.concat(channel.cache.getIntervalsByIndex(index));
    });
    return result;
  }
  getIntervalsByIndexAndType(wrapper, index, type) {
    return this.getIntervalsByIndex(wrapper, index).filter(interval => interval.annotation.normalType === type);
  }
  getLastAnnotationAtIndex(wrapper, index) {
    const annotations = wrapper.translationsChannel.annotationTranslationsCache.translatableAnnotations(indexRange(index)).filter(({
      intervalRanges
    }) => intervalRanges.some(range => range.includes(index)));
    return annotations[annotations.length - 1];
  }
  requestInRangeForWrapper(wrapper, range) {
    this.getChannels(wrapper).forEach(channel => channel.cache.request(range));
  }
  getIntervalsFromSelection(direction) {
    const selection = this.sv.selection?.current[0];
    if (selection) {
      if (direction == null) {
        direction = this.sv.selection?.isForwardSelection ? 1 : -1;
      }
      return [{
        direction,
        ...selection.range.to1BasedInclusiveInterval()
      }];
    } else {
      const end = this.sv.selection?.endAnchor;
      if (end == null) {
        return [];
      }
      return [{
        direction,
        min: end + 1,
        max: end
      }];
    }
  }
  getAnnotation(id) {
    return this.getAll().find(annotation => annotation.id === id);
  }
  initializeTracks(wrapper, tracks) {
    this.tracks.set(wrapper, tracks.map(({
      name,
      qualifiers,
      dataCallback
    }) => {
      return new AnnotationsTrack(wrapper, dataCallback, {
        text: name
      }, qualifiers);
    }));
  }
  initializeReferenceTracks() {
    if (this.sv.channelView.reference) {
      const tracks = this.getTracks(this.sv.channelView.reference).map(({
        label,
        qualifiers,
        dataCallback
      }) => {
        return new AnnotationsTrack(this.sv.channelView.referenceWrapper, dataCallback, label, qualifiers);
      });
      this.tracks.set(this.sv.channelView.referenceWrapper, tracks);
    }
  }
  blurAnnotation(annotation) {
    const focused = this.sv.view.focused;
    if (focused && focused.type === "interval" && focused.reference["annotation"] === annotation) {
      this.sv.view.focused = null;
    }
  }
  onMouseMove(node, event) {
    const interval = node.reference;
    this.sv.tooltip?.watch(node, event, () => interval.propertiesAsHTML);
  }
  serialize() {
    const types = {};
    Object.entries(this.types).forEach(([key, value]) => types[key] = value.visible);
    return {
      enabled: this.enabled,
      filter: this.filter,
      type: types
    };
  }
}
export { AnnotationsPlugin as default };