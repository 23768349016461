import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
import MetadataColumn from "./MetadataColumn.js";
import MetadataColumnGroup from "./MetadataColumnGroup.js";
import MetadataScrollbar from "./MetadataScrollbar.js";
import { clamp, reduce2Dto1D } from "../../includes/misc/Math.js";
class MetadataPlugin extends AbstractPlugin {
  columns = [];
  columnGroup;
  _offset = 0;
  adjustingLabelsHeight = false;
  adjustingColumn = null;
  _enabled = true;
  _showValue = false;
  _labelHeight = 0;
  _width = 0;
  COLUMN_MARGIN = 2;
  labelResizerSize = 30;
  columnResizerSize = 20;
  MIN_LABEL_HEIGHT = 68;
  scrollbar;
  corner;
  constructor(sv) {
    super(sv);
    const options = sv.options?.metadataColumns;
    sv.metadata = this;
    this.corner = document.createElement("div");
    this.corner.className = "sv-scrollbar-corner-metadata";
    this.scrollbar = new MetadataScrollbar(sv);
    sv.bind("ready", () => {
      this.removeOldElements();
      sv.view.element.querySelector(".sv-bottom")?.appendChild(this.scrollbar.container);
      this.scrollbar.container.appendChild(this.corner);
      sv.bind(["resize", "wrapped changed", "column visibility changed"], () => this.updateBars());
      this.updateBars();
    });
    const width = options?.filter(a => a.enabled)?.reduce((total, col) => total + (col.width ?? 0) + this.COLUMN_MARGIN, 0);
    this.initialize(this.getInitialOptions("metadata", {
      enabled: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      showValue: {
        default: true,
        validator: raw => typeof raw === "boolean"
      },
      labelHeight: {
        default: 0,
        validator: raw => typeof raw === "number"
      },
      width: {
        default: width,
        validator: raw => typeof raw === "number"
      },
      offset: {
        default: 0,
        validator: raw => typeof raw === "number"
      }
    }));
    sv.bindToNode("mousemove", "metadata", (node, e, offset) => this.onMouseMove(node, e, offset));
    sv.bind("wheel", e => this.onScroll(e));
    sv.bind("register sidebar columns", () => {
      this.columns = [];
      this.columnGroup = new MetadataColumnGroup(this);
      options?.forEach(columnOptions => {
        const column = new MetadataColumn(this, columnOptions);
        if (column.hasData) {
          this.columns.push(column);
        }
      });
      return this.columnGroup;
    });
    sv.bind("alter globals wrapper height", () => this.labelHeight);
    sv.bindToNode("mousedown", "channels column, metadata column", (node, event, offset) => this.mouseDownOnGlobals(offset));
    sv.bindToNode("mousemove", "channels column, metadata column", (node, event, offset) => this.mouseMoveOnGlobals(offset));
    sv.bindToNode("mouseleave", "channels column, metadata column", () => this.mouseLeaveGlobals());
    sv.bindToNode("mousemove", "metadata column", (node, event, offset) => this.mouseMoveOnColumn(node, offset));
    sv.bindToNode("mousedown", "metadata column", (node, event, offset) => this.mouseDownOnColumn(node, offset));
    sv.addEventListenerToDocument("mousemove", event => this.mouseMoveOnDocument(event));
    sv.addEventListenerToDocument("mouseup", () => this.mouseUpOnDocument());
  }
  updateBars() {
    this.sv.bindOnce("postrender", () => {
      this.scrollbar.container.style.width = `${this.width}px`;
      this.scrollbar.container.style.marginLeft = `${this.columnGroup?.offset ?? 0}px`;
      this.scrollbar.update();
    });
  }
  scrollable() {
    return !this.scrollbar.isFullyVisible;
  }
  get enabledColumns() {
    return this.columns.filter(col => col.enabled);
  }
  get enabledColumnsWidth() {
    return this.enabledColumns.reduce((sum, col) => sum + col.widthWithMargin, 0);
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(raw) {
    this.setBooleanOption("_enabled", raw);
    this.sv.emit(["column visibility changed", "height changed"]);
  }
  get showValue() {
    return this._showValue;
  }
  set showValue(raw) {
    this.setBooleanOption("_showValue", raw);
    this.sv.emit("column visibility changed");
  }
  get isResizing() {
    return this.adjustingColumn !== null;
  }
  get labelHeight() {
    if (!this.metadataVisible) {
      return 0;
    }
    const oldHeight = this._labelHeight;
    const viewHeight = this.sv.view.height;
    this._labelHeight = clamp(this._labelHeight, Math.round(viewHeight * 0.8), this.MIN_LABEL_HEIGHT);
    if (oldHeight !== this._labelHeight) {
      this.sv.emit("height changed");
    }
    return this._labelHeight;
  }
  get minGroupWidth() {
    return Math.min(this.enabledColumnsWidth, 100);
  }
  get width() {
    if (this.sv.isExportMode) {
      return this.enabledColumnsWidth;
    }
    const maxWidth = Math.round(this.sv.view.width * 0.8 - (this.columnGroup?.offset ?? 0));
    return clamp(this._width, maxWidth, this.minGroupWidth);
  }
  set width(raw) {
    const changed = this.setNumberOption("_width", raw);
    if (changed) {
      this.sv.emit("column visibility changed");
    }
  }
  get offset() {
    return this._offset;
  }
  set offset(raw) {
    const changed = this.setNumberOption("_offset", clamp(raw, this.maxOffset, 0));
    if (changed) {
      this.sv.emit("column visibility changed");
    }
  }
  get maxOffset() {
    return this.enabledColumnsWidth - this.width;
  }
  getColumn(name) {
    return this.columns.find(column => column.name === name);
  }
  serialize() {
    return {
      enabled: this.enabled,
      showValue: this.showValue,
      labelHeight: this._labelHeight,
      width: this.width,
      offset: this.offset
    };
  }
  initialize(options) {
    this._enabled = options.enabled;
    this._showValue = options.showValue;
    this._labelHeight = options.labelHeight;
    this._width = options.width;
    this._offset = options.offset;
  }
  onMouseMove(node, event, _offset) {
    const channel = node.reference;
    if (channel.type === "sequence") {
      const column = node.parent?.reference;
      if (column) {
        this.sv.tooltip?.watch(node, event, () => column.getTooltip(channel));
      }
    }
  }
  mouseInResizingWindow(offset) {
    const halfResizerSize = this.labelResizerSize / 2;
    const globalsHeight = this.sv.channelView.globals.height + this.sv.channelView.offset.y;
    return offset.y >= globalsHeight - halfResizerSize && offset.y <= globalsHeight + halfResizerSize;
  }
  mouseMoveOnGlobals(offset) {
    if (this.metadataVisible) {
      document.body.style.cursor = this.mouseInResizingWindow(offset) ? "row-resize" : "auto";
    }
  }
  mouseDownOnGlobals(offset) {
    if (this.metadataVisible) {
      this.adjustingLabelsHeight = this.mouseInResizingWindow(offset);
    }
  }
  mouseLeaveGlobals() {
    if (this.metadataVisible) {
      document.body.style.cursor = "auto";
    }
  }
  mouseMoveOnColumn(node, offset) {
    const column = node.reference;
    const index = this.columns.filter(col => col.enabled).indexOf(column);
    if (this.mouseInResizingWindow(offset)) {
      return;
    }
    const halfResizerSize = this.columnResizerSize / 2;
    if (offset.x >= column.width - halfResizerSize || offset.x <= halfResizerSize && index > 0) {
      document.body.style.cursor = "col-resize";
    }
  }
  mouseDownOnColumn(node, offset) {
    const column = node.reference;
    const index = this.columns.filter(col => col.enabled).indexOf(column);
    const halfResizerSize = this.columnResizerSize / 2;
    if (offset.x >= column.width - halfResizerSize) {
      this.adjustingColumn = column;
    } else if (offset.x <= halfResizerSize && index > 0) {
      this.adjustingColumn = this.columns[index - 1];
    }
  }
  mouseMoveOnDocument(event) {
    if (!this.metadataVisible) {
      return;
    }
    if (this.adjustingLabelsHeight) {
      this.sv.channelView.maintainFocusWhileChangingRowHeight(() => {
        this._labelHeight = Math.round(event.clientY - (this.sv.view.element.getBoundingClientRect().top + (this.sv.view.layout?.bounds?.y ?? 0)));
        document.body.style.cursor = "row-resize";
        this.sv.view.dirty = "metadata label resized";
      });
    } else if (this.adjustingColumn) {
      this.adjustingColumn.width = Math.round(event.clientX - (this.sv.view.element.getBoundingClientRect().left + (this.sv.view.layout?.bounds?.x ?? 0) + this.adjustingColumn.offset - this.offset));
      document.body.style.cursor = "col-resize";
      this.sv.emit("column visibility changed");
      this.sv.view.dirty = "metadata column resized";
    }
  }
  mouseUpOnDocument() {
    if (this.adjustingLabelsHeight || this.adjustingColumn) {
      this.adjustingLabelsHeight = false;
      this.adjustingColumn = null;
      document.body.style.cursor = "auto";
    }
    if (this.width > this.enabledColumnsWidth) {
      this.offset = 0;
    }
    if (this.width - (this.enabledColumnsWidth - this.offset) > 0) {
      this.offset = this.enabledColumnsWidth - this.scrollbar.thumbLength;
    }
  }
  get metadataVisible() {
    return this.enabled && this.columns.some(column => column.enabled);
  }
  /**
   * Removes any leftover scrollbars from previous instances.
   */
  removeOldElements() {
    const classNames = [this.corner.className, this.scrollbar.className];
    classNames.map(name => {
      const element = this.sv.view.element.querySelector(`.${name}`);
      if (element) {
        element.remove();
      }
    });
  }
  onScroll(e) {
    if (!(this.sv.view.hovered?.type === "metadata" && e.shiftKey)) {
      return;
    }
    e.preventDefault();
    const delta = reduce2Dto1D(e.deltaX, e.deltaY);
    this.offset += delta;
  }
}
export { MetadataPlugin as default };