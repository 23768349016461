import ArrowTip from "../EndingShapes/ArrowTip.js";
import FlatEnding from "../EndingShapes/FlatEnding.js";
import InwardZigZag from "../EndingShapes/InwardZigZag.js";
import OutwardZigZag from "../EndingShapes/OutwardZigZag.js";
import SlopeEnding from "../EndingShapes/SlopeEnding.js";
class BaseEnding {
  constructor(painter, interval) {
    this.painter = painter;
    this.interval = interval;
    this.path = this.newPath().multiply(this.painter.innerHeight);
    if (interval.zeroLength) {
      this.nudgeForZeroLengthInterval();
    }
  }
  path;
  get width() {
    return this.path.widthIncludingPadding;
  }
  get offset() {
    return this.index * this.painter.view.residueWidth;
  }
  newPath() {
    if (this.interval.zeroLength) {
      return this.zeroLengthPath();
    } else if (this.isTruncated) {
      if (this.isTerminal) {
        return new OutwardZigZag();
      } else {
        return new InwardZigZag();
      }
    } else {
      if (this.isTerminal) {
        if (this.interval.annotation.isPrimer) {
          return new SlopeEnding();
        } else {
          return new ArrowTip();
        }
      } else {
        return new FlatEnding();
      }
    }
  }
}
var BaseEnding_default = BaseEnding;
export { BaseEnding_default as default };