import { forkJoin, Observable, of as observableOf } from 'rxjs';
import { concatMap, map, reduce } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SequenceDataService } from './sequence-data.service';
import { getRowIdentifier } from '../ngs/getRowIdentifier';
import { getOrderOfRegion } from '../ngs/regions-order';
import Annotation from '@geneious/sequence-viewer/plugins/Annotations/Cache/Annotation';
import { SequenceData } from '@geneious/sequence-viewer/types';

export class SequenceDocument<T extends SequenceData = SequenceData> {
  trees?: string[];
  consensus?: {
    annotations: Annotation;
  };
  sequences: T[];
}

export interface SequenceDataInDocument extends SequenceData {
  documentId: string;
  sequenceIndexInDocument: number;
}

@Injectable({
  providedIn: 'root',
})
export class SequenceSelectionService {
  static readonly blacklistedAnnotationTypes = [
    'IMGT',
    'Kabat',
    'AA Number',
    'Isomerization',
    'Deamidation',
    'Oxidation',
    'Cleavage',
    'Glycosylation',
    'Hydrolysis',
    'Contamination',
    'AA Variant',
    'DNA Variant',
    'Stop Codon (Opal)',
    'Stop Codon (Amber)',
    'Stop Codon (Ochre)',
    'Missing Cysteine',
    'Extra Cysteine',
    'Frame Shift',
    'Not Fully Annotated',
    'Likely Sequencing Error',
    'Possible Sequencing Error',
    'Low Quality Base',
  ];

  constructor(private sequenceDataService: SequenceDataService) {}

  /**
   * Fetches Sequence data from a given selection of document rows.
   *
   * @param documents - rows from the files table.
   * @param limit - the limit of rows that should have sequence data fetched for.
   * @param documentFilterFunc - function to filter from the given rows.
   */
  fetchSequenceData(
    documents: any[],
    limit: number,
    documentFilterFunc: Function,
  ): Observable<SequenceData[]> {
    // If no filter function is set, keep all documents by default.
    documentFilterFunc =
      documentFilterFunc ||
      function () {
        return true;
      };
    const documentIDs = documents
      .filter((doc) => documentFilterFunc(doc))
      .slice(0, limit)
      .map((row) => getRowIdentifier(row));
    return this.retrieveAllSequencesData(documentIDs).pipe(map((doc) => doc.sequences));
  }

  /**
   * Retrieve Sequences per document and merge together into one array result.
   *
   * @param {string[]} ids
   * @returns {Observable<SequenceItem[]>}
   */
  retrieveAllSequencesData(ids: string[], revision?: number): Observable<SequenceDocument> {
    // Fetch the data and process it all once and together.

    return ids.length > 0
      ? this.fetchAndFilterSequences(ids, revision)
      : observableOf({ sequences: [] });
  }

  fetchAndFilterSequences(
    ids: string[],
    revision?: number,
  ): Observable<SequenceDocument<SequenceDataInDocument>> {
    return this.batchIDs(ids).pipe(
      // Only send a maximum of 10 requests at once
      concatMap((idsBatch) =>
        forkJoin(idsBatch.map((id) => this.sequenceDataService.fetchSequences(id, revision))),
      ),
      // Accumulate all sequences, otherwise sequence viewer keeps refreshing after each batch comes in
      reduce((acc, current) => acc.concat(current), []),
      map((documents: any[]) =>
        documents[0].trees || documents[0].consensus
          ? // Only ever return the first document if it's an alignment.
            documents[0]
          : // Otherwise merge all sequences together and show them as a combined sequence document with no tree / meadata / etc.
            SequenceSelectionService.mergeSequencesFromManyDocumentsIntoOne(documents),
      ),
    );
  }

  /**
   * Splits an array of IDs into batches, returning an observable that emits each batch
   * and then completes.
   *
   * @param ids array of IDs
   * @param batchSize the size of the batches
   * @returns an observable that emits batches of IDs
   */
  private batchIDs(ids: string[], batchSize = 10): Observable<string[]> {
    return new Observable((subscriber) => {
      let startIndex = 0;
      while (startIndex < ids.length) {
        const endIndex = startIndex + batchSize;
        // If endIndex exceeds ids.length, JS just returns up to the end of the array
        subscriber.next(ids.slice(startIndex, endIndex));
        startIndex = endIndex;
      }
      subscriber.complete();
    });
  }

  static mergeSequencesFromManyDocumentsIntoOne(
    documents: SequenceDocument<SequenceDataInDocument>[],
  ): SequenceDocument<SequenceDataInDocument> {
    const sequences = SequenceSelectionService.filterAndMergeSequences(
      documents.map((document) => document.sequences),
    );
    return { sequences };
  }

  /**
   * Removes any invalid sequences and flattens them all.
   */
  static filterAndMergeSequences(
    allSequences: SequenceDataInDocument[][],
  ): SequenceDataInDocument[] {
    return (
      SequenceSelectionService.filterInvalidSequences(allSequences)
        // Flatten the sequences from inside all remaining selected documents.
        .reduce((all, some) => [...all, ...some], [])
    );
  }

  /**
   * We want to know what documents are not sequences so we can display what we are currently
   * ignoring/showing.
   *
   * Returns an array of documents which are valid documents.
   * Valid documents are viewable by the sequence viewer:
   */
  static filterInvalidSequences(documents: SequenceDataInDocument[][]): SequenceDataInDocument[][] {
    // We can test for sequence like documents by seeing if they have things inside.
    return (
      documents
        // Remove empty/undefined documents.
        .filter((doc) => doc && !!doc.length)
        // Remove documents with invalid sequence structure.
        .filter((doc) => SequenceSelectionService.isValidSequence(doc))
    );
  }

  /**
   * Checks if the Document is a valid Sequence by checking the json structure.
   * TODO Make it check all properties of a sequence?
   *
   * @param {any[]} doc
   * @returns {boolean}
   */
  static isValidSequence(doc: SequenceData[]): boolean {
    const seq = doc[0];
    return !!(seq && seq.sequence && seq.sequence.sequenceType);
  }

  /**
   * Retrieve all annotation names of the specified type from a list of sequences.
   *
   * If no annotation type is specified, it will retrieve all non-trivial annotations.
   * "Trivial" annotations are annotations where the presence of the annotation may be useful, but
   * underlying sequence region isn't. This includes mutation, numbering, and liability
   * annotations, see SequenceSelectionService.blacklistedTypes for full list.
   *
   * @param {any[]} annotatedSequences Sequences to retrieve annotations from.
   * @param includeAnnotationTypes { string[] | null} If specified, only annotations of these types
   *     will be returned.
   * @param excludeAnnotationTypes { string[] | null} If specified, only annotations which are
   *     non-blacklisted and excluding these types will be returned.
   * @returns {AnnotationNameAndCount[]} The names of annotations present, and the number of
   *     sequences each occurs in.
   */
  public static getAllAnnotationNames(
    annotatedSequences: SequenceData[],
    includeAnnotationTypes?: string[],
    excludeAnnotationTypes?: string[],
  ): AnnotationNameAndCount[] {
    const annotationMap = SequenceSelectionService.buildMapOfAnnotations(
      annotatedSequences,
      includeAnnotationTypes,
      excludeAnnotationTypes,
    );

    const annotationNameAndCounts = [];
    for (const chainName of Object.keys(annotationMap)) {
      for (const chain of Object.keys(annotationMap[chainName]) as (
        | 'Heavy'
        | 'Light'
        | 'Unknown'
      )[]) {
        for (const annotationName of Object.keys(annotationMap[chainName][chain])) {
          const annotationNameAndCount: AnnotationNameAndCount = {
            name: annotationName,
            numOfSequences: annotationMap[chainName][chain][annotationName],
          };

          if (chain !== 'Unknown') {
            annotationNameAndCount.chain = chain;
          }

          if (chainName !== 'Unknown') {
            annotationNameAndCount.chainName = chainName;
          }

          annotationNameAndCounts.push(annotationNameAndCount);
        }
      }
    }

    return annotationNameAndCounts.sort((a, b) => {
      const aOrder = getOrderOfRegion(a.name, a.chain);
      const bOrder = getOrderOfRegion(b.name, b.chain);
      const nameOrder =
        a.chainName && b.chainName
          ? a.chainName.localeCompare(b.chainName)
          : a.name.localeCompare(b.name);
      if (aOrder === bOrder) {
        const diff = b.numOfSequences - a.numOfSequences;
        return diff === 0 ? nameOrder : diff;
      } else if (aOrder === undefined) {
        return 1;
      } else if (bOrder === undefined) {
        return -1;
      } else {
        return aOrder - bOrder;
      }
    });
  }

  /**
   * Retrieve all annotation names of the specified type that occur in more than one input
   * sequence.
   * Returned annotations are sorted by count.
   *
   * If no annotation type is specified, it will retrieve all non-trivial annotations.
   * "Trivial" annotations are annotations where the presence of the annotation may be useful, but
   * underlying sequence region isn't. This includes mutation, numbering, and liability
   * annotations, see SequenceSelectionService.blacklistedTypes for full list.
   *
   * @param {any[]} annotatedSequences Sequences to retrieve annotations from.
   * @param includeAnnotationTypes { string[] | null} If specified, only annotations of these types
   *     will be returned.
   * @param excludeAnnotationTypes { string[] | null} If specified, only annotations which are
   *     non-blacklisted and excluding these types will be returned.
   * @returns {AnnotationNameAndCount[]} The names of annotations present, and the number of
   *     sequences each occurs in. Sorted by count.
   */
  public static getAllAnnotationNamesForRegionChooser(
    annotatedSequences: any[],
    includeAnnotationTypes?: string[],
    excludeAnnotationTypes?: string[],
  ): AnnotationNameAndCount[] {
    return (
      this.getAllAnnotationNames(annotatedSequences, includeAnnotationTypes, excludeAnnotationTypes)
        // Remove any annotations only present on a single sequence - these aren't interesting.
        .filter((anno) => anno.numOfSequences > 1)
    );
  }

  /**
   * Retrieve all annotation names of the specified type from a list of sequences.
   *
   * If no annotation type is specified, it will retrieve all non-trivial annotations.
   * "Trivial" annotations are annotations where the presence of the annotation may be useful, but
   * underlying sequence region isn't. This includes mutation, numbering, and liability
   * annotations, see SequenceSelectionService.blacklistedTypes for full list.
   *
   * @param {any[]} annotatedSequences Sequences to retrieve annotations from.
   * @param includeAnnotationTypes { string[] | null} If specified, only annotations of these types
   *     will be returned.
   * @param excludeAnnotationTypes { string[] | null} If specified, only annotations which are
   *     non-blacklisted and excluding these types will be returned.
   * @returns Map containing names of annotations present (key), and count of the number of
   *     sequences they occur in (value).
   */
  public static buildMapOfAnnotations(
    annotatedSequences: any[],
    includeAnnotationTypes?: string[],
    excludeAnnotationTypes?: string[],
  ): AnnotatedMap {
    return (
      annotatedSequences
        // Get annotations from each sequence.
        .map((sequence) =>
          sequence.annotations
            // Remove all annotations not of specified type (if annotation type is specified)
            // or of a blacklisted type (if annotation type is not specified).
            .filter((annotation: any) => {
              if (includeAnnotationTypes) {
                return includeAnnotationTypes.includes(annotation.type);
              } else
                return !(
                  SequenceSelectionService.blacklistedAnnotationTypes.includes(annotation.type) ||
                  (excludeAnnotationTypes
                    ? excludeAnnotationTypes.includes(annotation.type)
                    : false)
                );
            }),
        )
        .map(SequenceSelectionService.removeDuplicatedAnnotations)
        // Build map of annotation name to the number of sequences with that annotation.
        .reduce((annotationMap: AnnotatedMap, annotations: any[]) => {
          annotations.forEach((annotation) => {
            const chainQualifier = annotation.qualifiers.find(
              (qualifier: any) => qualifier.name === 'Chain',
            );
            const chainNameQualifier = annotation.qualifiers.find(
              (qualifier: any) => qualifier.name === 'Chain Name',
            );
            const annotationName: string = annotation.name;
            const chain: AnnotatedMapKey = chainQualifier ? chainQualifier.value : 'Unknown';
            const chainName: string = chainNameQualifier ? chainNameQualifier.value : 'Unknown';
            if (!annotationName) {
              return;
            }
            if (!annotationMap[chainName]) {
              annotationMap[chainName] = {
                [chain]: {
                  [annotationName]: 1,
                },
              };
            } else if (!annotationMap[chainName][chain]) {
              annotationMap[chainName] = {
                ...annotationMap[chainName],
                [chain]: {
                  [annotationName]: 1,
                },
              };
            } else if (!annotationMap[chainName][chain][annotationName]) {
              annotationMap[chainName][chain] = {
                ...annotationMap[chainName][chain],
                [annotationName]: 1,
              };
            } else {
              annotationMap[chainName][chain] = {
                ...annotationMap[chainName][chain],
                [annotationName]: ++annotationMap[chainName][chain][annotationName],
              };
            }
          });
          return annotationMap;
        }, {})
    );
  }

  private static removeDuplicatedAnnotations(annotations: any[]): any[] {
    function getChain(annotation: any): { value: string } {
      return annotation.qualifiers.find((qualifier: any) => qualifier.name === 'Chain');
    }

    function getChainName(annotation: any): { value: string } {
      return annotation.qualifiers.find((qualifier: any) => qualifier.name === 'Chain Name');
    }

    function getAnnotationName(annotation: any): string {
      const chain = getChain(annotation);
      const chainName = getChainName(annotation);
      const annotationNameWithChain = chain ? chain.value + ' ' + annotation.name : annotation.name;
      return chainName ? chainName.value + ': ' + annotationNameWithChain : annotationNameWithChain;
    }

    const existing: { [type: string]: any } = {};
    return annotations.reduce((deduplicatedAnnotations, annotation) => {
      const annotationName = getAnnotationName(annotation);
      const existingAnnotation = existing[annotationName];
      if (!existingAnnotation) {
        existing[annotationName] = annotation;
        deduplicatedAnnotations.push(annotation);
      }
      return deduplicatedAnnotations;
    }, []);
  }

  /**
   * Apply negative selected filtering to the rows.
   * Will remove rows that have been selected in the state when select all is enabled.
   *
   * @param ids
   * @param {any[]} rows
   * @returns {any[]}
   */
  private static applyNegativeSelection(ids: string[], rows: any[]): any[] {
    return rows.filter((row) => !ids.includes(getRowIdentifier(row)));
  }
}

export type AnnotatedMapKey = keyof AnnotatedMap[string];
interface AnnotatedMap {
  [chainName: string]: {
    [chain in 'Heavy' | 'Light' | 'Unknown']?: {
      [annotationName: string]: number;
    };
  };
}

export interface AnnotationNameAndCount {
  name: string;
  numOfSequences: number;
  chain?: 'Heavy' | 'Light';
  chainName?: string;
}
