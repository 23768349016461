class AmbiguityMapper {
  /**
   * @param {string}    canonical The canonical residues.
   * @param {string}    wildcard  The character representing any canonical character.
   * @param {StringMap} map       Map of canonical residues to a residue.
   */
  constructor(canonical, wildcard, map) {
    this.canonical = canonical;
    this.wildcard = wildcard;
    const everything = new Set(canonical);
    this.reverse.set(wildcard, everything);
    this.reverse.set(this.unknown, everything);
    this._totalUniqueCharacters = canonical.length + Object.keys(map).length + 1;
    Object.keys(map).forEach(chars => {
      const code = map[chars];
      this.forward.set(this.uniqueKey(chars), code);
      this.reverse.set(code, new Set(chars));
    });
    this.forward.set("", this.unknown);
  }
  forward = /* @__PURE__ */new Map();
  reverse = /* @__PURE__ */new Map();
  _totalUniqueCharacters;
  gap = "-";
  unknown = "?";
  /**
   * Convert one or more residues into the single residue that best describes them.
   */
  toAmbiguous(chars) {
    const result = this.disambiguate(chars);
    if (result.size == 1) {
      return result.values().next().value;
    } else {
      const key = this.uniqueKey(result);
      return this.forward.get(key) || this.wildcard;
    }
  }
  /**
   * Convert a single-character code into a set containing the associated canonical characters.
   */
  toCanonical(char) {
    const canonical = this.reverse.get(char);
    return canonical ? canonical : new Set(char);
  }
  /**
   * Returns true if the character is ambiguous.
   */
  isAmbiguous(char) {
    return this.reverse.has(char);
  }
  /**
   * Returns true if the character is canonical.
   */
  isCanonical(char) {
    return this.canonical.includes(char);
  }
  isGap(char) {
    return char === this.gap;
  }
  get totalUniqueCharacters() {
    return this._totalUniqueCharacters;
  }
  /**
   * Mutate the set `chars`, adding `newChar` (or it's associated canonical states) to the set.
   */
  addCanonical(chars, newChar) {
    const canonical = this.toCanonical(newChar);
    canonical.forEach(elem => chars.add(elem));
  }
  /**
   * Generate a key for a given set of characters, so that structurally equivalent sets have the same key.
   */
  uniqueKey(set) {
    return Array.from(set).sort().join("");
  }
  /**
   * Take a set of (possibly ambiguous) characters and return the set of possible canonical characters.
   */
  disambiguate(set) {
    const result = /* @__PURE__ */new Set();
    set.forEach(char => this.addCanonical(result, char));
    return result;
  }
  residuesMatch(residueA, residueB) {
    const aCanonical = this.toCanonical(residueA.toUpperCase());
    const bCanonical = this.toCanonical(residueB.toUpperCase());
    for (const a of aCanonical) {
      for (const b of bCanonical) {
        if (this.isMatch(a, b)) {
          return true;
        }
      }
    }
    return false;
  }
  getMatchFraction(residueA, residueB) {
    let matches = 0;
    let total = 0;
    const aCanonical = this.toCanonical(residueA.toUpperCase());
    const bCanonical = this.toCanonical(residueB.toUpperCase());
    for (const a of aCanonical) {
      for (const b of bCanonical) {
        if (this.isMatch(a, b)) {
          matches++;
        }
        total++;
      }
    }
    return matches / total;
  }
  isMatch(a, b) {
    return a === b && a !== "-";
  }
}
export { AmbiguityMapper as default };