import {
  isViewerMasterDatabaseSearchSelection,
  ViewerMasterDatabaseSearchSelection,
  ViewerMultipleTableDocumentSelection,
} from '../../viewer-components/viewer-document-data';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { NgsSequenceViewerService } from '../../ngs/ngs-sequence-viewer.service';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { MasterDatabaseService } from '../master-database.service';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { SequenceData } from '@geneious/sequence-viewer/types';

@Injectable({
  // TODO Probably shouldn't be provided in root.
  providedIn: 'root',
})
export class MasterDatabaseSequencesResource implements OnDestroy {
  private currentEntityID: string;
  private blobName$ = new ReplaySubject<string>(1);
  private blobName: string;
  private cachedBlobName: string;
  private cachedBlobJSON: MasterDatabaseSearchResultAlignmentsBlob;

  constructor(
    private documentHttpService: DocumentHttpV2Service,
    private ngsSequenceViewerService: NgsSequenceViewerService,
  ) {}

  ngOnDestroy() {
    this.blobName$.complete();
  }

  getSequences(
    entityID: string,
    state: ViewerMasterDatabaseSearchSelection | ViewerMultipleTableDocumentSelection,
  ): Observable<SequenceData[]> {
    if (!isViewerMasterDatabaseSearchSelection(state)) {
      throw new Error('Wrong selection type for this resource.');
    }

    if (
      state.subTableRows.length === 1 ||
      (state.rows.length === 1 && state.rows[0].best_match_urn != null)
    ) {
      return this.getEntityBlob(entityID).pipe(
        map((blob) => this.getSelectedAlignmentFromBlob(blob, state)),
      );
    } else {
      return this.ngsSequenceViewerService.getSequences(entityID, {
        document: state.document,
        rows: state.rows,
        selectAll: false,
        selectedRows: state.rows,
        tableType: DocumentTableType.MASTER_DATABASE_SEARCH_RESULT,
        total: state.rows.length,
        totalSelected: state.rows.length,
      });
    }
  }

  setBlobName(blobName: string) {
    this.blobName$.next(blobName);
    this.blobName = blobName;
  }

  getBlobName() {
    return this.blobName;
  }

  private getEntityBlob(entityID: string): Observable<MasterDatabaseSearchResultAlignmentsBlob> {
    return this.blobName$.pipe(
      switchMap((blobName) => {
        if (this.currentEntityID === entityID && blobName === this.cachedBlobName) {
          this.currentEntityID = entityID;
          return of(this.cachedBlobJSON).pipe(
            // Delay is needed to avoid the sequence viewer being stuck on a loading indicator.
            // Is it stupid? Yes. It should be fixed.
            // TODO Figure out a way to avoid having to use delay(0).
            delay(0),
          );
        } else {
          return this.documentHttpService
            .getDocumentPart<MasterDatabaseSearchResultAlignmentsBlob>(entityID, blobName, 'json')
            .pipe(
              tap((blob) => {
                this.currentEntityID = entityID;
                this.cachedBlobName = blobName;
                this.cachedBlobJSON = blob;
              }),
            );
        }
      }),
      take(1),
    );
  }

  private getSelectedAlignmentFromBlob(
    blob: MasterDatabaseSearchResultAlignmentsBlob,
    state: ViewerMasterDatabaseSearchSelection,
  ): SequenceData[] {
    const matchRow =
      state.subTableRows[0] ||
      state.rows[0].matches.find((match: any) =>
        MasterDatabaseService.isBestMatch(match, state.rows[0]),
      );
    const selectedSequenceID = matchRow.geneious_row_index;
    const searchResult = blob.matchSequences[selectedSequenceID];
    if (searchResult) {
      const selectedMatch = searchResult.find(
        (match) =>
          matchRow['sequence_match_id'] === match.databaseSequenceID &&
          matchRow['collection_id'] === match.databaseID,
      );
      return [selectedMatch.querySequence].concat(selectedMatch.matchSequence);
    } else {
      return [];
    }
  }
}

interface MasterDatabaseSearchResultAlignmentsBlob {
  createdByUserID: string;
  matchSequences: {
    [type: string]: {
      querySequenceID: string;
      querySequence: SequenceData;
      databaseSequenceID: number;
      matchSequence: SequenceData;
      databaseID: string;
    }[];
  };
}
