import { Defaults, Items } from "./Merge.js";
class Options {
  /**
   * Whether to wrap sequence viewer instead of making it horizontally scrollable.
   *
   * @beta
   * @defaultValue `false`
   */
  wrapped;
  /**
   * Whether to center the sequence vertically.
   * Only affects linear mode, in circular mode the sequence is always centered.
   *
   * @defaultValue `true`
   */
  centered;
  /**
   * The sequence type that the input sequences are interpreted as.
   *
   * @defaultValue `"Nucleotide"`
   */
  sequenceType;
  /**
   * The index of the sequence that will be used as "reference sequence".
   *
   * @defaultValue `undefined`
   */
  reference;
  /**
   * Whether to show the current sequence in circular view.
   *
   * @defaultValue `false`
   */
  circular;
  /**
   * Annotation Type for color by annotation plugin.
   *
   * @beta
   * @defaultValue `null`
   */
  annotationType;
  /**
   * Whether to fetch all data at once instead of lazy loading data as user scrolls.
   *
   * @defaultValue `false`
   *
   * @remarks
   *
   * By default, Sequence Viewer will send out ranges of the sequences that are visible to user and require data to be
   * loaded. This is so that large sequences can be loaded in chunks to improve performance and offer flexibility.
   *
   * This option must be enabled to use the sequence editing feature. When an edit is made on a sequence, Sequence Viewer
   * has to recalculate the annotations, shifting current annotations and inserting new edit annotations. Therefore, it
   * needs to know the position of all annotations in the edited sequence.
   */
  fetchDataOnce;
  /**
   * Configurations for alignment sequences
   */
  alignment;
  /**
   * Configurations for alignment sequences
   */
  ruler;
  /**
   * Sequence data for rendering in SequenceViewer.
   */
  sequences;
  /**
   * The options for each metadata column to be rendered.
   */
  metadataColumns;
  /**
   * The initial range of the sequence viewer on first load.
   */
  range;
  /**
   * The initial zooming of sequence viewer. You can either specify the zooming fraction or residue width.
   * If a residue width is specified, SequenceViewer will prioritize using it to adjust the zooming to fit
   * with the `residueWidth`, otherwise, it will use the `sequenceFraction` to calculate the zooming.
   */
  zoom;
  /**
   * Data type being measured by the developer plugin
   *
   * @internal
   */
  measure;
  /**
   * Whether to show three letter amino acids when the amino acid sequence is zoomed in far enough.
   *
   * @defaultValue `false`
   */
  showThreeLetterAminoAcids;
  constructor(userOptions, sv) {
    const options = Options.coreOptions().merge(userOptions, sv, []);
    if (options.sequenceType === "DNA") {
      options.sequenceType = "Nucleotide";
    } else if (options.sequenceType === "AA") {
      options.sequenceType = "AminoAcid";
    }
    Object.assign(this, options);
  }
  static coreOptions() {
    return Defaults({
      wrapped: false,
      centered: true,
      circular: false,
      sequenceType: "Nucleotide",
      reference: {
        default: null,
        validator: raw => typeof raw === "number"
      },
      annotationType: void 0,
      fetchDataOnce: false,
      // Only key currently is enabled but we may add more in the future.
      alignment: Defaults({
        enabled: {
          default: false,
          validator: raw => typeof raw === "boolean"
        }
      }),
      ruler: Defaults({
        enabled: true,
        global: true,
        allSequences: false
      }),
      sequences: Items({
        name: "",
        length: void 0,
        metadata: void 0,
        graphs: false,
        dataCallback: void 0,
        annotationTracks: Items({
          name: void 0,
          qualifiers: void 0,
          dataCallback: void 0
        }),
        pairInformation: Defaults({
          mateIndex: void 0,
          expectedDistance: void 0,
          orientation: void 0,
          side: void 0
        })
      }),
      metadataColumns: Items({
        name: {
          default: void 0,
          validator: raw => typeof raw === "string"
        },
        label: null,
        groupName: null,
        type: "continuous",
        enabled: {
          default: false,
          validator: raw => typeof raw === "boolean"
        },
        width: {
          default: 40,
          validator: raw => typeof raw === "number"
        },
        cellRenderer: null,
        tooltipRenderer: null
      }),
      range: Defaults({
        start: {
          default: 0,
          validator: raw => typeof raw === "number" && raw > 0
        },
        end: void 0,
        length: void 0
      }),
      zoom: Defaults({
        sequenceFraction: 1,
        residueWidth: {
          default: null,
          validator: raw => typeof raw === "number" && raw > 0
        }
      }),
      showThreeLetterAminoAcids: false,
      measure: void 0
    });
  }
}
export { Options as default };