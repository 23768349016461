import { sum } from "../misc/Math.js";
class RenderNode {
  constructor(type, reference, bounds, children = []) {
    this.type = type;
    this.reference = reference;
    this.bounds = bounds;
    if (!Number.isInteger(bounds.y)) {
      throw new Error("RenderNode.bounds.y must be an integer to allow pixel-precision rendering.");
    }
    this.children = children;
  }
  /**
   * May be used to switch to a different layer for rendering and translating.
   */
  brush;
  zIndex = 0;
  _parent;
  // Recursion!
  _children = [];
  _background;
  _render;
  setRenderCallback(callback) {
    this._render = callback;
  }
  setBackgroundCallback(callback) {
    this._background = callback;
  }
  get parent() {
    return this._parent;
  }
  get children() {
    return this._children;
  }
  set children(children) {
    children.forEach(child => {
      child._parent = this;
    });
    this._children = children;
  }
  /**
   * Path from this node to the root node (in that order) excluding this node.
   */
  get ascendants() {
    const nodes = [];
    for (let node = this._parent; node; node = node._parent) {
      nodes.push(node);
    }
    return nodes;
  }
  get absoluteY() {
    return sum(this.path.map(node => node.bounds.y));
  }
  /**
   * Path from the root node to this node, inclusive, in that order.
   */
  get path() {
    const nodes = [this];
    return nodes.concat(this.ascendants).reverse();
  }
  /**
   * Returns the RenderContext for this node.
   */
  get renderContext() {
    if (this.type === "view row") {
      return this.reference;
    } else if (this.parent) {
      return this.parent.renderContext;
    }
  }
  /**
   * Returns the RenderContext.row of the 'view row' RenderNode containing this node.
   */
  get rowRange() {
    return this.renderContext?.row;
  }
  /**
   * Recursively paints this node it's descendants.
   */
  paint(brush) {
    brush = this.brush || brush;
    if (this._background) {
      this._background(brush);
    }
    this.children.forEach(child => {
      return child.translate(brush, () => child.paint(brush));
    });
    if (this._render) {
      this._render(brush);
    }
  }
  translate(brush, callback, yOnly = false) {
    brush.save();
    const x = yOnly ? 0 : this.bounds.x;
    const y = this.bounds.y;
    brush.translate(x, y);
    callback();
    brush.restore();
  }
}
export { RenderNode as default };