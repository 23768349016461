import AnnotationsChannel from "./AnnotationsChannel.js";
class AnnotationsTrack extends AnnotationsChannel {
  constructor(wrapper, dataCallback, label, qualifiers) {
    super(wrapper, dataCallback);
    this.label = label;
    this.qualifiers = qualifiers;
  }
  get cache() {
    if (this.isReference) {
      return this.sv.annotations.getTracks(this.view.reference).find(track => track.label === this.label)._cache;
    } else {
      return this._cache;
    }
  }
}
export { AnnotationsTrack as default };