import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { NucleusDataResponseV2 } from '../../../nucleus/nucleus.model';
import { pluck } from 'rxjs/operators';
import { SequenceData } from '@geneious/sequence-viewer/types';

@Injectable({
  providedIn: 'root',
})
export class Ngs2Service {
  readonly MAX_SELECT_QUERY_SIZE = 100;

  private readonly ngsEndpoint: Function;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    protected http: HttpClient,
  ) {
    this.ngsEndpoint = (docID: string) =>
      `${this.config.nucleusApiBaseUrl}/api/ngs/v1/documents/${docID}`;
  }

  getSequenceViews(documentID: string, sequenceIndices: number[]): Observable<NGSSequence[]> {
    const body: NGS2GetSequenceViewRequest = { indices: sequenceIndices };
    return this.http
      .post<
        NucleusDataResponseV2<NGSSequence[]>
      >(`${this.ngsEndpoint(documentID)}/sequences/view`, body)
      .pipe(pluck('data'));
  }
}

export interface NGSSequence {
  data: SequenceData;
  index: number;
}

interface NGS2GetSequenceViewRequest {
  indices: number[];
}
