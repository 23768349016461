import Pen from "../../includes/RenderEngine/Pen.js";
import RenderNode from "../../includes/RenderEngine/RenderNode.js";
import RRange from "../../includes/Range/RRange.js";
import { centerStrokeCoordinateForLowDPIScreen } from "../../includes/misc/DPIRatio.js";
import DataChannel from "../../includes/PluginBaseClasses/DataChannel.js";
import { clamp } from "../../includes/misc/Math.js";
import { boundsOverlap } from "../../includes/misc/Utils.js";
const {
  floor,
  log10,
  max,
  min
} = Math;
class RulerChannel extends DataChannel {
  constructor(wrapper, isGlobal = false) {
    super(wrapper);
    this.isGlobal = isGlobal;
  }
  margin = 1;
  settings = {
    minInterval: 100,
    fontSize: 11,
    tickHeight: 4,
    // The margin above and below the label.
    labelMargin: 3,
    // The horizontal margin on the left and right of the label.
    margin: 2,
    scaleUnits: [1, 2, 2.5, 5, 10]
  };
  pen = new Pen(this.settings.fontSize, "center", "alphabetic");
  calculateLayout(context, bounds) {
    const node = new RenderNode("ruler channel", this, bounds);
    if (context.visible.length > 0) {
      node.children = this.visibleTickLayouts(context);
    }
    return node;
  }
  visibleTickLayouts(context) {
    const sorted = this.sortedTicks(context);
    const visible = [];
    this.sv.emit("alter ruler", visible, context, this.wrapper.type === "globals wrapper");
    for (const tick of sorted) {
      if (visible.every(other => !boundsOverlap(tick.bounds, other.bounds))) {
        visible.push(tick);
      }
    }
    return visible;
  }
  /**
   * Return the arrays in ascending order of importance. This determines which tick should hide in the event of an
   * overlap.
   * Ticks with higher priority (lower number) are more important, and among ticks with the same priority, ticks
   * that appear first (lower number) are more important.
   */
  sortedTicks(context) {
    const sort = (a, b) => a.priority - b.priority || a.ungapped - b.ungapped;
    return Array.from(this.ticksInVisibleUngappedRanges(context)).filter(tick => context.row.includes(tick.gapped)).sort(sort).map(tick => this.calculateTickLayout(context, tick));
  }
  *ticksInVisibleUngappedRanges(context) {
    const spacing = this.spacing(this.view.residueWidth);
    const visible = context.visible.grow(spacing, spacing);
    if (this.isGlobal) {
      const range = new RRange(0, this.view.length);
      const ungapped = {
        start: 0,
        range
      };
      yield* this.ticksInGappedRange(ungapped, visible, -1, spacing);
    } else if (this.rangesCache) {
      this.rangesCache.request(visible);
      const ungappedRanges = this.rangesCache.getInRange(visible);
      for (const ungapped of ungappedRanges) {
        const start = this.firstTickAfter(ungapped.range.start, -1, spacing);
        yield* this.ticksInGappedRange(ungapped, visible, start, spacing);
      }
    }
  }
  *ticksInGappedRange(ungapped, visible, start, spacing) {
    const tick = (gapped, priority) => ({
      gapped,
      ungapped: gapped - ungapped.range.start + ungapped.start,
      priority
    });
    const next = this.firstTickAfter(visible.start, start, spacing) - spacing;
    const minimum = max(start, next);
    const maximum = min(ungapped.range.end, visible.end + spacing);
    yield tick(ungapped.range.start, 1);
    for (let i = minimum; i < maximum; i += spacing) {
      if (i > ungapped.range.start) {
        yield tick(i, 3);
      }
    }
    yield tick(ungapped.range.last, 2);
  }
  calculateTickLayout(context, index) {
    const spacing = this.spacing(this.view.residueWidth);
    const label = this.formatLabel(index.ungapped + 1, spacing);
    const width = this.labelWidth(label) + this.settings.margin * 2;
    const x = this.view.toPixels(index.gapped - context.row.start + 0.5) - width / 2 - this.view.offset.x;
    const labelX = floor(this.adjustedX(width, x, context));
    const bounds = {
      x: labelX,
      y: 0,
      width,
      height: this.height
    };
    const node = new RenderNode("ruler tick", label, bounds);
    if (this.sv.view.circular && index.ungapped === 0) {
      return node;
    }
    node.setRenderCallback(brush => this.renderLabel(brush, label, width / 2, x - labelX));
    return node;
  }
  renderLabel(brush, label, center, tickOffset) {
    const {
      fontSize,
      labelMargin
    } = this.settings;
    const tickY = fontSize + labelMargin;
    const tickX = centerStrokeCoordinateForLowDPIScreen(center + tickOffset);
    this.graphics.drawLine(brush, {
      x: tickX,
      y: tickY
    }, {
      x: tickX,
      y: this.height
    }, "black", 1);
    brush.fillStyle = "black";
    this.pen.write(this.graphics, brush, label, center, fontSize);
  }
  /**
   * Gives the adjusted x position of a label, to keep it within bounds.
   * @param width - The width of the label.
   * @param x - The x position of the left edge of the label.
   * @param context {RenderContext}
   * @returns {number} The adjusted x position of the label.
   */
  adjustedX(width, x, context) {
    if (this.sv.view.circular) {
      return x;
    }
    const {
      offset,
      viewport
    } = this.view;
    const left = 0 - offset.x;
    const rightOfRow = context.width - offset.x - width;
    const rightOfView = viewport.width - width;
    return clamp(max(rightOfRow, rightOfView), x, left);
  }
  /**
   * Returns the number to display, correctly formatted, eg. displaying large number in units of Mbp.
   */
  formatLabel(label, spacing) {
    if (spacing >= 1e3 && label >= 1e6 && label % 1e3 == 0) {
      return (label / 1e6).toLocaleString() + " Mbp";
    } else {
      return label.toLocaleString();
    }
  }
  labelWidth(label) {
    return this.pen.measureWidth(label);
  }
  // Returns the index of the first evenly spaced tick after a given index.
  firstTickAfter(index, start, step) {
    return index + (step - (index - start) % step);
  }
  // Calculates the number of residues between each tick.
  spacing(residueWidth) {
    const {
      scaleUnits,
      minInterval
    } = this.settings;
    const orderOfMagnitude = floor(log10(minInterval / residueWidth));
    const scale = 10 ** orderOfMagnitude;
    const bestUnit = scaleUnits.find(x => x * scale * residueWidth >= minInterval) ?? 0;
    return max(10, bestUnit * scale);
  }
  get height() {
    const {
      fontSize,
      labelMargin,
      tickHeight
    } = this.settings;
    const normalHeight = fontSize + labelMargin + tickHeight;
    return this.isGlobal ? normalHeight + 5 : normalHeight;
  }
  get rangesCache() {
    const wrapper = this.wrapper;
    return wrapper.rangesCache;
  }
  get plugin() {
    return this.view.sv.ruler;
  }
  get visible() {
    if (!this.plugin) {
      return false;
    }
    const {
      enabled,
      global,
      allSequences
    } = this.plugin;
    return enabled && this.isGlobal ? global : allSequences;
  }
}
export { RulerChannel as default };