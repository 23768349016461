import { indexRange } from "../misc/Utils.js";
class CharCache {
  getAsDNA(index) {
    const char = this.get(index) ?? "";
    return char === "U" ? "T" : char;
  }
  isDefined(index) {
    return this.isSet(index) && this.get(index) !== "-";
  }
  rangeIsSet(range) {
    return range.every(index => this.isSet(index));
  }
  getRange(range) {
    return range.map(i => this.get(i));
  }
  getRangeAsDNA(range) {
    return range.map(i => this.getAsDNA(i));
  }
  requestIndex(index) {
    this.request(indexRange(index));
  }
}
export { CharCache as default };