import { adjustRange } from "../../includes/misc/DPIRatio.js";
import Layer from "../../includes/PluginBaseClasses/Layer.js";
import { clipBrush } from "../../includes/misc/Utils.js";
const {
  min
} = Math;
class PairedReadsLayer extends Layer {
  constructor(plugin) {
    super(plugin.sv.view);
    this.plugin = plugin;
    plugin.sv.bind("channel column render", node => this.render(node));
  }
  types = ["channels column", "wrapper channel wrapper", "wrapper channel", "sequence channelwrapper", "sequence channel"];
  paint(node) {
    clipBrush(this.graphics, this.brush, node.bounds);
    this._paint(node);
  }
  _paint(node) {
    if (!this.types.includes(node.type)) {
      return;
    }
    node.children.forEach(child => {
      child.translate(this.brush, () => {
        if (child.type === "sequence channel") {
          this.renderBoxAroundSelection(child);
        } else {
          this._paint(child);
        }
      });
    });
  }
  renderBoxAroundSelection(node) {
    const channel = node.reference;
    const row = node.rowRange;
    if (row && (this.sequence === channel || this.sequence && this.sequence.wrapper === this.plugin.findPair(node.parent?.parent))) {
      const entire = channel.wrapper.rangesCache.entireRange;
      const start = row.start ? 0 : entire.start;
      const end = min(row.end, entire.end) - row.start;
      const length = end - start;
      this.paintBox(start, length, node.bounds.height);
    }
  }
  paintBox(start, length, height) {
    const {
      x,
      width
    } = this.rangeToPixels(start, length);
    if (width !== 0) {
      this.brush.strokeStyle = "red";
      this.brush.strokeRect(x + 0.5, 1.5, width + 1, height - 3);
    }
  }
  rangeToPixels(start, length) {
    const x = this.toOffset(start);
    const width = this.channelView.toPixels(length);
    const range = adjustRange(x, width);
    return {
      x: range.start,
      width: range.length
    };
  }
  toOffset(index) {
    const viewOffset = this.channelView.offset.x;
    return this.channelView.toPixels(index) - viewOffset;
  }
  get channelView() {
    return this.plugin.sv.channelView;
  }
  get sequence() {
    return this.plugin.sequence;
  }
}
export { PairedReadsLayer as default };