import RulerChannel from "./RulerChannel.js";
import AbstractPlugin from "../../includes/PluginBaseClasses/AbstractPlugin.js";
class RulerPlugin extends AbstractPlugin {
  _enabled = false;
  _global = false;
  _allSequences = false;
  constructor(sv) {
    super(sv);
    sv.ruler = this;
    sv.registerChannelClass("ruler", RulerChannel);
    const options = sv.options?.ruler;
    if (options) {
      this._enabled = options.enabled;
      this._global = options.global;
      this._allSequences = options.allSequences;
    }
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(value) {
    this.setBooleanOption("_enabled", value);
    this.sv.emit("channel visibility changed");
  }
  get global() {
    return this._global;
  }
  set global(value) {
    this.setBooleanOption("_global", value);
    this.sv.emit("channel visibility changed");
  }
  get allSequences() {
    return this._allSequences;
  }
  set allSequences(value) {
    this.setBooleanOption("_allSequences", value);
    this.sv.emit("channel visibility changed");
  }
  serialize() {
    return {
      enabled: this.enabled,
      global: this.global,
      allSequences: this.allSequences
    };
  }
}
export { RulerPlugin as default };