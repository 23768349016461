import ambiguityMappers from "../../includes/AmbiguityMappers/AmbiguityMappers.js";
import AbstractCalculator from "../../includes/PluginBaseClasses/AbstractCalculator.js";
class IdentityCalculator extends AbstractCalculator {
  getAggregate(residues) {
    if (residues.length === 1) {
      return residues[0] === "-" ? 0 : 1;
    }
    const matches = /* @__PURE__ */new Map();
    residues.forEach(residue => {
      if (!matches.has(residue)) {
        matches.set(residue, 1);
      } else {
        matches.set(residue, matches.get(residue) + 1);
      }
    });
    return this.getMatches(matches);
  }
  getMatches(matches) {
    let matching = 0;
    let total = 0;
    matches.forEach((firstCount, firstResidue) => {
      matches.forEach((secondCount, secondResidue) => {
        if (firstResidue === secondResidue) {
          secondCount--;
        }
        if (!this.isIndelPair(firstResidue, secondResidue)) {
          const fraction = this.mapper.getMatchFraction(firstResidue, secondResidue);
          matching += fraction * firstCount * secondCount / 2;
          total += firstCount * secondCount / 2;
        }
      });
    });
    return matching ? matching / total : 0;
  }
  isIndelPair(first, second) {
    return first === "-" && second === "-";
  }
  get mapper() {
    return ambiguityMappers[this.type];
  }
}
export { IdentityCalculator as default };