import SidebarColumn from "../../includes/PluginBaseClasses/SidebarColumn";
import RenderNode from "../../includes/RenderEngine/RenderNode";
import SequenceWrapper from "../../includes/Channels/SequenceWrapper";
import { darken, roundRect } from "../../includes/misc/Utils";
import RulerChannel from "../Ruler/RulerChannel";
import ConsensusChannel from "../Consensus/ConsensusChannel";
import IdentityChannel from "../Identity/IdentityChannel";
import ReferenceWrapper from "../../includes/Channels/ReferenceWrapper";
import WuKabatChannel from "../WuKabat/WuKabatChannel";
import { GlobalsWrapper, WrapperChannel } from "../../index";
class CheckboxColumn extends SidebarColumn {
  constructor(plugin) {
    super(plugin.sv.view);
    this.plugin = plugin;
  }
  columnWidth = 25;
  checkboxSize = 14;
  backgroundColor = "#f6f6f6";
  highlightedColor = "#0175FF";
  grey = "#7f8d8d";
  checkboxYMargin = 5;
  borderColor = darken(this.backgroundColor, 25).toRgbString();
  get nodeType() {
    return "checkbox wrapper";
  }
  get weight() {
    return -1;
  }
  get width() {
    return this.columnWidth;
  }
  get enabled() {
    return this.plugin.enabled;
  }
  alterWrapperNode(node, channel) {
    const referenceMargin = node.reference instanceof WrapperChannel ? node.reference.margin : 0;
    if (node.reference instanceof GlobalsWrapper) {
      node.zIndex = 1;
    }
    node.children = channel ? this.checkboxNodes(channel) : [];
    node.setBackgroundCallback(() => this.renderBackground({
      width: this.columnWidth,
      height: node.bounds.height + referenceMargin
    }));
    return node;
  }
  checkboxNodes(wrapperChannel) {
    const result = [];
    const processChannel = (node, yOffset) => {
      const channel = node.reference;
      if (this.isSequenceWrapper(channel)) {
        result.push(this.checkboxNode(channel));
      } else if (this.isReferenceSequenceWrapper(channel)) {
        result.push(this.checkboxNode(channel, true));
      } else if (this.isGlobalRulerChannel(channel)) {
        result.push(this.selectAllCheckboxNode(channel));
      } else if (channel instanceof ConsensusChannel || channel instanceof IdentityChannel || channel instanceof WuKabatChannel) {
        result.push(this.emptyPlaceholderNode(channel, yOffset));
      }
    };
    processChannel(wrapperChannel, 0);
    wrapperChannel.children[0].children.forEach(child => {
      processChannel(child, child.bounds.y + wrapperChannel.children[0].bounds.y);
    });
    return result;
  }
  /**
   * Render a special checkbox that represent the selection status of all sequences.
   * If all sequences are selected, it will render a checked checkbox.
   * If some but not all sequences are selected, it will render a partially checked checkbox.
   * If no sequence is selected, it will render an unchecked checkbox.
   *
   * @param channel the ruler channel of the global wrapper.
   * @private
   */
  selectAllCheckboxNode(channel) {
    const node = new RenderNode("selectAll checkbox", channel, {
      x: this.width - this.checkboxSize - 3,
      y: this.checkboxYMargin,
      width: this.checkboxSize,
      height: this.checkboxSize
    });
    node.setRenderCallback(() => this.renderSelectAllCheckbox(node));
    return node;
  }
  /**
   * Render an empty space. This is mainly used by consensus channel & identity channel since they cannot be selected
   * and therefore do not have a checkbox rendered next to their label.
   * @param channel The channel to render the checkbox for.
   * @private
   */
  emptyPlaceholderNode(channel, yOffset) {
    const node = new RenderNode("placeholder", channel, {
      x: 0,
      y: yOffset,
      width: this.width,
      height: this.checkboxSize
    });
    node.setRenderCallback(() => this.renderBorders());
    return node;
  }
  /**
   * Render a regular checkbox for a wrapper channel (mainly for sequence wrapper & reference wrapper).
   * @param channel The channel to render the checkbox for.
   * @param disabled Indicate whether this checkbox should be displayed as disabled.
   * @private
   */
  checkboxNode(channel, disabled = false) {
    const extraPadding = 10;
    const container = new RenderNode("checkbox container", channel, {
      x: 0,
      y: 0,
      width: this.width,
      height: this.checkboxSize + extraPadding
    });
    const checkbox = new RenderNode("checkbox", channel, {
      x: container.bounds.width - this.checkboxSize - 3,
      y: this.checkboxYMargin,
      width: this.checkboxSize,
      height: this.checkboxSize
    });
    checkbox.setRenderCallback(() => this.renderCheckbox(checkbox, disabled));
    container.children = [checkbox];
    container.setRenderCallback(() => this.renderBorders());
    return container;
  }
  renderSelectAllCheckbox(node) {
    const numberOfSelectedSequences = this.plugin.selectedSequences.length;
    const isAllSequencesSelected = numberOfSelectedSequences === this.plugin.sv.channelView.sequences.length;
    const isNoSequenceSelected = numberOfSelectedSequences === 0;
    const brush = this.layer.brush;
    brush.beginPath();
    roundRect(brush, 0, 0, node.bounds.width, node.bounds.height, 2);
    brush.strokeStyle = this.borderColor;
    brush.lineWidth = 3;
    brush.stroke();
    if (isNoSequenceSelected) {
      brush.fillStyle = "white";
      brush.fill();
    } else if (isAllSequencesSelected) {
      brush.fillStyle = this.highlightedColor;
      brush.fill();
      this.renderCheckmark(0, 0, "white");
    } else {
      brush.fillStyle = this.grey;
      brush.fill();
      this.renderPartialSelectBar(0, 0, "white");
    }
  }
  renderCheckbox(node, disabled = false) {
    const channel = node.reference;
    const brush = this.layer.brush;
    brush.beginPath();
    roundRect(brush, 0, 0, node.bounds.width, node.bounds.height, 2);
    brush.strokeStyle = this.borderColor;
    brush.lineWidth = 3;
    brush.stroke();
    const isSequenceSelected = this.plugin.isSequenceSelected(channel);
    if (disabled) {
      brush.fillStyle = "#e3e3e3";
      brush.fill();
      return;
    }
    if (!isSequenceSelected) {
      brush.fillStyle = "white";
      brush.fill();
    } else {
      brush.fillStyle = this.highlightedColor;
      brush.fill();
      this.renderCheckmark(0, 0, "white");
    }
  }
  renderCheckmark(x, y, color) {
    const brush = this.layer.brush;
    const padding = 3;
    const leftEdge = x + padding;
    const topEdge = y + padding;
    const rightEdge = x + this.checkboxSize - padding;
    const bottomEdge = y + this.checkboxSize - padding;
    brush.beginPath();
    brush.moveTo(leftEdge, bottomEdge - 3);
    brush.lineTo(leftEdge + 3, bottomEdge);
    brush.lineTo(rightEdge, topEdge);
    brush.lineWidth = 2;
    brush.strokeStyle = color;
    brush.stroke();
  }
  renderPartialSelectBar(x, y, color) {
    const brush = this.layer.brush;
    const padding = 3;
    const barHeight = 2;
    const leftEdge = x + padding;
    const rightEdge = this.checkboxSize - padding;
    brush.fillStyle = color;
    brush.fillRect(leftEdge, y + (this.checkboxSize - barHeight) / 2, rightEdge - leftEdge, barHeight);
  }
  renderBorders() {
    const brush = this.layer.brush;
    brush.fillStyle = this.borderColor;
    brush.fillRect(0, 0, this.columnWidth, 1);
  }
  isGlobalRulerChannel(channel) {
    if (channel instanceof RulerChannel) {
      return channel.isGlobal;
    }
    return false;
  }
  isSequenceWrapper(channel) {
    return channel instanceof SequenceWrapper;
  }
  isReferenceSequenceWrapper(channel) {
    return channel instanceof ReferenceWrapper;
  }
  renderBackground({
    width,
    height
  }) {
    const brush = this.layer.brush;
    brush.fillStyle = this.backgroundColor;
    brush.fillRect(0, 0, width, height);
  }
}
export { CheckboxColumn as default };