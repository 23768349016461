import WrapperChannel from "./WrapperChannel.js";
import RRange from "../Range/RRange.js";
class SequenceWrapper extends WrapperChannel {
  constructor(view, options, originalIndex) {
    super(view, options.metadata);
    this.originalIndex = originalIndex;
    this.name = options.name;
    this._label.text = options.name;
    this.dataCallback = options.dataCallback;
    const registry = view.sv.channelClasses;
    const type = view.sv.options?.sequenceType;
    this.pairInformation = options.pairInformation;
    const rulerChannel = registry.get("ruler");
    if (rulerChannel) {
      this.rulerChannel = new rulerChannel(this, false);
    }
    const graphsChannel = registry.get("graphs");
    if (graphsChannel) {
      this.graphsChannel = new graphsChannel(this, options.graphs);
    }
    const sequenceChannel = registry.get("sequence");
    if (type && sequenceChannel) {
      this.sequenceChannel = new sequenceChannel(this, type);
    }
    const translationsChannel = registry.get("translations");
    if (translationsChannel) {
      this.translationsChannel = new translationsChannel(this);
    }
    const annotationsChannel = registry.get("annotations");
    if (annotationsChannel) {
      this.annotationsChannel = new annotationsChannel(this, options.dataCallback);
    }
    this.sv.emit("initialize sequence wrapper children", this, options);
    this.shouldFetchDataOnce = this.sv.options?.fetchDataOnce ?? false;
    this.sv.bind("idle", () => {
      if (!this.shouldFetchDataOnce) {
        this.sendRequests();
      }
    });
  }
  isReference = false;
  margin = 0;
  referenceColor = "#FDFCD9";
  pairInformation;
  rulerChannel;
  graphsChannel;
  sequenceChannel;
  translationsChannel;
  annotationsChannel;
  name;
  dataCallback;
  shouldFetchDataOnce;
  backgroundColor;
  dataFetched = false;
  caches = /* @__PURE__ */new Map();
  _label = {
    text: ""
  };
  get type() {
    return "sequence";
  }
  get isSticky() {
    return this.sv.sorting && this.sv.sorting.pinReference ? this.isReference : false;
  }
  get children() {
    const children = [];
    if (this.rulerChannel) {
      children.push(this.rulerChannel);
    }
    if (this.graphsChannel) {
      children.push(this.graphsChannel);
    }
    if (this.sequenceChannel) {
      children.push(this.sequenceChannel);
    }
    if (this.translationsChannel) {
      children.push(this.translationsChannel);
    }
    if (this.annotationsChannel) {
      children.push(this.annotationsChannel);
    }
    return children.concat(...this.sv.invoke("append sequence wrapper children", this));
  }
  get sequence() {
    return this.sequenceCache.wholeSequence;
  }
  get currentSequenceIndex() {
    return this.view.sequences.indexOf(this);
  }
  replaceResidues(range, newResidues) {
    const replacedResidues = this.sequenceCache.getRange(range).filter(char => !!char).join("");
    const residues = newResidues + this.sequence.slice(range.end);
    const sequenceLastIndexInclusive = this.sequence.length;
    this.cacheNewData(new RRange(range.start, sequenceLastIndexInclusive), {
      data: residues
    }, this.sequenceCache);
    return replacedResidues;
  }
  calculateLayout(context, bounds) {
    const node = super.calculateLayout(context, bounds);
    node.setBackgroundCallback(brush => this.renderBackground(brush, bounds));
    if (this.shouldFetchDataOnce && !this.dataFetched) {
      this.sv.bindOnce("idle", () => {
        this.requestAllData();
        this.dataFetched = true;
      });
    }
    return node;
  }
  renderBackground(brush, bounds) {
    brush.fillStyle = this.background;
    brush.fillRect(0, 0, bounds.width, bounds.height);
  }
  requestAllData() {
    const entire = this.rangesCache.entireRange;
    this.sequenceCache.request(entire);
    this.rangesCache.request(entire);
    this.qualitiesCache.request(entire);
    this.chromatogramCache.request(entire);
    this.sv.annotations?.requestInRangeForWrapper(this, entire);
    this.sv.emit("request all data", this);
    this.sendRequests();
  }
  sendRequests() {
    this.collectRequests().forEach(request => {
      this.sv.emit("data request started");
      this.dataCallback(request, data => this.handleResponse(request, data));
    });
    this.sv.emit("data request", this, this.dataCallback);
  }
  collectRequests() {
    const requests = [];
    this.caches.forEach((cache, name) => {
      cache.getPendingAndMarkRequested().ranges.forEach((range, index) => {
        requests[index] = requests[index] || {};
        requests[index][name] = range;
      });
    });
    return requests;
  }
  handleResponse(requests, response, cache) {
    const changed = this.processNewData(requests, response.data, cache);
    if (changed) {
      this.sv.view.dirty = "new data";
    }
    if (response.complete) {
      this.sv.emit("data request resolved");
    }
  }
  processNewData(requests, data, defaultCache) {
    let result = false;
    const types = Object.keys(defaultCache ? requests : data);
    for (const type of types) {
      const cache = defaultCache || this.getCache(type);
      if (cache) {
        const changed = this.cacheNewData(requests[type], data[type], cache);
        result = result || changed;
      }
    }
    return result;
  }
  getCache(name) {
    if (!this.caches.has(name)) {
      if (!this.sv.cacheClasses.has(name)) {
        return;
      }
      const dataCache = this.sv.cacheClasses.get(name);
      if (dataCache) {
        this.caches.set(name, new dataCache(this));
      }
    }
    return this.caches.get(name);
  }
  useQuality(index) {
    const value = this.qualitiesCache?.get(index);
    return this.colorScheme === "quality" && value != null && value >= 0;
  }
  get sequenceCache() {
    return this.getCache("sequence");
  }
  get rangesCache() {
    return this.getCache("keyTranspositionRanges");
  }
  // Won't include cache for annotation tracks on this wrapper
  get annotationsCache() {
    return this.annotationsChannel?.cache;
  }
  get qualitiesCache() {
    return this.getCache("qualities");
  }
  get chromatogramCache() {
    return this.getCache("chromatogram");
  }
  get label() {
    const index = this.view.sequences && this.currentSequenceIndex + 1;
    const label = {
      text: index ? `${index}. ${this._label.text}` : this._label.text
    };
    this.sv.emit("alter label", label, this);
    return label;
  }
  set label(raw) {
    this._label = raw;
  }
  get background() {
    if (this.backgroundColor) {
      return this.backgroundColor;
    }
    return this.isReference ? this.referenceColor : "white";
  }
  set background(color) {
    this.backgroundColor = color;
  }
}
export { SequenceWrapper as default };