class AbstractCalculator {
  constructor(cache) {
    this.cache = cache;
  }
  get(index) {
    if (!this.sequences.some(channel => channel.pendingAt(index))) {
      return this.calculate(index);
    }
  }
  calculate(index) {
    const sequences = this.getCandidateSequences(index);
    const residues = this.getCandidateResidues(index, sequences);
    return this.getAggregate(residues);
  }
  getCandidateSequences(index) {
    return this.sequences.filter(sequence => sequence.cache.isSet(index)).filter(sequence => !sequence.wrapper.isTrimmed(index));
  }
  getCandidateResidues(index, sequences) {
    return sequences.map(sequence => sequence.cache.getAsDNA(index));
  }
  isSet(index) {
    return this.sequences.some(sequence => sequence.cache.isSet(index));
  }
  get sequences() {
    return this.cache.sequences;
  }
  get type() {
    return this.cache.channel["sequenceType"];
  }
}
export { AbstractCalculator as default };