import FPSLogger from "./FPSLogger.js";
import FrameTimer from "./FrameTimer.js";
class DeveloperPlugin {
  constructor(sv) {
    this.sv = sv;
    this.measure = this.sv.options?.measure;
    this.logger = new FPSLogger(this);
    this.timer = new FrameTimer(this);
    sv.developer = this;
  }
  timer;
  measure;
  _stayAwake = false;
  logger;
  // Only declare the event handler function once, so that it can be unbound reliably.
  markDirty = () => this.sv.view.dirty = "frame rate logging";
  logFPS(seconds) {
    this.logger.setTimer(seconds);
  }
  set stayAwake(value) {
    this._stayAwake = value;
    if (!value) {
      this.sv.unbind("idle", this.markDirty);
    } else {
      this.sv.bind("idle", this.markDirty);
      this.markDirty();
    }
  }
}
export { DeveloperPlugin as default };