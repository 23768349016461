const {
  min,
  max,
  hypot,
  sign,
  log10,
  abs,
  floor,
  round
} = Math;
function sum(values) {
  if (!values || !values.length) {
    throw new Error("Can only sum non empty arrays.");
  }
  return values.reduce((previous, current) => current == null ? previous : previous + current, 0);
}
function mean(values) {
  const filtered = values.filter(x => x != null);
  return sum(filtered) / filtered.length;
}
function isNumeric(value) {
  if (value === null) {
    return false;
  } else if (typeof value === "string" && value.trim().length === 0) {
    return false;
  } else {
    return !isNaN(value);
  }
}
function reduce2Dto1D(xDelta, yDelta) {
  const direction = sign(xDelta + yDelta) || 1;
  const magnitude = hypot(xDelta, yDelta);
  return magnitude * direction;
}
function roundHalfDown(value) {
  return 0 - Math.round(0 - value);
}
function centerOffset(outer, inner) {
  if (outer < inner) {
    throw new Error("Outer length must not be less than inner length.");
  }
  return roundHalfDown((outer - inner) / 2);
}
function bottomAlignOffset(outer, inner) {
  if (outer < inner) {
    throw new Error("Outer length must not be less than inner length.");
  }
  return roundHalfDown(outer - inner);
}
function clamp(value, maximum, minimum = 0) {
  return max(minimum, min(maximum, value));
}
function getExponent(value) {
  return floor(log10(abs(value)));
}
function exponentExceeds(value, lower, upper) {
  if (value === 0) {
    return false;
  }
  const exponent = getExponent(value);
  return exponent <= lower || exponent >= upper;
}
function roundToSF(value, sf) {
  const result = Number(toExponentialNotation(value, sf));
  return {
    value: result,
    changed: value !== result
  };
}
function toExponentialNotation(value, sf) {
  if (sf < 1) {
    throw new Error("Significant figure must be 1 or greater.");
  }
  if (value == null || sf == null) {
    throw new Error("Inputs must not be null or undefined.");
  }
  if (isNaN(value) || isNaN(sf)) {
    throw new Error("Inputs must be numbers.");
  }
  if (!isFinite(value)) {
    throw new Error("Inputs must be finite.");
  }
  if (value === 0) {
    return "0";
  }
  const exponent = getExponent(value);
  const product = 10 ** exponent;
  const decimal = 10 ** (sf - 1);
  const x = round(value * decimal / product) / decimal;
  return `${x}e${exponent}`;
}
function formatToSF(unformatted, sf) {
  const {
    value,
    changed
  } = roundToSF(unformatted, sf);
  const formatted = value.toLocaleString(void 0, {
    useGrouping: true,
    maximumFractionDigits: 20
  });
  if (changed === true) {
    const count = sf - getPrecision(value);
    if (count > 0) {
      const zeroes = "0".repeat(count);
      return Number.isInteger(value) ? `${formatted}.${zeroes}` : formatted + zeroes;
    }
  }
  return formatted;
}
function getPrecision(value) {
  const integer = floor(abs(value));
  const decimal = abs(value % 1);
  if (integer) {
    return abs(value).toString().length - (decimal ? 1 : 0);
  } else {
    const count = 0 - getExponent(decimal);
    return decimal.toString().length - count - 1;
  }
}
export { bottomAlignOffset, centerOffset, clamp, exponentExceeds, formatToSF, getExponent, getPrecision, isNumeric, mean, reduce2Dto1D, roundHalfDown, roundToSF, sum, toExponentialNotation };