import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { IGridResource } from '../../../features/grid/datasource/grid.resource';
import { map, switchMap } from 'rxjs/operators';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { SortModel } from '../../../features/grid/grid.interfaces';

@Injectable({
  providedIn: 'root',
})
export class FetchService {
  LIMIT = 1000;

  // Due to our code already using a GridResource in this function, the type will be enforced until it is refactored,
  // so we can avoid potential bugs and confusion.
  // TODO Refactor code to NOT pass in IGridResource Classes so this isn't reliant on IGridResource type of resources.
  getAll(resource: IGridResource, optionalParams: any = {}, sortModel: SortModel[] = []) {
    const requests: Observable<IGridResourceResponse<any>>[] = [];
    const rows: any[] = [];
    const initialRequest = this.getPage(resource, rows, sortModel, optionalParams);

    return initialRequest.pipe(
      switchMap((response) => {
        // Cache Initial request response.
        requests.push(of(response));

        // If there's a response handle it. Otherwise ignore; no action required.
        if (response.metadata) {
          // Get all pages in parallel to reduce time until popup is usable.
          for (let offset = this.LIMIT; offset < response.metadata.total; offset += this.LIMIT) {
            const moreRows = this.getPage(resource, rows, sortModel, optionalParams, offset);
            requests.push(moreRows);
          }
        }
        return forkJoin(requests).pipe(map(() => ({ columns: response.columns, rows })));
      }),
    );
  }

  getPage(
    resource: IGridResource,
    rows: any[],
    sortModel: SortModel[],
    optionalParams: any,
    offset = 0,
  ): Observable<any> {
    optionalParams = optionalParams || {};

    const gridRequestParams: any = {
      startRow: offset,
      endRow: offset + this.LIMIT,
      rowGroupCols: undefined,
      sortModel: sortModel,
      valueCols: undefined,
      filterModel: undefined,
      groupKeys: undefined,
    };
    return resource.query(gridRequestParams, optionalParams).pipe(
      map((response) => {
        this._appendRows(response, rows, offset);
        return response;
      }),
    );
  }

  _appendRows(response: any, rows: any[], offset: number) {
    response.data.forEach((row: any, index: number) => {
      rows[offset + index] = row;
    });
  }
}
