import { reduce2Dto1D } from "../includes/misc/Math.js";
class ZoomPlugin {
  sv;
  zoomFactor = 200;
  constructor(sv) {
    this.sv = sv;
    sv.zoom = this;
    sv.bind("wheel", e => this.onScroll(e));
  }
  onScroll(event) {
    if (!event.altKey) {
      return;
    }
    const delta = reduce2Dto1D(event.deltaX, event.deltaY);
    this.sv.zoomBy(delta / this.zoomFactor);
    event.preventDefault();
  }
}
export { ZoomPlugin as default };